/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { DataDecorator } from '@dpa/ui-common';

import { DeemVirtualMgtComponentType } from '@ws1c/deem-solution/models/deem-virtual-v2/deem-virtual-mgt-component-type.enum';
import { DeemVirtualMgtComponentsWidgetProps } from '@ws1c/deem-solution/models/deem-virtual-v2/deem-virtual-mgt-components-widget-props.interface';
import { MgtCompDetailMetaData } from '@ws1c/deem-solution/models/deem-virtual-v2/mgt-comp-detail-props.interface';
import { COLUMN_NAMES, Entity, NgxTrendResultFlattener, StandardWidgetSubtype, ThresholdAttribute } from '@ws1c/intelligence-models';

/**
 * DeemHorizonInfrastructureConfig
 * @export
 * @class DeemHorizonInfrastructureConfig
 */
export class DeemHorizonInfrastructureConfig {
  public static readonly tableColumnLabelsByComponentType = {
    [DeemVirtualMgtComponentType.CONNECTION_SERVER]: {
      [NgxTrendResultFlattener.getCounterKey(5)]: 'DEEM_VIRTUAL_SUMMARY.CONNECTION_SERVER_NAME',
      [NgxTrendResultFlattener.getCounterKey(6)]: 'DEEM_VIRTUAL_SUMMARY.BUILD_NUMBER',
      [COLUMN_NAMES.byName.overall_score]: 'DEEM_VIRTUAL_SUMMARY.OVERALL_SCORE',
      [COLUMN_NAMES.byName.status_score]: 'DEEM_VIRTUAL_SUMMARY.CONNECTION_SERVER_STATUS',
      [COLUMN_NAMES.byName.cpu_utilization_score]: 'DEEM_VIRTUAL_SUMMARY.CPU_USAGE',
      [COLUMN_NAMES.byName.memory_utilization_score]: 'DEEM_VIRTUAL_SUMMARY.MEMORY_USAGE',
      [COLUMN_NAMES.byName.num_sessions_score]: 'DEEM_COMMON_MESSAGES.SESSIONS',
    },
    [DeemVirtualMgtComponentType.UAG_APPLIANCE]: {
      [NgxTrendResultFlattener.getCounterKey(4)]: 'DEEM_VIRTUAL_SUMMARY.UAG_APPLIANCE_NAME',
      [NgxTrendResultFlattener.getCounterKey(5)]: 'DEEM_VIRTUAL_SUMMARY.FQDN',
      [NgxTrendResultFlattener.getCounterKey(6)]: 'DEEM_COMMON_MESSAGES.TYPE',
      [COLUMN_NAMES.byName.overall_score]: 'DEEM_VIRTUAL_SUMMARY.OVERALL_SCORE',
      [COLUMN_NAMES.byName.connection_count_score]: 'STANDARD_DASHBOARD.HORIZON.CONNECTIONS',
      [COLUMN_NAMES.byName.cpu_utilization_score]: 'DEEM_VIRTUAL_SUMMARY.CPU_USAGE',
      [COLUMN_NAMES.byName.memory_utilization_score]: 'DEEM_VIRTUAL_SUMMARY.MEMORY_USAGE',
    },
  };

  public static readonly tabsConfigByComponentType: Record<DeemVirtualMgtComponentType, DeemVirtualMgtComponentsWidgetProps> = {
    [DeemVirtualMgtComponentType.CONNECTION_SERVER]: {
      visibleWidgetSubtypes: [
        StandardWidgetSubtype.CONNECTION_SERVER_MAX_CPU_PERCENTAGE,
        StandardWidgetSubtype.CONNECTION_SERVER_MAX_MEMORY_UTILIZATION_PERCENTAGE,
        StandardWidgetSubtype.CONNECTION_SERVER_MAX_NUM_SESSIONS,
        StandardWidgetSubtype.CONNECTION_SERVER_PERFORMANCE_CPU_PERCENTAGE,
        StandardWidgetSubtype.CONNECTION_SERVER_PERFORMANCE_MEMORY_UTILIZATION_PERCENTAGE,
        StandardWidgetSubtype.CONNECTION_SERVER_PERFORMANCE_NUM_SESSIONS,
        StandardWidgetSubtype._CONNECTION_SERVER_LIST_BY_OVERALL_STATUS_CPU_MEMORY_NUMSESSION_SCORE,
      ],
      configs: [
        {
          label: 'DEEM_VIRTUAL_SUMMARY.CPU',
          maxWidgetId: StandardWidgetSubtype.CONNECTION_SERVER_MAX_CPU_PERCENTAGE,
          isAvgPercent: true,
          performanceWidgetId: StandardWidgetSubtype.CONNECTION_SERVER_PERFORMANCE_CPU_PERCENTAGE,
          tableWidgetId: StandardWidgetSubtype._CONNECTION_SERVER_LIST_BY_OVERALL_STATUS_CPU_MEMORY_NUMSESSION_SCORE,
          tableColumnNames: [
            NgxTrendResultFlattener.getCounterKey(5),
            NgxTrendResultFlattener.getCounterKey(6),
            COLUMN_NAMES.byName.overall_score,
            COLUMN_NAMES.byName.status_score,
            COLUMN_NAMES.byName.cpu_utilization_score,
            COLUMN_NAMES.byName.memory_utilization_score,
            COLUMN_NAMES.byName.num_sessions_score,
          ],
        },
        {
          label: 'DEEM_VIRTUAL_SUMMARY.MEMORY',
          maxWidgetId: StandardWidgetSubtype.CONNECTION_SERVER_MAX_MEMORY_UTILIZATION_PERCENTAGE,
          isAvgPercent: true,
          performanceWidgetId: StandardWidgetSubtype.CONNECTION_SERVER_PERFORMANCE_MEMORY_UTILIZATION_PERCENTAGE,
          tableWidgetId: StandardWidgetSubtype._CONNECTION_SERVER_LIST_BY_OVERALL_STATUS_CPU_MEMORY_NUMSESSION_SCORE,
          tableColumnNames: [
            NgxTrendResultFlattener.getCounterKey(5),
            NgxTrendResultFlattener.getCounterKey(6),
            COLUMN_NAMES.byName.overall_score,
            COLUMN_NAMES.byName.status_score,
            COLUMN_NAMES.byName.memory_utilization_score,
            COLUMN_NAMES.byName.cpu_utilization_score,
            COLUMN_NAMES.byName.num_sessions_score,
          ],
        },
        {
          label: 'DEEM_COMMON_MESSAGES.SESSIONS',
          maxWidgetId: StandardWidgetSubtype.CONNECTION_SERVER_MAX_NUM_SESSIONS,
          isAvgPercent: false,
          performanceWidgetId: StandardWidgetSubtype.CONNECTION_SERVER_PERFORMANCE_NUM_SESSIONS,
          tableWidgetId: StandardWidgetSubtype._CONNECTION_SERVER_LIST_BY_OVERALL_STATUS_CPU_MEMORY_NUMSESSION_SCORE,
          tableColumnNames: [
            NgxTrendResultFlattener.getCounterKey(5),
            NgxTrendResultFlattener.getCounterKey(6),
            COLUMN_NAMES.byName.overall_score,
            COLUMN_NAMES.byName.status_score,
            COLUMN_NAMES.byName.num_sessions_score,
            COLUMN_NAMES.byName.cpu_utilization_score,
            COLUMN_NAMES.byName.memory_utilization_score,
          ],
        },
      ],
    },
    [DeemVirtualMgtComponentType.UAG_APPLIANCE]: {
      visibleWidgetSubtypes: [
        StandardWidgetSubtype.UAG_APPLIANCE_MAX_CPU_PCT,
        StandardWidgetSubtype.UAG_APPLIANCE_MAX_MEMORY_PCT,
        StandardWidgetSubtype.UAG_APPLIANCE_MAX_CONNECTION_COUNT,
        StandardWidgetSubtype.UAG_APPLIANCE_PERFORMANCE_CPU_PCT,
        StandardWidgetSubtype.UAG_APPLIANCE_PERFORMANCE_MEMORY_PCT,
        StandardWidgetSubtype.UAG_APPLIANCE_PERFORMANCE_CONNECTION_COUNT,
        StandardWidgetSubtype._UAG_APPLIANCE_LIST_BY_OVERALL_CONNECTIONCOUNT_CPU_MEMORY_SCORE,
      ],
      configs: [
        {
          label: 'DEEM_VIRTUAL_SUMMARY.CPU',
          maxWidgetId: StandardWidgetSubtype.UAG_APPLIANCE_MAX_CPU_PCT,
          isAvgPercent: true,
          performanceWidgetId: StandardWidgetSubtype.UAG_APPLIANCE_PERFORMANCE_CPU_PCT,
          tableWidgetId: StandardWidgetSubtype._UAG_APPLIANCE_LIST_BY_OVERALL_CONNECTIONCOUNT_CPU_MEMORY_SCORE,
          tableColumnNames: [
            NgxTrendResultFlattener.getCounterKey(4),
            NgxTrendResultFlattener.getCounterKey(5),
            NgxTrendResultFlattener.getCounterKey(6),
            COLUMN_NAMES.byName.overall_score,
            COLUMN_NAMES.byName.cpu_utilization_score,
            COLUMN_NAMES.byName.memory_utilization_score,
            COLUMN_NAMES.byName.connection_count_score,
          ],
        },
        {
          label: 'DEEM_VIRTUAL_SUMMARY.MEMORY',
          maxWidgetId: StandardWidgetSubtype.UAG_APPLIANCE_MAX_MEMORY_PCT,
          isAvgPercent: true,
          performanceWidgetId: StandardWidgetSubtype.UAG_APPLIANCE_PERFORMANCE_MEMORY_PCT,
          tableWidgetId: StandardWidgetSubtype._UAG_APPLIANCE_LIST_BY_OVERALL_CONNECTIONCOUNT_CPU_MEMORY_SCORE,
          tableColumnNames: [
            NgxTrendResultFlattener.getCounterKey(4),
            NgxTrendResultFlattener.getCounterKey(5),
            NgxTrendResultFlattener.getCounterKey(6),
            COLUMN_NAMES.byName.overall_score,
            COLUMN_NAMES.byName.memory_utilization_score,
            COLUMN_NAMES.byName.cpu_utilization_score,
            COLUMN_NAMES.byName.connection_count_score,
          ],
        },
        {
          label: 'STANDARD_DASHBOARD.HORIZON.CONNECTIONS',
          maxWidgetId: StandardWidgetSubtype.UAG_APPLIANCE_MAX_CONNECTION_COUNT,
          isAvgPercent: false,
          performanceWidgetId: StandardWidgetSubtype.UAG_APPLIANCE_PERFORMANCE_CONNECTION_COUNT,
          tableWidgetId: StandardWidgetSubtype._UAG_APPLIANCE_LIST_BY_OVERALL_CONNECTIONCOUNT_CPU_MEMORY_SCORE,
          tableColumnNames: [
            NgxTrendResultFlattener.getCounterKey(4),
            NgxTrendResultFlattener.getCounterKey(5),
            NgxTrendResultFlattener.getCounterKey(6),
            COLUMN_NAMES.byName.overall_score,
            COLUMN_NAMES.byName.connection_count_score,
            COLUMN_NAMES.byName.cpu_utilization_score,
            COLUMN_NAMES.byName.memory_utilization_score,
          ],
        },
      ],
    },
  };

  public static readonly thresholdAttributeToEntityMapping: Record<string, Entity> = {
    [ThresholdAttribute.MEMORY_USAGE_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER]: Entity.HORIZON_EXPERIENCESCORE_CONNECTION_SERVER,
    [ThresholdAttribute.DAYS_BEFORE_CERTIFICATE_EXPIRATION_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER]:
      Entity.HORIZON_EXPERIENCESCORE_CONNECTION_SERVER,
    [ThresholdAttribute.CPU_USAGE_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER]: Entity.HORIZON_EXPERIENCESCORE_CONNECTION_SERVER,
    [ThresholdAttribute.SESSION_COUNT_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER]: Entity.HORIZON_EXPERIENCESCORE_CONNECTION_SERVER,
    [ThresholdAttribute.STATUS_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER]: Entity.HORIZON_EXPERIENCESCORE_CONNECTION_SERVER,
    [ThresholdAttribute.DAYS_BEFORE_CERTIFICATE_EXPIRATION_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE]:
      Entity.HORIZON_EXPERIENCESCORE_UAG_APPLIANCE,
    [ThresholdAttribute.CPU_USAGE_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE]: Entity.HORIZON_EXPERIENCESCORE_UAG_APPLIANCE,
    [ThresholdAttribute.MEMORY_USAGE_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE]: Entity.HORIZON_EXPERIENCESCORE_UAG_APPLIANCE,
    [ThresholdAttribute.CONNECTION_COUNT_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE]: Entity.HORIZON_EXPERIENCESCORE_UAG_APPLIANCE,
    [ThresholdAttribute.STATUS_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE]: Entity.HORIZON_EXPERIENCESCORE_UAG_APPLIANCE,
  };

  public static readonly entityToMgtCompConfigOnContributorPage: Record<string, Record<string, string>> = {
    [Entity.HORIZON_EXPERIENCESCORE_CONNECTION_SERVER]: {
      mgtCompType: DeemVirtualMgtComponentType.CONNECTION_SERVER,
      mgtCompNameKey: NgxTrendResultFlattener.getCounterKey(1),
      mgtCompIdKey: COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_connection_server_connection_server_id,
    },
    [Entity.HORIZON_EXPERIENCESCORE_UAG_APPLIANCE]: {
      mgtCompType: DeemVirtualMgtComponentType.UAG_APPLIANCE,
      mgtCompNameKey: NgxTrendResultFlattener.getCounterKey(1),
      mgtCompIdKey: COLUMN_NAMES.byFullyQualifiedName.internal_horizon_experiencescore_uag_appliance_uag_appliance_id,
    },
  };

  public static readonly metaDataByMgtCompType: Record<DeemVirtualMgtComponentType, MgtCompDetailMetaData[]> = {
    [DeemVirtualMgtComponentType.CONNECTION_SERVER]: [
      {
        label: 'DEEM_VIRTUAL_SUMMARY.CERTIFICATION_EXPIRATION',
        widgetId: StandardWidgetSubtype.CONNECTION_SERVER_DETAIL_CERT_BUILDNUM_STATUS_IP_META,
        counterIndex: 0,
        valueDecorator: DataDecorator.DATETIME,
      },
      {
        label: 'DEEM_VIRTUAL_SUMMARY.BUILD_NUMBER',
        widgetId: StandardWidgetSubtype.CONNECTION_SERVER_DETAIL_CERT_BUILDNUM_STATUS_IP_META,
        counterIndex: 2,
        valueDecorator: DataDecorator.STRING,
      },
      {
        label: 'DEEM_VIRTUAL_SUMMARY.CERTIFICATION_EXPIRATION_STATUS',
        widgetId: StandardWidgetSubtype.CONNECTION_SERVER_DETAIL_CERT_BUILDNUM_STATUS_IP_META,
        counterIndex: 1,
        valueDecorator: DataDecorator.STRING,
      },
      {
        label: 'DEEM_VIRTUAL_SUMMARY.IP',
        widgetId: StandardWidgetSubtype.CONNECTION_SERVER_DETAIL_CERT_BUILDNUM_STATUS_IP_META,
        counterIndex: 4,
        valueDecorator: DataDecorator.STRING,
      },
      {
        label: 'DEEM_VIRTUAL_SUMMARY.EDGE_ID',
        widgetId: StandardWidgetSubtype.CONNECTION_SERVER_DETAIL_SESSIONS_CPU_EDGE_META,
        counterIndex: 2,
        valueDecorator: DataDecorator.STRING,
      },
      {
        label: 'DEEM_VIRTUAL_SUMMARY.SESSION_COUNT',
        widgetId: StandardWidgetSubtype.CONNECTION_SERVER_DETAIL_SESSIONS_CPU_EDGE_META,
        counterIndex: 0,
        valueDecorator: DataDecorator.INTEGER,
      },
      {
        label: 'DEEM_VIRTUAL_SUMMARY.CURRENT_CPU',
        widgetId: StandardWidgetSubtype.CONNECTION_SERVER_DETAIL_SESSIONS_CPU_EDGE_META,
        counterIndex: 1,
        valueDecorator: DataDecorator.STRING_PERCENT,
      },
      {
        label: 'COMMON_MESSAGES.STATUS',
        widgetId: StandardWidgetSubtype.CONNECTION_SERVER_DETAIL_CERT_BUILDNUM_STATUS_IP_META,
        counterIndex: 3,
        valueDecorator: DataDecorator.STRING,
      },
    ],
    [DeemVirtualMgtComponentType.UAG_APPLIANCE]: [
      {
        label: 'COMMON_MESSAGES.STATUS',
        widgetId: StandardWidgetSubtype.UAG_APPLIANCE_DETAIL_CONNECTIONCOUNT_STATUS_CERTEND_METADATA,
        counterIndex: 1,
        valueDecorator: DataDecorator.STRING,
      },
      {
        label: 'DEEM_VIRTUAL_SUMMARY.FQDN',
        widgetId: StandardWidgetSubtype.UAG_APPLIANCE_DETAIL_FQDN_METADATA,
        counterIndex: 0,
        valueDecorator: DataDecorator.STRING,
        isBucketing: true,
      },
      {
        label: 'DEEM_VIRTUAL_SUMMARY.CONNECTION_COUNT',
        widgetId: StandardWidgetSubtype.UAG_APPLIANCE_DETAIL_CONNECTIONCOUNT_STATUS_CERTEND_METADATA,
        counterIndex: 0,
        valueDecorator: DataDecorator.INTEGER,
      },
      {
        label: 'DEEM_VIRTUAL_SUMMARY.CERTIFICATION_EXPIRATION',
        widgetId: StandardWidgetSubtype.UAG_APPLIANCE_DETAIL_CONNECTIONCOUNT_STATUS_CERTEND_METADATA,
        counterIndex: 2,
        valueDecorator: DataDecorator.DATETIME,
      },
    ],
  };

  public static readonly avgMaxColors = [
    {
      name: 'Avg',
      value: '#fac400',
    },
    {
      name: 'Max',
      value: '#f54f47',
    },
  ];

  public static readonly EDGE_HORIZON_ENTERPRISE_TYPE = 'Horizon Enterprise';
}
