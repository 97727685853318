/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import { getDeviceEventsRequest } from '@dpa-shared-merlot/store/dashboard/devices/devices-dashboard-reducer-helpers';
import { PreviewReportContentRequest } from '@ws1c/intelligence-models';
import { DeviceProfileActions } from './device-profile.actions';
import { DeviceProfileState, initialDeviceProfileState } from './device-profile.store';

const _reducer: ActionReducer<DeviceProfileState, Action> = createReducer(
  initialDeviceProfileState,
  on(
    DeviceProfileActions.initDevice,
    DeviceProfileActions.initDeviceSuccess,
    (state: DeviceProfileState, { device }: ReturnType<typeof DeviceProfileActions.initDevice>): DeviceProfileState => ({
      ...state,
      device,
    }),
  ),

  on(
    DeviceProfileActions.loadDeviceRiskScore,
    (state: DeviceProfileState, { deviceId }: ReturnType<typeof DeviceProfileActions.loadDeviceRiskScore>): DeviceProfileState => ({
      ...state,
      id: deviceId,
      riskScore: undefined,
    }),
  ),

  on(
    DeviceProfileActions.loadDeviceRiskScoreSuccess,
    (state: DeviceProfileState, { riskScore }: ReturnType<typeof DeviceProfileActions.loadDeviceRiskScoreSuccess>): DeviceProfileState => ({
      ...state,
      riskScore,
    }),
  ),

  on(
    DeviceProfileActions.loadDeviceHealthStatus,
    (state: DeviceProfileState, { deviceId }: ReturnType<typeof DeviceProfileActions.loadDeviceHealthStatus>): DeviceProfileState => ({
      ...state,
      deviceHealth: undefined,
      id: deviceId,
    }),
  ),

  on(
    DeviceProfileActions.loadDeviceHealthStatusSuccess,
    (
      state: DeviceProfileState,
      { deviceHealth }: ReturnType<typeof DeviceProfileActions.loadDeviceHealthStatusSuccess>,
    ): DeviceProfileState => ({
      ...state,
      deviceHealth,
    }),
  ),

  on(
    DeviceProfileActions.loadDeviceHealth,
    (state: DeviceProfileState, { deviceId }: ReturnType<typeof DeviceProfileActions.loadDeviceHealth>): DeviceProfileState => ({
      ...state,
      deviceHealthResponse: {
        [deviceId]: undefined,
      },
    }),
  ),

  on(
    DeviceProfileActions.loadDeviceHealthSuccess,
    (
      state: DeviceProfileState,
      { deviceId, response }: ReturnType<typeof DeviceProfileActions.loadDeviceHealthSuccess>,
    ): DeviceProfileState => ({
      ...state,
      deviceHealthResponse: {
        [deviceId]: response,
      },
    }),
  ),

  on(
    DeviceProfileActions.setDeviceProfileFilters,
    (
      state: DeviceProfileState,
      { ruleGroup, trendDateRange }: ReturnType<typeof DeviceProfileActions.setDeviceProfileFilters>,
    ): DeviceProfileState => ({
      ...state,
      filterRuleGroup: ruleGroup,
      trendDateRange,
    }),
  ),

  on(
    DeviceProfileActions.loadDeviceHealthThresholdsV2Success,
    (
      state: DeviceProfileState,
      { data }: ReturnType<typeof DeviceProfileActions.loadDeviceHealthThresholdsV2Success>,
    ): DeviceProfileState => ({
      ...state,
      thresholdsV2: data,
    }),
  ),

  on(
    DeviceProfileActions.loadDeviceEdiDataSuccess,
    (state: DeviceProfileState, { response }: ReturnType<typeof DeviceProfileActions.loadDeviceEdiDataSuccess>): DeviceProfileState => ({
      ...state,
      ediResponse: response,
    }),
  ),

  on(
    DeviceProfileActions.loadDeviceProperties,
    (state: DeviceProfileState): DeviceProfileState => ({
      ...state,
      propertiesResponse: undefined,
    }),
  ),

  on(
    DeviceProfileActions.loadDevicePropertiesSuccess,
    (state: DeviceProfileState, { response }: ReturnType<typeof DeviceProfileActions.loadDevicePropertiesSuccess>): DeviceProfileState => ({
      ...state,
      propertiesResponse: response,
    }),
  ),

  on(
    DeviceProfileActions.loadUserProperties,
    (state: DeviceProfileState): DeviceProfileState => ({
      ...state,
      userPropertiesResponse: undefined,
    }),
  ),

  on(
    DeviceProfileActions.loadUserPropertiesSuccess,
    (state: DeviceProfileState, { response }: ReturnType<typeof DeviceProfileActions.loadUserPropertiesSuccess>): DeviceProfileState => ({
      ...state,
      userPropertiesResponse: response,
    }),
  ),

  on(
    DeviceProfileActions.loadDeviceEvents,
    (
      state: DeviceProfileState,
      { id, entity, event, startDateMillis, endDateMillis }: ReturnType<typeof DeviceProfileActions.loadDeviceEvents>,
    ): DeviceProfileState => {
      const request = getDeviceEventsRequest({
        id,
        entity,
        event,
        startDateMillis,
        endDateMillis,
        request: {
          ...state.deviceEventsRequest,
          offset: 0,
          pageSize: 10,
        },
      });
      return {
        ...state,
        deviceEventsResponse: undefined,
        deviceEventsRequest: request,
      };
    },
  ),

  on(
    DeviceProfileActions.loadDeviceEventsSuccess,
    (
      state: DeviceProfileState,
      { request, response }: ReturnType<typeof DeviceProfileActions.loadDeviceEventsSuccess>,
    ): DeviceProfileState => ({
      ...state,
      deviceEventsRequest: request,
      deviceEventsResponse: response,
    }),
  ),

  on(
    DeviceProfileActions.loadDeviceEventsFailure,
    (state: DeviceProfileState): DeviceProfileState => ({
      ...state,
      deviceEventsResponse: undefined,
    }),
  ),

  on(
    DeviceProfileActions.setloadDeviceEventsPagedRequest,
    (
      state: DeviceProfileState,
      { pagedRequest }: ReturnType<typeof DeviceProfileActions.setloadDeviceEventsPagedRequest>,
    ): DeviceProfileState => ({
      ...state,
      deviceEventsRequest: new PreviewReportContentRequest({
        ...state.deviceEventsRequest,
        offset: pagedRequest.from,
        pageSize: pagedRequest.size,
      }),
    }),
  ),

  on(
    DeviceProfileActions.loadZoomSummaryData,
    (state: DeviceProfileState, { request }: ReturnType<typeof DeviceProfileActions.loadZoomSummaryData>): DeviceProfileState => ({
      ...state,
      performance: {
        ...state.performance,
        zoomSummaryRequest: request,
      },
    }),
  ),

  on(
    DeviceProfileActions.loadZoomSummaryDataFailure,
    (state: DeviceProfileState): DeviceProfileState => ({
      ...state,
      performance: {
        ...state.performance,
        zoomSummaryResponse: undefined,
      },
    }),
  ),

  on(
    DeviceProfileActions.loadZoomSummaryDataSuccess,
    (state: DeviceProfileState, { response }: ReturnType<typeof DeviceProfileActions.loadZoomSummaryDataSuccess>): DeviceProfileState => ({
      ...state,
      performance: {
        ...state.performance,
        zoomSummaryResponse: response,
      },
    }),
  ),

  on(
    DeviceProfileActions.loadDeviceCrumbListSuccess,
    (
      state: DeviceProfileState,
      { crumbListLocator, responses }: ReturnType<typeof DeviceProfileActions.loadDeviceCrumbListSuccess>,
    ): DeviceProfileState => ({
      ...state,
      deviceCrumbListsIndex: crumbListLocator.setCrumbResponses(state.deviceCrumbListsIndex, responses),
    }),
  ),

  on(
    DeviceProfileActions.saveDeviceProfilePerformance,
    (
      state: DeviceProfileState,
      { id, subType, trend }: ReturnType<typeof DeviceProfileActions.saveDeviceProfilePerformance>,
    ): DeviceProfileState => {
      return {
        ...state,
        performance: {
          ...state.performance,
          [subType]: {
            ...state.performance[subType],
            [id]: trend,
          },
        },
      };
    },
  ),

  on(
    DeviceProfileActions.showDeviceProfileSlideOver,
    (state: DeviceProfileState): DeviceProfileState => ({
      ...state,
      isSlideOverVisible: true,
    }),
  ),

  on(
    DeviceProfileActions.hideDeviceProfileSlideOver,
    (state: DeviceProfileState): DeviceProfileState => ({
      ...state,
      isSlideOverVisible: false,
    }),
  ),
);

/**
 * Creates and returns DeviceProfileState
 * @export
 * @param {DeviceProfileState} state - Holds state object of type DeviceProfileState
 * @param {Action} action - Holds the action which needs to be invoked in reducer
 * @returns {DeviceProfileState}
 */
export function deviceProfileState(state: DeviceProfileState, action: Action): DeviceProfileState {
  return _reducer(state, action);
}
