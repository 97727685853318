/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * DeemDashboardType
 * @export
 * @enum {string}
 */
export enum DeemDashboardType {
  DEEM_HUB_NOTIFICATIONS_DETAIL = 'DEEM_HUB_NOTIFICATIONS_DETAIL',
  DEEM_HUB_NOTIFICATIONS_OVERVIEW = 'DEEM_HUB_NOTIFICATIONS_OVERVIEW',
  DEEM_INCIDENT_DESKTOP_APP = 'DEEM_INCIDENT_DESKTOP_APP',
  DEEM_INCIDENT_DEVICE = 'DEEM_INCIDENT_DEVICE',
  DEEM_INCIDENT_MOBILE_APP = 'DEEM_INCIDENT_MOBILE_APP',
  DEEM_INCIDENT_USER = 'DEEM_INCIDENT_USER',
  // eslint-disable-next-line max-len
  DEEM_PHYSICAL_APP_CRASH_RATE_EXPERIENCESCORE_DESKTOP_APP_MACOS_ERROR_DETAILS = 'DEEM_PHYSICAL_APP_CRASH_RATE_EXPERIENCESCORE_DESKTOP_APP_MACOS_ERROR_DETAILS',
  // eslint-disable-next-line max-len
  DEEM_PHYSICAL_APP_CRASH_RATE_EXPERIENCESCORE_DESKTOP_APP_WINDOWS_ERROR_DETAILS = 'DEEM_PHYSICAL_APP_CRASH_RATE_EXPERIENCESCORE_DESKTOP_APP_WINDOWS_ERROR_DETAILS',
  // eslint-disable-next-line max-len
  DEEM_PHYSICAL_APP_HANG_RATE_EXPERIENCESCORE_DESKTOP_APP_MACOS_ERROR_DETAILS = 'DEEM_PHYSICAL_APP_HANG_RATE_EXPERIENCESCORE_DESKTOP_APP_MACOS_ERROR_DETAILS',
  // eslint-disable-next-line max-len
  DEEM_PHYSICAL_APP_HANG_RATE_EXPERIENCESCORE_DESKTOP_APP_WINDOWS_ERROR_DETAILS = 'DEEM_PHYSICAL_APP_HANG_RATE_EXPERIENCESCORE_DESKTOP_APP_WINDOWS_ERROR_DETAILS',
  DEEM_PHYSICAL_DESKTOP_APP = 'DEEM_PHYSICAL_DESKTOP_APP',
  DEEM_PHYSICAL_DESKTOP_APP_OVERVIEW = 'DEEM_PHYSICAL_DESKTOP_APP_OVERVIEW',
  DEEM_PHYSICAL_DESKTOP_APPS = 'DEEM_PHYSICAL_DESKTOP_APPS',
  DEEM_PHYSICAL_DESKTOP_APPS_MACOS = 'DEEM_PHYSICAL_DESKTOP_APPS_MACOS',
  DEEM_PHYSICAL_DESKTOP_APPS_WINDOWS = 'DEEM_PHYSICAL_DESKTOP_APPS_WINDOWS',
  DEEM_PHYSICAL_DESKTOP_APPS_OVERVIEW = 'DEEM_PHYSICAL_DESKTOP_APPS_OVERVIEW',
  DEEM_PHYSICAL_DESKTOP_APPS_MACOS_OVERVIEW = 'DEEM_PHYSICAL_DESKTOP_APPS_MACOS_OVERVIEW',
  DEEM_PHYSICAL_DESKTOP_APPS_WINDOWS_OVERVIEW = 'DEEM_PHYSICAL_DESKTOP_APPS_WINDOWS_OVERVIEW',
  DEEM_PHYSICAL_MOBILE_APP = 'DEEM_PHYSICAL_MOBILE_APP',
  DEEM_PHYSICAL_MOBILE_APP_OVERVIEW = 'DEEM_PHYSICAL_MOBILE_APP_OVERVIEW',
  DEEM_PHYSICAL_MOBILE_APPS = 'DEEM_PHYSICAL_MOBILE_APPS',
  DEEM_PHYSICAL_MOBILE_APPS_ANDROID = 'DEEM_PHYSICAL_MOBILE_APPS_ANDROID',
  DEEM_PHYSICAL_MOBILE_APPS_IOS = 'DEEM_PHYSICAL_MOBILE_APPS_IOS',
  DEEM_PHYSICAL_MOBILE_APPS_OVERVIEW = 'DEEM_PHYSICAL_MOBILE_APPS_OVERVIEW',
  DEEM_PHYSICAL_MOBILE_APPS_ANDROID_OVERVIEW = 'DEEM_PHYSICAL_MOBILE_APPS_ANDROID_OVERVIEW',
  DEEM_PHYSICAL_MOBILE_APPS_IOS_OVERVIEW = 'DEEM_PHYSICAL_MOBILE_APPS_IOS_OVERVIEW',
  DEEM_PHYSICAL_ORG = 'DEEM_PHYSICAL_ORG',
  DEEM_PHYSICAL_ORG_CURRENT_EXPERIENCE = 'DEEM_PHYSICAL_ORG_CURRENT_EXPERIENCE',
  DEEM_PHYSICAL_ORG_SCORE_ANALYSIS = 'DEEM_PHYSICAL_ORG_SCORE_ANALYSIS',
  DEEM_SURVEYS_SUMMARY = 'DEEM_SURVEYS_SUMMARY',
  DEEM_SURVEYS_RESULTS = 'DEEM_SURVEYS_RESULTS',
  DEEM_VIRTUAL_LOGON_DURATION_USER_DETAIL = 'DEEM_VIRTUAL_LOGON_DURATION_USER_DETAIL',
  DEEM_VIRTUAL_LOGON_DURATION_USER_LATEST_LOGON_INFO = 'DEEM_VIRTUAL_LOGON_DURATION_USER_LATEST_LOGON_INFO',
  DEEM_VIRTUAL_LOGON_EXPERIENCE = 'DEEM_VIRTUAL_LOGON_EXPERIENCE',
  // eslint-disable-next-line max-len
  DEEM_VIRTUAL_LOGON_EXPERIENCE_DEEM_VIRTUAL_SESSION_TOTAL_COUNT_LAST_4_HR_V2 = 'DEEM_VIRTUAL_LOGON_EXPERIENCE_DEEM_VIRTUAL_SESSION_TOTAL_COUNT_LAST_4_HR_V2',
  // eslint-disable-next-line max-len
  DEEM_VIRTUAL_LOGON_EXPERIENCE_DEEM_VIRTUAL_LOGON_EXPERIENCE_WITH_POOR_SCORE_COUNT_LAST_4_HR_V2 = 'DEEM_VIRTUAL_LOGON_EXPERIENCE_DEEM_VIRTUAL_LOGON_EXPERIENCE_WITH_POOR_SCORE_COUNT_LAST_4_HR_V2',
  // eslint-disable-next-line max-len
  DEEM_VIRTUAL_LOGON_EXPERIENCE_DEEM_VIRTUAL_LOGON_EXPERIENCE_WITH_NEUTRAL_SCORE_COUNT_LAST_4_HR_V2 = 'DEEM_VIRTUAL_LOGON_EXPERIENCE_DEEM_VIRTUAL_LOGON_EXPERIENCE_WITH_NEUTRAL_SCORE_COUNT_LAST_4_HR_V2',
  // eslint-disable-next-line max-len
  DEEM_VIRTUAL_LOGON_EXPERIENCE_DEEM_VIRTUAL_LOGON_EXPERIENCE_WITH_GOOD_SCORE_COUNT_LAST_4_HR_V2 = 'DEEM_VIRTUAL_LOGON_EXPERIENCE_DEEM_VIRTUAL_LOGON_EXPERIENCE_WITH_GOOD_SCORE_COUNT_LAST_4_HR_V2',

  // DEEM Virtual V2
  DEEM_VIRTUAL_APP_CPU_USAGE_HORIZON_EXPERIENCESCORE_APP = 'DEEM_VIRTUAL_APP_CPU_USAGE_HORIZON_EXPERIENCESCORE_APP',
  DEEM_VIRTUAL_APP_CRASH_RATE_ERROR_DETAILS = 'DEEM_VIRTUAL_APP_CRASH_RATE_ERROR_DETAILS',
  DEEM_VIRTUAL_APP_CRASH_RATE_HORIZON_EXPERIENCESCORE_APP = 'DEEM_VIRTUAL_APP_CRASH_RATE_HORIZON_EXPERIENCESCORE_APP',
  DEEM_VIRTUAL_APP_DETAIL = 'DEEM_VIRTUAL_APP_DETAIL',
  DEEM_VIRTUAL_APP_DETAIL_OVERVIEW = 'DEEM_VIRTUAL_APP_DETAIL_OVERVIEW',
  DEEM_VIRTUAL_APP_HANG_RATE_HORIZON_EXPERIENCESCORE_APP = 'DEEM_VIRTUAL_APP_HANG_RATE_HORIZON_EXPERIENCESCORE_APP',
  DEEM_VIRTUAL_APP_MEMORY_USAGE_HORIZON_EXPERIENCESCORE_APP = 'DEEM_VIRTUAL_APP_MEMORY_USAGE_HORIZON_EXPERIENCESCORE_APP',
  DEEM_VIRTUAL_APPS = 'DEEM_VIRTUAL_APPS',
  DEEM_VIRTUAL_MANAGEMENT_COMPONENTS = 'DEEM_VIRTUAL_MANAGEMENT_COMPONENTS',
  DEEM_VIRTUAL_NETWORK_PROTOCOL = 'DEEM_VIRTUAL_NETWORK_PROTOCOL',
  DEEM_VIRTUAL_ORG = 'DEEM_VIRTUAL_ORG',
  DEEM_VIRTUAL_OVERALL = 'DEEM_VIRTUAL_OVERALL',
  DEEM_VIRTUAL_SESSIONS = 'DEEM_VIRTUAL_SESSIONS',
  DEEM_VIRTUAL_SESSION_DETAILS_OVERVIEW = 'DEEM_VIRTUAL_SESSION_DETAILS_OVERVIEW',
  DEEM_VIRTUAL_SESSION_DETAILS_METADATA = 'DEEM_VIRTUAL_SESSION_DETAILS_METADATA',
  DEEM_VIRTUAL_SESSION_DETAILS_APP_PERFORMANCE = 'DEEM_VIRTUAL_SESSION_DETAILS_APP_PERFORMANCE',
  DEEM_VIRTUAL_SESSION_DETAILS_EXPERIENCE = 'DEEM_VIRTUAL_SESSION_DETAILS_EXPERIENCE',
  DEEM_VIRTUAL_VM_PERFORMANCE = 'DEEM_VIRTUAL_VM_PERFORMANCE',
  DEEM_VIRTUAL_LAN_AVERAGE_LATENCY_HORIZON_EXPERIENCESCORE_SESSION = 'DEEM_VIRTUAL_LAN_AVERAGE_LATENCY_HORIZON_EXPERIENCESCORE_SESSION',
  DEEM_VIRTUAL_MGT_COMP_CONNECTION_SERVER_DETAIL = 'DEEM_VIRTUAL_MGT_COMP_CONNECTION_SERVER_DETAIL',
  DEEM_VIRTUAL_MGT_COMP_UAG_APPLIANCE_DETAIL = 'DEEM_VIRTUAL_MGT_COMP_UAG_APPLIANCE_DETAIL',
  DEEM_VIRTUAL_MGT_COMPONENTS = 'DEEM_VIRTUAL_MGT_COMPONENTS',
  DEEM_VIRTUAL_MGT_COMPONENTS_CONNECTION_SERVER = 'DEEM_VIRTUAL_MGT_COMPONENTS_CONNECTION_SERVER',
  DEEM_VIRTUAL_MGT_COMPONENTS_UAG_APPLIANCE = 'DEEM_VIRTUAL_MGT_COMPONENTS_UAG_APPLIANCE',
  // eslint-disable-next-line max-len
  DEEM_VIRTUAL_CONNECTION_COUNT_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE = 'DEEM_VIRTUAL_CONNECTION_COUNT_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE',
  // eslint-disable-next-line max-len
  DEEM_VIRTUAL_DAYS_BEFORE_CERTIFICATE_EXPIRATION_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER = 'DEEM_VIRTUAL_DAYS_BEFORE_CERTIFICATE_EXPIRATION_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER',
  // eslint-disable-next-line max-len
  DEEM_VIRTUAL_DAYS_BEFORE_CERTIFICATE_EXPIRATION_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE = 'DEEM_VIRTUAL_DAYS_BEFORE_CERTIFICATE_EXPIRATION_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE',
  DEEM_VIRTUAL_CPU_USAGE_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER = 'DEEM_VIRTUAL_CPU_USAGE_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER',
  DEEM_VIRTUAL_CPU_USAGE_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE = 'DEEM_VIRTUAL_CPU_USAGE_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE',
  // eslint-disable-next-line max-len
  DEEM_VIRTUAL_MEMORY_USAGE_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER = 'DEEM_VIRTUAL_MEMORY_USAGE_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER',
  DEEM_VIRTUAL_MEMORY_USAGE_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE = 'DEEM_VIRTUAL_MEMORY_USAGE_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE',
  // eslint-disable-next-line max-len
  DEEM_VIRTUAL_SESSION_COUNT_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER = 'DEEM_VIRTUAL_SESSION_COUNT_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER',
  DEEM_VIRTUAL_STATUS_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER = 'DEEM_VIRTUAL_STATUS_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER',
  DEEM_VIRTUAL_STATUS_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE = 'DEEM_VIRTUAL_STATUS_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE',
  DEEM_VIRTUAL_WAN_AVERAGE_LATENCY_HORIZON_EXPERIENCESCORE_SESSION = 'DEEM_VIRTUAL_WAN_AVERAGE_LATENCY_HORIZON_EXPERIENCESCORE_SESSION',
  DEEM_VIRTUAL_DOT11_SIGNAL_QUALITY_HORIZON_EXPERIENCESCORE_SESSION = 'DEEM_VIRTUAL_DOT11_SIGNAL_QUALITY_HORIZON_EXPERIENCESCORE_SESSION',
  DEEM_VIRTUAL_PACKET_LOSS_RATE_HORIZON_EXPERIENCESCORE_SESSION = 'DEEM_VIRTUAL_PACKET_LOSS_RATE_HORIZON_EXPERIENCESCORE_SESSION',
  DEEM_VIRTUAL_PROTOCOL_LATENCY_HORIZON_EXPERIENCESCORE_SESSION = 'DEEM_VIRTUAL_PROTOCOL_LATENCY_HORIZON_EXPERIENCESCORE_SESSION',
  DEEM_VIRTUAL_CPU_PERCENT_HORIZON_EXPERIENCESCORE_SESSION = 'DEEM_VIRTUAL_CPU_PERCENT_HORIZON_EXPERIENCESCORE_SESSION',
  DEEM_VIRTUAL_MEMORY_PERCENT_HORIZON_EXPERIENCESCORE_SESSION = 'DEEM_VIRTUAL_MEMORY_PERCENT_HORIZON_EXPERIENCESCORE_SESSION',
  DEEM_VIRTUAL_DISK_LATENCY_HORIZON_EXPERIENCESCORE_SESSION = 'DEEM_VIRTUAL_DISK_LATENCY_HORIZON_EXPERIENCESCORE_SESSION',
  DEEM_VIRTUAL_DISK_IOPS_HORIZON_EXPERIENCESCORE_SESSION = 'DEEM_VIRTUAL_DISK_IOPS_HORIZON_EXPERIENCESCORE_SESSION',

  DEEM_PHYSICAL_DEVICES_MACOS = 'DEEM_PHYSICAL_DEVICES_MACOS',
  DEEM_PHYSICAL_DEVICES_MACOS_OVERVIEW = 'DEEM_PHYSICAL_DEVICES_MACOS_OVERVIEW',
  DEEM_PHYSICAL_DEVICES_WINDOWS = 'DEEM_PHYSICAL_DEVICES_WINDOWS',
  DEEM_PHYSICAL_DEVICES_WINDOWS_OVERVIEW = 'DEEM_PHYSICAL_DEVICES_WINDOWS_OVERVIEW',
  DEEM_PHYSICAL_OVERALL = 'DEEM_PHYSICAL_OVERALL',
  DEEM_PHYSICAL_USER = 'DEEM_PHYSICAL_USER',
  DEEM_PHYSICAL_USER_DETAIL = 'DEEM_PHYSICAL_USER_DETAIL',
  DEEM_PHYSICAL_USER_OVERVIEW = 'DEEM_PHYSICAL_USER_OVERVIEW',
  DEEM_PHYSICAL_DEVICES = 'DEEM_PHYSICAL_DEVICES',
  DEEM_PHYSICAL_DEVICES_OVERVIEW = 'DEEM_PHYSICAL_DEVICES_OVERVIEW',

  DEEM_ZOOM_CONTRIBUTORS = 'DEEM_ZOOM_CONTRIBUTORS',
  DEEM_ZOOM_MEETING = 'DEEM_ZOOM_MEETING',
  DEEM_ZOOM_MEETINGS = 'DEEM_ZOOM_MEETINGS',
  DEEM_ZOOM_USERS = 'DEEM_ZOOM_USERS',
  DEEM_ZOOM_USER = 'DEEM_ZOOM_USER',

  DEEM_ORGANIZATION_OVERVIEW = 'DEEM_ORGANIZATION_OVERVIEW',

  // DEEM Physical Contributor Dashboard
  DEEM_PHYSICAL_CRASH_COUNT_EXPERIENCESCORE_DEVICE = 'DEEM_PHYSICAL_CRASH_COUNT_EXPERIENCESCORE_DEVICE',
  DEEM_PHYSICAL_CRASH_COUNT_LIST = 'DEEM_PHYSICAL_CRASH_COUNT_LIST',
  DEEM_PHYSICAL_CPU_HEALTH_EXPERIENCESCORE_DEVICE = 'DEEM_PHYSICAL_CPU_HEALTH_EXPERIENCESCORE_DEVICE',
  DEEM_PHYSICAL_DEVICE_TEMPERATURE_EXPERIENCESCORE_DEVICE = 'DEEM_PHYSICAL_DEVICE_TEMPERATURE_EXPERIENCESCORE_DEVICE',
  DEEM_PHYSICAL_MEMORY_HEALTH_EXPERIENCESCORE_DEVICE = 'DEEM_PHYSICAL_MEMORY_HEALTH_EXPERIENCESCORE_DEVICE',
  DEEM_PHYSICAL_GPU_HEALTH_EXPERIENCESCORE_DEVICE = 'DEEM_PHYSICAL_GPU_HEALTH_EXPERIENCESCORE_DEVICE',
  DEEM_PHYSICAL_BATTERY_HEALTH_EXPERIENCESCORE_DEVICE = 'DEEM_PHYSICAL_BATTERY_HEALTH_EXPERIENCESCORE_DEVICE',
  DEEM_PHYSICAL_AVERAGE_BOOT_TIME_MILLIS_EXPERIENCESCORE_DEVICE = 'DEEM_PHYSICAL_AVERAGE_BOOT_TIME_MILLIS_EXPERIENCESCORE_DEVICE',
  DEEM_PHYSICAL_AVERAGE_SHUTDOWN_TIME_MILLIS_EXPERIENCESCORE_DEVICE = 'DEEM_PHYSICAL_AVERAGE_SHUTDOWN_TIME_MILLIS_EXPERIENCESCORE_DEVICE',

  // DEEM Physical Desktop app contributor dashboard
  DEEM_PHYSICAL_APP_CRASH_RATE_EXPERIENCESCORE_DESKTOP_APP = 'DEEM_PHYSICAL_APP_CRASH_RATE_EXPERIENCESCORE_DESKTOP_APP',
  DEEM_PHYSICAL_APP_HANG_RATE_EXPERIENCESCORE_DESKTOP_APP = 'DEEM_PHYSICAL_APP_HANG_RATE_EXPERIENCESCORE_DESKTOP_APP',
  DEEM_PHYSICAL_APP_CPU_USAGE_EXPERIENCESCORE_DESKTOP_APP = 'DEEM_PHYSICAL_APP_CPU_USAGE_EXPERIENCESCORE_DESKTOP_APP',
  DEEM_PHYSICAL_APP_GPU_USAGE_EXPERIENCESCORE_DESKTOP_APP = 'DEEM_PHYSICAL_APP_GPU_USAGE_EXPERIENCESCORE_DESKTOP_APP',
  // eslint-disable-next-line max-len
  DEEM_PHYSICAL_APP_MEMORY_USAGE_EXPERIENCESCORE_DESKTOP_APP = 'DEEM_PHYSICAL_APP_MEMORY_USAGE_EXPERIENCESCORE_DESKTOP_APP',
  // eslint-disable-next-line max-len
  DEEM_PHYSICAL_AVERAGE_APP_UI_UNRESPONSIVE_EVENTS_PER_DEVICE_EXPERIENCESCORE_DESKTOP_APP = 'DEEM_PHYSICAL_AVERAGE_APP_UI_UNRESPONSIVE_EVENTS_PER_DEVICE_EXPERIENCESCORE_DESKTOP_APP',
  // eslint-disable-next-line max-len
  DEEM_PHYSICAL_AVERAGE_APP_UI_UNRESPONSIVE_DURATION_EXPERIENCESCORE_DESKTOP_APP = 'DEEM_PHYSICAL_AVERAGE_APP_UI_UNRESPONSIVE_DURATION_EXPERIENCESCORE_DESKTOP_APP',
  DEEM_PHYSICAL_APP_UI_UNRESPONSIVE_RATE_EXPERIENCESCORE_DESKTOP_APP = 'DEEM_PHYSICAL_APP_UI_UNRESPONSIVE_RATE_EXPERIENCESCORE_DESKTOP_APP',

  // DEEM Synthetic Url Monitoring
  DEEM_SYNTHETIC_URL_MONITORING_TEST_DETAILS = 'DEEM_SYNTHETIC_URL_MONITORING_TEST_DETAILS',

  // DEEM SAM
  DEEM_SAM_PORTFOLIO_APP_TOTAL = 'DEEM_SAM_PORTFOLIO_APP_TOTAL',
  DEEM_SAM_PORTFOLIO_ACTIVE_APP_TOTAL = 'DEEM_SAM_PORTFOLIO_ACTIVE_APP_TOTAL',
  DEEM_SAM_PORTFOLIO_INACTIVE_APP_TOTAL = 'DEEM_SAM_PORTFOLIO_INACTIVE_APP_TOTAL',
  DEEM_SAM_PORTFOLIO_APP_AVG = 'DEEM_SAM_PORTFOLIO_APP_AVG',
  DEEM_SAM_PORTFOLIO_ACTIVE_APP_AVG = 'DEEM_SAM_PORTFOLIO_ACTIVE_APP_AVG',
  DEEM_SAM_PORTFOLIO_INACTIVE_APP_AVG = 'DEEM_SAM_PORTFOLIO_INACTIVE_APP_AVG',
  DEEM_SAM_PORTFOLIO_FOREGROUND_TOTAL = 'DEEM_SAM_PORTFOLIO_FOREGROUND_TOTAL',
  DEEM_SAM_PORTFOLIO_FOREGROUND_AVG = 'DEEM_SAM_PORTFOLIO_FOREGROUND_AVG',
  DEEM_SAM_DISCOVER = 'DEEM_SAM_DISCOVER',
  DEEM_SAM_OPTIMIZE_APP_TOTAL = 'DEEM_SAM_OPTIMIZE_APP_TOTAL',
  DEEM_SAM_OPTIMIZE_ACTIVE_APP_TOTAL = 'DEEM_SAM_OPTIMIZE_ACTIVE_APP_TOTAL',
  DEEM_SAM_OPTIMIZE_INACTIVE_APP_TOTAL = 'DEEM_SAM_OPTIMIZE_INACTIVE_APP_TOTAL',
  DEEM_SAM_COMPARE_APP_TOTAL = 'DEEM_SAM_COMPARE_APP_TOTAL',
  DEEM_SAM_COMPARE_ACTIVE_APP_TOTAL = 'DEEM_SAM_COMPARE_ACTIVE_APP_TOTAL',
  DEEM_SAM_COMPARE_INACTIVE_APP_TOTAL = 'DEEM_SAM_COMPARE_INACTIVE_APP_TOTAL',
  DEEM_SAM_PRODUCT_DETAIL_APP_TOTAL = 'DEEM_SAM_PRODUCT_DETAIL_APP_TOTAL',
  DEEM_SAM_PRODUCT_DETAIL_ACTIVE_APP_TOTAL = 'DEEM_SAM_PRODUCT_DETAIL_ACTIVE_APP_TOTAL',
  DEEM_SAM_PRODUCT_DETAIL_INACTIVE_APP_TOTAL = 'DEEM_SAM_PRODUCT_DETAIL_INACTIVE_APP_TOTAL',
}

/**
 * DeemWidgetSubtype
 * @export
 * @enum {string}
 */
export enum DeemWidgetSubtype {
  __DEEM_APP_CRASH_RATE_HISTORICAL = '__DEEM_APP_CRASH_RATE_HISTORICAL',
  DEEM_APP_BOOTS = 'DEEM_APP_BOOTS',
  DEEM_APP_SCORE_OVER_TIME = 'DEEM_APP_SCORE_OVER_TIME',
  DEEM_APP_SCORE_BY_APP_VERSION = 'DEEM_APP_SCORE_BY_APP_VERSION',
  DEEM_APP_HANG = 'DEEM_APP_HANG',

  DEEM_APP_HANG_COUNT = 'DEEM_APP_HANG_COUNT',
  DEEM_APP_HANG_COUNT_BY_OS_VERSION = 'DEEM_APP_HANG_COUNT_BY_OS_VERSION',
  DEEM_APP_HANG_COUNT_BY_DEVICE_MODEL = 'DEEM_APP_HANG_COUNT_BY_DEVICE_MODEL',
  DEEM_APP_HANG_COUNT_BY_APP_VERSION = 'DEEM_APP_HANG_COUNT_BY_APP_VERSION',
  DEEM_APP_HANG_COUNT_BY_APP_NAME = 'DEEM_APP_HANG_COUNT_BY_APP_NAME',
  DEEM_APP_HANG_LIST_MACOS = 'DEEM_APP_HANG_LIST_MACOS',
  DEEM_APP_HANG_LIST_WINDOWS = 'DEEM_APP_HANG_LIST_WINDOWS',
  DEEM_APP_ERRORS = 'DEEM_APP_ERRORS',
  DEEM_APP_ERRORS_COUNT = 'DEEM_APP_ERRORS_COUNT',
  DEEM_APP_ERRORS_COUNT_BY_OS_VERSION = 'DEEM_APP_ERRORS_COUNT_BY_OS_VERSION',
  DEEM_APP_ERRORS_COUNT_BY_DEVICE_MODEL = 'DEEM_APP_ERRORS_COUNT_BY_DEVICE_MODEL',
  DEEM_APP_ERRORS_COUNT_BY_APP_VERSION = 'DEEM_APP_ERRORS_COUNT_BY_APP_VERSION',
  DEEM_APP_ERRORS_COUNT_BY_APP_NAME = 'DEEM_APP_ERRORS_COUNT_BY_APP_NAME',
  DEEM_APP_ERRORS_DEVICE_COUNT = 'DEEM_APP_ERRORS_DEVICE_COUNT',
  DEEM_APP_ERRORS_LIST = 'DEEM_APP_ERRORS_LIST',
  DEEM_APP_ERRORS_LIST_MACOS = 'DEEM_APP_ERRORS_LIST_MACOS',
  DEEM_APP_ERRORS_LIST_WINDOWS = 'DEEM_APP_ERRORS_LIST_WINDOWS',
  DEEM_DESKTOP_APP_TOTAL_USERS_COUNT = 'DEEM_DESKTOP_APP_TOTAL_USERS_COUNT',
  DEEM_DESKTOP_APP_PERFORMANCE_CPU_STAT = 'DEEM_DESKTOP_APP_PERFORMANCE_CPU_STAT',
  DEEM_DESKTOP_APP_PERFORMANCE_CPU_USAGE_PERCENTAGE = 'DEEM_DESKTOP_APP_PERFORMANCE_CPU_USAGE_PERCENTAGE',
  DEEM_DESKTOP_APP_PERFORMANCE_CPU_USAGE_BY_FRIENDLY_OS_VERSION_NAME = 'DEEM_DESKTOP_APP_PERFORMANCE_CPU_USAGE_BY_FRIENDLY_OS_VERSION_NAME',
  DEEM_DESKTOP_APP_PERFORMANCE_CPU_USAGE_BY_DEVICE_MODEL = 'DEEM_DESKTOP_APP_PERFORMANCE_CPU_USAGE_BY_DEVICE_MODEL',
  DEEM_DESKTOP_APP_PERFORMANCE_CPU_USAGE_BY_ORG_GROUP = 'DEEM_DESKTOP_APP_PERFORMANCE_CPU_USAGE_BY_ORG_GROUP',
  DEEM_DESKTOP_APP_PERFORMANCE_CPU_USAGE_BY_DEVICE_TAGS = 'DEEM_DESKTOP_APP_PERFORMANCE_CPU_USAGE_BY_DEVICE_TAGS',
  DEEM_DESKTOP_APP_TOTAL_DEVICES_COUNT_REPORTING_CPU_USAGE = 'DEEM_DESKTOP_APP_TOTAL_DEVICES_COUNT_REPORTING_CPU_USAGE',
  DEEM_DESKTOP_APP_TOTAL_DEVICES_COUNT_WITH_APP_FOREGROUND_EVENT = 'DEEM_DESKTOP_APP_TOTAL_DEVICES_COUNT_WITH_APP_FOREGROUND_EVENT',
  _DEEM_DESKTOP_APP_DEVICES_BY_CPU_USAGE = '_DEEM_DESKTOP_APP_DEVICES_BY_CPU_USAGE',
  DEEM_DESKTOP_APP_PERFORMANCE_CPU_USAGE_DEVICES = 'DEEM_DESKTOP_APP_PERFORMANCE_CPU_USAGE_DEVICES',

  DEEM_DESKTOP_APP_TOTAL_DEVICES_COUNT = 'DEEM_DESKTOP_APP_TOTAL_DEVICES_COUNT',
  DEEM_DESKTOP_APP_COUNT_OF_APP_UI_UNRESPONSIVE_SCORE = 'DEEM_DESKTOP_APP_COUNT_OF_APP_UI_UNRESPONSIVE_SCORE',
  DEEM_DESKTOP_APP_UI_UNRESPONSIVE_COUNT = 'DEEM_DESKTOP_APP_UI_UNRESPONSIVE_COUNT',
  DEEM_DESKTOP_APP_UI_UNRESPONSIVE_COUNT_BY_OS_VERSION = 'DEEM_DESKTOP_APP_UI_UNRESPONSIVE_COUNT_BY_OS_VERSION',
  DEEM_DESKTOP_APP_UI_UNRESPONSIVE_COUNT_BY_DEVICE_MODEL = 'DEEM_DESKTOP_APP_UI_UNRESPONSIVE_COUNT_BY_DEVICE_MODEL',
  DEEM_DESKTOP_APP_UI_UNRESPONSIVE_COUNT_BY_APP_VERSION = 'DEEM_DESKTOP_APP_UI_UNRESPONSIVE_COUNT_BY_APP_VERSION',
  DEEM_DESKTOP_APP_UI_UNRESPONSIVE_COUNT_BY_APPLICATION_NAME = 'DEEM_DESKTOP_APP_UI_UNRESPONSIVE_COUNT_BY_APPLICATION_NAME',
  // eslint-disable-next-line max-len
  DEEM_DESKTOP_APP_TOTAL_DEVICES_COUNT_WITH_REPORTING_UNRESPONSIVE_EVENTS = 'DEEM_DESKTOP_APP_TOTAL_DEVICES_COUNT_WITH_REPORTING_UNRESPONSIVE_EVENTS',
  _DEEM_DESKTOP_APP_DEVICES_BY_APP_UI_UNRESPONSIVE = '_DEEM_DESKTOP_APP_DEVICES_BY_APP_UI_UNRESPONSIVE',
  DEEM_DESKTOP_APP_TOP_DEVICES_WITH_HIGH_APP_UI_UNRESPONSIVE_COUNT = 'DEEM_DESKTOP_APP_TOP_DEVICES_WITH_HIGH_APP_UI_UNRESPONSIVE_COUNT',
  // eslint-disable-next-line max-len
  DEEM_DESKTOP_APP_PERFORMANCE_EVENT_COUNT_BY_APP_UI_UNRESPONSIVE_DURATION_SCORE = 'DEEM_DESKTOP_APP_PERFORMANCE_EVENT_COUNT_BY_APP_UI_UNRESPONSIVE_DURATION_SCORE',

  DEEM_DESKTOP_APP_COUNT_OF_APP_UI_UNRESPONSIVE_DURATION_SCORE = 'DEEM_DESKTOP_APP_COUNT_OF_APP_UI_UNRESPONSIVE_DURATION_SCORE',
  DEEM_DESKTOP_APP_AVERAGE_APP_UI_UNRESPONSIVE_DURATION = 'DEEM_DESKTOP_APP_AVERAGE_APP_UI_UNRESPONSIVE_DURATION',
  // eslint-disable-next-line max-len
  DEEM_DESKTOP_APP_AVERAGE_APP_UI_UNRESPONSIVE_DURATION_BY_OS_VERSION = 'DEEM_DESKTOP_APP_AVERAGE_APP_UI_UNRESPONSIVE_DURATION_BY_OS_VERSION',
  // eslint-disable-next-line max-len
  DEEM_DESKTOP_APP_AVERAGE_APP_UI_UNRESPONSIVE_DURATION_BY_DEVICE_MODEL = 'DEEM_DESKTOP_APP_AVERAGE_APP_UI_UNRESPONSIVE_DURATION_BY_DEVICE_MODEL',
  // eslint-disable-next-line max-len
  DEEM_DESKTOP_APP_AVERAGE_APP_UI_UNRESPONSIVE_DURATION_BY_APP_VERSION = 'DEEM_DESKTOP_APP_AVERAGE_APP_UI_UNRESPONSIVE_DURATION_BY_APP_VERSION',
  // eslint-disable-next-line max-len
  DEEM_DESKTOP_APP_AVERAGE_APP_UI_UNRESPONSIVE_DURATION_BY_APPLICATION_NAME = 'DEEM_DESKTOP_APP_AVERAGE_APP_UI_UNRESPONSIVE_DURATION_BY_APPLICATION_NAME',
  // eslint-disable-next-line max-len
  DEEM_DESKTOP_APP_TOP_DEVICES_WITH_HIGH_APP_UI_UNRESPONSIVE_DURATION = 'DEEM_DESKTOP_APP_TOP_DEVICES_WITH_HIGH_APP_UI_UNRESPONSIVE_DURATION',

  DEEM_DESKTOP_APP_COUNT_OF_APP_UI_UNRESPONSIVE_RATE_SCORE = 'DEEM_DESKTOP_APP_COUNT_OF_APP_UI_UNRESPONSIVE_RATE_SCORE',
  // eslint-disable-next-line max-len
  DEEM_DESKTOP_APP_COUNT_OF_APP_UI_UNRESPONSIVE_RATE_SCORE_BY_APP_VERSION = 'DEEM_DESKTOP_APP_COUNT_OF_APP_UI_UNRESPONSIVE_RATE_SCORE_BY_APP_VERSION',
  _DEEM_DESKTOP_APP_UI_UNRESPONSIVE_RATE = '_DEEM_DESKTOP_APP_UI_UNRESPONSIVE_RATE',

  DEEM_DESKTOP_APP_EVENT_COUNT_OF_UNRESPONSIVE_EVENTS = 'DEEM_DESKTOP_APP_EVENT_COUNT_OF_UNRESPONSIVE_EVENTS',
  DEEM_DESKTOP_APP_EVENT_COUNT_OF_FOREGROUND_EVENTS = 'DEEM_DESKTOP_APP_EVENT_COUNT_OF_FOREGROUND_EVENTS',

  DEEM_DESKTOP_APP_PERFORMANCE_EVENT_COUNT_BY_CPU_SCORE = 'DEEM_DESKTOP_APP_PERFORMANCE_EVENT_COUNT_BY_CPU_SCORE',
  DEEM_DESKTOP_APP_PERFORMANCE_EVENT_COUNT_BY_GPU_SCORE = 'DEEM_DESKTOP_APP_PERFORMANCE_EVENT_COUNT_BY_GPU_SCORE',
  DEEM_DESKTOP_APP_PERFORMANCE_EVENT_COUNT_BY_MEMORY_SCORE = 'DEEM_DESKTOP_APP_PERFORMANCE_EVENT_COUNT_BY_MEMORY_SCORE',
  // eslint-disable-next-line max-len
  DEEM_DESKTOP_APP_PERFORMANCE_EVENT_COUNT_BY_APP_UI_UNRESPONSIVE_COUNT_SCORE = 'DEEM_DESKTOP_APP_PERFORMANCE_EVENT_COUNT_BY_APP_UI_UNRESPONSIVE_COUNT_SCORE',
  // eslint-disable-next-line max-len
  DEEM_DESKTOP_APP_PERFORMANCE_EVENT_COUNT_BY_APP_UI_UNRESPONSIVE_RATE_SCORE = 'DEEM_DESKTOP_APP_PERFORMANCE_EVENT_COUNT_BY_APP_UI_UNRESPONSIVE_RATE_SCORE',

  DEEM_DESKTOP_APP_PERFORMANCE_GPU_STAT = 'DEEM_DESKTOP_APP_PERFORMANCE_GPU_STAT',
  DEEM_DESKTOP_APP_PERFORMANCE_GPU_USAGE_PERCENTAGE = 'DEEM_DESKTOP_APP_PERFORMANCE_GPU_USAGE_PERCENTAGE',
  DEEM_DESKTOP_APP_PERFORMANCE_GPU1_USAGE_PERCENTAGE = 'DEEM_DESKTOP_APP_PERFORMANCE_GPU1_USAGE_PERCENTAGE',
  DEEM_DESKTOP_APP_PERFORMANCE_GPU2_USAGE_PERCENTAGE = 'DEEM_DESKTOP_APP_PERFORMANCE_GPU2_USAGE_PERCENTAGE',
  _DEEM_DESKTOP_APP_PERFORMANCE_GPU_USAGE_PERCENTAGE = '_DEEM_DESKTOP_APP_PERFORMANCE_GPU_USAGE_PERCENTAGE',

  // eslint-disable-next-line max-len
  DEEM_DESKTOP_APP_PERFORMANCE_GPU0_USAGE_BY_FRIENDLY_OS_VERSION_NAME = 'DEEM_DESKTOP_APP_PERFORMANCE_GPU0_USAGE_BY_FRIENDLY_OS_VERSION_NAME',
  DEEM_DESKTOP_APP_PERFORMANCE_GPU0_USAGE_BY_DEVICE_MODEL = 'DEEM_DESKTOP_APP_PERFORMANCE_GPU0_USAGE_BY_DEVICE_MODEL',
  DEEM_DESKTOP_APP_PERFORMANCE_GPU0_USAGE_BY_ORG_GROUP = 'DEEM_DESKTOP_APP_PERFORMANCE_GPU0_USAGE_BY_ORG_GROUP',
  DEEM_DESKTOP_APP_PERFORMANCE_GPU0_USAGE_BY_DEVICE_TAGS = 'DEEM_DESKTOP_APP_PERFORMANCE_GPU0_USAGE_BY_DEVICE_TAGS',

  // eslint-disable-next-line max-len
  DEEM_DESKTOP_APP_PERFORMANCE_GPU1_USAGE_BY_FRIENDLY_OS_VERSION_NAME = 'DEEM_DESKTOP_APP_PERFORMANCE_GPU1_USAGE_BY_FRIENDLY_OS_VERSION_NAME',
  DEEM_DESKTOP_APP_PERFORMANCE_GPU1_USAGE_BY_DEVICE_MODEL = 'DEEM_DESKTOP_APP_PERFORMANCE_GPU1_USAGE_BY_DEVICE_MODEL',
  DEEM_DESKTOP_APP_PERFORMANCE_GPU1_USAGE_BY_ORG_GROUP = 'DEEM_DESKTOP_APP_PERFORMANCE_GPU1_USAGE_BY_ORG_GROUP',
  DEEM_DESKTOP_APP_PERFORMANCE_GPU1_USAGE_BY_DEVICE_TAGS = 'DEEM_DESKTOP_APP_PERFORMANCE_GPU1_USAGE_BY_DEVICE_TAGS',

  // eslint-disable-next-line max-len
  DEEM_DESKTOP_APP_PERFORMANCE_GPU2_USAGE_BY_FRIENDLY_OS_VERSION_NAME = 'DEEM_DESKTOP_APP_PERFORMANCE_GPU2_USAGE_BY_FRIENDLY_OS_VERSION_NAME',
  DEEM_DESKTOP_APP_PERFORMANCE_GPU2_USAGE_BY_DEVICE_MODEL = 'DEEM_DESKTOP_APP_PERFORMANCE_GPU2_USAGE_BY_DEVICE_MODEL',
  DEEM_DESKTOP_APP_PERFORMANCE_GPU2_USAGE_BY_ORG_GROUP = 'DEEM_DESKTOP_APP_PERFORMANCE_GPU2_USAGE_BY_ORG_GROUP',
  DEEM_DESKTOP_APP_PERFORMANCE_GPU2_USAGE_BY_DEVICE_TAGS = 'DEEM_DESKTOP_APP_PERFORMANCE_GPU2_USAGE_BY_DEVICE_TAGS',

  DEEM_DESKTOP_APP_TOTAL_DEVICES_COUNT_REPORTING_GPU_USAGE = 'DEEM_DESKTOP_APP_TOTAL_DEVICES_COUNT_REPORTING_GPU_USAGE',
  _DEEM_DESKTOP_APP_DEVICES_BY_GPU_USAGE = '_DEEM_DESKTOP_APP_DEVICES_BY_GPU_USAGE',
  DEEM_DESKTOP_APP_PERFORMANCE_GPU_USAGE_DEVICES = 'DEEM_DESKTOP_APP_PERFORMANCE_GPU_USAGE_DEVICES',

  DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_STAT = 'DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_STAT',
  DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_USAGE_PERCENTAGE = 'DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_USAGE_PERCENTAGE',
  // eslint-disable-next-line max-len
  DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_USAGE_BY_FRIENDLY_OS_VERSION_NAME = 'DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_USAGE_BY_FRIENDLY_OS_VERSION_NAME',
  DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_USAGE_BY_DEVICE_MODEL = 'DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_USAGE_BY_DEVICE_MODEL',
  DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_USAGE_BY_ORG_GROUP = 'DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_USAGE_BY_ORG_GROUP',
  DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_USAGE_BY_DEVICE_TAGS = 'DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_USAGE_BY_DEVICE_TAGS',

  DEEM_DESKTOP_APP_TOTAL_DEVICES_COUNT_REPORTING_MEMORY_USAGE = 'DEEM_DESKTOP_APP_TOTAL_DEVICES_COUNT_REPORTING_MEMORY_USAGE',
  _DEEM_DESKTOP_APP_DEVICES_BY_MEMORY_USAGE = '_DEEM_DESKTOP_APP_DEVICES_BY_MEMORY_USAGE',
  DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_USAGE_DEVICES = 'DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_USAGE_DEVICES',

  DEEM_DESKTOP_APP_ERROR_DETAILS_OCCURENCES = 'DEEM_DESKTOP_APP_ERROR_DETAILS_OCCURENCES',
  DEEM_DESKTOP_APP_ERROR_DETAILS_OCCURENCES_SUMMARY = 'DEEM_DESKTOP_APP_ERROR_DETAILS_OCCURENCES_SUMMARY',
  DEEM_DESKTOP_APP_ERROR_DETAILS_OCCURENCES_BY_APP_VERSION = 'DEEM_DESKTOP_APP_ERROR_DETAILS_OCCURENCES_BY_APP_VERSION',
  DEEM_DESKTOP_APP_ERROR_DETAILS_OCCURENCES_BY_DEVICE_MODEL = 'DEEM_DESKTOP_APP_ERROR_DETAILS_OCCURENCES_BY_DEVICE_MODEL',
  DEEM_DESKTOP_APP_ERROR_DETAILS_OCCURENCES_BY_OS_VERSION = 'DEEM_DESKTOP_APP_ERROR_DETAILS_OCCURENCES_BY_OS_VERSION',
  DEEM_DESKTOP_APP_ERROR_DETAILS_DEVICES_LIST = 'DEEM_DESKTOP_APP_ERROR_DETAILS_DEVICES_LIST',

  DEEM_DESKTOP_APP_HANG_DETAILS_OCCURENCES = 'DEEM_DESKTOP_APP_HANG_DETAILS_OCCURENCES',
  DEEM_DESKTOP_APP_HANG_DETAILS_OCCURENCES_SUMMARY = 'DEEM_DESKTOP_APP_HANG_DETAILS_OCCURENCES_SUMMARY',
  DEEM_DESKTOP_APP_HANG_DETAILS_OCCURENCES_BY_APP_VERSION = 'DEEM_DESKTOP_APP_HANG_DETAILS_OCCURENCES_BY_APP_VERSION',
  DEEM_DESKTOP_APP_HANG_DETAILS_OCCURENCES_BY_DEVICE_MODEL = 'DEEM_DESKTOP_APP_HANG_DETAILS_OCCURENCES_BY_DEVICE_MODEL',
  DEEM_DESKTOP_APP_HANG_DETAILS_OCCURENCES_BY_OS_VERSION = 'DEEM_DESKTOP_APP_HANG_DETAILS_OCCURENCES_BY_OS_VERSION',
  DEEM_DESKTOP_APP_HANG_DETAILS_DEVICES_LIST = 'DEEM_DESKTOP_APP_HANG_DETAILS_DEVICES_LIST',

  DEEM_APP_ERROR_OCCURENCES_SUBHEADER = 'DEEM_APP_ERROR_OCCURENCES_SUBHEADER',

  DEEM_ORG_SCORE_LAST_4_HR = 'DEEM_ORG_SCORE_LAST_4_HR',

  DEEM_USER_WITH_POOR_SCORE_LAST_8_HR = 'DEEM_USER_WITH_POOR_SCORE_LAST_8_HR',
  DEEM_USER_SCORE_COUNT_BY_SCORE = 'DEEM_USER_SCORE_COUNT_BY_SCORE',
  DEEM_USER_SCORE_LIST = 'DEEM_USER_SCORE_LIST',
  _DEEM_USER_SCORE_LIST = '_DEEM_USER_SCORE_LIST',
  DEEM_USER_SCORE_LAST_4_HR = 'DEEM_USER_SCORE_LAST_4_HR',
  DEEM_USERS_BY_SCORE = 'DEEM_USERS_BY_SCORE',
  DEEM_DEVICE_COUNT_FOR_ONE_USER_LAST_8_HR = 'DEEM_DEVICE_COUNT_FOR_ONE_USER_LAST_8_HR',
  DEEM_DESKTOP_APP_COUNT_FOR_ONE_USER_LAST_8_HR = 'DEEM_DESKTOP_APP_COUNT_FOR_ONE_USER_LAST_8_HR',
  DEEM_MOBILE_APP_COUNT_FOR_ONE_USER_LAST_8_HR = 'DEEM_MOBILE_APP_COUNT_FOR_ONE_USER_LAST_8_HR',
  DEEM_USER_WITH_POOR_SCORE_LAST_4_HR = 'DEEM_USER_WITH_POOR_SCORE_LAST_4_HR',
  DEEM_USER_WITH_NEUTRAL_SCORE_LAST_4_HR = 'DEEM_USER_WITH_NEUTRAL_SCORE_LAST_4_HR',
  DEEM_USER_WITH_GOOD_SCORE_LAST_4_HR = 'DEEM_USER_WITH_GOOD_SCORE_LAST_4_HR',
  DEEM_USER_COUNT_LAST_8_HR = 'DEEM_USER_COUNT_LAST_8_HR',

  DEEM_DESKTOP_APPS_WITH_POOR_SCORE_COUNT_LAST_8_HR = 'DEEM_DESKTOP_APPS_WITH_POOR_SCORE_COUNT_LAST_8_HR',
  DEEM_DESKTOP_APPS_ALL_WITH_POOR_SCORE_COUNT_LAST_4_HR = 'DEEM_DESKTOP_APPS_ALL_WITH_POOR_SCORE_COUNT_LAST_4_HR',
  DEEM_DESKTOP_APPS_WITH_POOR_SCORE_COUNT_LAST_4_HR = 'DEEM_DESKTOP_APPS_WITH_POOR_SCORE_COUNT_LAST_4_HR',
  DEEM_DESKTOP_APPS_ALL_WITH_NEUTRAL_SCORE_COUNT_LAST_4_HR = 'DEEM_DESKTOP_APPS_ALL_WITH_NEUTRAL_SCORE_COUNT_LAST_4_HR',
  DEEM_DESKTOP_APPS_WITH_NEUTRAL_SCORE_COUNT_LAST_4_HR = 'DEEM_DESKTOP_APPS_WITH_NEUTRAL_SCORE_COUNT_LAST_4_HR',
  DEEM_DESKTOP_APPS_ALL_WITH_GOOD_SCORE_COUNT_LAST_4_HR = 'DEEM_DESKTOP_APPS_ALL_WITH_GOOD_SCORE_COUNT_LAST_4_HR',
  DEEM_DESKTOP_APPS_WITH_GOOD_SCORE_COUNT_LAST_4_HR = 'DEEM_DESKTOP_APPS_WITH_GOOD_SCORE_COUNT_LAST_4_HR',
  DEEM_DESKTOP_APPS_COUNT_LAST_8_HR = 'DEEM_DESKTOP_APPS_COUNT_LAST_8_HR',
  DEEM_DESKTOP_APPS_LIST_LAST_4_HR = 'DEEM_DESKTOP_APPS_LIST_LAST_4_HR',

  DEEM_MOBILE_APPS_WITH_POOR_SCORE_COUNT_LAST_8_HR = 'DEEM_MOBILE_APPS_WITH_POOR_SCORE_COUNT_LAST_8_HR',
  DEEM_MOBILE_APPS_ALL_WITH_POOR_SCORE_COUNT_LAST_4_HR = 'DEEM_MOBILE_APPS_ALL_WITH_POOR_SCORE_COUNT_LAST_4_HR',
  DEEM_MOBILE_APPS_WITH_POOR_SCORE_COUNT_LAST_4_HR = 'DEEM_MOBILE_APPS_WITH_POOR_SCORE_COUNT_LAST_4_HR',
  DEEM_MOBILE_APPS_ALL_WITH_NEUTRAL_SCORE_COUNT_LAST_4_HR = 'DEEM_MOBILE_APPS_ALL_WITH_NEUTRAL_SCORE_COUNT_LAST_4_HR',
  DEEM_MOBILE_APPS_WITH_NEUTRAL_SCORE_COUNT_LAST_4_HR = 'DEEM_MOBILE_APPS_WITH_NEUTRAL_SCORE_COUNT_LAST_4_HR',
  DEEM_MOBILE_APPS_ALL_WITH_GOOD_SCORE_COUNT_LAST_4_HR = 'DEEM_MOBILE_APPS_ALL_WITH_GOOD_SCORE_COUNT_LAST_4_HR',
  DEEM_MOBILE_APPS_WITH_GOOD_SCORE_COUNT_LAST_4_HR = 'DEEM_MOBILE_APPS_WITH_GOOD_SCORE_COUNT_LAST_4_HR',
  DEEM_MOBILE_APPS_COUNT_LAST_8_HR = 'DEEM_MOBILE_APPS_COUNT_LAST_8_HR',
  DEEM_MOBILE_APPS_LIST_LAST_4_HR = 'DEEM_MOBILE_APPS_LIST_LAST_4_HR',

  DEEM_DEVICES_WITH_POOR_SCORE_COUNT_LAST_8_HR = 'DEEM_DEVICES_WITH_POOR_SCORE_COUNT_LAST_8_HR',
  DEEM_DEVICES_WITH_POOR_SCORE_COUNT_LAST_4_HR = 'DEEM_DEVICES_WITH_POOR_SCORE_COUNT_LAST_4_HR',
  DEEM_DEVICES_WITH_NEUTRAL_SCORE_COUNT_LAST_4_HR = 'DEEM_DEVICES_WITH_NEUTRAL_SCORE_COUNT_LAST_4_HR',
  DEEM_DEVICES_WITH_GOOD_SCORE_COUNT_LAST_4_HR = 'DEEM_DEVICES_WITH_GOOD_SCORE_COUNT_LAST_4_HR',
  DEEM_DEVICES_SCORE_COUNT_BY_SCORE = 'DEEM_DEVICES_SCORE_COUNT_BY_SCORE',
  DEEM_DEVICES_COUNT_LAST_8_HR = 'DEEM_DEVICES_COUNT_LAST_8_HR',
  DEEM_DEVICES_SCORE_LAST_4_HR = 'DEEM_DEVICES_SCORE_LAST_4_HR',
  DEEM_DEVICES_SCORE_LIST = 'DEEM_DEVICES_SCORE_LIST',
  _DEEM_DEVICES_SCORE_LIST = '_DEEM_DEVICES_SCORE_LIST',
  DEEM_DEVICES_WITH_POOR_NEUTRAL_GOOD_SCORE_COUNT_LAST_4_HR = 'DEEM_DEVICES_WITH_POOR_NEUTRAL_GOOD_SCORE_COUNT_LAST_4_HR',
  _DEEM_DEVICES_WITH_POOR_NEUTRAL_GOOD_SCORE_COUNT_LAST_4_HR = '_DEEM_DEVICES_WITH_POOR_NEUTRAL_GOOD_SCORE_COUNT_LAST_4_HR',

  DEEM_DEVICES_ALL_TOTAL_COUNT_LAST_4_HR = 'DEEM_DEVICES_ALL_TOTAL_COUNT_LAST_4_HR',
  DEEM_DEVICES_MACOS_WITH_POOR_SCORE_COUNT_LAST_4_HR = 'DEEM_DEVICES_MACOS_WITH_POOR_SCORE_COUNT_LAST_4_HR',
  DEEM_DEVICES_WINDOWS_WITH_POOR_SCORE_COUNT_LAST_4_HR = 'DEEM_DEVICES_WINDOWS_WITH_POOR_SCORE_COUNT_LAST_4_HR',
  DEEM_DEVICES_PER_PLATFORM_WITH_POOR_SCORE_COUNT_LAST_4_HR = 'DEEM_DEVICES_PER_PLATFORM_WITH_POOR_SCORE_COUNT_LAST_4_HR',
  DEEM_DEVICES_PER_PLATFORM_WITH_NEUTRAL_SCORE_COUNT_LAST_4_HR = 'DEEM_DEVICES_PER_PLATFORM_WITH_NEUTRAL_SCORE_COUNT_LAST_4_HR',
  DEEM_DEVICES_PER_PLATFORM_WITH_GOOD_SCORE_COUNT_LAST_4_HR = 'DEEM_DEVICES_PER_PLATFORM_WITH_GOOD_SCORE_COUNT_LAST_4_HR',
  DEEM_DEVICES_MACOS_TOTAL_COUNT_LAST_4_HR = 'DEEM_DEVICES_MACOS_TOTAL_COUNT_LAST_4_HR',
  DEEM_DEVICES_WINDOWS_TOTAL_COUNT_LAST_4_HR = 'DEEM_DEVICES_WINDOWS_TOTAL_COUNT_LAST_4_HR',
  DEEM_DEVICES_PER_PLATFORM_TOTAL_COUNT_LAST_8_HR = 'DEEM_DEVICES_PER_PLATFORM_TOTAL_COUNT_LAST_8_HR',
  DEEM_DEVICES_BY_SCORE = 'DEEM_DEVICES_BY_SCORE',
  DEEM_DEVICES_TOTAL_OS_VERSION_COUNT_LAST_4_HR = 'DEEM_DEVICES_TOTAL_OS_VERSION_COUNT_LAST_4_HR',
  DEEM_DEVICES_GOOD_OS_VERSION_COUNT_LAST_4_HR = 'DEEM_DEVICES_GOOD_OS_VERSION_COUNT_LAST_4_HR',
  DEEM_DEVICES_NEURAL_OS_VERSION_COUNT_LAST_4_HR = 'DEEM_DEVICES_NEURAL_OS_VERSION_COUNT_LAST_4_HR',
  DEEM_DEVICES_POOR_OS_VERSION_COUNT_LAST_4_HR = 'DEEM_DEVICES_POOR_OS_VERSION_COUNT_LAST_4_HR',
  DEEM_DEVICES_OS_VERSION_LIST = 'DEEM_DEVICES_OS_VERSION_LIST',
  _DEEM_DEVICES_OS_VERSION_TABLE = '_DEEM_DEVICES_OS_VERSION_TABLE',
  DEEM_DEVICES_SESSIONS_BY_OS_VERSION_LIST = 'DEEM_DEVICES_SESSIONS_BY_OS_VERSION_LIST',
  DEEM_DESKTOP_APPS_ALL_TOTAL_COUNT_LAST_4_HR = 'DEEM_DESKTOP_APPS_ALL_TOTAL_COUNT_LAST_4_HR',
  DEEM_DESKTOP_APPS_MACOS_WITH_POOR_SCORE_COUNT_LAST_4_HR = 'DEEM_DESKTOP_APPS_MACOS_WITH_POOR_SCORE_COUNT_LAST_4_HR',
  DEEM_DESKTOP_APPS_MACOS_TOTAL_COUNT_LAST_4_HR = 'DEEM_DESKTOP_APPS_MACOS_TOTAL_COUNT_LAST_4_HR',
  DEEM_DESKTOP_APPS_WINDOWS_WITH_POOR_SCORE_COUNT_LAST_4_HR = 'DEEM_DESKTOP_APPS_WINDOWS_WITH_POOR_SCORE_COUNT_LAST_4_HR',
  DEEM_DESKTOP_APPS_WINDOWS_TOTAL_COUNT_LAST_4_HR = 'DEEM_DESKTOP_APPS_WINDOWS_TOTAL_COUNT_LAST_4_HR',
  DEEM_MOBILE_APPS_IOS_WITH_POOR_SCORE_COUNT_LAST_4_HR = 'DEEM_MOBILE_APPS_IOS_WITH_POOR_SCORE_COUNT_LAST_4_HR',
  DEEM_MOBILE_APPS_IOS_TOTAL_COUNT_LAST_4_HR = 'DEEM_MOBILE_APPS_IOS_TOTAL_COUNT_LAST_4_HR',
  DEEM_MOBILE_APPS_ANDROID_WITH_POOR_SCORE_COUNT_LAST_4_HR = 'DEEM_MOBILE_APPS_ANDROID_WITH_POOR_SCORE_COUNT_LAST_4_HR',
  DEEM_MOBILE_APPS_ANDROID_TOTAL_COUNT_LAST_4_HR = 'DEEM_MOBILE_APPS_ANDROID_TOTAL_COUNT_LAST_4_HR',
  DEEM_MOBILE_APPS_TOTAL_COUNT_LAST_4_HR = 'DEEM_MOBILE_APPS_TOTAL_COUNT_LAST_4_HR',
  DEEM_USERS_WITH_GOOD_SCORE_COUNT_LAST_4_HR = 'DEEM_USERS_WITH_GOOD_SCORE_COUNT_LAST_4_HR',
  DEEM_USERS_WITH_NEUTRAL_SCORE_COUNT_LAST_4_HR = 'DEEM_USERS_WITH_NEUTRAL_SCORE_COUNT_LAST_4_HR',
  DEEM_USERS_WITH_POOR_SCORE_COUNT_LAST_4_HR = 'DEEM_USERS_WITH_POOR_SCORE_COUNT_LAST_4_HR',
  DEEM_USERS_TOTAL_COUNT_LAST_4_HR = 'DEEM_USERS_TOTAL_COUNT_LAST_4_HR',

  // DEEM - Virtual V2
  DEEM_VIRTUAL_APP_OVERALL_SCORE_LAST_4_HR = 'DEEM_VIRTUAL_APP_OVERALL_SCORE_LAST_4_HR',
  DEEM_VIRTUAL_APP_CPU_USAGE_SCORE_LAST_4_HR = 'DEEM_VIRTUAL_APP_CPU_USAGE_SCORE_LAST_4_HR',
  DEEM_VIRTUAL_APP_COUNT_LAST_8_HR = 'DEEM_VIRTUAL_APP_COUNT_LAST_8_HR',
  DEEM_VIRTUAL_APP_LIST_LAST_4_HR = 'DEEM_VIRTUAL_APP_LIST_LAST_4_HR',
  DEEM_VIRTUAL_APP_CRASH_SCORE_LAST_4_HR = 'DEEM_VIRTUAL_APP_CRASH_SCORE_LAST_4_HR',
  DEEM_VIRTUAL_APP_DAU = 'DEEM_VIRTUAL_APP_DAU',
  DEEM_VIRTUAL_APP_DAU_LAST_24_HOURS = 'DEEM_VIRTUAL_APP_DAU_LAST_24_HOURS',
  DEEM_VIRTUAL_APP_DAU_LAST_24_HOURS_OFFSET_1 = 'DEEM_VIRTUAL_APP_DAU_LAST_24_HOURS_OFFSET_1',
  DEEM_VIRTUAL_APP_ERRORS = 'DEEM_VIRTUAL_APP_ERRORS',
  DEEM_VIRTUAL_APP_ERROR_DETAILS_OCCURENCES = 'DEEM_VIRTUAL_APP_ERROR_DETAILS_OCCURENCES',
  DEEM_DESKTOP_APP_ERROR_DETAILS_EVENT_LIST = 'DEEM_DESKTOP_APP_ERROR_DETAILS_EVENT_LIST',
  DEEM_VIRTUAL_APP_ERROR_DEVICE_COUNT = 'DEEM_VIRTUAL_APP_ERROR_DEVICE_COUNT',
  DEEM_VIRTUAL_APP_ERRORS_COUNT = 'DEEM_VIRTUAL_APP_ERRORS_COUNT',
  DEEM_VIRTUAL_APP_ERRORS_COUNT_BY_APP_VERSION = 'DEEM_VIRTUAL_APP_ERRORS_COUNT_BY_APP_VERSION',
  DEEM_VIRTUAL_APP_ERRORS_COUNT_BY_OS_VERSION = 'DEEM_VIRTUAL_APP_ERRORS_COUNT_BY_OS_VERSION',
  DEEM_VIRTUAL_APP_ERRORS_COUNT_BY_POOL = 'DEEM_VIRTUAL_APP_ERRORS_COUNT_BY_POOL',
  DEEM_VIRTUAL_APP_ERRORS_LIST = 'DEEM_VIRTUAL_APP_ERRORS_LIST',
  DEEM_VIRTUAL_APP_HANG = 'DEEM_VIRTUAL_APP_HANG',
  DEEM_VIRTUAL_APP_HANG_COUNT = 'DEEM_VIRTUAL_APP_HANG_COUNT',
  DEEM_VIRTUAL_APP_HANG_COUNT_BY_OS_VERSION = 'DEEM_VIRTUAL_APP_HANG_COUNT_BY_OS_VERSION',
  DEEM_VIRTUAL_APP_HANG_COUNT_BY_POOL = 'DEEM_VIRTUAL_APP_HANG_COUNT_BY_POOL',
  DEEM_VIRTUAL_APP_HANG_SCORE_LAST_4_HR = 'DEEM_VIRTUAL_APP_HANG_SCORE_LAST_4_HR',
  DEEM_VIRTUAL_APP_MAU = 'DEEM_VIRTUAL_APP_MAU',
  DEEM_VIRTUAL_APP_MAU_LAST_28_DAYS = 'DEEM_VIRTUAL_APP_MAU_LAST_28_DAYS',
  DEEM_VIRTUAL_APP_MAU_LAST_28_DAYS_OFFSET_1 = 'DEEM_VIRTUAL_APP_MAU_LAST_28_DAYS_OFFSET_1',
  DEEM_VIRTUAL_APP_MEMORY_USAGE_SCORE_LAST_4_HR = 'DEEM_VIRTUAL_APP_MEMORY_USAGE_SCORE_LAST_4_HR',
  DEEM_VIRTUAL_APP_USAGE_DURATION = 'DEEM_VIRTUAL_APP_USAGE_DURATION',
  DEEM_VIRTUAL_APP_USAGE_DURATION_LAST_28_DAYS = 'DEEM_VIRTUAL_APP_USAGE_DURATION_LAST_28_DAYS',
  DEEM_VIRTUAL_APP_USAGE_DURATION_LAST_28_DAYS_OFFSET_1 = 'DEEM_VIRTUAL_APP_USAGE_DURATION_LAST_28_DAYS_OFFSET_1',
  DEEM_VIRTUAL_APP_PERFORMANCE_TOTAL_EVENT_COUNT = 'DEEM_VIRTUAL_APP_PERFORMANCE_TOTAL_EVENT_COUNT',
  DEEM_VIRTUAL_APP_TOTAL_COUNT_LAST_4_HR = 'DEEM_VIRTUAL_APP_TOTAL_COUNT_LAST_4_HR',
  DEEM_VIRTUAL_APP_COUNT_WITH_POOR_SCORE_LAST_4_HR = 'DEEM_VIRTUAL_APP_COUNT_WITH_POOR_SCORE_LAST_4_HR',
  DEEM_VIRTUAL_APP_COUNT_WITH_NEUTRAL_SCORE_LAST_4_HR = 'DEEM_VIRTUAL_APP_COUNT_WITH_NEUTRAL_SCORE_LAST_4_HR',
  DEEM_VIRTUAL_APP_COUNT_WITH_GOOD_SCORE_LAST_4_HR = 'DEEM_VIRTUAL_APP_COUNT_WITH_GOOD_SCORE_LAST_4_HR',
  DEEM_VIRTUAL_APP_PERFORMANCE_CPU_STAT = 'DEEM_VIRTUAL_APP_PERFORMANCE_CPU_STAT',
  DEEM_VIRTUAL_APP_PERFORMANCE_CPU_USAGE_PERCENTAGE = 'DEEM_VIRTUAL_APP_PERFORMANCE_CPU_USAGE_PERCENTAGE',
  DEEM_VIRTUAL_APP_PERFORMANCE_CPU_USAGE_PERCENTILE = 'DEEM_VIRTUAL_APP_PERFORMANCE_CPU_USAGE_PERCENTILE',
  DEEM_VIRTUAL_APP_PERFORMANCE_CPU_USAGE_BY_OS_VERSION_NAME = 'DEEM_VIRTUAL_APP_PERFORMANCE_CPU_USAGE_BY_OS_VERSION_NAME',
  DEEM_VIRTUAL_APP_PERFORMANCE_CPU_USAGE_BY_POOL_NAME = 'DEEM_VIRTUAL_APP_PERFORMANCE_CPU_USAGE_BY_POOL_NAME',
  DEEM_VIRTUAL_APP_PERFORMANCE_SESSION_LIST_BY_CPU_USAGE = 'DEEM_VIRTUAL_APP_PERFORMANCE_SESSION_LIST_BY_CPU_USAGE',
  DEEM_VIRTUAL_APP_PERFORMANCE_EVENT_COUNT_BY_CPU_SCORE = 'DEEM_VIRTUAL_APP_PERFORMANCE_EVENT_COUNT_BY_CPU_SCORE',
  DEEM_VIRTUAL_APP_PERFORMANCE_EVENT_COUNT_BY_MEMORY_SCORE = 'DEEM_VIRTUAL_APP_PERFORMANCE_EVENT_COUNT_BY_MEMORY_SCORE',

  _DEEM_VIRTUAL_APP_SESSION_BY_CPU_USAGE = '_DEEM_VIRTUAL_APP_SESSION_BY_CPU_USAGE',
  DEEM_VIRTUAL_APP_TOTAL_SESSION_COUNT = 'DEEM_VIRTUAL_APP_TOTAL_SESSION_COUNT',
  DEEM_VIRTUAL_APP_TOTAL_SESSION_COUNT_USING_APP = 'DEEM_VIRTUAL_APP_TOTAL_SESSION_COUNT_USING_APP',
  DEEM_VIRTUAL_APP_PERFORMANCE_MEMORY_STAT = 'DEEM_VIRTUAL_APP_PERFORMANCE_MEMORY_STAT',
  DEEM_VIRTUAL_APP_PERFORMANCE_MEMORY_USAGE_PERCENTAGE = 'DEEM_VIRTUAL_APP_PERFORMANCE_MEMORY_USAGE_PERCENTAGE',
  DEEM_VIRTUAL_APP_PERFORMANCE_MEMORY_USAGE_PERCENTILE = 'DEEM_VIRTUAL_APP_PERFORMANCE_MEMORY_USAGE_PERCENTILE',
  DEEM_VIRTUAL_APP_PERFORMANCE_MEMORY_USAGE_BY_OS_VERSION_NAME = 'DEEM_VIRTUAL_APP_PERFORMANCE_MEMORY_USAGE_BY_OS_VERSION_NAME',
  DEEM_VIRTUAL_APP_PERFORMANCE_MEMORY_USAGE_BY_POOL_NAME = 'DEEM_VIRTUAL_APP_PERFORMANCE_MEMORY_USAGE_BY_POOL_NAME',
  DEEM_VIRTUAL_APP_PERFORMANCE_SESSION_LIST_BY_MEMORY_USAGE = 'DEEM_VIRTUAL_APP_PERFORMANCE_SESSION_LIST_BY_MEMORY_USAGE',
  _DEEM_VIRTUAL_APP_SESSION_BY_MEMORY_USAGE = '_DEEM_VIRTUAL_APP_SESSION_BY_MEMORY_USAGE',
  DEEM_VIRTUAL_CONNECTION_SERVER_TOTAL_COUNT_LAST_4_HR = 'DEEM_VIRTUAL_CONNECTION_SERVER_TOTAL_COUNT_LAST_4_HR',
  DEEM_VIRTUAL_CONNECTION_SERVER_WITH_GOOD_SCORE_COUNT_LAST_4_HR = 'DEEM_VIRTUAL_CONNECTION_SERVER_WITH_GOOD_SCORE_COUNT_LAST_4_HR',
  DEEM_VIRTUAL_CONNECTION_SERVER_WITH_NEUTRAL_SCORE_COUNT_LAST_4_HR = 'DEEM_VIRTUAL_CONNECTION_SERVER_WITH_NEUTRAL_SCORE_COUNT_LAST_4_HR',
  DEEM_VIRTUAL_CONNECTION_SERVER_WITH_POOR_SCORE_COUNT_LAST_4_HR = 'DEEM_VIRTUAL_CONNECTION_SERVER_WITH_POOR_SCORE_COUNT_LAST_4_HR',
  DEEM_VIRTUAL_UAG_TOTAL_COUNT_LAST_4_HR = 'DEEM_VIRTUAL_UAG_TOTAL_COUNT_LAST_4_HR',
  DEEM_VIRTUAL_UAG_WITH_GOOD_SCORE_COUNT_LAST_4_HR = 'DEEM_VIRTUAL_UAG_WITH_GOOD_SCORE_COUNT_LAST_4_HR',
  DEEM_VIRTUAL_UAG_WITH_NEUTRAL_SCORE_COUNT_LAST_4_HR = 'DEEM_VIRTUAL_UAG_WITH_NEUTRAL_SCORE_COUNT_LAST_4_HR',
  DEEM_VIRTUAL_UAG_WITH_POOR_SCORE_COUNT_LAST_4_HR = 'DEEM_VIRTUAL_UAG_WITH_POOR_SCORE_COUNT_LAST_4_HR',
  __DEEM_VIRTUAL_MGT_COMPONENTS_COUNT_WITH_GOOD_SCORE_LAST_4_HR = '__DEEM_VIRTUAL_MGT_COMPONENTS_COUNT_WITH_GOOD_SCORE_LAST_4_HR',
  __DEEM_VIRTUAL_MGT_COMPONENTS_COUNT_WITH_NEUTRAL_SCORE_LAST_4_HR = '__DEEM_VIRTUAL_MGT_COMPONENTS_COUNT_WITH_NEUTRAL_SCORE_LAST_4_HR',
  __DEEM_VIRTUAL_MGT_COMPONENTS_COUNT_WITH_POOR_SCORE_LAST_4_HR = '__DEEM_VIRTUAL_MGT_COMPONENTS_COUNT_WITH_POOR_SCORE_LAST_4_HR',
  __DEEM_VIRTUAL_MGT_COMPONENTS_TOTAL_COUNT_LAST_4_HR = '__DEEM_VIRTUAL_MGT_COMPONENTS_TOTAL_COUNT_LAST_4_HR',
  DEEM_VIRTUAL_SESSION_COUNT_LAST_8_HR = 'DEEM_VIRTUAL_SESSION_COUNT_LAST_8_HR',
  // eslint-disable-next-line max-len
  DEEM_VIRTUAL_SESSION_COUNT_WITH_NETWORK_PROTOCOL_POOR_SCORE_LAST_4_HR = 'DEEM_VIRTUAL_SESSION_COUNT_WITH_NETWORK_PROTOCOL_POOR_SCORE_LAST_4_HR',
  // eslint-disable-next-line max-len
  DEEM_VIRTUAL_SESSION_COUNT_WITH_NETWORK_PROTOCOL_NEUTRAL_SCORE_LAST_4_HR = 'DEEM_VIRTUAL_SESSION_COUNT_WITH_NETWORK_PROTOCOL_NEUTRAL_SCORE_LAST_4_HR',
  // eslint-disable-next-line max-len
  DEEM_VIRTUAL_SESSION_COUNT_WITH_NETWORK_PROTOCOL_GOOD_SCORE_LAST_4_HR = 'DEEM_VIRTUAL_SESSION_COUNT_WITH_NETWORK_PROTOCOL_GOOD_SCORE_LAST_4_HR',
  // eslint-disable-next-line max-len
  DEEM_VIRTUAL_SESSION_COUNT_WITH_VM_PERFORMANCE_POOR_SCORE_LAST_4_HR = 'DEEM_VIRTUAL_SESSION_COUNT_WITH_VM_PERFORMANCE_POOR_SCORE_LAST_4_HR',
  // eslint-disable-next-line max-len
  DEEM_VIRTUAL_SESSION_COUNT_WITH_VM_PERFORMANCE_NEUTRAL_SCORE_LAST_4_HR = 'DEEM_VIRTUAL_SESSION_COUNT_WITH_VM_PERFORMANCE_NEUTRAL_SCORE_LAST_4_HR',
  DEEM_VIRTUAL_ORG_UX_SCORE = 'DEEM_VIRTUAL_ORG_UX_SCORE',
  // eslint-disable-next-line max-len
  DEEM_VIRTUAL_SESSION_COUNT_WITH_VM_PERFORMANCE_GOOD_SCORE_LAST_4_HR = 'DEEM_VIRTUAL_SESSION_COUNT_WITH_VM_PERFORMANCE_GOOD_SCORE_LAST_4_HR',
  DEEM_VIRTUAL_SESSIONS_COUNT_UX_SCORE = 'DEEM_VIRTUAL_SESSIONS_COUNT_UX_SCORE',
  DEEM_VIRTUAL_SESSIONS_WITH_POOR_SCORE_COUNT_LAST_4_HR = 'DEEM_VIRTUAL_SESSIONS_WITH_POOR_SCORE_COUNT_LAST_4_HR',
  DEEM_VIRTUAL_SESSIONS_WITH_NEUTRAL_SCORE_COUNT_LAST_4_HR = 'DEEM_VIRTUAL_SESSIONS_WITH_NEUTRAL_SCORE_COUNT_LAST_4_HR',
  DEEM_VIRTUAL_SESSIONS_WITH_GOOD_SCORE_COUNT_LAST_4_HR = 'DEEM_VIRTUAL_SESSIONS_WITH_GOOD_SCORE_COUNT_LAST_4_HR',
  DEEM_SESSION_DETAILS_METADATA = 'DEEM_SESSION_DETAILS_METADATA',
  DEEM_SESSION_PROFILE_APPS_COMPARE = 'DEEM_SESSION_PROFILE_APPS_COMPARE',
  DEEM_SESSION_PROFILE_APPS_SUMMARY = 'DEEM_SESSION_PROFILE_APPS_SUMMARY',
  DEEM_SESSION_PROFILE_APP_LIST = 'DEEM_SESSION_PROFILE_APP_LIST',
  DEEM_SESSION_APP_PERFORMANCE_LIST = 'DEEM_SESSION_APP_PERFORMANCE_LIST',
  DEEM_SESSION_NETWORK_AND_PROTOCOL_LIST = 'DEEM_SESSION_NETWORK_AND_PROTOCOL_LIST',
  __DEEM_VIRTUAL_SESSION_DETAILS_APP_PERFORMANCE = '__DEEM_VIRTUAL_SESSION_DETAILS_APP_PERFORMANCE',
  DEEM_SESSION_APP_PERFORMANCE_CPU_USAGE = 'DEEM_SESSION_APP_PERFORMANCE_CPU_USAGE',
  DEEM_SESSION_APP_PERFORMANCE_MEMORY_USAGE = 'DEEM_SESSION_APP_PERFORMANCE_MEMORY_USAGE',
  DEEM_SESSION_PROFILE_PERFORMANCE_CPU_USAGE_BY_VM = 'DEEM_SESSION_PROFILE_PERFORMANCE_CPU_USAGE_BY_VM',
  DEEM_SESSION_PROFILE_PERFORMANCE_CPU_USAGE = 'DEEM_SESSION_PROFILE_PERFORMANCE_CPU_USAGE',
  DEEM_SESSION_PROFILE_PERFORMANCE_DISK_IO = 'DEEM_SESSION_PROFILE_PERFORMANCE_DISK_IO',
  DEEM_SESSION_PROFILE_PERFORMANCE_DISK_IO_AVG = 'DEEM_SESSION_PROFILE_PERFORMANCE_DISK_IO_AVG',
  DEEM_SESSION_PROFILE_PERFORMANCE_DISK_LATENCY = 'DEEM_SESSION_PROFILE_PERFORMANCE_DISK_LATENCY',
  DEEM_SESSION_PROFILE_PERFORMANCE_DISK_LATENCY_AVG = 'DEEM_SESSION_PROFILE_PERFORMANCE_DISK_LATENCY_AVG',
  DEEM_SESSION_PROFILE_PERFORMANCE_MEMORY_USAGE_BY_VM = 'DEEM_SESSION_PROFILE_PERFORMANCE_MEMORY_USAGE_BY_VM',
  DEEM_SESSION_PROFILE_PERFORMANCE_MEMORY_USAGE = 'DEEM_SESSION_PROFILE_PERFORMANCE_MEMORY_USAGE',
  DEEM_SESSION_PROFILE_PERFORMANCE_LAN_LATENCY = 'DEEM_SESSION_PROFILE_PERFORMANCE_LAN_LATENCY',
  DEEM_SESSION_PROFILE_PERFORMANCE_LAN_LATENCY_AVG = 'DEEM_SESSION_PROFILE_PERFORMANCE_LAN_LATENCY_AVG',
  DEEM_SESSION_PROFILE_PERFORMANCE_PROTOCOL_LATENCY = 'DEEM_SESSION_PROFILE_PERFORMANCE_PROTOCOL_LATENCY',
  DEEM_SESSION_PROFILE_PERFORMANCE_PROTOCOL_LATENCY_AVG = 'DEEM_SESSION_PROFILE_PERFORMANCE_PROTOCOL_LATENCY_AVG',
  DEEM_SESSION_PROFILE_PERFORMANCE_PROTOCOL_PACKET_LOSS = 'DEEM_SESSION_PROFILE_PERFORMANCE_PROTOCOL_PACKET_LOSS',
  DEEM_SESSION_PROFILE_PERFORMANCE_PROTOCOL_PACKET_LOSS_AVG = 'DEEM_SESSION_PROFILE_PERFORMANCE_PROTOCOL_PACKET_LOSS_AVG',
  DEEM_SESSION_PROFILE_PERFORMANCE_WAN_LATENCY = 'DEEM_SESSION_PROFILE_PERFORMANCE_WAN_LATENCY',
  DEEM_SESSION_PROFILE_PERFORMANCE_WAN_LATENCY_AVG = 'DEEM_SESSION_PROFILE_PERFORMANCE_WAN_LATENCY_AVG',
  DEEM_SESSION_PROFILE_PERFORMANCE_WIFI_SIGNAL_STRENGTH = 'DEEM_SESSION_PROFILE_PERFORMANCE_WIFI_SIGNAL_STRENGTH',
  DEEM_SESSION_PROFILE_PERFORMANCE_WIFI_SIGNAL_STRENGTH_AVG = 'DEEM_SESSION_PROFILE_PERFORMANCE_WIFI_SIGNAL_STRENGTH_AVG',
  _DEEM_SESSION_PROFILE_PERFORMANCE_NETWORK_LATENCY = '_DEEM_SESSION_PROFILE_PERFORMANCE_NETWORK_LATENCY',
  _DEEM_SESSION_PROFILE_PERFORMANCE_NETWORK_LATENCY_AVG = '_DEEM_SESSION_PROFILE_PERFORMANCE_NETWORK_LATENCY_AVG',
  DEEM_SESSION_PROFILE_SESSION_SCORE_LAST_4_HR = 'DEEM_SESSION_PROFILE_SESSION_SCORE_LAST_4_HR',
  DEEM_VIRTUAL_USER_COUNT_WITH_POOR_SCORE_LAST_8_HR = 'DEEM_VIRTUAL_USER_COUNT_WITH_POOR_SCORE_LAST_8_HR',
  DEEM_VIRTUAL_SESSION_COUNT_BY_LAN_LATENCY_SCORE_LAST_4_HR = 'DEEM_VIRTUAL_SESSION_COUNT_BY_LAN_LATENCY_SCORE_LAST_4_HR',
  DEEM_VIRTUAL_SESSION_COUNT_BY_WAN_LATENCY_SCORE_LAST_4_HR = 'DEEM_VIRTUAL_SESSION_COUNT_BY_WAN_LATENCY_SCORE_LAST_4_HR',
  DEEM_VIRTUAL_SESSION_COUNT_BY_PACKET_LOSS_SCORE_LAST_4_HR = 'DEEM_VIRTUAL_SESSION_COUNT_BY_PACKET_LOSS_SCORE_LAST_4_HR',
  DEEM_VIRTUAL_SESSION_COUNT_BY_WIFI_SIGNAL_STRENGTH_SCORE_LAST_4_HR = 'DEEM_VIRTUAL_SESSION_COUNT_BY_WIFI_SIGNAL_STRENGTH_SCORE_LAST_4_HR',
  DEEM_VIRTUAL_SESSION_COUNT_BY_PROTOCOL_LATENCY_SCORE_LAST_4_HR = 'DEEM_VIRTUAL_SESSION_COUNT_BY_PROTOCOL_LATENCY_SCORE_LAST_4_HR',
  DEEM_VIRTUAL_SESSION_COUNT_BY_CPU_USAGE_SCORE_LAST_4_HR = 'DEEM_VIRTUAL_SESSION_COUNT_BY_CPU_USAGE_SCORE_LAST_4_HR',
  DEEM_VIRTUAL_SESSION_COUNT_BY_MEMORY_USAGE_SCORE_LAST_4_HR = 'DEEM_VIRTUAL_SESSION_COUNT_BY_MEMORY_USAGE_SCORE_LAST_4_HR',
  DEEM_VIRTUAL_SESSION_COUNT_BY_DISK_LATENCY_SCORE_LAST_4_HR = 'DEEM_VIRTUAL_SESSION_COUNT_BY_DISK_LATENCY_SCORE_LAST_4_HR',
  DEEM_VIRTUAL_SESSION_COUNT_BY_DISK_IOPS_SCORE_LAST_4_HR = 'DEEM_VIRTUAL_SESSION_COUNT_BY_DISK_IOPS_SCORE_LAST_4_HR',
  DEEM_VIRTUAL_APP_TOTAL_USERS_COUNT = 'DEEM_VIRTUAL_APP_TOTAL_USERS_COUNT',
  DEEM_SESSION_LATEST_STATUS = 'DEEM_SESSION_LATEST_STATUS',
  DEEM_SESSION_LATEST_CLIENT_INFO = 'DEEM_SESSION_LATEST_CLIENT_INFO',
  DEEM_VIRTUAL_SESSION_LOGON_DURATION = 'DEEM_VIRTUAL_SESSION_LOGON_DURATION',
  DEEM_VIRTUAL_SESSION_CPU_USAGE = 'DEEM_VIRTUAL_SESSION_CPU_USAGE',
  DEEM_VIRTUAL_SESSION_DISK_LATENCY = 'DEEM_VIRTUAL_SESSION_DISK_LATENCY',
  DEEM_VIRTUAL_SESSION_DISK_IOPS = 'DEEM_VIRTUAL_SESSION_DISK_IOPS',
  DEEM_VIRTUAL_SESSION_MEMORY_USAGE = 'DEEM_VIRTUAL_SESSION_MEMORY_USAGE',
  DEEM_VIRTUAL_SESSION_LAN_LATENCY = 'DEEM_VIRTUAL_SESSION_LAN_LATENCY',
  DEEM_VIRTUAL_SESSION_WAN_LATENCY = 'DEEM_VIRTUAL_SESSION_WAN_LATENCY',
  DEEM_VIRTUAL_SESSION_PROTOCOL_LATENCY = 'DEEM_VIRTUAL_SESSION_PROTOCOL_LATENCY',
  DEEM_VIRTUAL_SESSION_PROTOCOL_PACKET_LOSS = 'DEEM_VIRTUAL_SESSION_PROTOCOL_PACKET_LOSS',
  DEEM_VIRTUAL_SESSION_WIFI_SIGNAL_STRENGTH = 'DEEM_VIRTUAL_SESSION_WIFI_SIGNAL_STRENGTH',

  // Horizon Infrastructure
  CONNECTION_SERVER_MAX_NUM_SESSIONS = 'CONNECTION_SERVER_MAX_NUM_SESSIONS',
  CONNECTION_SERVER_MAX_CPU_PERCENTAGE = 'CONNECTION_SERVER_MAX_CPU_PERCENTAGE',
  CONNECTION_SERVER_MAX_MEMORY_UTILIZATION_PERCENTAGE = 'CONNECTION_SERVER_MAX_MEMORY_UTILIZATION_PERCENTAGE',
  CONNECTION_SERVER_PERFORMANCE_NUM_SESSIONS = 'CONNECTION_SERVER_PERFORMANCE_NUM_SESSIONS',
  CONNECTION_SERVER_PERFORMANCE_CPU_PERCENTAGE = 'CONNECTION_SERVER_PERFORMANCE_CPU_PERCENTAGE',
  CONNECTION_SERVER_PERFORMANCE_MEMORY_UTILIZATION_PERCENTAGE = 'CONNECTION_SERVER_PERFORMANCE_MEMORY_UTILIZATION_PERCENTAGE',
  // eslint-disable-next-line max-len
  _CONNECTION_SERVER_LIST_BY_OVERALL_STATUS_CPU_MEMORY_NUMSESSION_SCORE = '_CONNECTION_SERVER_LIST_BY_OVERALL_STATUS_CPU_MEMORY_NUMSESSION_SCORE',
  CONNECTION_SERVER_LIST = 'CONNECTION_SERVER_LIST',
  __CONNECTION_SERVER_LIST_BY_CERT_SCORE = '__CONNECTION_SERVER_LIST_BY_CERT_SCORE',
  CONNECTION_SERVER_LIST_BY_CERT_SCORE = 'CONNECTION_SERVER_LIST_BY_CERT_SCORE',
  __CONNECTION_SERVER_LIST_BY_CPU_SCORE = '__CONNECTION_SERVER_LIST_BY_CPU_SCORE',
  CONNECTION_SERVER_LIST_BY_CPU_UTILIZATION_SCORE = 'CONNECTION_SERVER_LIST_BY_CPU_UTILIZATION_SCORE',
  __CONNECTION_SERVER_LIST_BY_MEMORY_SCORE = '__CONNECTION_SERVER_LIST_BY_MEMORY_SCORE',
  CONNECTION_SERVER_LIST_BY_MEMORY_UTILIZATION_SCORE = 'CONNECTION_SERVER_LIST_BY_MEMORY_UTILIZATION_SCORE',
  __CONNECTION_SERVER_LIST_BY_NUM_SESSION_SCORE = '__CONNECTION_SERVER_LIST_BY_NUM_SESSION_SCORE',
  CONNECTION_SERVER_LIST_BY_NUM_SESSIONS_SCORE = 'CONNECTION_SERVER_LIST_BY_NUM_SESSIONS_SCORE',
  __CONNECTION_SERVER_LIST_BY_STATUS_SCORE = '__CONNECTION_SERVER_LIST_BY_STATUS_SCORE',
  CONNECTION_SERVER_LIST_BY_STATUS_SCORE = 'CONNECTION_SERVER_LIST_BY_STATUS_SCORE',
  COUNT_OF_SCORES_BY_CONNECTION_SERVER_OVERALL_SCORE = 'COUNT_OF_SCORES_BY_CONNECTION_SERVER_OVERALL_SCORE',
  COUNT_OF_SCORES_BY_CONNECTION_SERVER_STATUS_SCORE = 'COUNT_OF_SCORES_BY_CONNECTION_SERVER_STATUS_SCORE',
  COUNT_OF_SCORES_BY_CONNECTION_SERVER_CERT_SCORE = 'COUNT_OF_SCORES_BY_CONNECTION_SERVER_CERT_SCORE',
  COUNT_OF_SCORES_BY_CONNECTION_SERVER_CPU_UTILIZATION_SCORE = 'COUNT_OF_SCORES_BY_CONNECTION_SERVER_CPU_UTILIZATION_SCORE',
  COUNT_OF_SCORES_BY_CONNECTION_SERVER_MEMORY_UTILIZATION_SCORE = 'COUNT_OF_SCORES_BY_CONNECTION_SERVER_MEMORY_UTILIZATION_SCORE',
  COUNT_OF_SCORES_BY_CONNECTION_SERVER_NUM_SESSIONS_SCORE = 'COUNT_OF_SCORES_BY_CONNECTION_SERVER_NUM_SESSIONS_SCORE',
  CONNECTION_SERVER_CPU_UTILIZATION_SCORE_STAT = 'CONNECTION_SERVER_CPU_UTILIZATION_SCORE_STAT',
  CONNECTION_SERVER_MEMORY_UTILIZATION_SCORE_STAT = 'CONNECTION_SERVER_MEMORY_UTILIZATION_SCORE_STAT',
  CONNECTION_SERVER_NUM_SESSIONS_SCORE_STAT = 'CONNECTION_SERVER_NUM_SESSIONS_SCORE_STAT',
  CONNECTION_SERVER_STATUS_SCORE_STAT = 'CONNECTION_SERVER_STATUS_SCORE_STAT',
  CONNECTION_SERVER_CERT_SCORE_STAT = 'CONNECTION_SERVER_CERT_SCORE_STAT',
  // eslint-disable-next-line max-len
  _UAG_APPLIANCE_LIST_BY_OVERALL_CONNECTIONCOUNT_CPU_MEMORY_SCORE = '_UAG_APPLIANCE_LIST_BY_OVERALL_CONNECTIONCOUNT_CPU_MEMORY_SCORE',
  UAG_APPLIANCE_LIST = 'UAG_APPLIANCE_LIST',
  __UAG_APPLIANCE_LIST_BY_CERT_SCORE = '__UAG_APPLIANCE_LIST_BY_CERT_SCORE',
  UAG_APPLIANCE_LIST_BY_CERT_SCORE = 'UAG_APPLIANCE_LIST_BY_CERT_SCORE',
  __UAG_APPLIANCE_LIST_BY_CONNECTION_COUNT_SCORE = '__UAG_APPLIANCE_LIST_BY_CONNECTION_COUNT_SCORE',
  UAG_APPLIANCE_LIST_BY_CONNECTION_COUNT_SCORE = 'UAG_APPLIANCE_LIST_BY_CONNECTION_COUNT_SCORE',
  __UAG_APPLIANCE_LIST_BY_CPU_SCORE = '__UAG_APPLIANCE_LIST_BY_CPU_SCORE',
  UAG_APPLIANCE_LIST_BY_CPU_UTILIZATION_SCORE = 'UAG_APPLIANCE_LIST_BY_CPU_UTILIZATION_SCORE',
  __UAG_APPLIANCE_LIST_BY_MEMORY_SCORE = '__UAG_APPLIANCE_LIST_BY_MEMORY_SCORE',
  UAG_APPLIANCE_LIST_BY_MEMORY_USAGE_SCORE = 'UAG_APPLIANCE_LIST_BY_MEMORY_USAGE_SCORE',
  __UAG_APPLIANCE_LIST_BY_STATUS_SCORE = '__UAG_APPLIANCE_LIST_BY_STATUS_SCORE',
  UAG_APPLIANCE_LIST_BY_STATUS_SCORE = 'UAG_APPLIANCE_LIST_BY_STATUS_SCORE',
  COUNT_OF_SCORES_BY_UAG_APPLIANCE_OVERALL_SCORE = 'COUNT_OF_SCORES_BY_UAG_APPLIANCE_OVERALL_SCORE',
  COUNT_OF_SCORES_BY_UAG_APPLIANCE_CERT_SCORE = 'COUNT_OF_SCORES_BY_UAG_APPLIANCE_CERT_SCORE',
  COUNT_OF_SCORES_BY_UAG_APPLIANCE_CONNECTION_COUNT_SCORE = 'COUNT_OF_SCORES_BY_UAG_APPLIANCE_CONNECTION_COUNT_SCORE',
  COUNT_OF_SCORES_BY_UAG_APPLIANCE_CPU_UTILIZATION_SCORE = 'COUNT_OF_SCORES_BY_UAG_APPLIANCE_CPU_UTILIZATION_SCORE',
  COUNT_OF_SCORES_BY_UAG_APPLIANCE_MEMORY_USAGE_SCORE = 'COUNT_OF_SCORES_BY_UAG_APPLIANCE_MEMORY_USAGE_SCORE',
  COUNT_OF_SCORES_BY_UAG_APPLIANCE_STATUS_SCORE = 'COUNT_OF_SCORES_BY_UAG_APPLIANCE_STATUS_SCORE',
  UAG_APPLIANCE_MAX_CONNECTION_COUNT = 'UAG_APPLIANCE_MAX_CONNECTION_COUNT',
  UAG_APPLIANCE_MAX_CPU_PCT = 'UAG_APPLIANCE_MAX_CPU_PCT',
  UAG_APPLIANCE_MAX_MEMORY_PCT = 'UAG_APPLIANCE_MAX_MEMORY_PCT',
  UAG_APPLIANCE_PERFORMANCE_CONNECTION_COUNT = 'UAG_APPLIANCE_PERFORMANCE_CONNECTION_COUNT',
  UAG_APPLIANCE_PERFORMANCE_CPU_PCT = 'UAG_APPLIANCE_PERFORMANCE_CPU_PCT',
  UAG_APPLIANCE_PERFORMANCE_MEMORY_PCT = 'UAG_APPLIANCE_PERFORMANCE_MEMORY_PCT',
  UAG_APPLIANCE_CONNECTION_COUNT_SCORE_STAT = 'UAG_APPLIANCE_CONNECTION_COUNT_SCORE_STAT',
  UAG_APPLIANCE_CERT_SCORE_STAT = 'UAG_APPLIANCE_CERT_SCORE_STAT',
  UAG_APPLIANCE_CPU_UTILIZATION_SCORE_STAT = 'UAG_APPLIANCE_CPU_UTILIZATION_SCORE_STAT',
  UAG_APPLIANCE_MEMORY_USAGE_SCORE_STAT = 'UAG_APPLIANCE_MEMORY_USAGE_SCORE_STAT',
  UAG_APPLIANCE_STATUS_SCORE_STAT = 'UAG_APPLIANCE_STATUS_SCORE_STAT',
  // contributors
  DEEM_VIRTUAL_CONNECTION_SERVER_CERT_SCORE_COUNT = 'DEEM_VIRTUAL_CONNECTION_SERVER_CERT_SCORE_COUNT',
  DEEM_VIRTUAL_CONNECTION_SERVER_CPU_SCORE_COUNT = 'DEEM_VIRTUAL_CONNECTION_SERVER_CPU_SCORE_COUNT',
  DEEM_VIRTUAL_CONNECTION_SERVER_MEMORY_SCORE_COUNT = 'DEEM_VIRTUAL_CONNECTION_SERVER_MEMORY_SCORE_COUNT',
  DEEM_VIRTUAL_CONNECTION_SERVER_SESSION_SCORE_COUNT = 'DEEM_VIRTUAL_CONNECTION_SERVER_SESSION_SCORE_COUNT',
  DEEM_VIRTUAL_CONNECTION_SERVER_STATUS_SCORE_COUNT = 'DEEM_VIRTUAL_CONNECTION_SERVER_STATUS_SCORE_COUNT',
  DEEM_VIRTUAL_UAG_APPLIANCE_CERT_SCORE_COUNT = 'DEEM_VIRTUAL_UAG_APPLIANCE_CERT_SCORE_COUNT',
  DEEM_VIRTUAL_UAG_APPLIANCE_CPU_SCORE_COUNT = 'DEEM_VIRTUAL_UAG_APPLIANCE_CPU_SCORE_COUNT',
  DEEM_VIRTUAL_UAG_APPLIANCE_MEMORY_SCORE_COUNT = 'DEEM_VIRTUAL_UAG_APPLIANCE_MEMORY_SCORE_COUNT',
  DEEM_VIRTUAL_UAG_APPLIANCE_CONNECTION_SCORE_COUNT = 'DEEM_VIRTUAL_UAG_APPLIANCE_CONNECTION_SCORE_COUNT',
  DEEM_VIRTUAL_UAG_APPLIANCE_STATUS_SCORE_COUNT = 'DEEM_VIRTUAL_UAG_APPLIANCE_STATUS_SCORE_COUNT',
  // Mgt Comp detail
  CONNECTION_SERVER_DETAIL_AVG_CPU_PERCENTAGE = 'CONNECTION_SERVER_DETAIL_AVG_CPU_PERCENTAGE',
  CONNECTION_SERVER_DETAIL_AVG_MEMORY_UTILIZATION_PERCENTAGE = 'CONNECTION_SERVER_DETAIL_AVG_MEMORY_UTILIZATION_PERCENTAGE',
  CONNECTION_SERVER_DETAIL_AVG_NUM_SESSIONS = 'CONNECTION_SERVER_DETAIL_AVG_NUM_SESSIONS',
  CONNECTION_SERVER_DETAIL_CERT_BUILDNUM_STATUS_IP_META = 'CONNECTION_SERVER_DETAIL_CERT_BUILDNUM_STATUS_IP_META',
  CONNECTION_SERVER_DETAIL_MAX_CPU_PERCENTAGE = 'CONNECTION_SERVER_DETAIL_MAX_CPU_PERCENTAGE',
  CONNECTION_SERVER_DETAIL_MAX_MEMORY_UTILIZATION_PERCENTAGE = 'CONNECTION_SERVER_DETAIL_MAX_MEMORY_UTILIZATION_PERCENTAGE',
  CONNECTION_SERVER_DETAIL_MAX_NUM_SESSIONS = 'CONNECTION_SERVER_DETAIL_MAX_NUM_SESSIONS',
  CONNECTION_SERVER_DETAIL_PERFORMANCE_AVG_CPU_PERCENTAGE = 'CONNECTION_SERVER_DETAIL_PERFORMANCE_AVG_CPU_PERCENTAGE',
  // eslint-disable-next-line max-len
  CONNECTION_SERVER_DETAIL_PERFORMANCE_AVG_MEMORY_UTILIZATION_PERCENTAGE = 'CONNECTION_SERVER_DETAIL_PERFORMANCE_AVG_MEMORY_UTILIZATION_PERCENTAGE',
  CONNECTION_SERVER_DETAIL_PERFORMANCE_AVG_NUM_SESSIONS = 'CONNECTION_SERVER_DETAIL_PERFORMANCE_AVG_NUM_SESSIONS',
  CONNECTION_SERVER_DETAIL_PERFORMANCE_MAX_CPU_PERCENTAGE = 'CONNECTION_SERVER_DETAIL_PERFORMANCE_MAX_CPU_PERCENTAGE',
  // eslint-disable-next-line max-len
  CONNECTION_SERVER_DETAIL_PERFORMANCE_MAX_MEMORY_UTILIZATION_PERCENTAGE = 'CONNECTION_SERVER_DETAIL_PERFORMANCE_MAX_MEMORY_UTILIZATION_PERCENTAGE',
  CONNECTION_SERVER_DETAIL_PERFORMANCE_MAX_NUM_SESSIONS = 'CONNECTION_SERVER_DETAIL_PERFORMANCE_MAX_NUM_SESSIONS',
  CONNECTION_SERVER_DETAIL_SESSIONS_CPU_EDGE_META = 'CONNECTION_SERVER_DETAIL_SESSIONS_CPU_EDGE_META',
  CONNECTION_SERVER_DETAIL_TIMELINE_EVENTS_ERROR_STATUS = 'CONNECTION_SERVER_DETAIL_TIMELINE_EVENTS_ERROR_STATUS',
  MGT_COMP_DETAIL_SCORE = 'MGT_COMP_DETAIL_SCORE',
  UAG_APPLIANCE_DETAIL_AVG_CONNECTION_COUNT = 'UAG_APPLIANCE_DETAIL_AVG_CONNECTION_COUNT',
  UAG_APPLIANCE_DETAIL_AVG_CPU_PCT = 'UAG_APPLIANCE_DETAIL_AVG_CPU_PCT',
  UAG_APPLIANCE_DETAIL_AVG_MEMORY_PCT = 'UAG_APPLIANCE_DETAIL_AVG_MEMORY_PCT',
  UAG_APPLIANCE_DETAIL_CONNECTIONCOUNT_STATUS_CERTEND_METADATA = 'UAG_APPLIANCE_DETAIL_CONNECTIONCOUNT_STATUS_CERTEND_METADATA',
  UAG_APPLIANCE_DETAIL_FQDN_METADATA = 'UAG_APPLIANCE_DETAIL_FQDN_METADATA',
  UAG_APPLIANCE_DETAIL_MAX_CONNECTION_COUNT = 'UAG_APPLIANCE_DETAIL_MAX_CONNECTION_COUNT',
  UAG_APPLIANCE_DETAIL_MAX_CPU_PCT = 'UAG_APPLIANCE_DETAIL_MAX_CPU_PCT',
  UAG_APPLIANCE_DETAIL_MAX_MEMORY_PCT = 'UAG_APPLIANCE_DETAIL_MAX_MEMORY_PCT',
  UAG_APPLIANCE_DETAIL_PERFORMANCE_AVG_CONNECTION_COUNT = 'UAG_APPLIANCE_DETAIL_PERFORMANCE_AVG_CONNECTION_COUNT',
  UAG_APPLIANCE_DETAIL_PERFORMANCE_AVG_CPU_PCT = 'UAG_APPLIANCE_DETAIL_PERFORMANCE_AVG_CPU_PCT',
  UAG_APPLIANCE_DETAIL_PERFORMANCE_AVG_MEMORY_PCT = 'UAG_APPLIANCE_DETAIL_PERFORMANCE_AVG_MEMORY_PCT',
  UAG_APPLIANCE_DETAIL_PERFORMANCE_MAX_CONNECTION_COUNT = 'UAG_APPLIANCE_DETAIL_PERFORMANCE_MAX_CONNECTION_COUNT',
  UAG_APPLIANCE_DETAIL_PERFORMANCE_MAX_CPU_PCT = 'UAG_APPLIANCE_DETAIL_PERFORMANCE_MAX_CPU_PCT',
  UAG_APPLIANCE_DETAIL_PERFORMANCE_MAX_MEMORY_PCT = 'UAG_APPLIANCE_DETAIL_PERFORMANCE_MAX_MEMORY_PCT',
  UAG_APPLIANCE_DETAIL_TIMELINE_EVENTS_ERROR_STATUS = 'UAG_APPLIANCE_DETAIL_TIMELINE_EVENTS_ERROR_STATUS',
  __CONNECTION_SERVER_DETAIL_PERFORMANCE_CPU_PERCENTAGE = '__CONNECTION_SERVER_DETAIL_PERFORMANCE_CPU_PERCENTAGE',
  // eslint-disable-next-line max-len
  __CONNECTION_SERVER_DETAIL_PERFORMANCE_MEMORY_UTILIZATION_PERCENTAGE = '__CONNECTION_SERVER_DETAIL_PERFORMANCE_MEMORY_UTILIZATION_PERCENTAGE',
  __CONNECTION_SERVER_DETAIL_PERFORMANCE_NUM_SESSIONS = '__CONNECTION_SERVER_DETAIL_PERFORMANCE_NUM_SESSIONS',
  __CONNECTION_SERVER_DETAIL_TIMELINE_EVENTS_ERROR_STATUS = '__CONNECTION_SERVER_DETAIL_TIMELINE_EVENTS_ERROR_STATUS',
  __UAG_APPLIANCE_DETAIL_PERFORMANCE_CONNECTION_COUNT = '__UAG_APPLIANCE_DETAIL_PERFORMANCE_CONNECTION_COUNT',
  __UAG_APPLIANCE_DETAIL_PERFORMANCE_CPU_PCT = '__UAG_APPLIANCE_DETAIL_PERFORMANCE_CPU_PCT',
  __UAG_APPLIANCE_DETAIL_PERFORMANCE_MEMORY_PCT = '__UAG_APPLIANCE_DETAIL_PERFORMANCE_MEMORY_PCT',
  __UAG_APPLIANCE_DETAIL_TIMELINE_EVENTS_ERROR_STATUS = '__UAG_APPLIANCE_DETAIL_TIMELINE_EVENTS_ERROR_STATUS',

  // DEEM - virtual
  DEEM_VIRTUAL_ORG_OVERALL_SCORE_LAST_4_HR = 'DEEM_VIRTUAL_ORG_OVERALL_SCORE_LAST_4_HR',
  DEEM_VIRTUAL_SESSION_COUNT_WITH_POOR_SCORE_LAST_4_HR = 'DEEM_VIRTUAL_SESSION_COUNT_WITH_POOR_SCORE_LAST_4_HR',
  DEEM_VIRTUAL_SESSION_COUNT_WITH_POOR_SCORE_LAST_8_HR = 'DEEM_VIRTUAL_SESSION_COUNT_WITH_POOR_SCORE_LAST_8_HR',
  DEEM_VIRTUAL_SESSION_COUNT_WITH_NEUTRAL_SCORE_LAST_4_HR = 'DEEM_VIRTUAL_SESSION_COUNT_WITH_NEUTRAL_SCORE_LAST_4_HR',
  DEEM_VIRTUAL_SESSION_COUNT_WITH_GOOD_SCORE_LAST_4_HR = 'DEEM_VIRTUAL_SESSION_COUNT_WITH_GOOD_SCORE_LAST_4_HR',
  DEEM_VIRTUAL_SESSIONS_WITH_POOR_NEUTRAL_GOOD_SCORE_COUNT_LAST_4_HR = 'DEEM_VIRTUAL_SESSIONS_WITH_POOR_NEUTRAL_GOOD_SCORE_COUNT_LAST_4_HR',
  // eslint-disable-next-line max-len
  _DEEM_VIRTUAL_SESSIONS_WITH_POOR_NEUTRAL_GOOD_SCORE_COUNT_LAST_4_HR = '_DEEM_VIRTUAL_SESSIONS_WITH_POOR_NEUTRAL_GOOD_SCORE_COUNT_LAST_4_HR',
  DEEM_VIRTUAL_LOGON_EXPERIENCE_TOTAL_COUNT_LAST_4_HR = 'DEEM_VIRTUAL_LOGON_EXPERIENCE_TOTAL_COUNT_LAST_4_HR',
  // eslint-disable-next-line max-len
  DEEM_VIRTUAL_LOGON_EXPERIENCE_SESSION_COUNT_WITH_NEUTRAL_SCORE_COUNT_LAST_4_HR = 'DEEM_VIRTUAL_LOGON_EXPERIENCE_SESSION_COUNT_WITH_NEUTRAL_SCORE_COUNT_LAST_4_HR',
  // eslint-disable-next-line max-len
  DEEM_VIRTUAL_LOGON_EXPERIENCE_SESSION_COUNT_WITH_GOOD_SCORE_COUNT_LAST_4_HR = 'DEEM_VIRTUAL_LOGON_EXPERIENCE_SESSION_COUNT_WITH_GOOD_SCORE_COUNT_LAST_4_HR',
  DEEM_VIRTUAL_SESSION_TOTAL_COUNT_LAST_4_HR = 'DEEM_VIRTUAL_SESSION_TOTAL_COUNT_LAST_4_HR',
  DEEM_VIRTUAL_SESSION_TOTAL_COUNT_LAST_8_HR_V2 = 'DEEM_VIRTUAL_SESSION_TOTAL_COUNT_LAST_8_HR_V2',

  DEEM_VIRTUAL_LOGON_EXPERIENCE_WITH_POOR_SCORE_COUNT_LAST_4_HR_V2 = 'DEEM_VIRTUAL_LOGON_EXPERIENCE_WITH_POOR_SCORE_COUNT_LAST_4_HR_V2',
  // eslint-disable-next-line max-len
  DEEM_VIRTUAL_LOGON_EXPERIENCE_WITH_NEUTRAL_SCORE_COUNT_LAST_4_HR_V2 = 'DEEM_VIRTUAL_LOGON_EXPERIENCE_WITH_NEUTRAL_SCORE_COUNT_LAST_4_HR_V2',
  DEEM_VIRTUAL_LOGON_EXPERIENCE_WITH_GOOD_SCORE_COUNT_LAST_4_HR_V2 = 'DEEM_VIRTUAL_LOGON_EXPERIENCE_WITH_GOOD_SCORE_COUNT_LAST_4_HR_V2',

  DEEM_VIRTUAL_NETWORK_PROTOCOL_WITH_POOR_SCORE_COUNT_LAST_4_HR = 'DEEM_VIRTUAL_NETWORK_PROTOCOL_WITH_POOR_SCORE_COUNT_LAST_4_HR',

  DEEM_VIRTUAL_VM_PERFORMANCE_WITH_POOR_SCORE_COUNT_LAST_4_HR = 'DEEM_VIRTUAL_VM_PERFORMANCE_WITH_POOR_SCORE_COUNT_LAST_4_HR',

  // DEEM - Virtual Contributors
  DEEM_VIRTUAL_SESSION_COUNT_BY_LOGON_DURATION_SCORE_V2 = 'DEEM_VIRTUAL_SESSION_COUNT_BY_LOGON_DURATION_SCORE_V2',
  DEEN_VIRTUAL_SESSION_COUNT_BY_WIFI_SIGNAL_STRENGTH_SCORE = 'DEEN_VIRTUAL_SESSION_COUNT_BY_WIFI_SIGNAL_STRENGTH_SCORE',
  DEEM_VIRTUAL_SESSION_COUNT_BY_PACKET_LOSS_SCORE = 'DEEM_VIRTUAL_SESSION_COUNT_BY_PACKET_LOSS_SCORE',
  DEEM_VIRTUAL_SESSION_COUNT_BY_PROTOCOL_LATENCY_SCORE = 'DEEM_VIRTUAL_SESSION_COUNT_BY_PROTOCOL_LATENCY_SCORE',
  DEEM_VIRTUAL_SESSION_COUNT_BY_LAN_LATENCY_SCORE = 'DEEM_VIRTUAL_SESSION_COUNT_BY_LAN_LATENCY_SCORE',
  DEEM_VIRTUAL_SESSION_COUNT_BY_WAN_LATENCY_SCORE = 'DEEM_VIRTUAL_SESSION_COUNT_BY_WAN_LATENCY_SCORE',
  DEEM_VIRTUAL_SESSION_COUNT_BY_CPU_USAGE_SCORE = 'DEEM_VIRTUAL_SESSION_COUNT_BY_CPU_USAGE_SCORE',
  DEEM_VIRTUAL_SESSION_COUNT_BY_MEMORY_USAGE_SCORE = 'DEEM_VIRTUAL_SESSION_COUNT_BY_MEMORY_USAGE_SCORE',
  DEEM_VIRTUAL_SESSION_COUNT_BY_DISK_LATENCY_SCORE = 'DEEM_VIRTUAL_SESSION_COUNT_BY_DISK_LATENCY_SCORE',
  DEEM_VIRTUAL_SESSION_COUNT_BY_DISK_IOPS_SCORE = 'DEEM_VIRTUAL_SESSION_COUNT_BY_DISK_IOPS_SCORE',
  DEEM_VIRTUAL_SESSION_LIST_BY_CPU_USAGE = 'DEEM_VIRTUAL_SESSION_LIST_BY_CPU_USAGE',
  DEEM_VIRTUAL_SESSION_LIST_BY_DISK_IOPS = 'DEEM_VIRTUAL_SESSION_LIST_BY_DISK_IOPS',
  DEEM_VIRTUAL_SESSION_LIST_BY_DISK_LATENCY = 'DEEM_VIRTUAL_SESSION_LIST_BY_DISK_LATENCY',
  DEEM_VIRTUAL_SESSION_LIST_BY_LAN_LATENCY = 'DEEM_VIRTUAL_SESSION_LIST_BY_LAN_LATENCY',
  DEEM_VIRTUAL_SESSION_LIST_BY_MEMORY_USAGE = 'DEEM_VIRTUAL_SESSION_LIST_BY_MEMORY_USAGE',
  DEEM_VIRTUAL_SESSION_LIST_BY_PACKET_LOSS = 'DEEM_VIRTUAL_SESSION_LIST_BY_PACKET_LOSS',
  DEEM_VIRTUAL_SESSION_LIST_BY_PROTOCOL_LATENCY = 'DEEM_VIRTUAL_SESSION_LIST_BY_PROTOCOL_LATENCY',
  DEEM_VIRTUAL_SESSION_LIST_BY_WAN_LATENCY = 'DEEM_VIRTUAL_SESSION_LIST_BY_WAN_LATENCY',
  DEEM_VIRTUAL_SESSION_LIST_BY_WIFI_SIGNAL_STRENGTH = 'DEEM_VIRTUAL_SESSION_LIST_BY_WIFI_SIGNAL_STRENGTH',

  _DEEM_VIRTUAL_LOGON_DURATION_BY_PHASE = '_DEEM_VIRTUAL_LOGON_DURATION_BY_PHASE',
  _DEEM_VIRTUAL_LOGON_DURATION_BY_PHASE_EMPTY = '_DEEM_VIRTUAL_LOGON_DURATION_BY_PHASE_EMPTY',
  DEEM_VIRTUAL_LOGON_DURATION_BY_GPO_LOAD = 'DEEM_VIRTUAL_LOGON_DURATION_BY_GPO_LOAD',
  DEEM_VIRTUAL_LOGON_DURATION_BY_PROFILE_LOAD = 'DEEM_VIRTUAL_LOGON_DURATION_BY_PROFILE_LOAD',
  DEEM_VIRTUAL_LOGON_DURATION_BY_SHELL_LOAD = 'DEEM_VIRTUAL_LOGON_DURATION_BY_SHELL_LOAD',
  DEEM_VIRTUAL_LOGON_DURATION_BY_LOGON_SCRIPT = 'DEEM_VIRTUAL_LOGON_DURATION_BY_LOGON_SCRIPT',
  DEEM_VIRTUAL_LOGON_DURATION_BY_OTHER = 'DEEM_VIRTUAL_LOGON_DURATION_BY_OTHER',
  _DEEM_VIRTUAL_POOLS_WITH_LONGEST_LOGON_DURATION = '_DEEM_VIRTUAL_POOLS_WITH_LONGEST_LOGON_DURATION',
  DEEM_VIRTUAL_POOLS_WITH_LONGEST_LOGON_DURATION = 'DEEM_VIRTUAL_POOLS_WITH_LONGEST_LOGON_DURATION',

  // DEEM - Virtual User Logon Duration
  _DEEM_VIRTUAL_USER_LATEST_LOGON_DURATION = '_DEEM_VIRTUAL_USER_LATEST_LOGON_DURATION',
  DEEM_VIRTUAL_USER_LATEST_LOGON_DURATION = 'DEEM_VIRTUAL_USER_LATEST_LOGON_DURATION',
  DEEM_VIRTUAL_USER_LATEST_LOGON_DURATION_SCORE = 'DEEM_VIRTUAL_USER_LATEST_LOGON_DURATION_SCORE',
  DEEM_VIRTUAL_USER_LATEST_GPO_LOAD_DURATION = 'DEEM_VIRTUAL_USER_LATEST_GPO_LOAD_DURATION',
  DEEM_VIRTUAL_USER_LATEST_PROFILE_LOAD_DURATION = 'DEEM_VIRTUAL_USER_LATEST_PROFILE_LOAD_DURATION',
  DEEM_VIRTUAL_USER_LATEST_SHELL_LOAD_DURATION = 'DEEM_VIRTUAL_USER_LATEST_SHELL_LOAD_DURATION',
  DEEM_VIRTUAL_USER_LATEST_LOGON_SCRIPT_DURATION = 'DEEM_VIRTUAL_USER_LATEST_LOGON_SCRIPT_DURATION',
  DEEM_VIRTUAL_USER_LATEST_LOGON_OTHER_DURATION = 'DEEM_VIRTUAL_USER_LATEST_LOGON_OTHER_DURATION',
  _DEEM_VIRTUAL_USER_LOGON_DURATION_BY_PHASE = '_DEEM_VIRTUAL_USER_LOGON_DURATION_BY_PHASE',
  DEEM_VIRTUAL_USER_LOGON_GPO_LOAD_DURATION = 'DEEM_VIRTUAL_USER_LOGON_GPO_LOAD_DURATION',
  DEEM_VIRTUAL_USER_LOGON_PROFILE_LOAD_DURATION = 'DEEM_VIRTUAL_USER_LOGON_PROFILE_LOAD_DURATION',
  DEEM_VIRTUAL_USER_LOGON_SHELL_LOAD_DURATION = 'DEEM_VIRTUAL_USER_LOGON_SHELL_LOAD_DURATION',
  DEEM_VIRTUAL_USER_LOGON_LOGON_SCRIPT_DURATION = 'DEEM_VIRTUAL_USER_LOGON_LOGON_SCRIPT_DURATION',
  DEEM_VIRTUAL_USER_LOGON_OTHER_DURATION = 'DEEM_VIRTUAL_USER_LOGON_OTHER_DURATION',
  DEEM_VIRTUAL_USER_LATEST_LOGON_TIME = 'DEEM_VIRTUAL_USER_LATEST_LOGON_TIME',

  DEEM_INCIDENT_DESKTOP_APPS_BY_WINDOWS = 'DEEM_INCIDENT_DESKTOP_APPS_BY_WINDOWS',
  DEEM_INCIDENT_DEVICE = 'DEEM_INCIDENT_DEVICE',
  DEEM_INCIDENT_MOBILE_APPS_BY_ANDROID = 'DEEM_INCIDENT_MOBILE_APPS_BY_ANDROID',
  DEEM_INCIDENT_MOBILE_APPS_BY_APPLE = 'DEEM_INCIDENT_MOBILE_APPS_BY_APPLE',
  DEEM_INCIDENT_USER = 'DEEM_INCIDENT_USER',

  _DEEM_DEVICE_WIDGET_DETAIL_24_HR = '_DEEM_DEVICE_WIDGET_DETAIL_24_HR',
  DEVICE_DRILL_DOWN_CRASH_24_HR = 'DEVICE_DRILL_DOWN_CRASH_24_HR',
  DEVICE_DRILL_DOWN_BOOT_TIME_24_HR = 'DEVICE_DRILL_DOWN_BOOT_TIME_24_HR',
  DEVICE_DRILL_DOWN_SHUTDOWN_TIME_24_HR = 'DEVICE_DRILL_DOWN_SHUTDOWN_TIME_24_HR',
  DEVICE_DRILL_DOWN_BATTERY_HEALTH_24_HR = 'DEVICE_DRILL_DOWN_BATTERY_HEALTH_24_HR',
  DEVICE_DRILL_DOWN_CPU_HEALTH_24_HR = 'DEVICE_DRILL_DOWN_CPU_HEALTH_24_HR',
  DEVICE_DRILL_DOWN_GPU_HEALTH_24_HR = 'DEVICE_DRILL_DOWN_GPU_HEALTH_24_HR',
  DEVICE_DRILL_DOWN_MEMORY_HEALTH_24_HR = 'DEVICE_DRILL_DOWN_MEMORY_HEALTH_24_HR',
  DEVICE_DRILL_DOWN_TOTAL_DEVICES_24_HR = 'DEVICE_DRILL_DOWN_TOTAL_DEVICES_24_HR',

  _DEEM_DEVICE_WIDGET_DETAIL_4_HR = '_DEEM_DEVICE_WIDGET_DETAIL_4_HR',
  DEVICE_DRILL_DOWN_CRASH_4_HR = 'DEVICE_DRILL_DOWN_CRASH_4_HR',
  DEVICE_DRILL_DOWN_BOOT_TIME_4_HR = 'DEVICE_DRILL_DOWN_BOOT_TIME_4_HR',
  DEVICE_DRILL_DOWN_SHUTDOWN_TIME_4_HR = 'DEVICE_DRILL_DOWN_SHUTDOWN_TIME_4_HR',
  DEVICE_DRILL_DOWN_BATTERY_HEALTH_4_HR = 'DEVICE_DRILL_DOWN_BATTERY_HEALTH_4_HR',
  DEVICE_DRILL_DOWN_CPU_HEALTH_4_HR = 'DEVICE_DRILL_DOWN_CPU_HEALTH_4_HR',
  DEVICE_DRILL_DOWN_GPU_HEALTH_4_HR = 'DEVICE_DRILL_DOWN_GPU_HEALTH_4_HR',
  DEVICE_DRILL_DOWN_MEMORY_HEALTH_4_HR = 'DEVICE_DRILL_DOWN_MEMORY_HEALTH_4_HR',
  DEVICE_DRILL_DOWN_WIFI_STRENGTH_4_HR = 'DEVICE_DRILL_DOWN_WIFI_STRENGTH_4_HR',
  DEVICE_DRILL_DOWN_DOWNLOAD_SPEED_4_HR = 'DEVICE_DRILL_DOWN_DOWNLOAD_SPEED_4_HR',
  DEVICE_DRILL_DOWN_UPLOAD_SPEED_4_HR = 'DEVICE_DRILL_DOWN_UPLOAD_SPEED_4_HR',
  DEVICE_DRILL_DOWN_TOTAL_DEVICES_4_HR = 'DEVICE_DRILL_DOWN_TOTAL_DEVICES_4_HR',

  // Deem - contributors - device
  DEEM_DEVICE_CRASH_COUNT_LAST_4_HR = 'DEEM_DEVICE_CRASH_COUNT_LAST_4_HR',
  DEEM_DEVICE_CPU_HEALTH_LAST_4_HR = 'DEEM_DEVICE_CPU_HEALTH_LAST_4_HR',
  DEEM_DEVICE_CPU_TEMPERATURE_LAST_4_HR = 'DEEM_DEVICE_CPU_TEMPERATURE_LAST_4_HR',
  DEEM_DEVICE_BATTERY_HEALTH_LAST_4_HR = 'DEEM_DEVICE_BATTERY_HEALTH_LAST_4_HR',
  DEEM_DEVICE_MEMORY_HEALTH_LAST_4_HR = 'DEEM_DEVICE_MEMORY_HEALTH_LAST_4_HR',
  DEEM_DEVICE_GPU_HEALTH_LAST_4_HR = 'DEEM_DEVICE_GPU_HEALTH_LAST_4_HR',
  DEEM_DEVICE_SHUTDOWN_TIME_LAST_4_HR = 'DEEM_DEVICE_SHUTDOWN_TIME_LAST_4_HR',
  DEEM_DEVICE_BOOT_TIME_LAST_4_HR = 'DEEM_DEVICE_BOOT_TIME_LAST_4_HR',
  DEEM_DEVICE_WIFI_STRENGTH_LAST_4_HR = 'DEEM_DEVICE_WIFI_STRENGTH_LAST_4_HR',
  DEEM_DEVICE_DOWNLOAD_SPEED_LAST_4_HR = 'DEEM_DEVICE_DOWNLOAD_SPEED_LAST_4_HR',
  DEEM_DEVICE_UPLOAD_SPEED_LAST_4_HR = 'DEEM_DEVICE_UPLOAD_SPEED_LAST_4_HR',

  // Deem - contributors - desktop
  DEEM_DESKTOP_APP_CRASH_RATE_SCORE_LAST_4_HR = 'DEEM_DESKTOP_APP_CRASH_RATE_SCORE_LAST_4_HR',
  DEEM_DESKTOP_APP_HANG_RATE_SCORE_LAST_4_HR = 'DEEM_DESKTOP_APP_HANG_RATE_SCORE_LAST_4_HR',
  DEEM_DESKTOP_APP_CPU_USAGE_SCORE_LAST_4_HR = 'DEEM_DESKTOP_APP_CPU_USAGE_SCORE_LAST_4_HR',
  DEEM_DESKTOP_APP_GPU_USAGE_SCORE_LAST_4_HR = 'DEEM_DESKTOP_APP_GPU_USAGE_SCORE_LAST_4_HR',
  DEEM_DESKTOP_APP_MEMORY_USAGE_SCORE_LAST_4_HR = 'DEEM_DESKTOP_APP_MEMORY_USAGE_SCORE_LAST_4_HR',
  DEEM_DESKTOP_APP_UI_UNRESPONSIVE_COUNT_SCORE_LAST_4_HR = 'DEEM_DESKTOP_APP_UI_UNRESPONSIVE_COUNT_SCORE_LAST_4_HR',
  DEEM_DESKTOP_APP_UI_UNRESPONSIVE_DURATION_SCORE_LAST_4_HR = 'DEEM_DESKTOP_APP_UI_UNRESPONSIVE_DURATION_SCORE_LAST_4_HR',
  DEEM_DESKTOP_APP_UI_UNRESPONSIVE_RATE_SCORE_LAST_4_HR = 'DEEM_DESKTOP_APP_UI_UNRESPONSIVE_RATE_SCORE_LAST_4_HR',

  // Deem - single Desktop App
  DEEM_DESKTOP_APP_OVERALL_SCORE_LAST_4_HR = 'DEEM_DESKTOP_APP_OVERALL_SCORE_LAST_4_HR',
  DEEM_DESKTOP_APP_TOTAL_VERSION_COUNT = 'DEEM_DESKTOP_APP_TOTAL_VERSION_COUNT',
  DEEM_DESKTOP_APP_TOTAL_VERSION_COUNT_WITH_GOOD_SCORE = 'DEEM_DESKTOP_APP_TOTAL_VERSION_COUNT_WITH_GOOD_SCORE',
  // eslint-disable-next-line max-len
  DEEM_DESKTOP_APP_TOTAL_VERSION_COUNT_WITH_NEUTRAL_SCORE = 'DEEM_DESKTOP_APP_TOTAL_VERSION_COUNT_WITH_NEUTRAL_SCORE',
  DEEM_DESKTOP_APP_TOTAL_VERSION_COUNT_WITH_POOR_SCORE = 'DEEM_DESKTOP_APP_TOTAL_VERSION_COUNT_WITH_POOR_SCORE',

  DEEM_DESKTOP_APP_MAU = 'DEEM_DESKTOP_APP_MAU',
  DEEM_DESKTOP_APP_MAU_LAST_28_DAYS = 'DEEM_DESKTOP_APP_MAU_LAST_28_DAYS',
  DEEM_DESKTOP_APP_MAU_LAST_28_DAYS_OFFSET_1 = 'DEEM_DESKTOP_APP_MAU_LAST_28_DAYS_OFFSET_1',

  DEEM_DESKTOP_APP_USAGE_DURATION = 'DEEM_DESKTOP_APP_USAGE_DURATION',
  DEEM_DESKTOP_APP_USAGE_DURATION_LAST_7_DAYS = 'DEEM_DESKTOP_APP_USAGE_DURATION_LAST_7_DAYS',
  DEEM_DESKTOP_APP_USAGE_DURATION_LAST_7_DAYS_OFFSET_1 = 'DEEM_DESKTOP_APP_USAGE_DURATION_LAST_7_DAYS_OFFSET_1',
  DEEM_DESKTOP_APP_SCORES_BY_VERSION = 'DEEM_DESKTOP_APP_SCORES_BY_VERSION',

  _DEEM_DESKTOP_APP_VERSIONS_TABLE = '_DEEM_DESKTOP_APP_VERSIONS_TABLE',
  DEEM_DESKTOP_APP_VERSIONS_LIST = 'DEEM_DESKTOP_APP_VERSIONS_LIST',
  DEEM_DESKTOP_APP_SESSION_BY_VERSIONS_LIST = 'DEEM_DESKTOP_APP_SESSION_BY_VERSIONS_LIST',

  // Deem - contributors - mobile
  DEEM_MOBILE_APP_CRASH_SCORE_LAST_4_HR = 'DEEM_MOBILE_APP_CRASH_SCORE_LAST_4_HR',
  DEEM_MOBILE_APP_HANDLED_EXCEPTIONS_SCORE_LAST_4_HR = 'DEEM_MOBILE_APP_HANDLED_EXCEPTIONS_SCORE_LAST_4_HR',
  DEEM_MOBILE_APP_NETWORK_ERROR_SCORE_LAST_4_HR = 'DEEM_MOBILE_APP_NETWORK_ERROR_SCORE_LAST_4_HR',
  DEEM_MOBILE_APP_RESPONSE_SCORE_LAST_4_HR = 'DEEM_MOBILE_APP_RESPONSE_SCORE_LAST_4_HR',

  // Deem - single Mobile app
  DEEM_MOBILE_APP_OVERALL_SCORE_LAST_4_HR = 'DEEM_MOBILE_APP_OVERALL_SCORE_LAST_4_HR',
  DEEM_MOBILE_APP_TOTAL_VERSION_COUNT = 'DEEM_MOBILE_APP_TOTAL_VERSION_COUNT',
  DEEM_MOBILE_APP_TOTAL_VERSION_COUNT_WITH_GOOD_SCORE = 'DEEM_MOBILE_APP_TOTAL_VERSION_COUNT_WITH_GOOD_SCORE',
  // eslint-disable-next-line max-len
  DEEM_MOBILE_APP_TOTAL_VERSION_COUNT_WITH_NEUTRAL_SCORE = 'DEEM_MOBILE_APP_TOTAL_VERSION_COUNT_WITH_NEUTRAL_SCORE',
  DEEM_MOBILE_APP_TOTAL_VERSION_COUNT_WITH_POOR_SCORE = 'DEEM_MOBILE_APP_TOTAL_VERSION_COUNT_WITH_POOR_SCORE',
  DEEM_MOBILE_APP_SCORES_BY_VERSION = 'DEEM_MOBILE_APP_SCORES_BY_VERSION',

  _DEEM_MOBILE_APP_VERSIONS_TABLE = '_DEEM_MOBILE_APP_VERSIONS_TABLE',
  DEEM_MOBILE_APP_VERSIONS_LIST = 'DEEM_MOBILE_APP_VERSIONS_LIST',
  DEEM_MOBILE_APP_SESSION_BY_VERSIONS_LIST = 'DEEM_MOBILE_APP_SESSION_BY_VERSIONS_LIST',

  DEEM_ORG_UX_SCORE = 'DEEM_ORG_UX_SCORE',
  DEEM_USER_UX_SCORE = 'DEEM_USER_UX_SCORE',
  DEEM_DESKTOP_CRASH_RATES_BY_APP = 'DEEM_DESKTOP_CRASH_RATES_BY_APP',
  DEEM_MOBILE_CRASH_RATES_BY_APP = 'DEEM_MOBILE_CRASH_RATES_BY_APP',
  DEEM_DESKTOP_APPS_UX_SCORE = 'DEEM_DESKTOP_APPS_UX_SCORE',
  DEEM_MOBILE_APPS_UX_SCORE = 'DEEM_MOBILE_APPS_UX_SCORE',
  DEEM_DEVICE_HEALTH = 'DEEM_DEVICE_HEALTH',
  _DEEM_ORG_WINDOWS_TOP_USED_APPS = '_DEEM_ORG_WINDOWS_TOP_USED_APPS',
  DEEM_ORG_WINDOWS_TOP_USED_APPS = 'DEEM_ORG_WINDOWS_TOP_USED_APPS',
  DEEM_ORG_WINDOWS_TOP_USED_APPS_SCORE = 'DEEM_ORG_WINDOWS_TOP_USED_APPS_SCORE',
  _DEEM_ORG_MACOS_TOP_USED_APPS = '_DEEM_ORG_MACOS_TOP_USED_APPS',
  DEEM_ORG_MACOS_TOP_USED_APPS = 'DEEM_ORG_MACOS_TOP_USED_APPS',
  DEEM_ORG_MACOS_TOP_USED_APPS_SCORE = 'DEEM_ORG_MACOS_TOP_USED_APPS_SCORE',
  _DEEM_ORG_IOS_TOP_USED_APPS = '_DEEM_ORG_IOS_TOP_USED_APPS',
  DEEM_ORG_IOS_TOP_USED_APPS = 'DEEM_ORG_IOS_TOP_USED_APPS',
  DEEM_ORG_IOS_TOP_USED_APPS_SCORE = 'DEEM_ORG_IOS_TOP_USED_APPS_SCORE',
  _DEEM_ORG_ANDROID_TOP_USED_APPS = '_DEEM_ORG_ANDROID_TOP_USED_APPS',
  DEEM_ORG_ANDROID_TOP_USED_APPS = 'DEEM_ORG_ANDROID_TOP_USED_APPS',
  DEEM_ORG_ANDROID_TOP_USED_APPS_SCORE = 'DEEM_ORG_ANDROID_TOP_USED_APPS_SCORE',
  DEEM_ORG_DEVICES_WITH_POOR_EXPERIENCE_COUNT = 'DEEM_ORG_DEVICES_WITH_POOR_EXPERIENCE_COUNT',
  DEEM_ORG_DEVICES_WITH_POOR_EXPERIENCE_COUNT_PREVIOUS_TIME = 'DEEM_ORG_DEVICES_WITH_POOR_EXPERIENCE_COUNT_PREVIOUS_TIME',
  DEEM_ORG_DESKTOP_APPS_WITH_POOR_EXPERIENCE_COUNT = 'DEEM_ORG_DESKTOP_APPS_WITH_POOR_EXPERIENCE_COUNT',
  DEEM_ORG_DESKTOP_APPS_WITH_POOR_EXPERIENCE_COUNT_PREVIOUS_TIME = 'DEEM_ORG_DESKTOP_APPS_WITH_POOR_EXPERIENCE_COUNT_PREVIOUS_TIME',
  DEEM_ORG_MOBILE_APPS_WITH_POOR_EXPERIENCE_COUNT = 'DEEM_ORG_MOBILE_APPS_WITH_POOR_EXPERIENCE_COUNT',
  DEEM_ORG_MOBILE_APPS_WITH_POOR_EXPERIENCE_COUNT_PREVIOUS_TIME = 'DEEM_ORG_MOBILE_APPS_WITH_POOR_EXPERIENCE_COUNT_PREVIOUS_TIME',
  DEEM_ORG_USERS_WITH_POOR_EXPERIENCE_COUNT = 'DEEM_ORG_USERS_WITH_POOR_EXPERIENCE_COUNT',
  DEEM_ORG_USERS_WITH_POOR_EXPERIENCE_COUNT_PREVIOUS_TIME = 'DEEM_ORG_USERS_WITH_POOR_EXPERIENCE_COUNT_PREVIOUS_TIME',
  DEEM_ORG_TOTAL_DEVICES = 'DEEM_ORG_TOTAL_DEVICES',
  DEEM_ORG_TOTAL_DESKTOP_APPS = 'DEEM_ORG_TOTAL_DESKTOP_APPS',
  DEEM_ORG_TOTAL_MOBILE_APPS = 'DEEM_ORG_TOTAL_MOBILE_APPS',
  DEEM_ORG_TOTAL_USERS = 'DEEM_ORG_TOTAL_USERS',
  DEEM_ORG_SCORE_LAST_7_DAYS = 'DEEM_ORG_SCORE_LAST_7_DAYS',

  DEEM_CRASH_COUNT_POOR_SESSIONS = 'DEEM_CRASH_COUNT_POOR_SESSIONS',
  DEEM_CRASH_COUNT_POOR_SESSIONS_PREVIOUS_TIME = 'DEEM_CRASH_COUNT_POOR_SESSIONS_PREVIOUS_TIME',
  DEEM_CPU_USAGE_POOR_SESSIONS = 'DEEM_CPU_USAGE_POOR_SESSIONS',
  DEEM_CPU_USAGE_POOR_SESSIONS_PREVIOUS_TIME = 'DEEM_CPU_USAGE_POOR_SESSIONS_PREVIOUS_TIME',
  DEEM_MEMORY_USAGE_POOR_SESSIONS = 'DEEM_MEMORY_USAGE_POOR_SESSIONS',
  DEEM_MEMORY_USAGE_POOR_SESSIONS_PREVIOUS_TIME = 'DEEM_MEMORY_USAGE_POOR_SESSIONS_PREVIOUS_TIME',
  DEEM_GPU_USAGE_POOR_SESSIONS = 'DEEM_GPU_USAGE_POOR_SESSIONS',
  DEEM_GPU_USAGE_POOR_SESSIONS_PREVIOUS_TIME = 'DEEM_GPU_USAGE_POOR_SESSIONS_PREVIOUS_TIME',
  DEEM_CPU_TEMPERATURE_POOR_SESSIONS = 'DEEM_CPU_TEMPERATURE_POOR_SESSIONS',
  DEEM_CPU_TEMPERATURE_POOR_SESSIONS_PREVIOUS_TIME = 'DEEM_CPU_TEMPERATURE_POOR_SESSIONS_PREVIOUS_TIME',
  DEEM_DOWNLOAD_SPEED_POOR_SESSIONS = 'DEEM_DOWNLOAD_SPEED_POOR_SESSIONS',
  DEEM_DOWNLOAD_SPEED_POOR_SESSIONS_PREVIOUS_TIME = 'DEEM_DOWNLOAD_SPEED_POOR_SESSIONS_PREVIOUS_TIME',
  DEEM_UPLOAD_SPEED_POOR_SESSIONS = 'DEEM_UPLOAD_SPEED_POOR_SESSIONS',
  DEEM_UPLOAD_SPEED_POOR_SESSIONS_PREVIOUS_TIME = 'DEEM_UPLOAD_SPEED_POOR_SESSIONS_PREVIOUS_TIME',
  DEEM_BATTERY_HEALTH_POOR_SESSIONS = 'DEEM_BATTERY_HEALTH_POOR_SESSIONS',
  DEEM_BATTERY_HEALTH_POOR_SESSIONS_PREVIOUS_TIME = 'DEEM_BATTERY_HEALTH_POOR_SESSIONS_PREVIOUS_TIME',
  DEEM_WIFI_STRENGTH_POOR_SESSIONS = 'DEEM_WIFI_STRENGTH_POOR_SESSIONS',
  DEEM_WIFI_STRENGTH_POOR_SESSIONS_PREVIOUS_TIME = 'DEEM_WIFI_STRENGTH_POOR_SESSIONS_PREVIOUS_TIME',
  DEEM_SHUTDOWN_TIME_POOR_SESSIONS = 'DEEM_SHUTDOWN_TIME_POOR_SESSIONS',
  DEEM_SHUTDOWN_TIME_POOR_SESSIONS_PREVIOUS_TIME = 'DEEM_SHUTDOWN_TIME_POOR_SESSIONS_PREVIOUS_TIME',
  DEEM_BOOT_TIME_POOR_SESSIONS = 'DEEM_BOOT_TIME_POOR_SESSIONS',
  DEEM_BOOT_TIME_POOR_SESSIONS_PREVIOUS_TIME = 'DEEM_BOOT_TIME_POOR_SESSIONS_PREVIOUS_TIME',
  DEEM_DESKTOP_APPS_WITH_POOR_SCORE = 'DEEM_DESKTOP_APPS_WITH_POOR_SCORE',
  DEEM_DESKTOP_APPS_WITH_POOR_SCORE_PREVIOUS_TIME = 'DEEM_DESKTOP_APPS_WITH_POOR_SCORE_PREVIOUS_TIME',
  DEEM_MOBILE_APPS_WITH_POOR_SCORE = 'DEEM_MOBILE_APPS_WITH_POOR_SCORE',
  DEEM_MOBILE_APPS_WITH_POOR_SCORE_PREVIOUS_TIME = 'DEEM_MOBILE_APPS_WITH_POOR_SCORE_PREVIOUS_TIME',

  // DEEM - surveys
  __SURVEY_RESPONSE_COUNT = '__SURVEY_RESPONSE_COUNT',
  SURVEY_RESPONSE_COUNT = 'SURVEY_RESPONSE_COUNT',
  SURVEY_RESPONSE_OVER_TIME = 'SURVEY_RESPONSE_OVER_TIME',
  __SURVEY_RESULT_Q1 = '__SURVEY_RESULT_Q1',
  __SURVEY_RESULT_Q2 = '__SURVEY_RESULT_Q2',
  __SURVEY_RESULT_Q3 = '__SURVEY_RESULT_Q3',
  __SURVEY_RESULT_Q4 = '__SURVEY_RESULT_Q4',
  __SURVEY_RESULT_Q5 = '__SURVEY_RESULT_Q5',
  SURVEY_RESULT_Q1 = 'SURVEY_RESULT_Q1',
  SURVEY_RESULT_Q2 = 'SURVEY_RESULT_Q2',
  SURVEY_RESULT_Q3 = 'SURVEY_RESULT_Q3',
  SURVEY_RESULT_Q4 = 'SURVEY_RESULT_Q4',
  SURVEY_RESULT_Q5 = 'SURVEY_RESULT_Q5',
  SURVEY_RESPONSE_TOTAL_COUNT = 'SURVEY_RESPONSE_TOTAL_COUNT',

  // DEEM - Hub Notifications
  HUB_NOTIFICATIONS_DETAIL_BY_ACTION_TYPE_TOTAL = 'HUB_NOTIFICATIONS_DETAIL_BY_ACTION_TYPE_TOTAL',
  HUB_NOTIFICATIONS_DETAIL_BY_ACTION_TYPE_UNIQUE = 'HUB_NOTIFICATIONS_DETAIL_BY_ACTION_TYPE_UNIQUE',
  HUB_NOTIFICATIONS_DETAIL_TOTAL_ACTIONED_COUNT = 'HUB_NOTIFICATIONS_DETAIL_TOTAL_ACTIONED_COUNT',
  HUB_NOTIFICATIONS_DETAIL_TOTAL_DISMISSED_COUNT = 'HUB_NOTIFICATIONS_DETAIL_TOTAL_DISMISSED_COUNT',
  HUB_NOTIFICATIONS_DETAIL_TOTAL_OPENED_COUNT = 'HUB_NOTIFICATIONS_DETAIL_TOTAL_OPENED_COUNT',
  HUB_NOTIFICATIONS_DETAIL_TOTAL_SENT_COUNT = 'HUB_NOTIFICATIONS_DETAIL_TOTAL_SENT_COUNT',
  HUB_NOTIFICATIONS_DETAIL_TOTAL_SUCCESS_COUNT = 'HUB_NOTIFICATIONS_DETAIL_TOTAL_SUCCESS_COUNT',
  HUB_NOTIFICATIONS_DETAIL_TOTAL_VIEWED_COUNT = 'HUB_NOTIFICATIONS_DETAIL_TOTAL_VIEWED_COUNT',
  HUB_NOTIFICATIONS_DETAIL_UNIQUE_SENT_COUNT = 'HUB_NOTIFICATIONS_DETAIL_UNIQUE_SENT_COUNT',
  HUB_NOTIFICATIONS_DETAIL_UNIQUE_ACTIONED_COUNT = 'HUB_NOTIFICATIONS_DETAIL_UNIQUE_ACTIONED_COUNT',
  HUB_NOTIFICATIONS_DETAIL_UNIQUE_DISMISSED_COUNT = 'HUB_NOTIFICATIONS_DETAIL_UNIQUE_DISMISSED_COUNT',
  HUB_NOTIFICATIONS_DETAIL_UNIQUE_SUCCESS_COUNT = 'HUB_NOTIFICATIONS_DETAIL_UNIQUE_SUCCESS_COUNT',
  HUB_NOTIFICATIONS_DETAIL_UNIQUE_OPENED_COUNT = 'HUB_NOTIFICATIONS_DETAIL_UNIQUE_OPENED_COUNT',
  HUB_NOTIFICATIONS_DETAIL_UNIQUE_VIEWED_COUNT = 'HUB_NOTIFICATIONS_DETAIL_UNIQUE_VIEWED_COUNT',
  HUB_NOTIFICATIONS_DETAIL_VIEWED_BY_ACTION_TOTAL = 'HUB_NOTIFICATIONS_DETAIL_VIEWED_BY_ACTION_TOTAL',
  HUB_NOTIFICATIONS_DETAIL_VIEWED_BY_ACTION_UNIQUE = 'HUB_NOTIFICATIONS_DETAIL_VIEWED_BY_ACTION_UNIQUE',
  HUB_NOTIFICATIONS_DETAIL_VIEWED_BY_PLATFORM_TOTAL = 'HUB_NOTIFICATIONS_DETAIL_VIEWED_BY_PLATFORM_TOTAL',
  HUB_NOTIFICATIONS_DETAIL_VIEWED_BY_PLATFORM_UNIQUE = 'HUB_NOTIFICATIONS_DETAIL_VIEWED_BY_PLATFORM_UNIQUE',
  HUB_NOTIFICATIONS_OVERVIEW_DISMISSED_BY_PLATFORM = 'HUB_NOTIFICATIONS_OVERVIEW_DISMISSED_BY_PLATFORM',
  HUB_NOTIFICATIONS_OVERVIEW_DISMISSED_COUNT = 'HUB_NOTIFICATIONS_OVERVIEW_DISMISSED_COUNT',
  HUB_NOTIFICATIONS_OVERVIEW_TOTAL_CREATED_COUNT = 'HUB_NOTIFICATIONS_OVERVIEW_TOTAL_CREATED_COUNT',
  HUB_NOTIFICATIONS_OVERVIEW_TOTAL_SENT_COUNT = 'HUB_NOTIFICATIONS_OVERVIEW_TOTAL_SENT_COUNT',
  HUB_NOTIFICATIONS_OVERVIEW_TOTAL_SENT_COUNT_PREVIOUS_PERIOD = 'HUB_NOTIFICATIONS_OVERVIEW_TOTAL_SENT_COUNT_PREVIOUS_PERIOD',
  HUB_NOTIFICATIONS_OVERVIEW_TOTAL_SUCCESS_COUNT = 'HUB_NOTIFICATIONS_OVERVIEW_TOTAL_SUCCESS_COUNT',
  HUB_NOTIFICATIONS_OVERVIEW_TOTAL_ACTIONABLE_COUNT = 'HUB_NOTIFICATIONS_OVERVIEW_TOTAL_ACTIONABLE_COUNT',
  HUB_NOTIFICATIONS_OVERVIEW_TOTAL_INFORMATIONAL_COUNT = 'HUB_NOTIFICATIONS_OVERVIEW_TOTAL_INFORMATIONAL_COUNT',
  HUB_NOTIFICATIONS_OVERVIEW_BY_ACTION_TYPE_TOTAL = 'HUB_NOTIFICATIONS_OVERVIEW_BY_ACTION_TYPE_TOTAL',
  HUB_NOTIFICATIONS_OVERVIEW_ACTIONED_BY_PLATFORM = 'HUB_NOTIFICATIONS_OVERVIEW_ACTIONED_BY_PLATFORM',
  HUB_NOTIFICATIONS_OVERVIEW_ACTIONED_COUNT = 'HUB_NOTIFICATIONS_OVERVIEW_ACTIONED_COUNT',
  HUB_NOTIFICATIONS_OVERVIEW_OPENED_BY_PLATFORM = 'HUB_NOTIFICATIONS_OVERVIEW_OPENED_BY_PLATFORM',
  HUB_NOTIFICATIONS_OVERVIEW_OPENED_COUNT = 'HUB_NOTIFICATIONS_OVERVIEW_OPENED_COUNT',
  HUB_NOTIFICATIONS_OVERVIEW_VIEWED_BY_PLATFORM = 'HUB_NOTIFICATIONS_OVERVIEW_VIEWED_BY_PLATFORM',
  HUB_NOTIFICATIONS_OVERVIEW_VIEWED_COUNT = 'HUB_NOTIFICATIONS_OVERVIEW_VIEWED_COUNT',

  // DEEM - Synthetic Url Monitoring
  DEEM_SYNTHETIC_URL_MONITORING_TEST_RESPONSE_TIME = 'DEEM_SYNTHETIC_URL_MONITORING_TEST_RESPONSE_TIME',
  DEEM_SYNTHETIC_URL_MONITORING_TEST_TOTAL_RUNS_COUNT = 'DEEM_SYNTHETIC_URL_MONITORING_TEST_TOTAL_RUNS_COUNT',
  DEEM_SYNTHETIC_URL_MONITORING_TEST_TOTAL_SUCCESSFUL_RUNS_COUNT = 'DEEM_SYNTHETIC_URL_MONITORING_TEST_TOTAL_SUCCESSFUL_RUNS_COUNT',
  DEEM_SYNTHETIC_URL_MONITORING_TEST_NAME_RESPONSE_TIME = 'DEEM_SYNTHETIC_URL_MONITORING_TEST_NAME_RESPONSE_TIME',
  DEEM_SYNTHETIC_URL_MONITORING_TEST_SOCKET_CONNECTION_TIME = 'DEEM_SYNTHETIC_URL_MONITORING_TEST_SOCKET_CONNECTION_TIME',
  DEEM_SYNTHETIC_URL_MONITORING_TEST_TLS_SETUP_TIME = 'DEEM_SYNTHETIC_URL_MONITORING_TEST_TLS_SETUP_TIME',
  DEEM_SYNTHETIC_URL_MONITORING_TEST_HTTP_RESPONSE_TIME = 'DEEM_SYNTHETIC_URL_MONITORING_TEST_HTTP_RESPONSE_TIME',
  DEEM_SYNTHETIC_URL_MONITORING_FAILED_TEST_COUNT_BY_GROUP_NAME = 'DEEM_SYNTHETIC_URL_MONITORING_FAILED_TEST_COUNT_BY_GROUP_NAME',
  DEEM_SYNTHETIC_URL_MONITORING_FAILED_TEST_COUNT_BY_DEVICE_TAG = 'DEEM_SYNTHETIC_URL_MONITORING_FAILED_TEST_COUNT_BY_DEVICE_TAG',
  // eslint-disable-next-line max-len
  DEEM_SYNTHETIC_URL_MONITORING_TEST_IP_ADDRESSES_WITH_LONGEST_RESPONSE_TIME = 'DEEM_SYNTHETIC_URL_MONITORING_TEST_IP_ADDRESSES_WITH_LONGEST_RESPONSE_TIME',
  // eslint-disable-next-line max-len
  _DEEM_SYNTHETIC_URL_MONITORING_TEST_IP_ADDRESSES_WITH_LONGEST_RESPONSE_TIME = '_DEEM_SYNTHETIC_URL_MONITORING_TEST_IP_ADDRESSES_WITH_LONGEST_RESPONSE_TIME',
  _DEEM_SYNTHETIC_URL_MONITORING_TEST_RESPONSE_TIME_BY_PHASE = '_DEEM_SYNTHETIC_URL_MONITORING_TEST_RESPONSE_TIME_BY_PHASE',
  _DEEM_SYNTHETIC_URL_MONITORING_TEST_UP_TIME = '_DEEM_SYNTHETIC_URL_MONITORING_TEST_UP_TIME',
  _DEEM_SYNTHETIC_URL_MONITORING_TEST_TOTAL_RUNS_COUNT = '_DEEM_SYNTHETIC_URL_MONITORING_TEST_TOTAL_RUNS_COUNT',
  _DEEM_SYNTHETIC_URL_MONITORING_TEST_TOTAL_SUCCESSFUL_RUNS_COUNT = '_DEEM_SYNTHETIC_URL_MONITORING_TEST_TOTAL_SUCCESSFUL_RUNS_COUNT',

  // DEEM - Zoom Experience Score
  _DEEM_ZOOM_MEETINGS_BY_APP_VERSION = '_DEEM_ZOOM_MEETINGS_BY_APP_VERSION',
  _DEEM_ZOOM_BAD_POOR_USERS_TABLE = '_DEEM_ZOOM_BAD_POOR_USERS_TABLE',
  _DEEM_ZOOM_USER_MEETING_QUALITY_COUNT_BY_QUALITY = '_DEEM_ZOOM_USER_MEETING_QUALITY_COUNT_BY_QUALITY',
  _DEEM_ZOOM_USERS_BY_CONTRIBUTOR_SCORES = '_DEEM_ZOOM_USERS_BY_CONTRIBUTOR_SCORES',
  DEEM_ZOOM_AUDIO_QUALITY_BY_APP_VERSION = 'DEEM_ZOOM_AUDIO_QUALITY_BY_APP_VERSION',
  DEEM_ZOOM_AVG_BITRATE_INPUT = 'DEEM_ZOOM_AVG_BITRATE_INPUT',
  DEEM_ZOOM_AVG_BITRATE_INPUT_CHART = 'DEEM_ZOOM_AVG_BITRATE_INPUT_CHART',
  DEEM_ZOOM_AVG_BITRATE_OUTPUT = 'DEEM_ZOOM_AVG_BITRATE_OUTPUT',
  DEEM_ZOOM_AVG_BITRATE_OUTPUT_CHART = 'DEEM_ZOOM_AVG_BITRATE_OUTPUT_CHART',
  DEEM_ZOOM_AVG_JITTER_INPUT = 'DEEM_ZOOM_AVG_JITTER_INPUT',
  DEEM_ZOOM_AVG_JITTER_INPUT_CHART = 'DEEM_ZOOM_AVG_JITTER_INPUT_CHART',
  DEEM_ZOOM_AVG_JITTER_OUTPUT = 'DEEM_ZOOM_AVG_JITTER_OUTPUT',
  DEEM_ZOOM_AVG_JITTER_OUTPUT_CHART = 'DEEM_ZOOM_AVG_JITTER_OUTPUT_CHART',
  DEEM_ZOOM_AVG_LATENCY_INPUT = 'DEEM_ZOOM_AVG_LATENCY_INPUT',
  DEEM_ZOOM_AVG_LATENCY_INPUT_CHART = 'DEEM_ZOOM_AVG_LATENCY_INPUT_CHART',
  DEEM_ZOOM_AVG_LATENCY_OUTPUT = 'DEEM_ZOOM_AVG_LATENCY_OUTPUT',
  DEEM_ZOOM_AVG_LATENCY_OUTPUT_CHART = 'DEEM_ZOOM_AVG_LATENCY_OUTPUT_CHART',
  DEEM_ZOOM_AVG_MEETING_SCORE_BY_USERS = 'DEEM_ZOOM_AVG_MEETING_SCORE_BY_USERS',
  DEEM_ZOOM_AVG_PACKET_LOSS_INPUT = 'DEEM_ZOOM_AVG_PACKET_LOSS_INPUT',
  DEEM_ZOOM_AVG_PACKET_LOSS_INPUT_CHART = 'DEEM_ZOOM_AVG_PACKET_LOSS_INPUT_CHART',
  DEEM_ZOOM_AVG_PACKET_LOSS_OUTPUT = 'DEEM_ZOOM_AVG_PACKET_LOSS_OUTPUT',
  DEEM_ZOOM_AVG_PACKET_LOSS_OUTPUT_CHART = 'DEEM_ZOOM_AVG_PACKET_LOSS_OUTPUT_CHART',
  DEEM_ZOOM_BAD_MEETINGS_COUNT = 'DEEM_ZOOM_BAD_MEETINGS_COUNT',
  DEEM_ZOOM_BAD_POOR_USERS_TABLE = 'DEEM_ZOOM_BAD_POOR_USERS_TABLE',
  DEEM_ZOOM_BAD_USERS_COUNT = 'DEEM_ZOOM_BAD_USERS_COUNT',
  DEEM_ZOOM_BAD_USERS_COUNT_BY_CONTRIBUTOR = 'DEEM_ZOOM_BAD_USERS_COUNT_BY_CONTRIBUTOR',
  DEEM_ZOOM_FAIR_MEETINGS_COUNT = 'DEEM_ZOOM_FAIR_MEETINGS_COUNT',
  DEEM_ZOOM_FAIR_USERS_COUNT = 'DEEM_ZOOM_FAIR_USERS_COUNT',
  DEEM_ZOOM_FAIR_USERS_COUNT_BY_CONTRIBUTOR = 'DEEM_ZOOM_FAIR_USERS_COUNT_BY_CONTRIBUTOR',
  DEEM_ZOOM_GOOD_MEETINGS_COUNT = 'DEEM_ZOOM_GOOD_MEETINGS_COUNT',
  DEEM_ZOOM_GOOD_USERS_COUNT = 'DEEM_ZOOM_GOOD_USERS_COUNT',
  DEEM_ZOOM_GOOD_USERS_COUNT_BY_CONTRIBUTOR = 'DEEM_ZOOM_GOOD_USERS_COUNT_BY_CONTRIBUTOR',
  DEEM_ZOOM_MEETING_TIMES = 'DEEM_ZOOM_MEETING_TIMES',
  DEEM_ZOOM_MEETINGS_BAD_COUNT_BY_APP_VERSION = 'DEEM_ZOOM_MEETINGS_BAD_COUNT_BY_APP_VERSION',
  DEEM_ZOOM_MEETINGS_CHART = 'DEEM_ZOOM_MEETINGS_CHART',
  DEEM_ZOOM_MEETINGS_FAIR_COUNT_BY_APP_VERSION = 'DEEM_ZOOM_MEETINGS_FAIR_COUNT_BY_APP_VERSION',
  DEEM_ZOOM_MEETINGS_GOOD_COUNT_BY_APP_VERSION = 'DEEM_ZOOM_MEETINGS_GOOD_COUNT_BY_APP_VERSION',
  DEEM_ZOOM_MEETINGS_POOR_COUNT_BY_APP_VERSION = 'DEEM_ZOOM_MEETINGS_POOR_COUNT_BY_APP_VERSION',
  DEEM_ZOOM_MEETINGS_SCORE_BY_APP_VERSION = 'DEEM_ZOOM_MEETINGS_SCORE_BY_APP_VERSION',
  DEEM_ZOOM_MEETINGS_TOTAL_COUNT_BY_APP_VERSION = 'DEEM_ZOOM_MEETINGS_TOTAL_COUNT_BY_APP_VERSION',
  DEEM_ZOOM_POOR_MEETINGS_COUNT = 'DEEM_ZOOM_POOR_MEETINGS_COUNT',
  DEEM_ZOOM_POOR_USERS_COUNT = 'DEEM_ZOOM_POOR_USERS_COUNT',
  DEEM_ZOOM_SCREEN_SHARE_QUALITY_BY_APP_VERSION = 'DEEM_ZOOM_SCREEN_SHARE_QUALITY_BY_APP_VERSION',
  DEEM_ZOOM_POOR_USERS_COUNT_BY_CONTRIBUTOR = 'DEEM_ZOOM_POOR_USERS_COUNT_BY_CONTRIBUTOR',
  DEEM_ZOOM_TOTAL_POOR_MEETINGS_BY_USERS = 'DEEM_ZOOM_TOTAL_POOR_MEETINGS_BY_USERS',
  DEEM_ZOOM_TOTAL_MEETINGS_COUNT = 'DEEM_ZOOM_TOTAL_MEETINGS_COUNT',
  DEEM_ZOOM_TOTAL_MEETINGS_COUNT_BY_ORG = 'DEEM_ZOOM_TOTAL_MEETINGS_COUNT_BY_ORG',
  DEEM_ZOOM_TOTAL_MEETINGS_COUNT_BY_DEVICE_TAG = 'DEEM_ZOOM_TOTAL_MEETINGS_COUNT_BY_DEVICE_TAG',
  DEEM_ZOOM_TOTAL_MEETINGS_COUNT_LAST_28_DAYS = 'DEEM_ZOOM_TOTAL_MEETINGS_COUNT_LAST_28_DAYS',
  DEEM_ZOOM_TOTAL_MEETINGS_TABLE = 'DEEM_ZOOM_TOTAL_MEETINGS_TABLE',
  DEEM_ZOOM_MAX_PACKET_LOSS_INPUT = 'DEEM_ZOOM_MAX_PACKET_LOSS_INPUT',
  DEEM_ZOOM_MAX_PACKET_LOSS_INPUT_CHART = 'DEEM_ZOOM_MAX_PACKET_LOSS_INPUT_CHART',
  DEEM_ZOOM_MAX_PACKET_LOSS_OUTPUT = 'DEEM_ZOOM_MAX_PACKET_LOSS_OUTPUT',
  DEEM_ZOOM_MAX_PACKET_LOSS_OUTPUT_CHART = 'DEEM_ZOOM_MAX_PACKET_LOSS_OUTPUT_CHART',
  DEEM_ZOOM_TOTAL_USERS_COUNT = 'DEEM_ZOOM_TOTAL_USERS_COUNT',
  DEEM_ZOOM_TOTAL_USERS_COUNT_BY_CONTRIBUTOR = 'DEEM_ZOOM_TOTAL_USERS_COUNT_BY_CONTRIBUTOR',
  DEEM_ZOOM_TOTAL_USERS_COUNT_BY_DEVICE_TAG = 'DEEM_ZOOM_TOTAL_USERS_COUNT_BY_DEVICE_TAG',
  DEEM_ZOOM_TOTAL_USERS_COUNT_BY_ORG = 'DEEM_ZOOM_TOTAL_USERS_COUNT_BY_ORG',
  DEEM_ZOOM_USERS_AVG_SCORE_TABLE = 'DEEM_ZOOM_USERS_AVG_SCORE_TABLE',
  DEEM_ZOOM_USERS_COUNT_BY_CONTRIBUTOR_CHART = 'DEEM_ZOOM_USERS_COUNT_BY_CONTRIBUTOR_CHART',
  DEEM_ZOOM_USERS_COUNT_BY_CONTRIBUTOR_BY_APP_VERSION = 'DEEM_ZOOM_USERS_COUNT_BY_CONTRIBUTOR_BY_APP_VERSION',
  DEEM_ZOOM_USERS_COUNT_BY_CONTRIBUTOR_BY_DEVICE_TAG = 'DEEM_ZOOM_USERS_COUNT_BY_CONTRIBUTOR_BY_DEVICE_TAG',
  DEEM_ZOOM_USERS_COUNT_BY_CONTRIBUTOR_BY_ORG = 'DEEM_ZOOM_USERS_COUNT_BY_CONTRIBUTOR_BY_ORG',
  DEEM_ZOOM_USERS_COUNT_BY_CONTRIBUTOR_BY_PLATFORM = 'DEEM_ZOOM_USERS_COUNT_BY_CONTRIBUTOR_BY_PLATFORM',
  DEEM_ZOOM_USERS_CHART = 'DEEM_ZOOM_USERS_CHART',
  DEEM_ZOOM_USERS_QUALITY_COUNT_BY_QUALITY_BY_CONTRIBUTOR = 'DEEM_ZOOM_USERS_QUALITY_COUNT_BY_QUALITY_BY_CONTRIBUTOR',
  DEEM_ZOOM_USERS_QUALITY_COUNT_BY_QUALITY_BY_PLATFORM = 'DEEM_ZOOM_USERS_QUALITY_COUNT_BY_QUALITY_BY_PLATFORM',
  DEEM_ZOOM_USERS_SCORE_LIST_BY_CONTRIBUTOR = 'DEEM_ZOOM_USERS_SCORE_LIST_BY_CONTRIBUTOR',
  DEEM_ZOOM_USERS_SCORE_LIST_BY_PLATFORM = 'DEEM_ZOOM_USERS_SCORE_LIST_BY_PLATFORM',
  DEEM_ZOOM_MEETING_AUDIO_QUALITY_SCORE = 'DEEM_ZOOM_MEETING_AUDIO_QUALITY_SCORE',
  DEEM_ZOOM_MEETING_VIDEO_QUALITY_SCORE = 'DEEM_ZOOM_MEETING_VIDEO_QUALITY_SCORE',
  DEEM_ZOOM_MEETING_SCREEN_SHARE_QUALITY_SCORE = 'DEEM_ZOOM_MEETING_SCREEN_SHARE_QUALITY_SCORE',
  DEEM_ZOOM_MEETING_QUALITY_SCORE = 'DEEM_ZOOM_MEETING_QUALITY_SCORE',
  DEEM_ZOOM_MEETING_PARTICIPANTS_TABLE = 'DEEM_ZOOM_MEETING_PARTICIPANTS_TABLE',
  DEEM_ZOOM_USER_TOTAL_MEETING_COUNT = 'DEEM_ZOOM_USER_TOTAL_MEETING_COUNT',
  DEEM_ZOOM_USER_BAD_MEETING_COUNT = 'DEEM_ZOOM_USER_BAD_MEETING_COUNT',
  DEEM_ZOOM_USER_FAIR_MEETING_COUNT = 'DEEM_ZOOM_USER_FAIR_MEETING_COUNT',
  DEEM_ZOOM_USER_GOOD_MEETING_COUNT = 'DEEM_ZOOM_USER_GOOD_MEETING_COUNT',
  DEEM_ZOOM_USER_POOR_MEETING_COUNT = 'DEEM_ZOOM_USER_POOR_MEETING_COUNT',
  DEEM_ZOOM_USER_INFO = 'DEEM_ZOOM_USER_INFO',
  DEEM_ZOOM_USER_MEETINGS_TABLE = 'DEEM_ZOOM_USER_MEETINGS_TABLE',
  _DEEM_ZOOM_USERS_QUALITY_BY_PLATFORM = '_DEEM_ZOOM_USERS_QUALITY_BY_PLATFORM',
  DEEM_ZOOM_USERS_GOOD_COUNT_BY_PLATFORM = 'DEEM_ZOOM_USERS_GOOD_COUNT_BY_PLATFORM',
  DEEM_ZOOM_USERS_FAIR_COUNT_BY_PLATFORM = 'DEEM_ZOOM_USERS_FAIR_COUNT_BY_PLATFORM',
  DEEM_ZOOM_USERS_POOR_COUNT_BY_PLATFORM = 'DEEM_ZOOM_USERS_POOR_COUNT_BY_PLATFORM',
  DEEM_ZOOM_USERS_BAD_COUNT_BY_PLATFORM = 'DEEM_ZOOM_USERS_BAD_COUNT_BY_PLATFORM',
  DEEM_ZOOM_USERS_TOTAL_COUNT_BY_PLATFORM = 'DEEM_ZOOM_USERS_TOTAL_COUNT_BY_PLATFORM',
  _DEEM_ZOOM_USERS_QUALITY_BY_CONTRIBUTOR = '_DEEM_ZOOM_USERS_QUALITY_BY_CONTRIBUTOR',
  DEEM_ZOOM_USERS_GOOD_COUNT_BY_CONTRIBUTOR = 'DEEM_ZOOM_USERS_GOOD_COUNT_BY_CONTRIBUTOR',
  DEEM_ZOOM_USERS_FAIR_COUNT_BY_CONTRIBUTOR = 'DEEM_ZOOM_USERS_FAIR_COUNT_BY_CONTRIBUTOR',
  DEEM_ZOOM_USERS_POOR_COUNT_BY_CONTRIBUTOR = 'DEEM_ZOOM_USERS_POOR_COUNT_BY_CONTRIBUTOR',
  DEEM_ZOOM_USERS_BAD_COUNT_BY_CONTRIBUTOR = 'DEEM_ZOOM_USERS_BAD_COUNT_BY_CONTRIBUTOR',
  DEEM_ZOOM_USERS_TOTAL_COUNT_BY_CONTRIBUTOR = 'DEEM_ZOOM_USERS_TOTAL_COUNT_BY_CONTRIBUTOR',
  DEEM_ZOOM_VIDEO_QUALITY_BY_APP_VERSION = 'DEEM_ZOOM_VIDEO_QUALITY_BY_APP_VERSION',

  // DEEM - SAM Discover
  __DEEM_SAM_DISCOVER_NEW_APPS = '__DEEM_SAM_DISCOVER_NEW_APPS',
  DEEM_SAM_DISCOVER_NEW_APPS_MANAGED = 'DEEM_SAM_DISCOVER_NEW_APPS_MANAGED',
  DEEM_SAM_DISCOVER_NEW_APPS_UNMANAGED = 'DEEM_SAM_DISCOVER_NEW_APPS_UNMANAGED',

  // DEEM - SAM Optimize
  __DEEM_SAM_OPTIMIZE_TOP_10 = '__DEEM_SAM_OPTIMIZE_TOP_10',
  DEEM_SAM_OPTIMIZE_TOP_10 = 'DEEM_SAM_OPTIMIZE_TOP_10',

  // DEEM - SAM Compare
  DEEM_SAM_COMPARE_APPS = 'DEEM_SAM_COMPARE_APPS',
  DEEM_SAM_COMPARE_LIST = 'DEEM_SAM_COMPARE_LIST',

  // DEEM - SAM Portfolio
  DEEM_SAM_PORTFOLIO_TREND = 'DEEM_SAM_PORTFOLIO_TREND',
  DEEM_SAM_PORTFOLIO_APPS = 'DEEM_SAM_PORTFOLIO_APPS',
  DEEM_SAM_PORTFOLIO_MANAGED_APPS = 'DEEM_SAM_PORTFOLIO_MANAGED_APPS',
  DEEM_SAM_PORTFOLIO_UNMANAGED_APPS = 'DEEM_SAM_PORTFOLIO_UNMANAGED_APPS',

  // DEEM - SAM - Detail
  DEEM_SAM_DETAIL_BREAKDOWN_BY_OS = 'DEEM_SAM_DETAIL_BREAKDOWN_BY_OS',
  DEEM_SAM_DETAIL_APP_USAGE = 'DEEM_SAM_DETAIL_APP_USAGE',
  DEEM_SAM_DETAIL_APP_USAGE_TREND = 'DEEM_SAM_DETAIL_APP_USAGE_TREND',

  // Service Now ITSM Connector
  SERVICE_NOW_ITSM_ISSUES_BY_CATEGORY = 'SERVICE_NOW_ITSM_ISSUES_BY_CATEGORY',
  SERVICE_NOW_ITSM_ISSUES_BY_PRIORITY = 'SERVICE_NOW_ITSM_ISSUES_BY_PRIORITY',
  SERVICE_NOW_ITSM_CONNECTOR_USAGE = 'SERVICE_NOW_ITSM_CONNECTOR_USAGE',
}
