/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { GenericObject, SkeletonClassName } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { cloneDeep, each, isNull, isUndefined, round, some } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';

import { Marker, MarkerContext } from '@ws1c/dashboard-common/chart';
import { TableChartConfig } from '@ws1c/dashboard-common/const';
import { NgxChartFactoryService } from '@ws1c/dashboard-common/services';
import { I18NService } from '@ws1c/intelligence-common/services/i18n.service';
import { IntegrationMetaSelectors } from '@ws1c/intelligence-core/store/integration-meta';
import {
  AggregationWidgetChartType,
  ChartDrilldownEvent,
  Column,
  ColumnIndex,
  Counter,
  DashboardConfig,
  FocusedSeries,
  KeyValuePair,
  LocalDataGridSettings,
  NameValue,
  NgxChart,
  NgxSingleData,
  Tooltip,
  Trend,
  TrendMode,
  TrendResult,
  WidgetColorSchema,
  WidgetRangeFilter,
  WidgetSequence,
} from '@ws1c/intelligence-models';

/**
 * StandardChartComponent
 * @export
 * @class StandardChartComponent
 * @implements {OnChanges}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-standard-chart',
  templateUrl: 'standard-chart.component.html',
  styleUrls: ['standard-chart.component.scss'],
})
export class StandardChartComponent implements OnChanges, OnInit, OnDestroy {
  @Input() public chartData: Trend;
  @Input() public overlayChartData: Trend;
  @Input() public noBucketingColor: string;
  @Input() public activeChartType?: AggregationWidgetChartType = AggregationWidgetChartType.LINE;
  @Input() public loading?: boolean = false;
  @Input() public showSeriesNames?: boolean = true;
  @Input() public showDetailsLink?: boolean = false;
  @Input() public isPercentageOverride: boolean;
  @Input() public stackedTooltip?: TemplateRef<any>;
  @Input() public xAxisLabelOverride?: string;
  @Input() public yAxisLabelOverride?: string;
  @Input() public customColors?: any[];
  @Input() public colorSchemas?: WidgetColorSchema[];
  @Input() public isExploreMode?: boolean = false;
  @Input() public availableColumns?: Column[];
  @Input() public availableColumnsForOverlay?: Column[];
  @Input() public showAllAvailableLabels?: boolean = false;
  @Input() public focusedSeries: FocusedSeries;
  @Input() public noDataMessage?: string;
  @Input() public noDataTooltip?: string;
  @Input() public showXAxis?: boolean = true;
  @Input() public showYAxis?: boolean = true;
  @Input() public showDataLabel?: boolean = true;
  @Input() public showXAxisLabel?: boolean = true;
  @Input() public showYAxisLabel?: boolean = true;
  @Input() public showLabels?: boolean = true;
  @Input() public showTimeline?: boolean = false;
  @Input() public showRefLines?: boolean = false;
  @Input() public showRefLabels?: boolean = true;
  @Input() public referenceLines?: Array<NameValue<number>>;
  @Input() public totalHeaderKey?: string = 'COMMON_MESSAGES.TOTAL';
  @Input() public totalHeaderValue?: number;
  @Input() public totalHeaderClass?: string;
  @Input() public tableColumnNames?: string[];
  @Input() public tableColumnLabelsByName: Record<string, string> = {};
  @Input() public tableCellTemplatesByName: Record<string, TemplateRef<any>> = {};
  @Input() public tableExpandedRowTemplate?: TemplateRef<any>;
  @Input() public selectable?: { enabled: boolean; single: boolean } = TableChartConfig.selectableDefault;
  @Input() public showDetailView?: boolean = false;
  @Input() public detailHeaderTemplate?: TemplateRef<any>;
  @Input() public detailBodyTemplate?: TemplateRef<any>;
  @Input() public tableSettings?: LocalDataGridSettings;
  @Input() public chartCurveType?: any = DashboardConfig.chartCurveTypes.Default;
  @Input() public labelFormatting?: any;
  @Input() public valueFormatting?: any;
  @Input() public showSameSizeCell?: boolean;
  @Input() public markers?: Marker[];
  @Input() public markerTooltipTemplate?: TemplateRef<any>;
  @Input() public yScaleMax?: number;
  @Input() public yScaleMin?: number;
  @Input() public mergeColumns?: Record<string, string[]>;
  @Input() public isRangedData?: boolean = false;
  @Input() public showTableFilter?: boolean = true;
  @Input() public showLegend?: boolean = true;
  @Input() public isInvertMode?: boolean = false;
  @Input() public trimXAxisTicks?: boolean = true;
  @Input() public enablePagination?: boolean = true;
  @Input() public hideSeriesTooltipSubtotal?: boolean = false;
  @Input() public isCountersClickable?: boolean = true;
  @Input() public rotateXAxisTicks?: boolean = false;
  @Input() public xAxisLabelFactor?: number = 1;
  @Input() public skipSort?: boolean;
  @Input() public rangeFilter: WidgetRangeFilter;
  @Input() public yAxisTickFormatting?: any;
  @Input() public yAxisTicks?: any[];
  @Input() public focusEvents: GenericObject[];
  @Input() public focussedSequence?: WidgetSequence;
  @Input() public columnWidthByKey?: { [key: string]: number } = {};
  @Input() public showZeroSeries?: boolean = false;
  @Output() public drilldown: EventEmitter<ChartDrilldownEvent> = new EventEmitter<ChartDrilldownEvent>();
  @Output() public tooltipChange = new EventEmitter<Tooltip>();
  @Output() public selectedChange: EventEmitter<GenericObject[]> = new EventEmitter();
  @Output() public onMarkerClick: EventEmitter<MarkerContext> = new EventEmitter<MarkerContext>();

  public CHART_TYPE = AggregationWidgetChartType;
  public SKELETON_CLASS_NAME = SkeletonClassName;
  public CHART_TYPES_WITH_LEGEND = new Set([
    AggregationWidgetChartType.LINE,
    AggregationWidgetChartType.AREA,
    AggregationWidgetChartType.VERTICAL,
    AggregationWidgetChartType.VERTICAL_GROUP,
    AggregationWidgetChartType.HORIZONTAL,
    AggregationWidgetChartType.HORIZONTAL_GROUP,
    AggregationWidgetChartType.DONUT,
    AggregationWidgetChartType.BUBBLE,
    AggregationWidgetChartType.HEAT_MAP,
  ]);
  public readonly maxTooltipItems: number = 10;
  public ngxChart: NgxChart;
  public overlayChart: NgxChart;
  public isPercentage: boolean = false;

  public xAxisLabel: string;
  public yAxisLabel: string;
  public activeSeries: Set<string> = new Set<string>();
  public isLegendVisible: boolean = false;
  public isOverlayLegendVisible: boolean = false;
  public colorizedColumn: Column;
  public colorizedColumnForOverlay: Column;
  public otherSeries: NgxSingleData[];
  public TREND_MODE = TrendMode;
  public chartTypeClassName?: SkeletonClassName;
  public rawChartData: Trend;
  public allColumnsByName: ColumnIndex = {};
  public allColumnsByNameByCategoryId$: Observable<Record<string, Record<string, Column>>>;
  public sub: Subscription = new Subscription();
  private chartChangeTrigger$ = new BehaviorSubject<void>(null);

  /**
   * Creates an instance of StandardChartComponent.
   * @param {NgxChartFactoryService} ngxChartFactoryService
   * @param {I18NService} i18nService
   * @param {Store} store
   * @memberof StandardChartComponent
   */
  public constructor(private ngxChartFactoryService: NgxChartFactoryService, private i18nService: I18NService, private store: Store) {
    this.allColumnsByNameByCategoryId$ = this.store.select(
      IntegrationMetaSelectors.categoriesByCategoryId(IntegrationMetaSelectors.isCrossCategory),
    );
  }

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof StandardChartComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    // INTEL-40969 need to pick up focusedSeries changes
    if (
      changes.chartData ||
      changes.activeChartType ||
      changes.noBucketingColor ||
      changes.customColors ||
      changes.colorSchemas ||
      changes.availableColumns ||
      changes.rangeFilter ||
      changes.focusedSeries
    ) {
      if (changes.activeChartType) {
        this.chartTypeClassName = this.getChartTypeClassName();
      }

      if (changes.chartData) {
        this.rawChartData = changes.chartData.currentValue;
      }
      this.chartData = cloneDeep(this.rawChartData);
      this.chartChangeTrigger$.next();
      this.setNgxChart();
      this.setColorizedColumn();
    }

    if (this.ngxChart) {
      this.isLegendVisible =
        (this.isExploreMode || this.showSeriesNames) &&
        this.ngxChart &&
        this.ngxChart.colorizedAttributeValues &&
        this.ngxChart.colorizedAttributeValues.length > 1 &&
        this.CHART_TYPES_WITH_LEGEND.has(this.activeChartType) &&
        this.showLabels &&
        this.showLegend;
      this.xAxisLabel = this.getXAxisLabelHorizontalChart();
      this.yAxisLabel = this.getYAxisLabel();
      this.setOverlayDetails();
    }
  }

  /**
   * ngOnInit
   * @memberof StandardChartComponent
   */
  public ngOnInit() {
    this.sub.add(
      combineLatest([this.allColumnsByNameByCategoryId$, this.chartChangeTrigger$]).subscribe(
        ([allColumnsByNameByCategoryId, _]: [Record<string, Record<string, Column>>, any]) => {
          this.chartData?.trendDefinition?.categories?.forEach((trendCategoryId: string) => {
            Object.keys(allColumnsByNameByCategoryId).forEach((categoryId: string) => {
              if (categoryId === trendCategoryId) {
                this.allColumnsByName = {
                  ...this.allColumnsByName,
                  ...allColumnsByNameByCategoryId[categoryId],
                };
              }
            });
          });
          if (Object.keys(this.allColumnsByName).length) {
            this.setNgxChart();
          }
        },
      ),
    );
  }

  /**
   * ngOnDestroy
   * @memberof StandardChartComponent
   */
  public ngOnDestroy() {
    this.sub.unsubscribe();
  }

  /**
   * setNgxChart
   * @memberof StandardChartComponent
   */
  public setNgxChart() {
    if (isUndefined(this.activeChartType) || isNull(this.activeChartType) || !this.chartData) {
      return;
    }
    this.ngxChart = this.ngxChartFactoryService.buildNgxChartModel(
      this.chartData,
      this.activeChartType,
      this.customColors,
      this.colorSchemas,
      this.noBucketingColor,
      this.isRangedData,
      this.isInvertMode,
      this.skipSort,
      this.rangeFilter,
      this.xAxisLabelFactor,
      undefined,
      this.allColumnsByName,
      this.showZeroSeries,
    );
    if (this.activeChartType === AggregationWidgetChartType.DONUT) {
      this.ngxChart.applyOtherGrouping();
    }
    this.isPercentage = this.isPercentageOverride ?? false;
    this.deactivateAllSeries();

    if (this.isPercentage && this.ngxChart.hasTrendResults()) {
      this.ngxChart.labels.counterFormatter = (counterValue: number) => {
        return `${round(counterValue * 100, 2)}%`;
      };
    }

    this.tooltipChange.emit(this.ngxChart.infoTooltip);

    if (this.focusedSeries) {
      // INTEL-40969 escape merged series due to conversion of its datatype and value mismatch
      this.focusedSeries.seriesNames = this.focusedSeries.seriesNames.map((name: any) => {
        if (['boolean', 'number', 'bigint'].includes(typeof name)) {
          return name.toString();
        }
        return name;
      });
      this.ngxChart.setFocusedSeries(this.focusedSeries);
    }
  }

  /**
   * getChartTypeClassName
   * @returns {SkeletonClassName}
   * @memberof StandardChartComponent
   */
  public getChartTypeClassName(): SkeletonClassName {
    switch (this.activeChartType) {
      case this.CHART_TYPE.HEAT_MAP:
      case this.CHART_TYPE.TABLE:
        return this.SKELETON_CLASS_NAME.TABLE_DATA;
      case this.CHART_TYPE.METRIC:
        return this.SKELETON_CLASS_NAME.METRIC_DATA;
      case this.CHART_TYPE.LINE_SPARK:
        return this.SKELETON_CLASS_NAME.SPARKLINE_CHART;
      case this.CHART_TYPE.HORIZONTAL:
      case this.CHART_TYPE.HORIZONTAL_GROUP:
        return this.SKELETON_CLASS_NAME.HORIZONTAL_CHART;
      case this.CHART_TYPE.DONUT:
        return this.SKELETON_CLASS_NAME.PIE_CHART;
      case this.CHART_TYPE.AREA:
        return this.SKELETON_CLASS_NAME.AREA_CHART;
      case this.CHART_TYPE.LINE:
        return this.SKELETON_CLASS_NAME.LINE_CHART;
      case this.CHART_TYPE.VERTICAL:
      case this.CHART_TYPE.VERTICAL_GROUP:
        return this.SKELETON_CLASS_NAME.BAR_CHART;
      case this.CHART_TYPE.BUBBLE:
        return this.SKELETON_CLASS_NAME.BUBBLE_CHART;
      case this.CHART_TYPE.TREE_MAP:
        return this.SKELETON_CLASS_NAME.TREE_MAP_CHART;
      default:
        return this.SKELETON_CLASS_NAME.DEFAULT;
    }
  }

  /**
   * setColorizedColumn
   * @memberof StandardChartComponent
   */
  public setColorizedColumn() {
    if (!this.ngxChart || !this.ngxChart.groupBys || !this.availableColumns) {
      this.colorizedColumn = undefined;
      return;
    }
    const colorizedColumnName = this.ngxChart.groupBys[this.ngxChart.getColorizedLevel()];
    this.colorizedColumn = this.availableColumns.find((column: Column) => column.attributeName === colorizedColumnName);
  }

  /**
   * onDrilldown
   * @param {ChartDrilldownEvent} drilldownEvent
   * @memberof StandardChartComponent
   */
  public onDrilldown(drilldownEvent: ChartDrilldownEvent) {
    const drilldownEventHasValues =
      (drilldownEvent.selectedBuckets && drilldownEvent.selectedBuckets.length) ||
      drilldownEvent.bucketAttributeChange ||
      drilldownEvent.startDateMillis !== undefined ||
      drilldownEvent.endDateMillis !== undefined ||
      drilldownEvent.addFilters ||
      drilldownEvent.setFocusedSeries ||
      drilldownEvent.selectedCounter;

    if (!drilldownEventHasValues) {
      return;
    }

    this.deactivateAllSeries();
    this.drilldown.emit(drilldownEvent);
  }

  /**
   * tooltipItemsOverMax
   * @param {any[]} items
   * @returns {number}
   * @memberof StandardChartComponent
   */
  public tooltipItemsOverMax(items: any[] = []): number {
    if (!this.chartData) {
      return 0;
    }
    const cardinality = this.chartData.trendDefinition.cardinality;
    const effectiveItemLength = Math.min(items.length, cardinality);
    return Math.max(effectiveItemLength - this.maxTooltipItems, 0);
  }

  /**
   * sortFilterTooltipItems
   * @param {any[]} ngxTooltipItems
   * @returns {KeyValuePair[]}
   * @memberof StandardChartComponent
   */
  public sortFilterTooltipItems(ngxTooltipItems: KeyValuePair[]): KeyValuePair[] {
    const visibleTooltipItems = Math.min(this.chartData?.trendDefinition.cardinality, this.maxTooltipItems);
    return ngxTooltipItems
      .slice(0)
      .sort((a: KeyValuePair, b: KeyValuePair) => (a.rawValue ? b.rawValue - a.rawValue : b.value - a.value))
      .slice(0, visibleTooltipItems);
  }

  /**
   * getXAxisLabelHorizontalChart
   * @returns {string}
   * @memberof StandardChartComponent
   */
  public getXAxisLabelHorizontalChart(): string {
    if (!this.ngxChart?.labels) {
      return '';
    }
    return isUndefined(this.xAxisLabelOverride) ? this.ngxChart.labels.counter : this.xAxisLabelOverride;
  }

  /**
   * getYAxisLabel
   * Used for unstacked line, line, and vertical bar charts
   * @memberof StandardChartComponent
   * @returns {string}
   */
  public getYAxisLabel(): string {
    if (!this.showYAxisLabel || this.isInvertMode) {
      return '';
    }
    return this.getYAxisLabelString();
  }

  /**
   * getYAxisLabelString
   * @returns {string}
   * @memberof StandardChartComponent
   */
  public getYAxisLabelString(): string {
    if (this.isPercentage || !this.ngxChart?.labels) {
      return '';
    }
    return isUndefined(this.yAxisLabelOverride) ? this.ngxChart.labels.counter : this.yAxisLabelOverride;
  }

  /**
   * onSelectSeries
   * @param {string} seriesName
   * @memberof StandardChartComponent
   */
  public onSelectSeries(seriesName: string) {
    if (!this.showDetailsLink) {
      return;
    }

    // Clicking on others in the legend goes through normal drilldown
    if (this.i18nService.translate('COMMON_MESSAGES.OTHERS') === seriesName) {
      const drilldownEvent: ChartDrilldownEvent = this.ngxChart.drilldownEventBuilder.getEvent(seriesName);
      this.onDrilldown(drilldownEvent);
    } else {
      this.onDrilldown({
        setFocusedSeries: this.ngxChart.drilldownEventBuilder.getNextFocusedSeriesToggle(this.focusedSeries, seriesName),
      });
    }
  }

  /**
   * clearFocusedSeries
   * @memberof StandardChartComponent
   */
  public clearFocusedSeries() {
    this.onDrilldown({
      setFocusedSeries: {
        colorizedAttributeName: this.ngxChart.getColorizedAttributeName(),
        colorizedAttributeLabel: this.ngxChart.colorizedAttributeLabel,
        seriesNames: [],
        seriesDataType: undefined,
      },
    });
  }

  /**
   * onActivateSeries
   * @param {string} seriesName
   * @memberof StandardChartComponent
   */
  public onActivateSeries(seriesName: string) {
    if (this.activeSeries.has(seriesName)) {
      return;
    }
    this.activeSeries = new Set(this.activeSeries);
    this.activeSeries.add(seriesName);
  }

  /**
   * onDeactivateSeries
   * @param {string} seriesName
   * @memberof StandardChartComponent
   */
  public onDeactivateSeries(seriesName: string) {
    if (this.activeSeries.has(seriesName)) {
      this.activeSeries = new Set(this.activeSeries);
      this.activeSeries.delete(seriesName);
    }
  }

  /**
   * onSelectedChange
   * @param {GenericObject[]} selected
   * @memberof StandardChartComponent
   */
  public onSelectedChange(selected: GenericObject[]) {
    this.selectedChange.emit(selected);
  }

  /**
   * deactivateAllSeries
   * @memberof StandardChartComponent
   */
  public deactivateAllSeries() {
    if (this.activeSeries.size !== 0) {
      this.activeSeries = new Set();
    }
  }

  /**
   * onSelectColorizedColumn
   * @param {Column} column
   * @memberof StandardChartComponent
   */
  public onSelectColorizedColumn(column: Column) {
    const drilldownEvent = {
      bucketAttributeChange: {
        bucketAttributeIndex: this.ngxChart.getColorizedBucketingAttributeIndex(),
        nextAttributeName: column && column.attributeName,
        nextAttributeLabel: column && column.label,
      },
    } as ChartDrilldownEvent;
    this.drilldown.emit(drilldownEvent);
  }

  /**
   * getCsvData
   * @returns {string[][]}
   * @memberof StandardChartComponent
   */
  public getCsvData(): string[][] {
    return this.ngxChart && this.ngxChart.getCsvData();
  }

  /**
   * getWidgetTheme
   * @returns {GenericObject}
   * @memberof StandardChartComponent
   */
  public getWidgetTheme(): GenericObject {
    return this.ngxChart?.colorsByAttribute;
  }

  /**
   * handleMarkerClick
   * @param {MarkerContext} event
   * @memberof StandardChartComponent
   */
  public handleMarkerClick(event: MarkerContext) {
    this.onMarkerClick.emit(event);
  }

  /**
   * isDataPresent
   * @returns {boolean}
   * @memberof StandardChartComponent
   */
  public isDataPresent(): boolean {
    return this.chartData?.trendResults?.length && !this.isDataMissing();
  }

  /**
   * trackByName
   * @param {number} _index
   * @param {any} tooltipItem
   * @returns {string}
   * @memberof StandardChartComponent
   */
  public trackByName(_index: number, tooltipItem: any): string {
    return tooltipItem?.name;
  }

  /**
   * setOverlayDetails
   * @private
   * @memberof StandardChartComponent
   */
  private setOverlayDetails() {
    this.overlayChart = this.overlayChartData
      ? this.ngxChartFactoryService.buildNgxChartModel(
          this.overlayChartData,
          AggregationWidgetChartType.LINE,
          this.customColors,
          this.colorSchemas,
          DashboardConfig.OVERLAY_WIDGET_COLORS[1],
          this.isRangedData,
          this.isInvertMode,
          this.skipSort,
          this.rangeFilter,
          this.xAxisLabelFactor,
          DashboardConfig.OVERLAY_WIDGET_COLORS,
        )
      : null;
    this.isOverlayLegendVisible =
      (this.isExploreMode || this.showSeriesNames) &&
      this.overlayChart?.colorizedAttributeValues?.length > 1 &&
      this.showLabels &&
      this.showLegend;
  }

  /**
   * isDataMissing
   * @private
   * @returns {boolean}
   * @memberof StandardChartComponent
   */
  private isDataMissing(): boolean {
    const trend = this.chartData;
    const chartType = this.activeChartType;

    // missing trend means data is still loading or there was a network error
    if (!trend) {
      return false;
    }
    // missing trend definition means a partial error in a batch preview request
    if (!trend.trendDefinition) {
      return true;
    }
    // METRIC should show 0 counter
    if (chartType === AggregationWidgetChartType.METRIC) {
      return false;
    }

    const allCounterValues = [];
    each(trend.trendResults, (trendResult: TrendResult) => {
      each(trendResult.counters, (counter: Counter) => allCounterValues.push(counter.result.value));
    });
    return !some(allCounterValues);
  }
}
