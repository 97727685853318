/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { GenericObject, WebError } from '@dpa/ui-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { has } from 'lodash-es';
import moment from 'moment';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, debounceTime, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { helper } from '@dpa-shared-merlot/effects/dashboard/devices-dashboard.effect.helpers';
import { getDeviceDetailRequest } from '@dpa-shared-merlot/store/dashboard/user-profile/user-profile-selector-helpers';
import { AppConfig, I18NService } from '@ws1c/intelligence-common';
import { ReportMetaService, SolutionSettingMetricService } from '@ws1c/intelligence-core/services';
import {
  DashboardActions,
  DashboardTrendDefinitionOverridesSelectors,
  DeviceProfileActions,
  DeviceProfileSelectors,
} from '@ws1c/intelligence-core/store';
import {
  COLUMN_NAMES,
  CustomReportPreviewSearchResponse,
  DataType,
  Device,
  Entity,
  FilterRule,
  getFQN,
  getThresholdsByMetricByEntity,
  Integration,
  PreviewReportContentRequest,
  QueryBuilder,
  RuleGroup,
  ScoreType,
  Solution,
  SolutionSettingMetricDetails,
  SolutionSettingMetricKey,
  StandardDashboardRequest,
  StandardDashboardType,
  StandardWidgetSubtype,
  TrendDateRange,
  TrendDefinition,
} from '@ws1c/intelligence-models';

/**
 * DeviceProfileEffects
 *
 * @export
 * @class DeviceProfileEffects
 */
@Injectable()
export class DeviceProfileEffects {
  /**
   * initDevice$
   * @type {Observable<Action>}
   * @memberof DeviceProfileEffects
   */
  public initDevice$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceProfileActions.initDevice),
      switchMap(({ device }: ReturnType<typeof DeviceProfileActions.initDevice>) => {
        const request = getDeviceDetailRequest(device);
        return this.reportMetaService.previewReportV2(request).pipe(
          map((response: CustomReportPreviewSearchResponse) => {
            const latestPlatform =
              response.results?.[0]?.[getFQN(Integration.AIRWATCH, Entity.DEVICE, COLUMN_NAMES.byName._device_platform)];
            const latestModel = response.results?.[0]?.[getFQN(Integration.AIRWATCH, Entity.DEVICE, COLUMN_NAMES.byName.device_model)];
            const updatedDevice = new Device({
              ...device,
              model: device.model || latestModel,
              platform: device.platform || latestPlatform,
            });
            return DeviceProfileActions.initDeviceSuccess({ device: updatedDevice });
          }),
          catchError((error: WebError) => of(DeviceProfileActions.initDeviceFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * loadDeviceProfileDashboard$
   * @type {Observable<Action>}
   * @memberof DeviceProfileEffects
   */
  public loadDeviceProfileDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceProfileActions.loadDeviceProfileDashboard, DeviceProfileActions.setDeviceProfileFilters),
      withLatestFrom(
        this.store.select(DeviceProfileSelectors.getProfileFilterRuleGroup),
        this.store.select(DeviceProfileSelectors.getProfileTrendDateRange),
        this.store.select(DeviceProfileSelectors.getDevice),
      ),
      switchMap(
        ([props, ruleGroup, trendDateRange, device]: [
          ReturnType<typeof DeviceProfileActions.loadDeviceProfileDashboard | typeof DeviceProfileActions.setDeviceProfileFilters>,
          RuleGroup,
          TrendDateRange,
          Device,
        ]) => {
          const additionalParams = {
            device_guid: props.deviceId,
          };
          let defaultStandardDashboard = StandardDashboardType.DEVICES_PROFILE;
          if (device.isAndroidDevice) {
            defaultStandardDashboard = StandardDashboardType.DEVICES_PROFILE_ANDROID;
          }
          if (device.isIosDevice) {
            defaultStandardDashboard = StandardDashboardType.DEVICES_PROFILE_IOS;
          }
          const requestOverview = new StandardDashboardRequest(
            defaultStandardDashboard,
            ruleGroup,
            props.trendDateRange || trendDateRange,
            additionalParams,
          );
          return [
            DeviceProfileActions.loadDeviceHealthStatus({ deviceId: props.deviceId }),
            DeviceProfileActions.loadDeviceRiskScore({ deviceId: props.deviceId }),
            DashboardActions.loadStandardDashboard({ request: requestOverview }),
            DeviceProfileActions.loadDeviceEdiData({ deviceId: props.deviceId }),
          ];
        },
      ),
    ),
  );

  /**
   * loadDeviceProfileCompareAppsDashboard
   * @type {Observable<Action>}
   * @memberof DeviceProfileEffects
   */
  public loadDeviceProfileCompareAppsDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceProfileActions.loadDeviceProfileCompareAppsDashboard),
      withLatestFrom(this.store.select(DashboardTrendDefinitionOverridesSelectors.getTrendDefinitionsByStandardWidgetSubtype)),
      filter(
        ([_, trendDefintionByWidgetSubtype]: [
          ReturnType<typeof DeviceProfileActions.loadDeviceProfileCompareAppsDashboard>,
          GenericObject,
        ]) => {
          return !!trendDefintionByWidgetSubtype[StandardWidgetSubtype.DEVICES_PROFILE_TIMELINE_DEVICE_APP_COMPARE];
        },
      ),
      debounceTime(200),
      switchMap(
        ([{ deviceId, appNames, trendDateRange }, trendDefintionByWidgetSubtype]: [
          ReturnType<typeof DeviceProfileActions.loadDeviceProfileCompareAppsDashboard>,
          GenericObject,
        ]) => {
          const trendDefinition: TrendDefinition =
            trendDefintionByWidgetSubtype[StandardWidgetSubtype.DEVICES_PROFILE_TIMELINE_DEVICE_APP_COMPARE];
          const deviceIdFilterRule = new FilterRule({
            attribute: COLUMN_NAMES.byFullyQualifiedName.employee_experience_resource_consumption_airwatch_device_guid,
            condition: FilterRule.FILTER_CONDITION.equals,
            data: deviceId,
            dataType: DataType[DataType.STRING],
          });
          const appNamesFilterRule = new FilterRule({
            attribute: COLUMN_NAMES.byFullyQualifiedName.employee_experience_resource_consumption_name,
            condition: FilterRule.FILTER_CONDITION.includes,
            data: appNames,
            dataType: DataType[DataType.STRING],
          });
          if (trendDateRange) {
            trendDefinition.dateRange = trendDateRange;
          }
          const filterRules = [deviceIdFilterRule];
          if (appNames.length) {
            filterRules.push(appNamesFilterRule);
          }
          const rulesGroup = new RuleGroup(filterRules);
          const queryBuilder = new QueryBuilder(rulesGroup);
          trendDefinition.filter = queryBuilder.getQueryString();
          return [
            DashboardActions.loadStandardDashboardDataV2({
              trendDefinitionIndex: {
                [StandardWidgetSubtype.DEVICES_PROFILE_TIMELINE_DEVICE_APP_COMPARE]: trendDefinition,
              },
            }),
          ];
        },
      ),
    ),
  );

  /**
   * loadDeviceEdiData$
   * @type {Observable<Action>}
   * @memberof DeviceProfileEffects
   */
  public loadDeviceEdiData$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceProfileActions.loadDeviceEdiData),
      withLatestFrom(this.store.select(DeviceProfileSelectors.getDevice)),
      switchMap(([{ deviceId }, device]: [ReturnType<typeof DeviceProfileActions.loadDeviceEdiData>, Device]) => {
        if (device.isMobileDevice) {
          const uemRequest = helper.getMobileUemEdiRequest(deviceId);
          const sdkRequest = helper.getMobileSdkEdiRequest(deviceId);
          return forkJoin([
            this.reportMetaService.previewReportAny(uemRequest, true),
            this.reportMetaService.previewReportContentMultiV2(sdkRequest),
          ]).pipe(
            switchMap(([uemResponse, sdkResponse]: [CustomReportPreviewSearchResponse, CustomReportPreviewSearchResponse[]]) => {
              const response = helper.mergeResponseResults(uemResponse, sdkResponse);
              const userGuid = uemResponse.results?.[0]?.[COLUMN_NAMES.byFullyQualifiedName.airwatch_device_user_guid];
              return [DeviceProfileActions.loadDeviceEdiDataSuccess({ response }), DeviceProfileActions.loadUserProperties({ userGuid })];
            }),
            catchError((error: WebError) => of(DeviceProfileActions.loadDeviceEdiDataFailure({ error }))),
          );
        }
        const request = helper.getDesktopEdiRequest(deviceId);
        return this.reportMetaService.previewReportAny(request, true).pipe(
          map((response: CustomReportPreviewSearchResponse) => {
            return DeviceProfileActions.loadDeviceEdiDataSuccess({ response });
          }),
          catchError((error: WebError) => of(DeviceProfileActions.loadDeviceEdiDataFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * loadDeviceProfileExperienceDashboard$
   * @type {Observable<Action>}
   * @memberof DeviceProfileEffects
   */
  public loadDeviceProfileExperienceDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceProfileActions.loadDeviceProfileExperienceDashboard, DeviceProfileActions.setDeviceProfileFilters),
      withLatestFrom(
        this.store.select(DeviceProfileSelectors.getProfileFilterRuleGroup),
        this.store.select(DeviceProfileSelectors.getProfileTrendDateRange),
      ),
      switchMap(
        ([{ deviceId }, ruleGroup, trendDateRange]: [
          ReturnType<typeof DeviceProfileActions.loadDeviceProfileExperienceDashboard>,
          RuleGroup,
          TrendDateRange,
        ]) => {
          const additionalParams = {
            device_guid: deviceId,
          };
          const request = new StandardDashboardRequest(
            StandardDashboardType.DEVICES_PROFILE_EXPERIENCE_V2,
            ruleGroup,
            trendDateRange,
            additionalParams,
          );

          return [
            DeviceProfileActions.loadDeviceHealthStatus({ deviceId }),
            DeviceProfileActions.loadDeviceRiskScore({ deviceId }),
            DashboardActions.loadStandardDashboard({ request }),
            DeviceProfileActions.loadDeviceHealthThresholdsV2({ entity: Entity.EXPERIENCESCORE_DEVICE }),
          ];
        },
      ),
    ),
  );

  /**
   * loadDeviceProperties
   * @type {Observable<Action>}
   * @memberof DeviceProfileEffects
   */
  public loadDeviceProperties$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceProfileActions.loadDeviceProperties),
      switchMap(({ deviceId }: ReturnType<typeof DeviceProfileActions.loadDeviceProperties>) => {
        const request = helper.getDevicePropertiesRequest(deviceId);
        return this.reportMetaService.previewReportV2(request).pipe(
          switchMap((response: CustomReportPreviewSearchResponse) => {
            const userGuid = response.results?.[0]?.[COLUMN_NAMES.byFullyQualifiedName.airwatch_device_user_guid];
            return [DeviceProfileActions.loadDevicePropertiesSuccess({ response }), DeviceProfileActions.loadUserProperties({ userGuid })];
          }),
          catchError((error: WebError) => of(DeviceProfileActions.loadDevicePropertiesFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * loadUserProperties
   * @type {Observable<Action>}
   * @memberof DeviceProfileEffects
   */
  public loadUserProperties$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceProfileActions.loadUserProperties),
      switchMap(({ userGuid }: ReturnType<typeof DeviceProfileActions.loadUserProperties>) => {
        const request = helper.getUserPropertiesRequest(userGuid);
        return this.reportMetaService.previewReportV2(request).pipe(
          map((response: CustomReportPreviewSearchResponse) => DeviceProfileActions.loadUserPropertiesSuccess({ response })),
          catchError((error: WebError) => of(DeviceProfileActions.loadUserPropertiesFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * loadDevicePropertiesDashboard$
   * @type {Observable<Action>}
   * @memberof DeviceProfileEffects
   */
  public loadDevicePropertiesDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceProfileActions.loadDevicePropertiesDashboard),
      withLatestFrom(
        this.store.select(DeviceProfileSelectors.getProfileFilterRuleGroup),
        this.store.select(DeviceProfileSelectors.getProfileTrendDateRange),
      ),
      switchMap(
        ([{ deviceId }, ruleGroup, trendDateRange]: [
          ReturnType<typeof DeviceProfileActions.loadDevicePropertiesDashboard>,
          RuleGroup,
          TrendDateRange,
        ]) => {
          const additionalParams = {
            [COLUMN_NAMES.byName.device_guid]: deviceId,
          };
          const request = new StandardDashboardRequest(
            StandardDashboardType.DEVICES_PROFILE_PROPERTIES,
            ruleGroup,
            trendDateRange,
            additionalParams,
          );
          return [
            DashboardActions.loadStandardDashboard({ request }),
            DeviceProfileActions.loadDeviceEdiData({ deviceId }),
            DeviceProfileActions.loadDeviceProperties({ deviceId }),
          ];
        },
      ),
    ),
  );

  /**
   * loadDeviceHealthThresholdsV2$
   * @type {Observable<Action>}
   * @memberof DeviceProfileEffects
   */
  public loadDeviceHealthThresholdsV2$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceProfileActions.loadDeviceHealthThresholdsV2),
      mergeMap(({ entity }: ReturnType<typeof DeviceProfileActions.loadDeviceHealthThresholdsV2>) => {
        return this.solutionSettingMetricService
          .getSolutionSettingMetrics(new SolutionSettingMetricKey({ solutionType: Solution.DEEM, solutionSetting: entity }))
          .pipe(
            map((response: SolutionSettingMetricDetails[]) => {
              const thresholdsV2Data = getThresholdsByMetricByEntity(response);
              return DeviceProfileActions.loadDeviceHealthThresholdsV2Success({ data: thresholdsV2Data[entity] });
            }),
          );
      }),
    ),
  );

  /**
   * loadDeviceRiskScore$
   * @type {Observable<Action>}
   * @memberof DeviceProfileEffects
   */
  public loadDeviceRiskScore$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceProfileActions.loadDeviceRiskScore),
      switchMap(({ deviceId }: ReturnType<typeof DeviceProfileActions.loadDeviceRiskScore>) => {
        const date = new Date();
        const request = helper.loadDeviceRiskScoreRequest(
          deviceId,
          new Date().setDate(date.getDate() - 30),
          new Date().setDate(date.getDate()),
        );
        return this.reportMetaService.previewReportContentForMultiIntegrationV2(request).pipe(
          map((response: CustomReportPreviewSearchResponse) => {
            const hasResults = has(response, 'results') && response.results.length;
            const riskScore = hasResults
              ? response.results[0][COLUMN_NAMES.byFullyQualifiedName.airwatch_devicesummaryriskscore_score_severity]
              : ScoreType.NA;
            return DeviceProfileActions.loadDeviceRiskScoreSuccess({ riskScore });
          }),
          catchError(() => of(DeviceProfileActions.loadDeviceRiskScoreFailure())),
        );
      }),
    ),
  );

  /**
   * loadDeviceHealthStatus$
   * @type {Observable<Action>}
   * @memberof DeviceProfileEffects
   */
  public loadDeviceHealthStatus$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceProfileActions.loadDeviceHealthStatus),
      switchMap(({ deviceId }: ReturnType<typeof DeviceProfileActions.loadDeviceHealthStatus>) => {
        const date = new Date();
        const request = helper.loadDeviceHealthStatusRequest(
          deviceId,
          new Date().setDate(date.getDate() - 30),
          new Date().setDate(date.getDate()),
        );
        return this.reportMetaService.previewReportContentForMultiIntegrationV2(request).pipe(
          map((response: CustomReportPreviewSearchResponse) => {
            const hasResults = has(response, 'results') && response.results.length;
            const newStatus = hasResults
              ? response.results[0][
                  getFQN(Integration.INTELLIGENCE, Entity.EXPERIENCESCORE_DEVICE, COLUMN_NAMES.byName._device_overall_score)
                ]
              : this.i18nService.translate('COMMON_MESSAGES.UNKNOWN_VALUE');
            return DeviceProfileActions.loadDeviceHealthStatusSuccess({ deviceHealth: newStatus });
          }),
          catchError(() => of(DeviceProfileActions.loadDeviceHealthStatusFailure())),
        );
      }),
    ),
  );

  /**
   * loadDeviceHealth$
   * @type {Observable<Action>}
   * @memberof DeviceProfileEffects
   */
  public loadDeviceHealth$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceProfileActions.loadDeviceHealth),
      switchMap(({ deviceId }: ReturnType<typeof DeviceProfileActions.loadDeviceHealth>) => {
        const date = new Date();
        const devicesRequest = helper.loadDeviceHealthRequest(deviceId, moment(date).subtract(1, 'days').valueOf(), moment(date).valueOf());
        return this.reportMetaService.previewReportAny(devicesRequest, true).pipe(
          map((response: CustomReportPreviewSearchResponse) => {
            return DeviceProfileActions.loadDeviceHealthSuccess({ deviceId, response });
          }),
          catchError(() => of(DeviceProfileActions.loadDeviceHealthFailure())),
        );
      }),
    ),
  );

  /**
   * loadDeviceEvents$
   * @type {Observable<Action>}
   * @memberof DeviceProfileEffects
   */
  public loadDeviceEvents$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceProfileActions.loadDeviceEvents, DeviceProfileActions.setloadDeviceEventsPagedRequest),
      map(DeviceProfileActions.loadDeviceEventsPaged),
    ),
  );

  /**
   * loadDeviceEventsPaged$
   * @type {Observable<Action>}
   * @memberof DeviceProfileEffects
   */
  public loadDeviceEventsPaged$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceProfileActions.loadDeviceEventsPaged),
      withLatestFrom(this.store.select(DeviceProfileSelectors.getDeviceEventsRequest)),
      switchMap(([_action, request]: [Action, PreviewReportContentRequest]) => {
        const requestNext = new PreviewReportContentRequest(request);
        return this.reportMetaService.previewReportContentForMultiIntegrationV2(requestNext).pipe(
          map((response: CustomReportPreviewSearchResponse) => {
            return DeviceProfileActions.loadDeviceEventsSuccess({ request, response });
          }),
          catchError((error: WebError) => of(DeviceProfileActions.loadDeviceEventsFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * loadZoomSummaryDataPaged$
   * @type {Observable<Action>}
   * @memberof DeviceProfileEffects
   */
  public loadZoomSummaryDataPaged$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceProfileActions.loadZoomSummaryData),
      switchMap(({ request }: ReturnType<typeof DeviceProfileActions.loadZoomSummaryData>) => {
        return this.reportMetaService.previewReportContentForMultiIntegrationV2(request).pipe(
          map((response: CustomReportPreviewSearchResponse) => {
            return DeviceProfileActions.loadZoomSummaryDataSuccess({ response });
          }),
          catchError((error: WebError) => of(DeviceProfileActions.loadZoomSummaryDataFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * loadDevicesProfileSystemTrend$
   * @type {Observable<Action>}
   * @memberof DeviceProfileEffects
   */
  public loadDevicesProfileSystemTrend$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceProfileActions.loadDevicesProfileSystemTrend),
      withLatestFrom(
        this.store.select(DeviceProfileSelectors.getProfileFilterRuleGroup),
        this.store.select(DeviceProfileSelectors.getProfileTrendDateRange),
      ),
      switchMap(
        ([{ deviceId }, ruleGroup, trendDateRange]: [
          ReturnType<typeof DeviceProfileActions.loadDevicesProfileSystemTrend>,
          RuleGroup,
          TrendDateRange,
        ]) => {
          const additionalParams = {
            device_guid: deviceId,
          };
          const request = new StandardDashboardRequest(
            StandardDashboardType.DEVICES_PROFILE_EXPERIENCE_PERFORMANCE,
            ruleGroup,
            trendDateRange,
            additionalParams,
          );
          return [DashboardActions.loadNestedTrendDashboard({ request })];
        },
      ),
    ),
  );

  /**
   * loadDeviceProfileAppPerformance$
   * @type {Observable<Action>}
   * @memberof DeviceProfileEffects
   */
  public loadDeviceProfileAppPerformance$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceProfileActions.loadDeviceProfileAppPerformance),
      withLatestFrom(this.store.select(DeviceProfileSelectors.getProfileTrendDateRange)),
      switchMap(
        ([{ deviceId, appName }, trendDateRange]: [
          ReturnType<typeof DeviceProfileActions.loadDeviceProfileAppPerformance>,
          TrendDateRange,
        ]) => {
          const additionalParams = {
            [AppConfig.QUERY_PARAM_DEVICE_GUID]: deviceId,
            [AppConfig.QUERY_PARAM_APPLICATION_NAME]: appName,
          };
          const request = new StandardDashboardRequest(
            StandardDashboardType.DEVICES_PROFILE_APP_PERFORMANCE,
            undefined,
            trendDateRange,
            additionalParams,
          );
          return [DashboardActions.loadStandardDashboard({ request })];
        },
      ),
    ),
  );

  /**
   * loadDeviceProfileDestinationPerformance$
   * @type {Observable<Action>}
   * @memberof DeviceProfileEffects
   */
  public loadDeviceProfileDestinationPerformance$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceProfileActions.loadDeviceProfileDestinationPerformance),
      withLatestFrom(this.store.select(DeviceProfileSelectors.getProfileTrendDateRange)),
      switchMap(
        ([{ deviceId, url }, trendDateRange]: [
          ReturnType<typeof DeviceProfileActions.loadDeviceProfileDestinationPerformance>,
          TrendDateRange,
        ]) => {
          const additionalParams = {
            [AppConfig.QUERY_PARAM_DEVICE_GUID]: deviceId,
            [AppConfig.QUERY_PARAM_ACTION_URL]: url,
          };
          const request = new StandardDashboardRequest(
            StandardDashboardType.DEVICES_PROFILE_DESTINATION_PERFORMANCE,
            undefined,
            trendDateRange,
            additionalParams,
          );
          return [DashboardActions.loadStandardDashboard({ request })];
        },
      ),
    ),
  );

  /**
   * loadDeviceProfileMobileNetworkDestinationBreakdownPerformance$
   * @type {Observable<Action>}
   * @memberof DeviceProfileEffects
   */
  public loadDeviceProfileMobileNetworkDestinationBreakdownPerformance$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceProfileActions.loadDeviceProfileMobileNetworkDestinationBreakdownPerformance),
      withLatestFrom(this.store.select(DeviceProfileSelectors.getProfileTrendDateRange)),
      switchMap(
        ([{ deviceId, appName, hostName }, trendDateRange]: [
          ReturnType<typeof DeviceProfileActions.loadDeviceProfileMobileNetworkDestinationBreakdownPerformance>,
          TrendDateRange,
        ]) => {
          const additionalParams = {
            [AppConfig.QUERY_PARAM_DEVICE_GUID]: deviceId,
            [AppConfig.QUERY_PARAM_APPLICATION_NAME]: appName,
            [AppConfig.QUERY_PARAM_URL_DOMAIN]: hostName,
          };
          const request = new StandardDashboardRequest(
            StandardDashboardType.DEVICES_PROFILE_MOBILE_NETWORK_BREAKDOWN_PERFORMANCE,
            undefined,
            trendDateRange,
            additionalParams,
          );
          return [DashboardActions.loadStandardDashboard({ request })];
        },
      ),
    ),
  );

  /**
   * loadDeviceProfileNetworkPerformance$
   * @type {Observable<Action>}
   * @memberof DeviceProfileEffects
   */
  public loadDeviceProfileNetworkPerformance$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceProfileActions.loadDeviceProfileNetworkPerformance),
      withLatestFrom(this.store.select(DeviceProfileSelectors.getProfileTrendDateRange)),
      switchMap(
        ([{ deviceId, nicType, nicDescription }, trendDateRange]: [
          ReturnType<typeof DeviceProfileActions.loadDeviceProfileNetworkPerformance>,
          TrendDateRange,
        ]) => {
          const additionalParams = {
            [AppConfig.QUERY_PARAM_DEVICE_GUID]: deviceId,
            [AppConfig.QUERY_PARAM_NIC_TYPE]: nicType,
            [AppConfig.QUERY_PARAM_NIC_FILTER]: nicDescription
              ? `${COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_nic_description} = '${nicDescription}'`
              : `${COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_nic_description} IS NULL`,
          };
          const request = new StandardDashboardRequest(
            StandardDashboardType.DEVICES_PROFILE_NETWORK_PERFORMANCE,
            undefined,
            trendDateRange,
            additionalParams,
          );
          return [DashboardActions.loadStandardDashboard({ request })];
        },
      ),
    ),
  );

  /**
   * loadDeviceProfileUrlMonitoringPerformance$
   * @type {Observable<Action>}
   * @memberof DeviceProfileEffects
   */
  public loadDeviceProfileUrlMonitoringPerformance$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceProfileActions.loadDeviceProfileUrlMonitoringPerformance),
      withLatestFrom(this.store.select(DeviceProfileSelectors.getProfileTrendDateRange)),
      switchMap(
        ([{ deviceId, testId }, trendDateRange]: [
          ReturnType<typeof DeviceProfileActions.loadDeviceProfileUrlMonitoringPerformance>,
          TrendDateRange,
        ]) => {
          const request = new StandardDashboardRequest(
            StandardDashboardType.DEVICES_PROFILE_URL_MONITORING_PERFORMANCE,
            undefined,
            trendDateRange,
            {
              [AppConfig.QUERY_PARAM_DEVICE_GUID]: deviceId,
              [AppConfig.QUERY_PARAM_TEST_ID]: testId,
            },
          );

          return [DashboardActions.loadStandardDashboard({ request })];
        },
      ),
    ),
  );

  /**
   * loadDeviceProfileEnergyPerformance$
   * @type {Observable<Action>}
   * @memberof DeviceProfileEffects
   */
  public loadDeviceProfileEnergyPerformance$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceProfileActions.loadDeviceProfileEnergyPerformance),
      withLatestFrom(this.store.select(DeviceProfileSelectors.getProfileTrendDateRange)),
      switchMap(
        ([{ deviceId }, trendDateRange]: [ReturnType<typeof DeviceProfileActions.loadDeviceProfileEnergyPerformance>, TrendDateRange]) => {
          const request = new StandardDashboardRequest(
            StandardDashboardType.DEVICES_PROFILE_ENERGY_PERFORMANCE,
            undefined,
            trendDateRange,
            {
              [AppConfig.QUERY_PARAM_DEVICE_GUID]: deviceId,
            },
          );
          return [DashboardActions.loadStandardDashboard({ request })];
        },
      ),
    ),
  );

  /**
   * loadDeviceProfilePerformance$
   * @type {Observable<Action>}
   * @memberof DeviceProfileEffects
   */
  public loadDeviceProfilePerformance$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceProfileActions.loadDeviceProfilePerformance),
      withLatestFrom(this.store.select(DeviceProfileSelectors.getProfileTrendDateRange)),
      switchMap(
        ([{ deviceId, dashboardType }, trendDateRange]: [
          ReturnType<typeof DeviceProfileActions.loadDeviceProfilePerformance>,
          TrendDateRange,
        ]) => {
          const additionalParams = {
            [AppConfig.QUERY_PARAM_DEVICE_GUID]: deviceId,
          };
          const request = new StandardDashboardRequest(dashboardType, undefined, trendDateRange, additionalParams);
          return [DashboardActions.loadStandardDashboard({ request })];
        },
      ),
    ),
  );

  /**
   * loadDeviceCrumbList$
   * @type {Observable<Action>}
   * @memberof DeviceProfileEffects
   */
  public loadDeviceCrumbList$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceProfileActions.loadDeviceCrumbList),
      withLatestFrom(this.store.select(DeviceProfileSelectors.getProfileFilterRuleGroup)),
      mergeMap(([{ crumbListLocator }, ruleGroup]: [ReturnType<typeof DeviceProfileActions.loadDeviceCrumbList>, RuleGroup]) => {
        const crumbsRequests = helper.getDeviceCrumbListMultiRequest(crumbListLocator, ruleGroup);
        return this.reportMetaService.previewReportContentMultiV2(crumbsRequests).pipe(
          map((responses: CustomReportPreviewSearchResponse[]) => {
            return DeviceProfileActions.loadDeviceCrumbListSuccess({
              crumbListLocator,
              responses,
            });
          }),
          catchError((error: WebError) => {
            return of(
              DeviceProfileActions.loadDeviceCrumbListFailure({
                crumbListLocator,
                error,
              }),
            );
          }),
        );
      }),
    ),
  );

  /**
   * loadDeviceDetailsDashboard$
   * @type {Observable<Action>}
   * @memberof DeviceProfileEffects
   */
  public loadDeviceDetailsDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceProfileActions.loadDeviceDetailsDashboard),
      withLatestFrom(
        this.store.select(DeviceProfileSelectors.getProfileFilterRuleGroup),
        this.store.select(DeviceProfileSelectors.getProfileTrendDateRange),
      ),
      map(
        ([{ deviceId }, ruleGroup, trendDateRange]: [
          ReturnType<typeof DeviceProfileActions.loadDeviceDetailsDashboard>,
          RuleGroup,
          TrendDateRange,
        ]) => {
          const additionalParams = {
            device_guid: deviceId,
          };
          const request = new StandardDashboardRequest(StandardDashboardType.DEVICES_DETAILS, ruleGroup, trendDateRange, additionalParams);
          return DashboardActions.loadStandardDashboard({ request });
        },
      ),
    ),
  );

  /**
   * showDeviceProfileSlideOver$
   * @type {Observable<Action>}
   * @memberof DeviceProfileEffects
   */
  public showDeviceProfilePanel$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceProfileActions.showDeviceProfileSlideOver),
      map(({ device }: ReturnType<typeof DeviceProfileActions.showDeviceProfileSlideOver>) => DeviceProfileActions.initDevice({ device })),
    ),
  );

  /**
   * Creates an instance of DeviceProfileEffects.
   * @param {Actions} actions$
   * @param {I18NService} i18nService
   * @param {Store} store
   * @param {SolutionSettingMetricService} solutionSettingMetricService
   * @param {ReportMetaService} reportMetaService
   * @memberof DeviceProfileEffects
   */
  constructor(
    private actions$: Actions,
    public i18nService: I18NService,
    private store: Store,
    private solutionSettingMetricService: SolutionSettingMetricService,
    private reportMetaService: ReportMetaService,
  ) {}
}
