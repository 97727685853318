/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { FeatureControl } from '@ws1c/intelligence-common';
import { UserPreferenceCommonSelectors } from './user-preference-common.selectors';

/**
 * UserPreferenceFeatureControlsSelectors
 *
 * @export
 * @class UserPreferenceFeatureControlsSelectors
 */
export class UserPreferenceFeatureControlsSelectors {
  public static isApteligentNetworkInsightsDashboardEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.APTELIGENT_NETWORK_INSIGHTS_DASHBOARD_ENABLED,
  );
  public static isApteligentUserFlowsDashboardEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.APTELIGENT_USER_FLOWS_ENABLED,
  );
  public static isApteligentUsersDashboardEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.APTELIGENT_USERS_ENABLED);
  public static isApteligentErrorsDashboardEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.APTELIGENT_ERRORS_DASHBOARD_ENABLED,
  );
  public static isApteligentEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.APTELIGENT_ENABLED);
  public static isApteligentHandledExceptionDashboardEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.APTELIGENT_HE_DASHBOARD_ENABLED,
  );
  public static isApteligentPluginExceptionDashboardEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.APTELIGENT_PE_DASHBOARD_ENABLED,
  );
  public static isAutomationBatchModeEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.AUTOMATION_BATCH_MODE_ENABLED);
  public static isAutomationEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.AUTOMATION_ENABLED);
  public static isAutomationRepeatTriggerEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.AUTOMATION_REPEAT_TRIGGER_ENABLED,
  );
  public static isAutomationQuotaUsageEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.AUTOMATION_QUOTA_USAGE_ENABLED,
  );
  public static isAutomationEvaluationTriggersEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.AUTOMATION_EVALUATION_TRIGGERS_ENABLED,
  );
  public static isAzureAdSetupEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.AZURE_AD_ENABLED);
  public static isCustomConnectorEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.AUTOMATION_CUSTOM_CONNECTOR_ENABLED,
  );
  public static isCveVulnerabilitiesEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.DASHBOARD_CVE_VULNERABILITIES);
  public static isUEMEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.AIRWATCH_ENABLED);
  public static isDashboardDevicesEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.DASHBOARD_DEVICES_ENABLED);
  public static isGlobalSearchEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.GLOBAL_SEARCH_ENABLED);
  public static isHistoricalChartsSupported = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.DASHBOARD_HISTORICAL_ENABLED);
  public static isHorizonEnabled = UserPreferenceCommonSelectors.anyFeaturesEnabled([
    FeatureControl.DASHBOARD_HORIZON_ENABLED,
    FeatureControl.DASHBOARD_HORIZON_TITAN_ENABLED,
  ]);
  public static isIDMEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.IDM_ENABLED);
  public static isMultiSubscriptionsEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.MULTI_SUBSCRIPTIONS_ENABLED);
  public static isPreviewTagEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.PREVIEW_ENABLED);
  public static isRbacEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.RBAC_ENABLED);
  public static isSecurityDeviceRiskEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.DASHBOARD_DEVICE_RISK_ENABLED);
  public static isStandaloneApteligentEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.STANDALONE_APTELIGENT_ENABLED,
  );
  public static isTrustNetworkFeatureEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.TRUST_NETWORK_ENABLED);
  public static isEventForwarderEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.EVENT_FORWARDER_ENABLED);
  public static isUserBookmarksEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.USER_BOOKMARKS_ENABLED);
  public static isEmployeeExperienceAppsDashboardEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.EMPLOYEE_EXPERIENCE_APPS_ENABLED,
  );
  public static isEmployeeExperienceAppsUxScoreDashboardEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.EMPLOYEE_EXPERIENCE_APPS_UX_SCORE_ENABLED,
  );
  public static isEmployeeExperienceAppDetailsDashboardEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.EMPLOYEE_EXPERIENCE_APP_DETAILS_ENABLED,
  );
  public static isEmployeeExperienceDeviceDetailsDashboardEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.EMPLOYEE_EXPERIENCE_DEVICE_DETAILS_ENABLED,
  );
  public static isApteligentDeviceDetailsDashboardEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.APTELIGENT_DEVICE_DETAILS_ENABLED,
  );
  public static isDeviceProfileOverviewDashboardEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.DASHBOARD_DEVICE_PROFILE_OVERVIEW_ENABLED,
  );
  public static isUserProfileExperienceDashboardEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.USER_PROFILE_EXPERIENCE_ENABLED,
  );
  public static isUserProfileUserRiskScoreEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.AIRWATCH_USERRISKSCORE_ENABLED,
  );
  public static isUserProfileSecurityDashboardEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.USER_PROFILE_SECURITY_ENABLED,
  );
  public static isDeviceProfileExperienceDashboardEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.DASHBOARD_DEVICE_PROFILE_EXPERIENCE_ENABLED,
  );
  public static isDeviceProfileExperienceGpuEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.DASHBOARD_DEVICE_PROFILE_EXPERIENCE_GPU_ENABLED,
  );
  public static isDeviceProfileExperienceMemoryEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.DASHBOARD_DEVICE_PROFILE_EXPERIENCE_MEMORY_ENABLED,
  );
  public static isDeviceProfileDeviceCrumbEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.DASHBOARD_DEVICE_PROFILE_DEVICE_CRUMB_ENABLED,
  );
  public static isDeviceProfileSecurityDashboardEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.DASHBOARD_DEVICE_PROFILE_SECURITY_ENABLED,
  );
  public static isIntegrationAutoOptinEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.INTEGRATION_AUTO_OPTIN);
  public static isDashboardSecurityEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.DASHBOARD_SECURITY_ENABLED);
  public static isDashboardUserRiskEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.DASHBOARD_USER_RISK_ENABLED);
  public static isDashboardOsUpdatesEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.DASHBOARD_ENROLLMENT_ENABLED);
  public static isDashboardAppsEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.DASHBOARD_APP_ADOPTION_ENABLED);
  public static isDashboardDefaultEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.DASHBOARD_DEFAULT_ENABLED);
  public static isDashboardsEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.DASHBOARDS_ENABLED);
  public static isSolutionsEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.SOLUTIONS_ENABLED);
  public static isSolutionsCveEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.SOLUTIONS_CVE_ENABLED);
  public static isSolutionsCveIosEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.SOLUTIONS_CVE_IOS_ENABLED);
  public static isSolutionsCveMacosEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.SOLUTIONS_CVE_MACOS_ENABLED);
  public static isSolutionsDeemHubNotificationsEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.SOLUTIONS_DEEM_HUB_NOTIFICATIONS,
  );
  public static isSolutionsDeemPhysicalEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.SOLUTIONS_DEEM_PHYSICAL_ENABLED,
  );
  public static isSolutionsDeemVirtualEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.SOLUTIONS_DEEM_VIRTUAL_ENABLED,
  );
  public static isSolutionsDeemIncidentEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.SOLUTIONS_DEEM_INCIDENT_ENABLED,
  );
  public static isSolutionsDeemSurveysEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.SOLUTIONS_DEEM_SURVEYS_ENABLED,
  );
  public static isSolutionsDeemSyntheticUrlMonitoringEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.SOLUTIONS_DEEM_SYNTHETIC_URL_MONITORING_ENABLED,
  );
  public static isSolutionsDeemZoomDashboardEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.SOLUTIONS_DEEM_ZOOM_DASHBOARD_ENABLED,
  );
  public static isDesktopAdvancedTelemetryEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.DESKTOP_ADVANCED_TELEMETRY_ENABLED,
  );
  public static isMobileAdvancedTelemetryEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.MOBILE_ADVANCED_TELEMETRY_ENABLED,
  );
  public static isEmployeeExperienceEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.EMPLOYEE_EXPERIENCE_ENABLED);
  public static isProductivityAppsEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.APTELIGENT_PRODUCTIVITY_APPS_ENABLED,
  );
  public static isDashboardDeviceNetworkEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.DASHBOARD_DEVICE_DESKTOP_NETWORK_ENABLED,
  );
  public static isDataAccessPolicyEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.DATA_ACCESS_POLICY_ENABLED);
  public static isPendoTelemetryEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.PENDO_TELEMETRY_ENABLED);
  public static isNestedGroupsFilterEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.NESTED_GROUPS_FILTER_ENABLED);
  public static isIntegrationUagEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.INTEGRATION_UAG_ENABLED);
  public static isIntegrationZoomEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.ZOOM_ENABLED);
  public static isProductFeedbackEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.PRODUCT_FEEDBACK_ENABLED);
  public static isAirwatchHistoricalDeprecationEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.DASHBOARD_AIRWATCH_HISTORICAL_DEPRECATION_ENABLED,
  );
  public static isWorkflowRunHistoryEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.WORKFLOW_RUN_HISTORY_ENABLED);
  public static isDeemInsightsEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.SOLUTIONS_DEEM_INSIGHTS_ENABLED);
  public static isDeemInsightToIncidentEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.SOLUTIONS_DEEM_INSIGHT_TO_INCIDENT,
  );
  public static isDeemRcaEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.SOLUTIONS_DEEM_RCA_ENABLED);
  public static isDashboardFrontlineEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.DASHBOARD_FRONTLINE_ENABLED);

  // WS1C Feature Flags
  public static isWorkspaceOneCloudEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.WORKSPACE_ONE_CLOUD_ENABLED);
  public static isCspFederationSetupEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.CSP_FEDERATION_SETUP_ENABLED);
  public static isHubSilentOptInEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.INTEGRATION_HUB_SILENT_OPTIN_ENABLED,
  );
  public static isSilentOptInEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.INTEGRATION_SILENT_OPTIN_ENABLED);
  public static isRenameAccessTenantEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.ACCESS_RENAME_TENANT_ENABLED);
  public static isWorkspaceOneRbacEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.WORKSPACE_ONE_RBAC_ENABLED);
  public static isWorkspaceOneBrownfieldNotificationExperienceEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.WORKSPACE_ONE_BROWNFIELD_NOTIFICATION_EXPERIENCE_ENABLED,
  );
  public static isWorkspaceOneBrownfieldRbacEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.WORKSPACE_ONE_BROWNFIELD_RBAC_ENABLED,
  );
  public static isTitanIntegrationEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.WORKSPACE_ONE_TITAN_INTEGRATION_ENABLED,
  );
  public static isBrownfieldLimitedAvailibityEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.WS1C_BROWNFILED_LIMITED_AVAILIBILITY_ENABLED,
  );
  public static isIntegrationExtremeNetworksEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.EXTREME_NETWORKS_ENABLED,
  );

  // Reports
  public static isTimeseriesReportingEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.TIMESERIES_REPORT_ENABLED);
  public static isAccessPoliciesForReportSharingEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.REPORT_SHARING_ACCESS_POLICIES_ENABLED,
  );
  public static isReportFormatOptionEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.REPORT_FORMAT_OPTION_ENABLED);
  public static isReportCsvFriendlyColumnLabelsOptionEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.REPORT_CSV_FRIENDLY_COLUMN_LABELS_OPTION_ENABLED,
  );
  public static isReportBuilderV2Enabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.REPORT_BUILDER_V2);
  public static isReportPublicSharingEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.REPORT_PUBLIC_SHARING_ENABLED);

  // Global Orchestrator Features
  public static isGlobalOrchestratorGroupsEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.GLOBAL_ORCHESTRATOR_GROUPS_ENABLED,
  );

  public static isGlobalOrchestratorConditionsEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.GLOBAL_ORCHESTRATOR_CONDITIONS_ENABLED,
  );

  // Notification
  public static isNotificationEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.NOTIFICATION_ENABLED);
  public static isNotificationRulesEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.NOTIFICATION_RULES_ENABLED);

  // Dashboard Widgets
  public static isWidgetEditRangeEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.DASHBOARD_WIDGET_AGGREGATION_RESULT_FILTER_ENABLED,
  );
  public static isWidgetEditThemeEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.DASHBOARD_WIDGET_COLOR_SCHEMA_PREFERENCE_ENABLED,
  );

  // IA V2
  public static isIaV2Phase2Enabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.IA_V2_PHASE2_ENABLED);

  // Scripts and Sensors in Marketplace
  public static isMarketplaceScriptsEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.MARKETPLACE_SCRIPTS_ENABLED);
  public static isMarketplaceSensorsEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.MARKETPLACE_SENSORS_ENABLED);

  // Widget overlays
  public static isWidgetOverlaysEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.WIDGET_OVERLAYS_ENABLED);

  // Widget join
  public static isWidgetJoinEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.DASHBOARD_WIDGETS_JOIN_ENABLED);

  // Custom Role
  public static isCustomRoleEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.CUSTOM_ROLE_ENABLED);

  // Attribute selector v2
  public static isAttributeSelectorV2Enabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.ATTRIBUTE_SELECTOR_V2);

  // UEM Freestyle Workflows in Marketplace
  public static isMarketplaceUEMFreestyleWorkflowsEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.MARKETPLACE_UEM_FREESTYLE_WORKFLOWS_ENABLED,
  );

  // WS1C Broker Feature Flags
  public static isBrownFieldMigrationEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.WS1_BROKER_BROWNFIELD_MIGRATION_ENABLED,
  );

  // In-product trial
  public static isInProductTrialEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.IN_PRODUCT_TRIAL_ENABLED);

  public static isDeemVirtualInfraDashboardEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.SOLUTIONS_DEEM_VIRTUAL_INFRA_DASHBOARD_ENABLED,
  );

  public static isDeemVirtualSessionTimelineEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.SOLUTIONS_DEEM_VIRTUAL_SESSION_TIMELINE_ENABLED,
  );

  public static isDeemVirtualRdshAppSupportEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.SOLUTIONS_DEEM_VIRTUAL_RDSH_APP_SUPPORT_ENABLED,
  );

  // Data Explorer
  public static isDataExplorerEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.UI_DATA_EXPLORER_ENABLED);

  // Playbooks
  public static isPlaybooksEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.SOLUTIONS_PLAYBOOKS_ENABLED);

  // UEM V2 toggle for standard dashboard
  public static isUemV2DashboardToggleEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.UI_UEM_V2_DASHBOARD_TOGGLE_ENABLED,
  );

  // ServiceNow ITSM
  public static isServiceNowITSMEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.SERVICE_NOW_ITSM_ENABLED);

  // Incremental Loading
  public static isWidgetsIncrementalLoadingEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(
    FeatureControl.WIDGETS_INCREMENTAL_LOADING_ENABLED,
  );

  // SAM Dashboard
  public static isSamEnabled = UserPreferenceCommonSelectors.isFeatureEnabled(FeatureControl.SOLUTIONS_DEEM_SAM_ENABLED);
}
