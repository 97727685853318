/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { AppConfig } from './app-config';

/**
 * Common Endpoints
 *
 * @export
 * @class Endpoint
 */
/* eslint-disable jsdoc/require-jsdoc */
export class Endpoint {
  public static readonly CONSOLE_LOGOUT = '/logout';
  public static readonly CSP_ROOT = `${AppConfig.API_ROOT}/csp`;
  public static readonly SESSION_PING = `${AppConfig.API_ROOT}/ping`;
  public static readonly UI_ROOT = '/ui';
  public static readonly UI_V1_ROOT = `${Endpoint.UI_ROOT}/v1`;
  public static readonly PENDO_API_ROOT = '/api/v1';

  public static readonly WS1_BROKER_GATEWAY = '/brokergateway';

  public static readonly CRASH = `${AppConfig.API_ROOT}/crash`;
  public static readonly CRASH_UPLOAD_SEARCH = `${Endpoint.CRASH}/search/debug/files`;
  public static readonly GET_CRASH_UPLOAD_IOS = (appId: string, appVersion: string) => {
    return `${Endpoint.CRASH}/symbols/ios/${appId}/${appVersion}`;
  };

  public static readonly DOWNLOAD_CRASH_FILE = (fileId: string) => {
    return `${Endpoint.CRASH}/debug/file/${fileId}/download`;
  };
  public static readonly GET_CRASH_UPLOAD_ANDROID = (appId: string, appVersion: string) => {
    return `${Endpoint.CRASH}/mapping/android/${appId}/${appVersion}`;
  };
  public static readonly DELETE_CRASH_UPLOAD = (uploadId: string) => {
    return `${Endpoint.CRASH}/debug/file/${uploadId}`;
  };

  public static readonly INTEGRATION_META_ROOT = `${AppConfig.API_ROOT}/meta`;
  public static readonly BULK_ATTRIBUTES = `${Endpoint.INTEGRATION_META_ROOT}/attributes`;
  public static INTEGRATION_ENTITY_ROOT(integration: string, entity: string) {
    return `${Endpoint.INTEGRATION_META_ROOT}/integration/${integration}/entity/${entity}`;
  }
  public static INTEGRATION_ENTITY_ATTRIBUTES(integration: string, entity: string) {
    return `${Endpoint.INTEGRATION_ENTITY_ROOT(integration, entity)}/attributes`;
  }
  public static INTEGRATION_ENTITY_THRESHOLDS_DEFAULT(integration: string, entity: string) {
    return `${Endpoint.INTEGRATION_ENTITY_ROOT(integration, entity)}/thresholds/default`;
  }

  public static AGGREGRATION_ROOT = `${AppConfig.API_ROOT}/aggregation`;
  public static AGGREGRATION_META_ROOT = `${Endpoint.AGGREGRATION_ROOT}/meta`;
  public static AGGREGATION_INTEGRATION_ENTITY_ROOT(integration: string, entity: string) {
    return `${Endpoint.AGGREGRATION_META_ROOT}/integration/${integration}/entity/${entity}`;
  }
  public static AGGREGATION_INTEGRATION_ENTITY_TRENDS(integration: string, entity: string) {
    return `${Endpoint.AGGREGATION_INTEGRATION_ENTITY_ROOT(integration, entity)}/trends`;
  }
  public static AGGREGATION_TREND_ID(id: string) {
    return `${Endpoint.AGGREGRATION_META_ROOT}/trends/${id}`;
  }

  public static readonly REPORT_CATEGORIES_ROOT = `${AppConfig.API_ROOT}/categories`;
  public static readonly REPORT_CATEGORY_DETAILS = (entity: string) => `${Endpoint.REPORT_CATEGORIES_ROOT}/${entity}`;
  public static REPORT_PREVIEW_FOR_CATEGORY(entity: string) {
    return `${Endpoint.REPORT_CATEGORY_DETAILS(entity)}/preview`;
  }

  public static readonly CONTENT_ROOT = `${AppConfig.API_ROOT}/content`;
  public static readonly GET_CONTENT_ROOT_INTEGRATION_AND_ENTITY = (integration: string, entity: string) => {
    return `${Endpoint.CONTENT_ROOT}/integration/${integration}/entity/${entity}`;
  };
  public static GET_CVE_BY_INTEGRATION_AND_ENTITY = (integration: string, entity: string) => {
    return `${Endpoint.GET_CONTENT_ROOT_INTEGRATION_AND_ENTITY(integration, entity)}/search/cve`;
  };
  public static readonly CONTENT_SEARCH = `${Endpoint.CONTENT_ROOT}/search`;

  public static readonly REPORTS_ROOT = `${AppConfig.API_ROOT}/reports`;
  public static readonly REPORTS_SCHEDULES = `${Endpoint.REPORTS_ROOT}/schedules`;
  public static readonly REPORTS_TRACKING = `${Endpoint.REPORTS_ROOT}/tracking`;
  public static readonly REPORTS_ORG_SUMMARY = `${Endpoint.REPORTS_ROOT}/org/summary`;
  public static readonly REPORTS_TRANSFER_OWNERSHIP = `${Endpoint.REPORTS_ROOT}/transferowner`;
  public static SCHEDULE_DETAILS = (id: string) => `${Endpoint.REPORTS_SCHEDULES}/${id}`;
  public static DOWNLOAD_DETAILS_FOR_SCHEDULE = (id: string) => `${Endpoint.SCHEDULE_DETAILS(id)}/downloads/search`;
  public static REPORTS_TRACKING_ID = (id: string) => `${Endpoint.REPORTS_TRACKING}/${id}`;
  public static SCHEDULE_DETAILS_DOWNLOAD_DETAILS = (_id: string) => `${Endpoint.REPORTS_TRACKING_ID}/download`;

  public static REPORT_DETAILS = (id: string) => `${Endpoint.REPORTS_ROOT}/${id}`;
  public static SCHEDULES_FOR_REPORT = (id: string) => `${Endpoint.REPORT_DETAILS(id)}/schedules/search`;
  public static DOWNLOAD_DETAILS_FOR_REPORT = (id: string) => `${Endpoint.REPORT_DETAILS(id)}/downloads/search`;
  public static REPORT_RECIPIENTS = (id: string) => `${Endpoint.REPORT_DETAILS(id)}/recipients`;
  public static REPORT_RUN = (id: string) => `${Endpoint.REPORT_DETAILS(id)}/run`;

  public static REPORT_INTERNAL_SHARE = (id: string) => `${Endpoint.REPORT_DETAILS(id)}/share`;
  public static REPORT_INTERNAL_SHARE_ACCOUNTS = (id: string) => `${Endpoint.REPORT_INTERNAL_SHARE(id)}/accounts`;

  public static readonly TAGS = (quickFilterTagType: string) => `${AppConfig.API_ROOT}/tags/${quickFilterTagType}`;
  public static readonly TEMPLATES_ROOT = `${AppConfig.API_ROOT}/templates`;
  public static readonly TEMPLATES_TAGS = `${Endpoint.TEMPLATES_ROOT}/tags`;
  public static readonly TEMPLATES_BOOKMARK_ID = (type, id) => {
    return `${AppConfig.API_ROOT}/${type}/templates/${encodeURIComponent(id)}/bookmarks`;
  };
  public static readonly TEMPLATES_REPORTS = `${Endpoint.TEMPLATES_ROOT}/reports`;
  public static readonly TEMPLATES_REPORTS_ID = (id: string) => `${Endpoint.TEMPLATES_REPORTS}/${id}`;
  public static readonly TEMPLATES_REPORTS_SEARCH = `${Endpoint.TEMPLATES_REPORTS}/search`;
  public static readonly TEMPLATES_DEFAULT = `${Endpoint.TEMPLATES_ROOT}/default`;
  public static readonly TEMPLATES_REPORTS_INTEGRATION_ENTITY_NAME = (integration: string, entity: string, name: string) =>
    `${Endpoint.TEMPLATES_DEFAULT}/reports/integration/${integration}/entity/${entity}/name/${name}`;

  public static readonly ACTIVITY_ROOT = `${AppConfig.API_ROOT}/activity/reports`;
  public static REPORT_ACTIVITY = (id: string) => `${Endpoint.ACTIVITY_ROOT}/${id}`;

  public static readonly APPS = `${AppConfig.API_ROOT}/apps`;
  public static readonly APPS_SEARCH = `${Endpoint.APPS}/search`;

  public static readonly EULA = `${AppConfig.API_ROOT}/eula`;

  public static readonly ADMIN_USER_ROOT = `${AppConfig.API_ROOT}/adminuser`;
  public static readonly AUTOMATION_ROOT = `${AppConfig.API_ROOT}/automations`;
  public static readonly DASHBOARD_ROOT = `${AppConfig.API_ROOT}/dashboards`;
  public static readonly DASHBOARDS_ORG_SUMMARY = `${Endpoint.DASHBOARD_ROOT}/org/summary`;
  public static readonly DASHBOARD_THRESHOLD = `${Endpoint.DASHBOARD_ROOT}/thresholds/standard`;
  public static DASHBOARD_THRESHOLD_NAME = (name: string) => `${Endpoint.DASHBOARD_THRESHOLD}/${name}`;

  public static readonly APP_REGISTRATIONS = `${AppConfig.API_ROOT}/appregistrations`;
  public static readonly APP_LOADS_ROOT = `${AppConfig.API_ROOT}/apteligent`;

  public static readonly DEEM_ROOT = `${Endpoint.APPS}/deem`;

  public static readonly MLJOB = `${AppConfig.API_ROOT}/mljob`;
  public static readonly RCA_ROOT = `${Endpoint.MLJOB}/rca`;
  public static readonly RCA_FEATURES = `${Endpoint.RCA_ROOT}/features`;
  public static readonly RCA_ESTIMATE = `${Endpoint.RCA_ROOT}/estimate`;
  public static readonly RCA_RUN = `${Endpoint.RCA_ROOT}/run`;
  public static readonly RCA_CURRENT_JOB = (sourceObjectType: string, sourceObjectId: string) =>
    `${Endpoint.RCA_ROOT}/${sourceObjectType}/${sourceObjectId}/latest`;
  public static readonly RCA_RESULTS = (sourceObjectType: string, sourceObjectId: string, glueJobId: string, notUseful: boolean) =>
    `${Endpoint.RCA_ROOT}/${sourceObjectType}/${glueJobId}/${sourceObjectId}?not_useful=${notUseful}`;
  public static readonly RCA_JOBS_BY_ID = (sourceObjectType: string, sourceObjectId: string) =>
    `${Endpoint.RCA_ROOT}/${sourceObjectType}/${sourceObjectId}`;
  public static readonly RCA_FEEDBACK = `${Endpoint.RCA_ROOT}/feedback`;
  public static readonly RCA_CANCEL = (glueJobId: string, sourceObjectType: string, sourceObjectId: string) =>
    `${Endpoint.RCA_ROOT}/cancel/${sourceObjectType}/${glueJobId}/${sourceObjectId}`;

  public static readonly ORG_ROOT = `${AppConfig.API_ROOT}/org`;
  public static readonly ORG_PREFERENCES = `${Endpoint.ORG_ROOT}/preferences`;
  public static readonly ORG_REPORT_RETENTION = `${Endpoint.ORG_ROOT}/reportretention`;

  public static readonly TRIAL_ROOT = `${Endpoint.ORG_ROOT}/trial`;
  public static readonly START_TRIAL = `${Endpoint.TRIAL_ROOT}/start`;
  public static readonly DECLINE_TRIAL = `${Endpoint.TRIAL_ROOT}/decline`;
  public static readonly TRIAL_CONTACT_DETAILS = `${Endpoint.TRIAL_ROOT}/contactdetails`;

  public static readonly REPORT_ROOT = `${AppConfig.API_ROOT}/reports`;
  public static readonly WIDGET_ROOT = `${AppConfig.API_ROOT}/widgets`;
  public static readonly IAM_ROOT = `${AppConfig.API_ROOT}/iam`;

  public static readonly WIDGET_TEMPLATE_ROOT = `${Endpoint.WIDGET_ROOT}/templates`;

  public static readonly AUTOMATION_ACTIONS_LOOKUP = `${AppConfig.API_ROOT}/actions/lookup`;
  public static readonly AUTOMATION_PREVIEW = `${Endpoint.AUTOMATION_ROOT}/preview`;
  public static readonly AUTOMATION_QUOTAS = `${Endpoint.AUTOMATION_ROOT}/quotas`;
  public static readonly AUTOMATIONS_ORG_SUMMARY = `${Endpoint.AUTOMATION_ROOT}/org/summary`;
  public static readonly AUTOMATIONS_BY_ACTION_IDS = `${Endpoint.AUTOMATION_ROOT}/actiontemplatesids`;
  public static readonly AUTOMATIONS_BY_SERVICE_IDS = `${Endpoint.AUTOMATION_ROOT}/serviceids`;

  public static readonly SERVICE_ACCOUNT = `${AppConfig.API_ROOT}/oauth`;

  public static readonly ORG_HIERACHY = `${Endpoint.ORG_ROOT}/locationgroup/hierarchy`;
  public static readonly WIDGET_TREND_PREVIEW = `${Endpoint.WIDGET_ROOT}/trend/preview`;

  // integration - idm, horizon
  public static INTEGRATION_ROOT = (integration: string) => `${Endpoint.ORG_ROOT}/${integration}`;
  public static INTEGRATION_AUTHORIZE = (integration: string, redirectPath?: string) =>
    `${Endpoint.INTEGRATION_ROOT(integration)}/activate?redirect_path=${redirectPath}`;

  // integration by id endpoints
  public static INTEGRATION_ID = (id: string) => `${Endpoint.ORG_ROOT}/integration/${id}`;
  public static INTEGRATION_ID_DISABLE = (id: string) => `${Endpoint.INTEGRATION_ID(id)}/disable`;
  public static INTEGRATION_ID_OPT_IN = (id: string) => `${Endpoint.INTEGRATION_ID(id)}/optin`;
  public static INTEGRATION_ID_OPT_OUT = (id: string) => `${Endpoint.INTEGRATION_ID(id)}/optout`;

  // integrations
  public static readonly INTEGRATIONS = `${Endpoint.ORG_ROOT}/integrations`;
  public static readonly INTEGRATIONS_IDM = `${Endpoint.INTEGRATIONS}/idm`;
  public static readonly INTEGRATIONS_IDM_ID = (id: string) => `${Endpoint.INTEGRATIONS_IDM}/${id}`;
  public static readonly REPROVISION_STATUS = `${Endpoint.INTEGRATIONS_IDM}/reprovision/status`;
  public static readonly REPROVISION_ID = (id: string) => `${Endpoint.INTEGRATIONS_IDM_ID(id)}/reprovision`;
  public static readonly REPROVISION_STATUS_ID = (id: string) => `${Endpoint.REPROVISION_ID(id)}/status`;
  public static readonly TENANT_AVAILABILITY_ID = (id: string, newTenantName: string) =>
    `${Endpoint.INTEGRATIONS_IDM_ID(id)}/availability?new_tenant_name=${encodeURIComponent(newTenantName)}`;

  // idm endpoints
  public static readonly IDM = `${Endpoint.ORG_ROOT}/idm`;
  public static readonly IDM_ID = (id: string) => `${Endpoint.IDM}/${id}`;
  public static readonly IDM_USERS_COUNT = (id: string) => `${Endpoint.IDM_ID(id)}/users/count`;

  // integration - credential based (uag)
  public static readonly INTEGRATION_DOWNLOAD_CREDENTIAL = `${Endpoint.ORG_ROOT}/credentials`;
  public static INTEGRATION_DEACTIVATE_CREDENTIAL = (integration: string, sourceSystemId: string) =>
    `${Endpoint.INTEGRATION_DOWNLOAD_CREDENTIAL}/${integration}/${sourceSystemId}/deactivate`;
  public static INTEGRATION_REACTIVATE_CREDENTIAL = (integration: string, sourceSystemId: string) =>
    `${Endpoint.INTEGRATION_DOWNLOAD_CREDENTIAL}/${integration}/${sourceSystemId}/activate`;

  // org feature activate/deactivate
  public static ORG_FEATURE_ROOT = (feature: string) => `${Endpoint.ORG_ROOT}/${feature}`;
  public static ENABLE_ORG_FEATURE = (feature: string, sendEmail: boolean) =>
    `${Endpoint.ORG_FEATURE_ROOT(feature)}/activate?send_email=${sendEmail}`;
  public static DISABLE_ORG_FEATURE = (feature: string) => `${Endpoint.ORG_FEATURE_ROOT(feature)}/deactivate`;

  public static AUTOMATION_ID = (id: string) => `${Endpoint.AUTOMATION_ROOT}/${id}`;
  public static AUTOMATION_ACTIVE_TOGGLE = (id: string, active: boolean) => `${Endpoint.AUTOMATION_ID(id)}/enable/${active}`;

  public static ORG_INTEGRATION_ENTITY_ROOT(integration: string, entity: string) {
    return `${Endpoint.ORG_ROOT}/integration/${integration}/entity/${entity}`;
  }
  public static ORG_INTEGRATION_ENTITY_THRESHOLDS = (integration: string, entity: string) => {
    return `${Endpoint.ORG_INTEGRATION_ENTITY_ROOT(integration, entity)}/thresholds`;
  };

  // backend wants name in query string
  public static AUTOMATION_CLONE = (id: string) => `${Endpoint.AUTOMATION_ID(id)}/copy`;
  public static AUTOMATION_RENAME = (id: string) => `${Endpoint.AUTOMATION_ID(id)}/rename`;
  public static AUTOMATION_EXECUTE = (id: string) => `${Endpoint.AUTOMATION_ID(id)}/execute`;

  // App registration
  public static readonly REGISTERED_APPS = `${Endpoint.APP_REGISTRATIONS}/list`;
  public static readonly NON_DEPLOYED_APPS = `${Endpoint.REGISTERED_APPS}?deployed=false`;
  public static APP_LOADS_SEND_EMAIL = (id: string) => `${Endpoint.APP_REGISTRATIONS}/notifications/${id}`;
  public static APP_LOADS_APP_SUGGESTIONS = (search: string) =>
    `${Endpoint.APP_REGISTRATIONS}/suggest?search_term=${encodeURIComponent(search)}`;
  public static APP_REGISTRATIONS_DEACTIVATE = `${Endpoint.APP_REGISTRATIONS}/deactivate`;

  // Productivity Apps
  public static readonly PRODUCTIVITY_APPS = `${Endpoint.DEEM_ROOT}/productivity`;

  public static readonly AUTOMATION_CONNECTION = `${AppConfig.API_ROOT}/services`;
  public static readonly AUTOMATION_CONNECTION_ID = (id: string) => `${Endpoint.AUTOMATION_CONNECTION}/${id}`;
  public static readonly AUTOMATION_ACTION_TEMPLATES = (id: string) => {
    return `${Endpoint.AUTOMATION_CONNECTION_ID(id)}/actiontemplates`;
  };
  public static readonly AUTOMATION_ACTION_TEMPLATES_SEARCH = (id: string) => `${Endpoint.AUTOMATION_ACTION_TEMPLATES(id)}/search`;
  public static readonly AUTOMATION_ACTION_TEMPLATE_ID = (serviceId: string, actionId: string) => {
    return `${Endpoint.AUTOMATION_ACTION_TEMPLATES(serviceId)}/${actionId}`;
  };
  public static readonly AUTOMATION_CONNECTION_CONFIGURATIONS = (serviceId: string) => {
    return `${Endpoint.AUTOMATION_CONNECTION}/${serviceId}/configurations`;
  };
  public static readonly AUTOMATION_CONNECTION_CONFIGURATIONS_ID = (serviceId: string, serviceConfigId: string) => {
    return `${Endpoint.AUTOMATION_CONNECTION_CONFIGURATIONS(serviceId)}/${serviceConfigId}`;
  };
  public static readonly AUTOMATION_CONNECTION_SEARCH = `${Endpoint.AUTOMATION_CONNECTION}/search`;
  public static readonly AUTOMATION_CONNECTION_SEARCH_INBOUND = `${Endpoint.AUTOMATION_CONNECTION_SEARCH}/inbound`;
  public static readonly AUTOMATION_SERVICE_TEMPLATES = `${Endpoint.AUTOMATION_CONNECTION}/templates`;
  public static readonly AUTOMATION_ACTION_METHOD = `${Endpoint.AUTOMATION_CONNECTION}/actions`;
  public static readonly AUTOMATION_ACTION_TEST = `${AppConfig.API_ROOT}/actions/test`;

  public static readonly PARTNERS = `${AppConfig.API_ROOT}/partners`;
  public static PARTNERS_ID = (id: string) => `${Endpoint.PARTNERS}/${id}`;
  public static PARTNERS_ID_REGENERATE_TOKEN = (id: string) => `${Endpoint.PARTNERS_ID(id)}/regeneratetokens`;
  public static PARTNERS_NAME_OAUTH2 = (partnerName: string) => `${Endpoint.PARTNERS}/${partnerName}/oauth2`;
  public static PARTNERS_NAME_OAUTH2_SETUP = (partnerName: string, isReauthorize: boolean) =>
    `${Endpoint.PARTNERS}/${partnerName}/oauth2/setup?re_authorize=${isReauthorize}`;
  public static PARTNERS_NAME_OAUTH2_DEAUTHORIZE = (partnerName: string) => `${Endpoint.PARTNERS}/${partnerName}/oauth2/revoke`;

  public static readonly PARTNERS_EVENT_FORWARDERS = `${Endpoint.PARTNERS}/eventforwarders`;
  public static readonly PARTNERS_EVENT_FORWARDERS_ID = (id: string) => `${Endpoint.PARTNERS_EVENT_FORWARDERS}/${id}`;
  public static readonly PARTNERS_EVENT_FORWARDERS_TEMPLATES = `${Endpoint.PARTNERS_EVENT_FORWARDERS}/templates`;

  public static DASHBOARD_ID = (id: string) => `${Endpoint.DASHBOARD_ROOT}/${id}`;
  public static DASHBOARD_BOOKMARKS = (id: string) => `${Endpoint.DASHBOARD_ID(id)}/bookmark`;
  public static DASHBOARD_SHARE = (id: string) => `${Endpoint.DASHBOARD_ID(id)}/share`;
  public static DASHBOARD_BY_INCIDENT_ID = (incidentId: string) => `${Endpoint.DASHBOARD_ROOT}/incident/${incidentId}`;
  public static readonly STANDARD_DASHBOARD_WIDGETS_PREVIEW = `${Endpoint.WIDGET_ROOT}/standard/preview`;

  public static readonly DASHBOARD_WIDGETS = `${Endpoint.DASHBOARD_ROOT}/widgets`;
  public static readonly DASHBOARD_SEARCH = `${Endpoint.DASHBOARD_ROOT}/search`;
  public static readonly DASHBOARDS_TRANSFER_OWNERSHIP = `${Endpoint.DASHBOARD_ROOT}/transferowner`;

  public static REPORT_ID_DOWNLOAD = (id: string) => `${Endpoint.REPORT_ROOT}/tracking/${id}/download`;

  public static WIDGET_ID = (id: string) => `${Endpoint.WIDGET_ROOT}/${id}`;

  // Share
  public static SHARE_ID = (shareType: string, id: string) => `${AppConfig.API_ROOT}/type/${shareType}/share/${id}`;

  // Non Admins un-authenticated endpoints
  public static SHARE_ROOT = (shareId: string) => `/share/m/${shareId}/v1`;
  public static REPORT_SHARE = (shareId: string) => `${Endpoint.SHARE_ROOT(shareId)}/reports`;
  public static REPORT_SHARE_ID = (shareId: string, id: string) => `${Endpoint.REPORT_SHARE(shareId)}/${id}`;
  public static REPORT_SHARE_LIST = (shareId: string, id: string) => `${Endpoint.REPORT_SHARE_ID(shareId, id)}/downloads/search`;
  public static REPORT_SHARE_DOWNLOAD = (shareId: string, id: string) => `${Endpoint.REPORT_SHARE(shareId)}/tracking/${id}/download`;

  // IAM
  public static IAM_SETUP = (integration: string) => `${Endpoint.IAM_ROOT}/${integration}/setup`;
  public static IAM_INGEGRATIONS = `${Endpoint.IAM_ROOT}/integrations`;
  public static IAM_INGEGRATION_ID = (id: string) => `${Endpoint.IAM_INGEGRATIONS}/${id}`;
  public static IAM_INGEGRATION_ID_REAUTHORIZE = (id: string) => `${Endpoint.IAM_INGEGRATION_ID(id)}/reauthorize`;
  public static IAM_ROLES = `${Endpoint.IAM_ROOT}/roles`;
  public static IAM_ROLES_ACCOUNT_ASSIGNMENTS = (roleId: string) => `${AppConfig.API_ROOT}/accountassignments/roles/${roleId}`;
  public static IAM_ROLES_SEARCH = `${Endpoint.IAM_ROLES}/search`;
  public static SESSION_SCOPES = `${Endpoint.IAM_ROOT}/sessionscopes`;
  public static readonly SCOPESET_ROOT = `${Endpoint.IAM_ROOT}/scopesets`;
  public static readonly SCOPESET_SEARCH = `${Endpoint.SCOPESET_ROOT}/search`;

  // Accounts
  public static readonly IAM_ACCOUNT = `${Endpoint.IAM_ROOT}/account`;
  public static readonly IAM_ROLES_ASSIGN_ACCOUNTS = `${Endpoint.IAM_ACCOUNT}/assign/roles`;
  public static readonly IAM_ACCOUNT_ID = (id: string) => `${Endpoint.IAM_ACCOUNT}/${id}`;
  public static readonly IAM_ACCOUNT_SEARCH = `${Endpoint.IAM_ACCOUNT}/search`;
  public static readonly IAM_ACCOUNT_EXTERNAL_ID = (id: string) =>
    `${Endpoint.IAM_ACCOUNT}/azureonpremisesimmutableid?azure_on_premises_immutable_id=${encodeURIComponent(id)}`;

  // Account Details for the given list of user descriptors
  public static readonly ACCOUNTS_USER_DESCRIPTORS = `${Endpoint.IAM_ACCOUNT}/userdescriptors`;
  public static readonly ACCOUNTS_SEARCH_BY_USER_DESCRIPTORS = `${Endpoint.IAM_ACCOUNT_SEARCH}/userdescriptors`;

  // Accounts Groups
  public static readonly ACCOUNT_GROUP = `${AppConfig.API_ROOT}/accountgroup`;
  public static readonly ACCOUNT_GROUP_ID = (id) => `${AppConfig.API_ROOT}/accountgroup/${id}`;
  public static readonly ACCOUNT_GROUP_ID_ROLES = (id) => `${Endpoint.ACCOUNT_GROUP_ID(id)}/roles`;
  public static readonly ACCOUNT_GROUP_ROLES = `${Endpoint.ACCOUNT_GROUP}/roles`;

  // Integration User/Groups Search
  public static readonly IAM_INTEGRATIONS = `${Endpoint.IAM_ROOT}/integrations`;
  public static readonly IAM_INTEGRATIONS_ID = (id: string) => `${Endpoint.IAM_INGEGRATIONS}/${id}`;
  public static readonly IAM_INTEGRATIONS_GROUPS_SEARCH = (id: string, searchTerm: string) =>
    `${Endpoint.IAM_INTEGRATIONS_ID(id)}/groups/search?search_term=${encodeURIComponent(searchTerm)}`;

  // Integrated Services
  public static readonly INTEGRATED_SERVICES = `${Endpoint.ORG_ROOT}/integrated/services`;

  // Shortcuts
  public static readonly USER_PREFERENCE = `${AppConfig.API_ROOT}/userpreference`;
  public static readonly SHORTCUT = `${Endpoint.USER_PREFERENCE}/shortcut`;
  public static readonly SHORTCUT_CATEGORY = (category: string) => `${Endpoint.SHORTCUT}/${category}`;
  public static readonly SHORTCUT_CATEGORY_BY_ID = (category: string, id: string) => `${Endpoint.SHORTCUT_CATEGORY(category)}/${id}`;

  // Widget preference
  public static readonly USER_WIDGET_PREFERENCE = (widgetId: string, dashboardId: string) => {
    return `${Endpoint.USER_PREFERENCE}/widgets/${dashboardId}/${widgetId}`;
  };
  public static readonly USER_WIDGETSET_PREFERENCE = (widgetId: string, dashboardId: string) => {
    return `${Endpoint.USER_PREFERENCE}/dashboards/${dashboardId}/widgets/${widgetId}`;
  };

  // Service Accounts
  public static readonly SERVICE_ACCOUNTS_ID = (id: string) => `${Endpoint.SERVICE_ACCOUNT}/${encodeURIComponent(id)}`;
  public static readonly SERVICE_ACCOUNTS_REGENERATE = (id: string) => `${Endpoint.SERVICE_ACCOUNTS_ID(id)}/credentials`;
  public static readonly OAUTH_TOKEN = '/auth/console/token';

  // UI Endpoints
  public static readonly UI_ORG = `${Endpoint.UI_V1_ROOT}/org`;

  // Solutions Config
  public static readonly SOLUTIONS_CONFIG = `${Endpoint.UI_ORG}/solutions`;
  public static readonly SOLUTIONS_CONFIG_ID = (configId: string) => `${Endpoint.SOLUTIONS_CONFIG}/${configId}`;

  // Solution Settings
  public static readonly SOLUTION_SETTING_METRIC = `${AppConfig.API_ROOT}/solutionsettingmetric`;
  public static readonly SOLUTION_SETTING_METRIC_DEFAULTS = `${Endpoint.SOLUTION_SETTING_METRIC}/defaults`;
  public static readonly SOLUTION_SETTING_METRIC_RESET = `${Endpoint.SOLUTION_SETTING_METRIC}/reset`;
  public static readonly SOLUTION_SETTING_METRIC_UPDATE = `${Endpoint.SOLUTION_SETTING_METRIC}/update`;

  // User
  public static readonly UI_USER = `${Endpoint.UI_V1_ROOT}/user`;

  // User Account
  public static readonly UI_USER_ACCOUNT = `${Endpoint.UI_V1_ROOT}/useraccount`;

  // Request Access
  public static readonly REQUEST_ACCESS = `${Endpoint.UI_USER_ACCOUNT}/requestaccess`;

  // i18n JSON files
  public static readonly ASSETS_I18N = (locale: string, timestamp: number) => `./assets/i18n/${locale}.json?_dc=${timestamp}`;
  public static readonly ASSETS_I18N_CLARITY = (locale: string, timestamp: number) =>
    `./assets/i18n-clarity/${locale}.json?_dc=${timestamp}`;

  // Data Access Policy
  public static readonly DATA_ACCESS_POLICY = `${Endpoint.IAM_ROOT}/dataaccesspolicy`;
  public static readonly DATA_ACCESS_POLICY_SEARCH = `${Endpoint.DATA_ACCESS_POLICY}/search`;
  public static readonly DATA_ACCESS_POLICY_ASSIGN_USERS = `${Endpoint.DATA_ACCESS_POLICY}/assign`;
  public static readonly DATA_ACCESS_POLICY_ASSIGN_USERS_PREVIEW = `${Endpoint.DATA_ACCESS_POLICY_ASSIGN_USERS}/preview`;
  public static readonly DATA_ACCESS_POLICY_UNASSIGN_USERS = `${Endpoint.DATA_ACCESS_POLICY}/unassign`;
  public static readonly DATA_ACCESS_POLICY_ID = (id: string) => `${Endpoint.DATA_ACCESS_POLICY}/${id}`;
  public static readonly DATA_ACCESS_POLICY_GET_ASSIGNED_USERS = (id: string) => `${Endpoint.DATA_ACCESS_POLICY_ID(id)}/accounts`;

  // Global search
  public static readonly GLOBAL_SEARCH = (type: string, searchTerm: string) =>
    `${AppConfig.API_ROOT}/globalsearch/${type}?search_term=${encodeURIComponent(searchTerm)}`;

  // Incidents
  public static readonly INCIDENTS = `${AppConfig.API_ROOT}/incidents`;
  public static readonly INCIDENTS_ID = (id: string) => `${Endpoint.INCIDENTS}/${id}`;
  public static readonly INCIDENTS_STATUS = (status: string) => `${Endpoint.INCIDENTS}/status/${status}`;
  public static readonly INCIDENTS_SEARCH = `${Endpoint.INCIDENTS}/search`;
  public static readonly INCIDENTS_COPY = (id: string, name: string, description: string) =>
    `${Endpoint.INCIDENTS_ID(id)}?action=copy&name=${encodeURIComponent(name)}&description=${encodeURIComponent(description)}`;

  // Surveys
  public static readonly SURVEYS = `${AppConfig.API_ROOT}/surveys`;
  public static readonly SURVEYS_ID = (id: string) => `${Endpoint.SURVEYS}/${id}`;
  public static readonly SURVEYS_PUBLISH = (id: string) => `${Endpoint.SURVEYS_ID(id)}/publish`;
  public static readonly SURVEYS_COPY = (id: string) => `${Endpoint.SURVEYS_ID(id)}/copy`;
  public static readonly SURVEYS_SEARCH = `${Endpoint.SURVEYS}/search`;
  public static readonly QUESTION_TEMPLATES = `${Endpoint.SURVEYS}/questions/templates`;

  // trial questionnaire
  public static readonly QUESTIONS = (surveyType: string) => `${Endpoint.SURVEYS}/system/type/${surveyType}`;

  // Insights
  public static readonly INSIGHTS_ROOT = `${AppConfig.API_ROOT}/insights`;
  public static readonly INSIGHTS_SEARCH = `${Endpoint.INSIGHTS_ROOT}/search`;
  public static readonly INSIGHT_BY_ID = (insightId: string) => `${Endpoint.INSIGHTS_ROOT}/${insightId}`;
  public static readonly INSIGHT_UPDATE_STATUS = (id: string, status: string) => `${Endpoint.INSIGHT_BY_ID(id)}/status/${status}`;
  public static readonly INSIGHTS_CATEGORIES = `${Endpoint.INSIGHTS_ROOT}/categories`;
  public static readonly INSIGHTS_UPDATE_SETTINGS = `${Endpoint.INSIGHTS_CATEGORIES}/enable`;

  // Synthetic URL Monitoring
  public static readonly SYNTHETIC_URL_MONITORING_TEST_CONFIG = `${AppConfig.API_ROOT}/configuration/deem/syntheticurlmonitoring`;
  public static readonly SYNTHETIC_URL_MONITORING_TEST_BY_ID = (id: string) => `${Endpoint.SYNTHETIC_URL_MONITORING_TEST_CONFIG}/${id}`;
  public static readonly SYNTHETIC_URL_MONITORING_TEST_UPDATE_STATUS = (id: string, enabled: string) =>
    `${Endpoint.SYNTHETIC_URL_MONITORING_TEST_BY_ID(id)}/${enabled}`;

  // Comments
  public static readonly COMMENTS_BASE = (resourceType: string, id: string) => `${AppConfig.API_ROOT}/${resourceType}/${id}/comments`;
  public static readonly COMMENTS_SEARCH = (resourceType: string, id: string) => `${Endpoint.COMMENTS_BASE(resourceType, id)}/search`;
  public static readonly UPDATE_COMMENT = (resourceType: string, id: string, commentId: string) =>
    `${Endpoint.COMMENTS_BASE(resourceType, id)}/${commentId}`;

  // Pendo
  public static readonly PENDO_VISITOR_GET_ALL_METADATA = (visitorId: string) => `${Endpoint.PENDO_API_ROOT}/visitor/${visitorId}`;
  public static readonly PENDO_VISITOR_UPDATE_METADATA = `${Endpoint.PENDO_API_ROOT}/metadata/visitor/pendo/value`;

  // Gangster API's
  public static readonly USER_ADMIN = `${AppConfig.API_ROOT}/user`;
  public static readonly USER_SEARCH = `${Endpoint.USER_ADMIN}/search`;
  public static readonly USER_ADMIN_SEARCH = (searchTerm: string) => {
    return `${Endpoint.USER_SEARCH}?search_term=${encodeURIComponent(searchTerm)}`;
  };
  public static readonly USER_ADMIN_SEARCH_FOR_ROLE_ASSIGNMENT = (searchTerm: string) => {
    return `${Endpoint.USER_SEARCH}/roleassignment?search_term=${encodeURIComponent(searchTerm)}`;
  };

  public static readonly ACCOUNT = `${AppConfig.API_ROOT}/account`;
  public static readonly ACCOUNT_ID = (id: string) => `${Endpoint.ACCOUNT}/${id}`;
  public static readonly ACCOUNT_SEARCH = `${Endpoint.ACCOUNT}/search`;
  public static readonly ACCOUNT_ID_STATUS = (id: string, status: boolean) => `${Endpoint.ACCOUNT_ID(id)}/status?enabled=${status}`;
  public static readonly ACCOUNT_STATUS = (status: boolean) => `${Endpoint.ACCOUNT}/status?enabled=${status}`;

  public static DIRECTORY = `${AppConfig.API_ROOT}/directory`;
  public static DIRECTORY_TYPE = (directoryType: string) => `${Endpoint.DIRECTORY}/type/${directoryType}`;
  public static DIRECTORY_ID = (directoryType: string, id: string) => `${Endpoint.DIRECTORY}/${id}/type/${directoryType}`;

  public static readonly GROUP = `${AppConfig.API_ROOT}/group`;
  public static readonly GROUP_SEARCH = (searchTerm: string) => {
    return `${Endpoint.GROUP}/search?search_term=${encodeURIComponent(searchTerm)}`;
  };

  public static DIRECTORY_GROUP = `${AppConfig.API_ROOT}/directorygroup`;
  public static DIRECTORY_GROUP_ID = (id) => `${Endpoint.DIRECTORY_GROUP}/${id}`;
  public static DIRECTORY_GROUP_SEARCH = `${Endpoint.DIRECTORY_GROUP}/search`;
  public static DIRECTORY_GROUP_BY_GROUP_ID = (groupId: string, type: string) => {
    return `${Endpoint.DIRECTORY_GROUP}/groupid/${groupId}/type/${type}`;
  };
  public static DIRECTORY_GROUP_ID_STATUS = (id: string, status: boolean) => {
    return `${Endpoint.DIRECTORY_GROUP_ID(id)}/status?enabled=${status}`;
  };

  // Pranqster Account API's
  public static readonly ACCOUNT_ASSIGNMENTS = `${AppConfig.API_ROOT}/accountassignments`;

  // Integrations API
  public static readonly INTEGRATIONS_NAME = (integration: string) => `${AppConfig.API_ROOT}/integrations/${integration}`;
  public static readonly INTEGRATIONS_HEALTH = (integration: string) => `${Endpoint.INTEGRATIONS_NAME(integration)}/health`;
  public static readonly INTEGRATIONS_TENANT_HEALTH = (integration: string, tenantId: string) =>
    `${Endpoint.INTEGRATIONS_NAME(integration)}/health?multi_tenant_id=${tenantId}`;
  public static readonly INTEGRATIONS_OBJECTS_COUNTS = (integration: string) => `${Endpoint.INTEGRATIONS_NAME(integration)}/objects/counts`;
  public static readonly INTEGRATIONS_OBJECTS = (integration: string, integrationObject: string) => {
    return `${Endpoint.INTEGRATIONS_NAME(integration)}/objects/${integrationObject}`;
  };
  public static readonly INTEGRATIONS_ACTIONS = (integration: string) => `${Endpoint.INTEGRATIONS_NAME(integration)}/actions`;
  public static readonly INTEGRATIONS_GEM_TENANT_HEALTH = (integration: string, sourceSystemId: string, tenantId: string) => {
    return `${Endpoint.INTEGRATIONS_NAME(integration)}/${sourceSystemId}/${tenantId}/health`;
  };

  // WS1C RBAC APIs
  public static readonly ADMIN_PLATFORM = `${AppConfig.API_ROOT}/adminplatform`;
  public static readonly ADMIN_PLATFORM_INTEGRATIONS = `${Endpoint.ADMIN_PLATFORM}/integrations`;
  public static readonly ADMIN_PLATFORM_ROLES = `${Endpoint.ADMIN_PLATFORM}/roles`;
  public static readonly CSP_GROUPS = `${AppConfig.API_ROOT}/groups/csp`;
  public static readonly CSP_USERS = `${AppConfig.API_ROOT}/users/csp`;
  public static readonly ROLES_BY_CSP_GROUP_ID = (id: string) => `${Endpoint.ADMIN_PLATFORM}/groups/csp/${id}/roles`;
  public static readonly ROLES_BY_CSP_USER_ID = (id: string) => `${Endpoint.ADMIN_PLATFORM}/users/csp/${id}/roles`;
  public static readonly ROLES_BY_INTEGRATION_ID = (id: string, tenantUuid: string) => {
    return `${Endpoint.ADMIN_PLATFORM_INTEGRATIONS}/${id}/roles?tenant_uuid=${tenantUuid}`;
  };
  public static readonly ALL_ROLES_BY_INTEGRATION_ID = (id: string, tenantUuid: string, includeTenantHierarchy: boolean) =>
    `${Endpoint.ADMIN_PLATFORM_INTEGRATIONS}/${id}/roles?tenant_uuid=${tenantUuid}&include_tenant_hierarchy=${includeTenantHierarchy}`;
  public static readonly GROUPS_SEARCH = (searchTerm: string) => {
    return `${Endpoint.CSP_GROUPS}/search?search_term=${encodeURIComponent(searchTerm)}`;
  };
  public static readonly ORG_GROUP_TENANT_HIERARCHY = (id: string) => `${Endpoint.ADMIN_PLATFORM}/integrations/${id}/tenant/hierarchy`;
  public static readonly PENDING_INVITATIONS = `${Endpoint.ADMIN_PLATFORM}/users/csp/pending_invitations`;
  public static readonly INVITE_RESEND = `${Endpoint.PENDING_INVITATIONS}?action=resend`;
  public static readonly INVITE_REVOKE = `${Endpoint.PENDING_INVITATIONS}?action=revoke`;
  public static readonly USERS_SEARCH = (searchTerm: string) => {
    return `${Endpoint.CSP_USERS}/search?search_term=${encodeURIComponent(searchTerm)}`;
  };
  public static readonly USERS_EXISTENCE = `${Endpoint.CSP_USERS}/existence`;
  public static readonly GROUPS_INVITATIONS = `${Endpoint.ADMIN_PLATFORM}/groups/csp/invitations`;
  public static readonly USERS_INVITATIONS = `${Endpoint.ADMIN_PLATFORM}/users/csp/invitations`;

  public static readonly STATISTICS_SERVICES = `${AppConfig.API_ROOT}/statistics/services`;
  public static readonly STATISTICS_SERVICES_ID = (id: string) => `${Endpoint.STATISTICS_SERVICES}/${id}`;
  public static readonly STATISTICS_SERVICES_ID_ERRORS = (id: string) => {
    return `${Endpoint.STATISTICS_SERVICES_ID(id)}/errors`;
  };
  public static readonly NESTED_GROUPS_FOR_GROUP = (id: string) => `${Endpoint.CSP_GROUPS}/${id}/groups`;
  public static readonly USERS_FOR_GROUP = (id: string) => `${Endpoint.CSP_GROUPS}/${id}/users`;

  // Apps Dashboard
  public static readonly NETWORK_INSIGHTS = `${Endpoint.APPS}/network/insight`;
  public static readonly NETWORK_INSIGHTS_URLS = (appId: string, host: string) => {
    return `${Endpoint.NETWORK_INSIGHTS}/urls/${appId}/${encodeURIComponent(host)}`;
  };

  // GRAPHQL
  public static readonly GRAPHQL = 'graphql';

  // Service Limits
  public static readonly SERVICE_LIMITS = `${AppConfig.API_ROOT}/servicelimits`;
  public static readonly ORG_SERVICE_LIMITS = `${Endpoint.SERVICE_LIMITS}/org/account`;
  public static readonly USER_SERVICE_LIMITS = `${Endpoint.SERVICE_LIMITS}/account`;
  public static readonly USER_SERVICE_LIMITS_ID = (id: string) => `${Endpoint.SERVICE_LIMITS}/account/${id}`;

  // Notifications
  public static readonly NOTIFICATIONS = `${AppConfig.API_ROOT}/notifications`;
  public static readonly NOTIFICATIONS_SETTINGS = `${Endpoint.NOTIFICATIONS}/subscriptions`;
  public static readonly NOTIFICATIONS_SERVICES = `${Endpoint.NOTIFICATIONS}/services`;
  public static readonly NOTIFICATIONS_INAPP = `${Endpoint.NOTIFICATIONS}/inapp`;
  public static readonly NOTIFICATIONS_INAPP_COUNT = `${Endpoint.NOTIFICATIONS_INAPP}/unread-count`;
  public static readonly NOTIFICATIONS_VIEW = (view: string) => `${Endpoint.NOTIFICATIONS_INAPP}/search?queryType=${view}`;
  public static readonly NOTIFICATIONS_ACTION = `${Endpoint.NOTIFICATIONS_INAPP}/actions`;
  public static readonly NOTIFICATIONS_INAPP_ID = (id: string) => `${Endpoint.NOTIFICATIONS_INAPP}/${id}`;

  // CMS
  public static readonly CONNECTORS = `${AppConfig.API_ROOT}/connectors`;
  public static readonly CONNECTORS_WITH_ACTIONS = `${Endpoint.UI_V1_ROOT}/connectors`;
  public static readonly CONNECTORS_REPOSITORY = `${Endpoint.CONNECTORS}/repository`;
  public static readonly CONNECTORS_REPOSITORY_SEARCH = `${Endpoint.CONNECTORS_REPOSITORY}/search`;
  public static readonly CONNECTORS_MANAGED_INTEGRATION = (integration: string) => `${Endpoint.CONNECTORS}/managed/${integration}`;
  public static readonly CONNECTORS_SCHEMAS_ID = (configSchemaId: string) => `${Endpoint.CONNECTORS}/schemas/${configSchemaId}`;
  public static readonly CONNECTORS_ID = (id: string) => `${Endpoint.CONNECTORS}/${id}`;
  public static readonly CONNECTORS_ID_CONFIG = (id: string) => `${Endpoint.CONNECTORS_ID(id)}/config`;
  public static readonly CONNECTORS_CONFIG = `${Endpoint.CONNECTORS}/config`;
  public static readonly CONNECTORS_CONFIG_DATA_ID = (id: string) => `${Endpoint.CONNECTORS_CONFIG}/data/${id}`;
  public static readonly CONNECTORS_CONFIG_ID = (id: string) => `${Endpoint.CONNECTORS_CONFIG}/${id}`;
  public static readonly CONNECTOR_ID_ACTIONS = (id: string) => `${Endpoint.CONNECTORS_ID(id)}/actions`;
  public static readonly CONNECTOR_ACTIONS = `${Endpoint.CONNECTORS}/actions`;
  public static readonly CONNECTOR_ACTIONS_ID = (id: string, actionId: string) => `${Endpoint.CONNECTOR_ID_ACTIONS(id)}/${actionId}`;
  public static readonly CONNECTOR_ACTIONS_SEARCH = (id: string) => `${Endpoint.CONNECTOR_ID_ACTIONS(id)}/search`;

  // WS1 Broker
  public static readonly WS1_BROKER_DIRECTORIES = (integrationId: string) =>
    `${Endpoint.WS1_BROKER_GATEWAY}/${integrationId}/usergroup/broker/directories`;

  // WS1 update account migration status
  public static readonly CSP_ACCOUNT = `${Endpoint.ADMIN_PLATFORM}/csp/account`;
  public static readonly UPDATE_ACCOUNT_MIGRATION_STATUS = (id: string) => {
    return `${Endpoint.CSP_ACCOUNT}/${id}/csp_migration_status`;
  };

  // WS1 account merge endpoint
  public static readonly IAM_ACCOUNT_MERGE = `${Endpoint.IAM_ACCOUNT}/merge`;

  // Scripts and Sensors
  public static readonly TEMPLATES_DEPLOY_BY_ID = (integrationId: string, type: string, id: string) =>
    `${AppConfig.API_ROOT}/integration/${integrationId}/resources/${type}/${id}/deployment`;
  public static readonly TEMPLATES_DEPLOYMENT_VALIDATIONS_BY_ID = (integrationId: string, type: string, id: string) =>
    `${Endpoint.TEMPLATES_DEPLOY_BY_ID(integrationId, type, id)}/validations`;
  public static readonly TEMPLATES_SCRIPTS_ROOT = `${Endpoint.TEMPLATES_ROOT}/scripts`;
  public static readonly TEMPLATES_SCRIPTS_SEARCH = `${Endpoint.TEMPLATES_SCRIPTS_ROOT}/search`;
  public static readonly TEMPLATES_SENSORS_ROOT = `${Endpoint.TEMPLATES_ROOT}/sensors`;
  public static readonly TEMPLATES_SENSORS_SEARCH = `${Endpoint.TEMPLATES_SENSORS_ROOT}/search`;

  // Marketplace
  public static readonly MARKETPLACE_ROOT = `${AppConfig.API_ROOT}/marketplace`;
  public static readonly MARKETPLACE_SEARCH = `${Endpoint.MARKETPLACE_ROOT}/search`;

  // Login
  public static readonly DISCOVERY_LOGIN = (email: string) => `${AppConfig.API_ROOT}/discovery/${email}`;

  // User Orgs
  public static readonly USER_ORGS = (email: string) => `${AppConfig.API_ROOT}/org/discovery/${email}`;

  // Playbooks
  public static readonly PLAYBOOKS = `${AppConfig.API_ROOT}/playbooks`;
  public static readonly PLAYBOOKS_SEARCH = `${Endpoint.PLAYBOOKS}/search`;
  public static readonly INCIDENT_PLAYBOOK_STEP = (id: string) => `${Endpoint.INCIDENTS}/steps/${id}`;
  public static readonly PLAYBOOK_ID = (id: string) => `${Endpoint.PLAYBOOKS}/${id}`;
  public static readonly PLAYBOOK_VERSIONS_ID = (id: string) => `${Endpoint.PLAYBOOKS}/versions/${id}`;
  public static readonly ATTACH_PLAYBOOK_INCIDENT = (incidentId: string, playbookVersionId: string) =>
    `${Endpoint.INCIDENTS_ID(incidentId)}/playbook/${playbookVersionId}`;
}
