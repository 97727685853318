/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { COLUMN_NAMES } from '@ws1c/intelligence-models/integration-meta';
import { ComposeFunction, StandardWidgetSubtype } from './dashboard.enum';

/**
 * DeemSolutionDashboardConfig
 * @export
 * @class DeemSolutionDashboardConfig
 */
export class DeemSolutionDashboardConfig {
  public static readonly WIDGET_SERIES_KEY_VALUE = {
    AVERAGE_TREND: 'Average Trend',
    OS_CRASHES: 'OS Crashes',
    BOOT_TIME: 'Boot Time',
    SHUTDOWN_TIME: 'Shutdown Time',
    BATTERY_HEALTH: 'Battery Health',
    CPU_HEALTH: 'CPU Health',
    GPU_0_USAGE: 'GPU 0 Usage',
    GPU_1_USAGE: 'GPU 1 Usage',
    GPU_2_USAGE: 'GPU 2 Usage',
    GPU_HEALTH: 'GPU Health',
    MEMORY_HEALTH: 'Memory Health',
    NETWORK_LAN_LATENCY: 'LAN Latency',
    NETWORK_WAN_LATENCY: 'WAN Latency',
    WIFI_STRENGTH: 'Wifi Strength',
    DOWNLOAD_SPEED: 'Download Speed',
    UPLOAD_SPEED: 'Upload Speed',
    GPO_LOAD: 'GPO Load',
    PROFILE_LOAD: 'Profile Load',
    SHELL_LOAD: 'Shell Load',
    LOGON_SCRIPT: 'Logon Script',
    NUMBER_OF_CONNECTION_SERVER_ERROR_STATUS: 'Number of Connection Server Error Status',
    NUMBER_OF_UAG_APPLIANCE_ERROR_STATUS: 'Number of UAG Appliance Error Status',
    TOTAL_DEVICES_REPORTING_CPU_USAGE: 'Total devices reporting CPU usage',
    TOTAL_DEVICES_REPORTING_GPU_USAGE: 'Total devices reporting GPU usage',
    TOTAL_DEVICES_REPORTING_MEMORY_USAGE: 'Total devices reporting Memory usage',
    TOTAL_DEVICES_REPORTING_UNRESPONSIVE_EVENTS: 'Total devices reporting unresponsive events',
    TOTAL_DEVICES_USING_APP: 'Total devices using app',
    TOTAL_SESSIONS: 'Total sessions',
    TOTAL_SESSIONS_USING_APP: 'Total sessions using app',
    OTHER: 'Other',
    NAME_RESPONSE_TIME: 'Name Response Time',
    SOCKET_CONNECTION_TIME: 'Socket Connection Time',
    TLS_SETUP_TIME: 'TLS Setup Time',
    HTTP_RESPONSE_TIME: 'HTTP Response Time',
    CPU: 'CPU',
    MEMORY: 'Memory',
  };
  public static readonly COMPOSE_CONFIGS_BY_DEEM_DASHBOARD = {
    DEEM: {
      _DEEM_SYNTHETIC_URL_MONITORING_TEST_RESPONSE_TIME_BY_PHASE: {
        dependencies: [
          StandardWidgetSubtype.DEEM_SYNTHETIC_URL_MONITORING_TEST_NAME_RESPONSE_TIME,
          StandardWidgetSubtype.DEEM_SYNTHETIC_URL_MONITORING_TEST_SOCKET_CONNECTION_TIME,
          StandardWidgetSubtype.DEEM_SYNTHETIC_URL_MONITORING_TEST_TLS_SETUP_TIME,
          StandardWidgetSubtype.DEEM_SYNTHETIC_URL_MONITORING_TEST_HTTP_RESPONSE_TIME,
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          legendLabel: 'Phases',
          seriesName: 'phases',
          seriesValues: [
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.NAME_RESPONSE_TIME,
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.SOCKET_CONNECTION_TIME,
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.TLS_SETUP_TIME,
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.HTTP_RESPONSE_TIME,
          ],
        },
      },
      _DEEM_SYNTHETIC_URL_MONITORING_TEST_IP_ADDRESSES_WITH_LONGEST_RESPONSE_TIME: {
        dependencies: [StandardWidgetSubtype.DEEM_SYNTHETIC_URL_MONITORING_TEST_IP_ADDRESSES_WITH_LONGEST_RESPONSE_TIME],
        composeFunction: ComposeFunction.SET_TREND_MODE,
        composeFunctionParams: {
          toMode: 'SNAPSHOT',
          fromMode: 'HISTORICAL',
        },
      },
      _DEEM_SYNTHETIC_URL_MONITORING_TEST_UP_TIME: {
        dependencies: [
          'DEEM_SYNTHETIC_URL_MONITORING_TEST_TOTAL_SUCCESSFUL_RUNS_COUNT',
          'DEEM_SYNTHETIC_URL_MONITORING_TEST_TOTAL_RUNS_COUNT',
        ],
        composeFunction: ComposeFunction.RATIO_BY_TIME_AND_BUCKETS,
      },
      _DEEM_SYNTHETIC_URL_MONITORING_TEST_TOTAL_RUNS_COUNT: {
        dependencies: ['DEEM_SYNTHETIC_URL_MONITORING_TEST_TOTAL_RUNS_COUNT'],
        composeFunction: ComposeFunction.SUM_ALL_COUNT,
      },
      _DEEM_SYNTHETIC_URL_MONITORING_TEST_TOTAL_SUCCESSFUL_RUNS_COUNT: {
        dependencies: ['DEEM_SYNTHETIC_URL_MONITORING_TEST_TOTAL_SUCCESSFUL_RUNS_COUNT'],
        composeFunction: ComposeFunction.SUM_ALL_COUNT,
      },
      _DEEM_DEVICE_WIDGET_DETAIL_24_HR: {
        dependencies: ['_DEEM_DEVICE_WIDGET_DETAIL_IMPACT_24_HR'],
        composeFunction: ComposeFunction.RENAME_COUNTERS,
        composeFunctionParams: {
          counterAttributes: ['impact'],
        },
      },
      _DEEM_DEVICES_SCORE_LIST: {
        dependencies: ['DEEM_DEVICES_SCORE_LIST', '_DEEM_DEVICES_SCORE_COUNT_BY_SCORE_REMOVE_ZERO'],
        composeFunction: ComposeFunction.MERGE_BUCKETING_ATTRS,
        composeFunctionParams: {
          mergedBucketMatchIndices: [0],
          mergedBucketDefault: { Poor: 0, Neutral: 0, Good: 0, Total: 0 },
          mergedBucketName: 'session',
          mergedBucketIndex: 1,
          mergedCounterIndex: 0,
        },
      },
      _DEEM_DEVICES_SCORE_COUNT_BY_SCORE_REMOVE_ZERO: {
        dependencies: ['DEEM_DEVICES_SCORE_COUNT_BY_SCORE'],
        ComposeFunction: ComposeFunction.REMOVE_ZEROS,
      },
      _DEEM_ORG_WINDOWS_TOP_USED_APPS: {
        dependencies: ['DEEM_ORG_WINDOWS_TOP_USED_APPS', '_DEEM_ORG_WINDOWS_TOP_USED_APPS_SCORE_REMOVE_ZERO'],
        composeFunction: ComposeFunction.MERGE_BUCKETING_ATTRS,
        composeFunctionParams: {
          mergedBucketMatchIndices: [0],
          mergedBucketDefault: { Poor: 0, Neutral: 0, Good: 0, Total: 0 },
          mergedBucketName: 'session',
          mergedBucketIndex: 1,
          mergedCounterIndex: 0,
        },
      },
      _DEEM_ORG_WINDOWS_TOP_USED_APPS_SCORE_REMOVE_ZERO: {
        dependencies: ['DEEM_ORG_WINDOWS_TOP_USED_APPS_SCORE'],
        ComposeFunction: ComposeFunction.REMOVE_ZEROS,
      },
      //macos
      _DEEM_ORG_MACOS_TOP_USED_APPS: {
        dependencies: ['DEEM_ORG_MACOS_TOP_USED_APPS', '_DEEM_ORG_MACOS_TOP_USED_APPS_SCORE_REMOVE_ZERO'],
        composeFunction: ComposeFunction.MERGE_BUCKETING_ATTRS,
        composeFunctionParams: {
          mergedBucketMatchIndices: [0],
          mergedBucketDefault: { Poor: 0, Neutral: 0, Good: 0, Total: 0 },
          mergedBucketName: 'session',
          mergedBucketIndex: 1,
          mergedCounterIndex: 0,
        },
      },
      _DEEM_ORG_MACOS_TOP_USED_APPS_SCORE_REMOVE_ZERO: {
        dependencies: ['DEEM_ORG_MACOS_TOP_USED_APPS_SCORE'],
        ComposeFunction: ComposeFunction.REMOVE_ZEROS,
      },
      _DEEM_ORG_ANDROID_TOP_USED_APPS: {
        dependencies: ['DEEM_ORG_ANDROID_TOP_USED_APPS', '_DEEM_ORG_ANDROID_TOP_USED_APPS_SCORE_REMOVE_ZERO'],
        composeFunction: ComposeFunction.MERGE_BUCKETING_ATTRS,
        composeFunctionParams: {
          mergedBucketMatchIndices: [0],
          mergedBucketDefault: { Poor: 0, Neutral: 0, Good: 0, Total: 0 },
          mergedBucketName: 'session',
          mergedBucketIndex: 1,
          mergedCounterIndex: 0,
        },
      },
      _DEEM_ORG_ANDROID_TOP_USED_APPS_SCORE_REMOVE_ZERO: {
        dependencies: ['DEEM_ORG_ANDROID_TOP_USED_APPS_SCORE'],
        ComposeFunction: ComposeFunction.REMOVE_ZEROS,
      },
      _DEEM_ORG_IOS_TOP_USED_APPS: {
        dependencies: ['DEEM_ORG_IOS_TOP_USED_APPS', '_DEEM_ORG_IOS_TOP_USED_APPS_SCORE_REMOVE_ZERO'],
        composeFunction: ComposeFunction.MERGE_BUCKETING_ATTRS,
        composeFunctionParams: {
          mergedBucketMatchIndices: [0],
          mergedBucketDefault: { Poor: 0, Neutral: 0, Good: 0, Total: 0 },
          mergedBucketName: 'session',
          mergedBucketIndex: 1,
          mergedCounterIndex: 0,
        },
      },
      _DEEM_ORG_IOS_TOP_USED_APPS_SCORE_REMOVE_ZERO: {
        dependencies: ['DEEM_ORG_IOS_TOP_USED_APPS_SCORE'],
        ComposeFunction: ComposeFunction.REMOVE_ZEROS,
      },
      _DEEM_DEVICE_WIDGET_DETAIL_IMPACT_24_HR: {
        dependencies: ['_DEEM_DEVICE_WIDGET_DETAIL_COUNTS_24_HR', 'DEVICE_DRILL_DOWN_TOTAL_DEVICES_24_HR'],
        composeFunction: ComposeFunction.DIVIDE_BY_COUNT,
        composeFunctionParams: {},
      },
      _DEEM_DEVICE_WIDGET_DETAIL_COUNTS_24_HR: {
        dependencies: [
          'DEVICE_DRILL_DOWN_CRASH_24_HR',
          'DEVICE_DRILL_DOWN_BOOT_TIME_24_HR',
          'DEVICE_DRILL_DOWN_SHUTDOWN_TIME_24_HR',
          'DEVICE_DRILL_DOWN_BATTERY_HEALTH_24_HR',
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          seriesName: 'issue',
          seriesValues: [
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.OS_CRASHES,
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.BOOT_TIME,
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.SHUTDOWN_TIME,
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.BATTERY_HEALTH,
          ],
        },
      },
      _DEEM_DEVICE_WIDGET_DETAIL_4_HR: {
        dependencies: ['_DEEM_DEVICE_WIDGET_DETAIL_IMPACT_4_HR'],
        composeFunction: ComposeFunction.RENAME_COUNTERS,
        composeFunctionParams: {
          counterAttributes: ['impact'],
        },
      },
      _DEEM_DEVICE_WIDGET_DETAIL_IMPACT_4_HR: {
        dependencies: ['_DEEM_DEVICE_WIDGET_DETAIL_COUNTS_4_HR', 'DEVICE_DRILL_DOWN_TOTAL_DEVICES_4_HR'],
        composeFunction: ComposeFunction.DIVIDE_BY_COUNT,
        composeFunctionParams: {},
      },
      _DEEM_DEVICE_WIDGET_DETAIL_COUNTS_4_HR: {
        dependencies: [
          'DEVICE_DRILL_DOWN_CRASH_4_HR',
          'DEVICE_DRILL_DOWN_BOOT_TIME_4_HR',
          'DEVICE_DRILL_DOWN_SHUTDOWN_TIME_4_HR',
          'DEVICE_DRILL_DOWN_BATTERY_HEALTH_4_HR',
          'DEVICE_DRILL_DOWN_CPU_HEALTH_4_HR',
          'DEVICE_DRILL_DOWN_GPU_HEALTH_4_HR',
          'DEVICE_DRILL_DOWN_MEMORY_HEALTH_4_HR',
          'DEVICE_DRILL_DOWN_WIFI_STRENGTH_4_HR',
          'DEVICE_DRILL_DOWN_DOWNLOAD_SPEED_4_HR',
          'DEVICE_DRILL_DOWN_UPLOAD_SPEED_4_HR',
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          seriesName: 'issue',
          seriesValues: [
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.OS_CRASHES,
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.BOOT_TIME,
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.SHUTDOWN_TIME,
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.BATTERY_HEALTH,
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.CPU_HEALTH,
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.GPU_HEALTH,
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.MEMORY_HEALTH,
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.WIFI_STRENGTH,
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.DOWNLOAD_SPEED,
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.UPLOAD_SPEED,
          ],
        },
      },
      __DEEM_DEVICES_SESSIONS_BY_OS_VERSION_LIST: {
        dependencies: ['DEEM_DEVICES_SESSIONS_BY_OS_VERSION_LIST'],
        composeFunction: ComposeFunction.REMOVE_ZEROS,
      },
      _DEEM_DEVICES_OS_VERSION_TABLE: {
        dependencies: ['DEEM_DEVICES_OS_VERSION_LIST', '__DEEM_DEVICES_SESSIONS_BY_OS_VERSION_LIST'],
        composeFunction: ComposeFunction.MERGE_BUCKETING_ATTRS,
        composeFunctionParams: {
          mergedBucketMatchIndices: [0],
          mergedBucketDefault: { Poor: 0, Neutral: 0, Good: 0, Total: 0 },
          mergedBucketName: 'session',
          mergedBucketIndex: 1,
          mergedCounterIndex: 0,
        },
      },
      __DEEM_DESKTOP_APP_SESSION_BY_VERSIONS_LIST: {
        dependencies: ['DEEM_DESKTOP_APP_SESSION_BY_VERSIONS_LIST'],
        composeFunction: ComposeFunction.REMOVE_ZEROS,
      },
      _DEEM_DESKTOP_APP_VERSIONS_TABLE: {
        dependencies: ['DEEM_DESKTOP_APP_VERSIONS_LIST', '__DEEM_DESKTOP_APP_SESSION_BY_VERSIONS_LIST'],
        composeFunction: ComposeFunction.MERGE_BUCKETING_ATTRS,
        composeFunctionParams: {
          mergedBucketMatchIndices: [0],
          mergedBucketDefault: { Poor: 0, Neutral: 0, Good: 0, Total: 0 },
          mergedBucketName: 'session',
          mergedBucketIndex: 1,
          mergedCounterIndex: 0,
        },
      },
      _DEEM_DESKTOP_APP_DEVICES_BY_CPU_USAGE: {
        dependencies: [
          'DEEM_DESKTOP_APP_TOTAL_DEVICES_COUNT_REPORTING_CPU_USAGE',
          'DEEM_DESKTOP_APP_TOTAL_DEVICES_COUNT_WITH_APP_FOREGROUND_EVENT',
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          seriesDataType: 'INTEGER',
          legendLabel: 'Device Count',
          seriesName: '_merged_series',
          seriesValues: [
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.TOTAL_DEVICES_REPORTING_CPU_USAGE,
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.TOTAL_DEVICES_USING_APP,
          ],
        },
      },
      _DEEM_DESKTOP_APP_DEVICES_BY_GPU_USAGE: {
        dependencies: [
          'DEEM_DESKTOP_APP_TOTAL_DEVICES_COUNT_REPORTING_GPU_USAGE',
          'DEEM_DESKTOP_APP_TOTAL_DEVICES_COUNT_WITH_APP_FOREGROUND_EVENT',
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          seriesDataType: 'INTEGER',
          legendLabel: 'Device Count',
          seriesName: '_merged_series',
          seriesValues: [
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.TOTAL_DEVICES_REPORTING_GPU_USAGE,
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.TOTAL_DEVICES_USING_APP,
          ],
        },
      },
      _DEEM_DESKTOP_APP_PERFORMANCE_GPU_USAGE_PERCENTAGE: {
        dependencies: [
          'DEEM_DESKTOP_APP_PERFORMANCE_GPU_USAGE_PERCENTAGE',
          'DEEM_DESKTOP_APP_PERFORMANCE_GPU1_USAGE_PERCENTAGE',
          'DEEM_DESKTOP_APP_PERFORMANCE_GPU2_USAGE_PERCENTAGE',
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          seriesDataType: 'INTEGER',
          legendLabel: 'Average of GPU Usage',
          seriesName: '_merged_series',
          seriesValues: [
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.GPU_0_USAGE,
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.GPU_1_USAGE,
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.GPU_2_USAGE,
          ],
        },
      },
      _DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_STAT_TOTAL_EVENT_COUNT_WITH_GOOD_SCORE_PERCENT: {
        dependencies: [
          'DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_STAT_TOTAL_EVENT_COUNT_WITH_GOOD_SCORE',
          'DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_STAT_TOTAL_EVENT_COUNT',
        ],
        composeFunction: ComposeFunction.COUNT_RATIO,
      },
      _DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_STAT_TOTAL_EVENT_COUNT_WITH_NEUTRAL_SCORE_PERCENT: {
        dependencies: [
          'DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_STAT_TOTAL_EVENT_COUNT_WITH_NEUTRAL_SCORE',
          'DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_STAT_TOTAL_EVENT_COUNT',
        ],
        composeFunction: ComposeFunction.COUNT_RATIO,
      },
      _DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_STAT_TOTAL_EVENT_COUNT_WITH_POOR_SCORE_PERCENT: {
        dependencies: [
          'DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_STAT_TOTAL_EVENT_COUNT_WITH_POOR_SCORE',
          'DEEM_DESKTOP_APP_PERFORMANCE_MEMORY_STAT_TOTAL_EVENT_COUNT',
        ],
        composeFunction: ComposeFunction.COUNT_RATIO,
      },
      _DEEM_DESKTOP_APP_DEVICES_BY_MEMORY_USAGE: {
        dependencies: [
          'DEEM_DESKTOP_APP_TOTAL_DEVICES_COUNT_REPORTING_MEMORY_USAGE',
          'DEEM_DESKTOP_APP_TOTAL_DEVICES_COUNT_WITH_APP_FOREGROUND_EVENT',
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          seriesDataType: 'INTEGER',
          legendLabel: 'Device Count',
          seriesName: '_merged_series',
          seriesValues: [
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.TOTAL_DEVICES_REPORTING_MEMORY_USAGE,
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.TOTAL_DEVICES_USING_APP,
          ],
        },
      },
      _DEEM_DESKTOP_APP_DEVICES_BY_APP_UI_UNRESPONSIVE: {
        dependencies: [
          'DEEM_DESKTOP_APP_TOTAL_DEVICES_COUNT_WITH_REPORTING_UNRESPONSIVE_EVENTS',
          'DEEM_DESKTOP_APP_TOTAL_DEVICES_COUNT_WITH_APP_FOREGROUND_EVENT',
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          seriesDataType: 'INTEGER',
          legendLabel: 'Device Count',
          seriesName: '_merged_series',
          seriesValues: [
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.TOTAL_DEVICES_REPORTING_UNRESPONSIVE_EVENTS,
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.TOTAL_DEVICES_USING_APP,
          ],
        },
      },
      _DEEM_DESKTOP_APP_UI_UNRESPONSIVE_RATE: {
        dependencies: ['DEEM_DESKTOP_APP_EVENT_COUNT_OF_UNRESPONSIVE_EVENTS', 'DEEM_DESKTOP_APP_EVENT_COUNT_OF_FOREGROUND_EVENTS'],
        composeFunction: ComposeFunction.RATIO_BY_TIME_AND_BUCKETS,
      },
      __DEEM_MOBILE_APP_SESSION_BY_VERSIONS_LIST: {
        dependencies: ['DEEM_MOBILE_APP_SESSION_BY_VERSIONS_LIST'],
        composeFunction: ComposeFunction.REMOVE_ZEROS,
      },
      _DEEM_MOBILE_APP_VERSIONS_TABLE: {
        dependencies: ['DEEM_MOBILE_APP_VERSIONS_LIST', '__DEEM_MOBILE_APP_SESSION_BY_VERSIONS_LIST'],
        composeFunction: ComposeFunction.MERGE_BUCKETING_ATTRS,
        composeFunctionParams: {
          mergedBucketMatchIndices: [0],
          mergedBucketDefault: { Poor: 0, Neutral: 0, Good: 0, Total: 0 },
          mergedBucketName: 'session',
          mergedBucketIndex: 1,
          mergedCounterIndex: 0,
        },
      },
      _DEEM_USER_SCORE_COUNT_BY_SCORE_REMOVE_ZERO: {
        dependencies: ['DEEM_USER_SCORE_COUNT_BY_SCORE'],
        ComposeFunction: ComposeFunction.REMOVE_ZEROS,
      },
      _DEEM_USER_SCORE_LIST: {
        dependencies: ['DEEM_USER_SCORE_LIST', '_DEEM_USER_SCORE_COUNT_BY_SCORE_REMOVE_ZERO'],
        composeFunction: ComposeFunction.MERGE_BUCKETING_ATTRS,
        composeFunctionParams: {
          mergedBucketMatchIndices: [0],
          mergedBucketDefault: { Poor: 0, Neutral: 0, Good: 0, Total: 0 },
          mergedBucketName: 'session',
          mergedBucketIndex: 1,
          mergedCounterIndex: 0,
        },
      },
      _DEEM_VIRTUAL_LOGON_DURATION_BY_PHASE: {
        dependencies: [
          StandardWidgetSubtype.DEEM_VIRTUAL_LOGON_DURATION_BY_GPO_LOAD,
          StandardWidgetSubtype.DEEM_VIRTUAL_LOGON_DURATION_BY_PROFILE_LOAD,
          StandardWidgetSubtype.DEEM_VIRTUAL_LOGON_DURATION_BY_SHELL_LOAD,
          StandardWidgetSubtype.DEEM_VIRTUAL_LOGON_DURATION_BY_LOGON_SCRIPT,
          StandardWidgetSubtype.DEEM_VIRTUAL_LOGON_DURATION_BY_OTHER,
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          legendLabel: 'Logon Phases',
          seriesName: 'logon_phases',
          seriesValues: [
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.GPO_LOAD,
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.PROFILE_LOAD,
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.SHELL_LOAD,
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.LOGON_SCRIPT,
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.OTHER,
          ],
        },
      },
      _DEEM_VIRTUAL_LOGON_DURATION_BY_PHASE_EMPTY: {
        dependencies: [StandardWidgetSubtype._DEEM_VIRTUAL_LOGON_DURATION_BY_PHASE],
        composeFunction: ComposeFunction.REMOVE_ZEROS,
      },
      _DEEM_VIRTUAL_POOLS_WITH_LONGEST_LOGON_DURATION: {
        dependencies: [StandardWidgetSubtype.DEEM_VIRTUAL_POOLS_WITH_LONGEST_LOGON_DURATION],
        composeFunction: ComposeFunction.SET_TREND_MODE,
        composeFunctionParams: {
          toMode: 'SNAPSHOT',
          fromMode: 'HISTORICAL',
        },
      },
      _DEEM_VIRTUAL_USER_LOGON_DURATION_BY_PHASE: {
        dependencies: [
          StandardWidgetSubtype.DEEM_VIRTUAL_USER_LOGON_GPO_LOAD_DURATION,
          StandardWidgetSubtype.DEEM_VIRTUAL_USER_LOGON_PROFILE_LOAD_DURATION,
          StandardWidgetSubtype.DEEM_VIRTUAL_USER_LOGON_SHELL_LOAD_DURATION,
          StandardWidgetSubtype.DEEM_VIRTUAL_USER_LOGON_LOGON_SCRIPT_DURATION,
          StandardWidgetSubtype.DEEM_VIRTUAL_USER_LOGON_OTHER_DURATION,
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          legendLabel: 'Logon Phases',
          seriesName: 'logon_phases',
          seriesValues: [
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.GPO_LOAD,
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.PROFILE_LOAD,
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.SHELL_LOAD,
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.LOGON_SCRIPT,
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.OTHER,
          ],
        },
      },
      _DEEM_VIRTUAL_APP_SESSION_BY_CPU_USAGE: {
        dependencies: [
          StandardWidgetSubtype.DEEM_VIRTUAL_APP_TOTAL_SESSION_COUNT,
          StandardWidgetSubtype.DEEM_VIRTUAL_APP_TOTAL_SESSION_COUNT_USING_APP,
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          seriesDataType: 'INTEGER',
          legendLabel: 'Session Count',
          seriesName: '_merged_series',
          seriesValues: [
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.TOTAL_SESSIONS,
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.TOTAL_SESSIONS_USING_APP,
          ],
        },
      },
      _DEEM_VIRTUAL_APP_SESSION_BY_MEMORY_USAGE: {
        dependencies: [
          StandardWidgetSubtype.DEEM_VIRTUAL_APP_TOTAL_SESSION_COUNT,
          StandardWidgetSubtype.DEEM_VIRTUAL_APP_TOTAL_SESSION_COUNT_USING_APP,
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          seriesDataType: 'INTEGER',
          legendLabel: 'Session Count',
          seriesName: '_merged_series',
          seriesValues: [
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.TOTAL_SESSIONS,
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.TOTAL_SESSIONS_USING_APP,
          ],
        },
      },
      _DEEM_SESSION_PROFILE_PERFORMANCE_NETWORK_LATENCY: {
        dependencies: [
          StandardWidgetSubtype.DEEM_SESSION_PROFILE_PERFORMANCE_LAN_LATENCY,
          StandardWidgetSubtype.DEEM_SESSION_PROFILE_PERFORMANCE_WAN_LATENCY,
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          seriesDataType: 'INTEGER',
          insertSeriesIndex: 0,
          legendLabel: 'Network Latency',
          seriesName: '_merged_series',
          seriesValues: [
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.NETWORK_LAN_LATENCY,
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.NETWORK_WAN_LATENCY,
          ],
        },
      },
      _DEEM_SESSION_PROFILE_PERFORMANCE_NETWORK_LATENCY_AVG: {
        dependencies: [
          StandardWidgetSubtype.DEEM_SESSION_PROFILE_PERFORMANCE_LAN_LATENCY_AVG,
          StandardWidgetSubtype.DEEM_SESSION_PROFILE_PERFORMANCE_WAN_LATENCY_AVG,
        ],
        composeFunction: ComposeFunction.MERGE_RESULTS,
      },
      _DEEM_ZOOM_USER_MEETING_QUALITY_COUNT_BY_QUALITY: {
        dependencies: [
          StandardWidgetSubtype.DEEM_ZOOM_USER_TOTAL_MEETING_COUNT,
          StandardWidgetSubtype.DEEM_ZOOM_USER_GOOD_MEETING_COUNT,
          StandardWidgetSubtype.DEEM_ZOOM_USER_FAIR_MEETING_COUNT,
          StandardWidgetSubtype.DEEM_ZOOM_USER_POOR_MEETING_COUNT,
          StandardWidgetSubtype.DEEM_ZOOM_USER_BAD_MEETING_COUNT,
        ],
        composeFunction: ComposeFunction.MERGE_COUNTERS,
      },
      _DEEM_ZOOM_USERS_BY_CONTRIBUTOR_SCORES: {
        dependencies: ['DEEM_ZOOM_AVG_MEETING_SCORE_BY_USERS', 'DEEM_ZOOM_TOTAL_POOR_MEETINGS_BY_USERS'],
        composeFunction: ComposeFunction.MERGE_COUNTERS,
      },
      _DEEM_ZOOM_BAD_POOR_USERS_TABLE: {
        dependencies: [StandardWidgetSubtype.DEEM_ZOOM_USERS_AVG_SCORE_TABLE, StandardWidgetSubtype.DEEM_ZOOM_BAD_POOR_USERS_TABLE],
        composeFunction: ComposeFunction.MERGE_COUNTERS,
      },
      _DEEM_ZOOM_MEETINGS_BY_APP_VERSION: {
        dependencies: [
          StandardWidgetSubtype.DEEM_ZOOM_MEETINGS_TOTAL_COUNT_BY_APP_VERSION,
          StandardWidgetSubtype.DEEM_ZOOM_MEETINGS_GOOD_COUNT_BY_APP_VERSION,
          StandardWidgetSubtype.DEEM_ZOOM_MEETINGS_FAIR_COUNT_BY_APP_VERSION,
          StandardWidgetSubtype.DEEM_ZOOM_MEETINGS_POOR_COUNT_BY_APP_VERSION,
          StandardWidgetSubtype.DEEM_ZOOM_MEETINGS_BAD_COUNT_BY_APP_VERSION,
          StandardWidgetSubtype.DEEM_ZOOM_MEETINGS_SCORE_BY_APP_VERSION,
          StandardWidgetSubtype.DEEM_ZOOM_AUDIO_QUALITY_BY_APP_VERSION,
          StandardWidgetSubtype.DEEM_ZOOM_VIDEO_QUALITY_BY_APP_VERSION,
          StandardWidgetSubtype.DEEM_ZOOM_SCREEN_SHARE_QUALITY_BY_APP_VERSION,
        ],
        composeFunction: ComposeFunction.MERGE_COUNTERS,
      },
      _DEEM_DEVICES_WITH_POOR_NEUTRAL_GOOD_SCORE_COUNT_LAST_4_HR: {
        dependencies: [StandardWidgetSubtype.DEEM_DEVICES_WITH_POOR_NEUTRAL_GOOD_SCORE_COUNT_LAST_4_HR],
        composeFunction: ComposeFunction.SET_TREND_MODE,
        composeFunctionParams: {
          toMode: 'SNAPSHOT',
          fromMode: 'HISTORICAL',
        },
      },
      _DEEM_VIRTUAL_SESSIONS_WITH_POOR_NEUTRAL_GOOD_SCORE_COUNT_LAST_4_HR: {
        dependencies: [StandardWidgetSubtype.DEEM_VIRTUAL_SESSIONS_WITH_POOR_NEUTRAL_GOOD_SCORE_COUNT_LAST_4_HR],
        composeFunction: ComposeFunction.SET_TREND_MODE,
        composeFunctionParams: {
          toMode: 'SNAPSHOT',
          fromMode: 'HISTORICAL',
        },
      },
      _DEEM_ZOOM_USERS_QUALITY_BY_PLATFORM: {
        dependencies: [
          StandardWidgetSubtype.DEEM_ZOOM_USERS_GOOD_COUNT_BY_PLATFORM,
          StandardWidgetSubtype.DEEM_ZOOM_USERS_FAIR_COUNT_BY_PLATFORM,
          StandardWidgetSubtype.DEEM_ZOOM_USERS_POOR_COUNT_BY_PLATFORM,
          StandardWidgetSubtype.DEEM_ZOOM_USERS_BAD_COUNT_BY_PLATFORM,
          StandardWidgetSubtype.DEEM_ZOOM_USERS_TOTAL_COUNT_BY_PLATFORM,
        ],
        composeFunction: ComposeFunction.MERGE_COUNTERS,
      },
      _DEEM_ZOOM_USERS_QUALITY_BY_CONTRIBUTOR: {
        dependencies: [
          StandardWidgetSubtype.DEEM_ZOOM_USERS_GOOD_COUNT_BY_CONTRIBUTOR,
          StandardWidgetSubtype.DEEM_ZOOM_USERS_FAIR_COUNT_BY_CONTRIBUTOR,
          StandardWidgetSubtype.DEEM_ZOOM_USERS_POOR_COUNT_BY_CONTRIBUTOR,
          StandardWidgetSubtype.DEEM_ZOOM_USERS_BAD_COUNT_BY_CONTRIBUTOR,
          StandardWidgetSubtype.DEEM_ZOOM_USERS_TOTAL_COUNT_BY_CONTRIBUTOR,
        ],
        composeFunction: ComposeFunction.MERGE_COUNTERS,
      },
      _DEEM_VIRTUAL_MGT_COMPONENTS_COUNT_WITH_GOOD_SCORE_LAST_4_HR: {
        dependencies: [
          StandardWidgetSubtype.DEEM_VIRTUAL_CONNECTION_SERVER_WITH_GOOD_SCORE_COUNT_LAST_4_HR,
          StandardWidgetSubtype.DEEM_VIRTUAL_UAG_WITH_GOOD_SCORE_COUNT_LAST_4_HR,
        ],
        composeFunction: ComposeFunction.MERGE_RESULTS,
      },
      __DEEM_VIRTUAL_MGT_COMPONENTS_COUNT_WITH_GOOD_SCORE_LAST_4_HR: {
        dependencies: ['_DEEM_VIRTUAL_MGT_COMPONENTS_COUNT_WITH_GOOD_SCORE_LAST_4_HR'],
        composeFunction: ComposeFunction.SUM_ALL_COUNT,
      },
      _DEEM_VIRTUAL_MGT_COMPONENTS_COUNT_WITH_NEUTRAL_SCORE_LAST_4_HR: {
        dependencies: [
          StandardWidgetSubtype.DEEM_VIRTUAL_CONNECTION_SERVER_WITH_NEUTRAL_SCORE_COUNT_LAST_4_HR,
          StandardWidgetSubtype.DEEM_VIRTUAL_UAG_WITH_NEUTRAL_SCORE_COUNT_LAST_4_HR,
        ],
        composeFunction: ComposeFunction.MERGE_RESULTS,
      },
      __DEEM_VIRTUAL_MGT_COMPONENTS_COUNT_WITH_NEUTRAL_SCORE_LAST_4_HR: {
        dependencies: ['_DEEM_VIRTUAL_MGT_COMPONENTS_COUNT_WITH_NEUTRAL_SCORE_LAST_4_HR'],
        composeFunction: ComposeFunction.SUM_ALL_COUNT,
      },
      _DEEM_VIRTUAL_MGT_COMPONENTS_COUNT_WITH_POOR_SCORE_LAST_4_HR: {
        dependencies: [
          StandardWidgetSubtype.DEEM_VIRTUAL_CONNECTION_SERVER_WITH_POOR_SCORE_COUNT_LAST_4_HR,
          StandardWidgetSubtype.DEEM_VIRTUAL_UAG_WITH_POOR_SCORE_COUNT_LAST_4_HR,
        ],
        composeFunction: ComposeFunction.MERGE_RESULTS,
      },
      __DEEM_VIRTUAL_MGT_COMPONENTS_COUNT_WITH_POOR_SCORE_LAST_4_HR: {
        dependencies: ['_DEEM_VIRTUAL_MGT_COMPONENTS_COUNT_WITH_POOR_SCORE_LAST_4_HR'],
        composeFunction: ComposeFunction.SUM_ALL_COUNT,
      },
      _DEEM_VIRTUAL_MGT_COMPONENTS_TOTAL_COUNT_LAST_4_HR: {
        dependencies: [
          StandardWidgetSubtype.DEEM_VIRTUAL_CONNECTION_SERVER_TOTAL_COUNT_LAST_4_HR,
          StandardWidgetSubtype.DEEM_VIRTUAL_UAG_TOTAL_COUNT_LAST_4_HR,
        ],
        composeFunction: ComposeFunction.MERGE_RESULTS,
      },
      __DEEM_VIRTUAL_MGT_COMPONENTS_TOTAL_COUNT_LAST_4_HR: {
        dependencies: ['_DEEM_VIRTUAL_MGT_COMPONENTS_TOTAL_COUNT_LAST_4_HR'],
        composeFunction: ComposeFunction.SUM_ALL_COUNT,
      },
      _DEEM_VIRTUAL_CONNECTION_SERVER_LIST_BY_OVERALL_SCORE: {
        dependencies: [
          StandardWidgetSubtype.CONNECTION_SERVER_LIST,
          StandardWidgetSubtype.COUNT_OF_SCORES_BY_CONNECTION_SERVER_OVERALL_SCORE,
        ],
        composeFunction: ComposeFunction.MERGE_BUCKETING_ATTRS,
        composeFunctionParams: {
          mergedBucketMatchIndices: [0],
          mergedBucketDefault: { Poor: 0, Neutral: 0, Good: 0, Total: 0 },
          mergedBucketName: COLUMN_NAMES.byName.overall_score,
          mergedBucketIndex: 1,
          mergedCounterIndex: 0,
        },
      },
      _DEEM_VIRTUAL_CONNECTION_SERVER_LIST_BY_OVERALL_STATUS_SCORE: {
        dependencies: [
          '_DEEM_VIRTUAL_CONNECTION_SERVER_LIST_BY_OVERALL_SCORE',
          StandardWidgetSubtype.COUNT_OF_SCORES_BY_CONNECTION_SERVER_STATUS_SCORE,
        ],
        composeFunction: ComposeFunction.MERGE_BUCKETING_ATTRS,
        composeFunctionParams: {
          mergedBucketMatchIndices: [0],
          mergedBucketDefault: { Poor: 0, Neutral: 0, Good: 0, Total: 0 },
          mergedBucketName: COLUMN_NAMES.byName.status_score,
          mergedBucketIndex: 1,
          mergedCounterIndex: 0,
        },
      },
      _DEEM_VIRTUAL_CONNECTION_SERVER_LIST_BY_OVERALL_STATUS_CPU_SCORE: {
        dependencies: [
          '_DEEM_VIRTUAL_CONNECTION_SERVER_LIST_BY_OVERALL_STATUS_SCORE',
          StandardWidgetSubtype.COUNT_OF_SCORES_BY_CONNECTION_SERVER_CPU_UTILIZATION_SCORE,
        ],
        composeFunction: ComposeFunction.MERGE_BUCKETING_ATTRS,
        composeFunctionParams: {
          mergedBucketMatchIndices: [0],
          mergedBucketDefault: { Poor: 0, Neutral: 0, Good: 0, Total: 0 },
          mergedBucketName: COLUMN_NAMES.byName.cpu_utilization_score,
          mergedBucketIndex: 1,
          mergedCounterIndex: 0,
        },
      },
      _DEEM_VIRTUAL_CONNECTION_SERVER_LIST_BY_OVERALL_STATUS_CPU_MEMORY_SCORE: {
        dependencies: [
          '_DEEM_VIRTUAL_CONNECTION_SERVER_LIST_BY_OVERALL_STATUS_CPU_SCORE',
          StandardWidgetSubtype.COUNT_OF_SCORES_BY_CONNECTION_SERVER_MEMORY_UTILIZATION_SCORE,
        ],
        composeFunction: ComposeFunction.MERGE_BUCKETING_ATTRS,
        composeFunctionParams: {
          mergedBucketMatchIndices: [0],
          mergedBucketDefault: { Poor: 0, Neutral: 0, Good: 0, Total: 0 },
          mergedBucketName: COLUMN_NAMES.byName.memory_utilization_score,
          mergedBucketIndex: 1,
          mergedCounterIndex: 0,
        },
      },
      _CONNECTION_SERVER_LIST_BY_OVERALL_STATUS_CPU_MEMORY_NUMSESSION_SCORE: {
        dependencies: [
          '_DEEM_VIRTUAL_CONNECTION_SERVER_LIST_BY_OVERALL_STATUS_CPU_MEMORY_SCORE',
          StandardWidgetSubtype.COUNT_OF_SCORES_BY_CONNECTION_SERVER_NUM_SESSIONS_SCORE,
        ],
        composeFunction: ComposeFunction.MERGE_BUCKETING_ATTRS,
        composeFunctionParams: {
          mergedBucketMatchIndices: [0],
          mergedBucketDefault: { Poor: 0, Neutral: 0, Good: 0, Total: 0 },
          mergedBucketName: COLUMN_NAMES.byName.num_sessions_score,
          mergedBucketIndex: 1,
          mergedCounterIndex: 0,
        },
      },
      __CONNECTION_SERVER_LIST_BY_CERT_SCORE: {
        dependencies: [StandardWidgetSubtype.CONNECTION_SERVER_LIST, StandardWidgetSubtype.CONNECTION_SERVER_LIST_BY_CERT_SCORE],
        composeFunction: ComposeFunction.MERGE_BUCKETING_ATTRS,
        composeFunctionParams: {
          mergedBucketMatchIndices: [0],
          mergedBucketDefault: { Poor: 0, Neutral: 0, Good: 0, Total: 0 },
          mergedBucketName: COLUMN_NAMES.byName.count_of_scores,
          mergedBucketIndex: 1,
          mergedCounterIndex: 0,
        },
      },
      __CONNECTION_SERVER_LIST_BY_CPU_SCORE: {
        dependencies: [StandardWidgetSubtype.CONNECTION_SERVER_LIST, StandardWidgetSubtype.CONNECTION_SERVER_LIST_BY_CPU_UTILIZATION_SCORE],
        composeFunction: ComposeFunction.MERGE_BUCKETING_ATTRS,
        composeFunctionParams: {
          mergedBucketMatchIndices: [0],
          mergedBucketDefault: { Poor: 0, Neutral: 0, Good: 0, Total: 0 },
          mergedBucketName: COLUMN_NAMES.byName.count_of_scores,
          mergedBucketIndex: 1,
          mergedCounterIndex: 0,
        },
      },
      __CONNECTION_SERVER_LIST_BY_MEMORY_SCORE: {
        dependencies: [
          StandardWidgetSubtype.CONNECTION_SERVER_LIST,
          StandardWidgetSubtype.CONNECTION_SERVER_LIST_BY_MEMORY_UTILIZATION_SCORE,
        ],
        composeFunction: ComposeFunction.MERGE_BUCKETING_ATTRS,
        composeFunctionParams: {
          mergedBucketMatchIndices: [0],
          mergedBucketDefault: { Poor: 0, Neutral: 0, Good: 0, Total: 0 },
          mergedBucketName: COLUMN_NAMES.byName.count_of_scores,
          mergedBucketIndex: 1,
          mergedCounterIndex: 0,
        },
      },
      __CONNECTION_SERVER_LIST_BY_NUM_SESSION_SCORE: {
        dependencies: [StandardWidgetSubtype.CONNECTION_SERVER_LIST, StandardWidgetSubtype.CONNECTION_SERVER_LIST_BY_NUM_SESSIONS_SCORE],
        composeFunction: ComposeFunction.MERGE_BUCKETING_ATTRS,
        composeFunctionParams: {
          mergedBucketMatchIndices: [0],
          mergedBucketDefault: { Poor: 0, Neutral: 0, Good: 0, Total: 0 },
          mergedBucketName: COLUMN_NAMES.byName.count_of_scores,
          mergedBucketIndex: 1,
          mergedCounterIndex: 0,
        },
      },
      __CONNECTION_SERVER_LIST_BY_STATUS_SCORE: {
        dependencies: [StandardWidgetSubtype.CONNECTION_SERVER_LIST, StandardWidgetSubtype.CONNECTION_SERVER_LIST_BY_STATUS_SCORE],
        composeFunction: ComposeFunction.MERGE_BUCKETING_ATTRS,
        composeFunctionParams: {
          mergedBucketMatchIndices: [0],
          mergedBucketDefault: { Poor: 0, Good: 0, Total: 0 },
          mergedBucketName: COLUMN_NAMES.byName.count_of_scores,
          mergedBucketIndex: 1,
          mergedCounterIndex: 0,
        },
      },
      __UAG_APPLIANCE_LIST_BY_CPU_SCORE: {
        dependencies: [StandardWidgetSubtype.UAG_APPLIANCE_LIST, StandardWidgetSubtype.UAG_APPLIANCE_LIST_BY_CPU_UTILIZATION_SCORE],
        composeFunction: ComposeFunction.MERGE_BUCKETING_ATTRS,
        composeFunctionParams: {
          mergedBucketMatchIndices: [0],
          mergedBucketDefault: { Poor: 0, Neutral: 0, Good: 0, Total: 0 },
          mergedBucketName: COLUMN_NAMES.byName.count_of_scores,
          mergedBucketIndex: 1,
          mergedCounterIndex: 0,
        },
      },
      __UAG_APPLIANCE_LIST_BY_MEMORY_SCORE: {
        dependencies: [StandardWidgetSubtype.UAG_APPLIANCE_LIST, StandardWidgetSubtype.UAG_APPLIANCE_LIST_BY_MEMORY_USAGE_SCORE],
        composeFunction: ComposeFunction.MERGE_BUCKETING_ATTRS,
        composeFunctionParams: {
          mergedBucketMatchIndices: [0],
          mergedBucketDefault: { Poor: 0, Neutral: 0, Good: 0, Total: 0 },
          mergedBucketName: COLUMN_NAMES.byName.count_of_scores,
          mergedBucketIndex: 1,
          mergedCounterIndex: 0,
        },
      },
      __UAG_APPLIANCE_LIST_BY_CERT_SCORE: {
        dependencies: [StandardWidgetSubtype.UAG_APPLIANCE_LIST, StandardWidgetSubtype.UAG_APPLIANCE_LIST_BY_CERT_SCORE],
        composeFunction: ComposeFunction.MERGE_BUCKETING_ATTRS,
        composeFunctionParams: {
          mergedBucketMatchIndices: [0],
          mergedBucketDefault: { Poor: 0, Neutral: 0, Good: 0, Total: 0 },
          mergedBucketName: COLUMN_NAMES.byName.count_of_scores,
          mergedBucketIndex: 1,
          mergedCounterIndex: 0,
        },
      },
      __UAG_APPLIANCE_LIST_BY_CONNECTION_COUNT_SCORE: {
        dependencies: [StandardWidgetSubtype.UAG_APPLIANCE_LIST, StandardWidgetSubtype.UAG_APPLIANCE_LIST_BY_CONNECTION_COUNT_SCORE],
        composeFunction: ComposeFunction.MERGE_BUCKETING_ATTRS,
        composeFunctionParams: {
          mergedBucketMatchIndices: [0],
          mergedBucketDefault: { Poor: 0, Neutral: 0, Good: 0, Total: 0 },
          mergedBucketName: COLUMN_NAMES.byName.count_of_scores,
          mergedBucketIndex: 1,
          mergedCounterIndex: 0,
        },
      },
      __UAG_APPLIANCE_LIST_BY_STATUS_SCORE: {
        dependencies: [StandardWidgetSubtype.UAG_APPLIANCE_LIST, StandardWidgetSubtype.UAG_APPLIANCE_LIST_BY_STATUS_SCORE],
        composeFunction: ComposeFunction.MERGE_BUCKETING_ATTRS,
        composeFunctionParams: {
          mergedBucketMatchIndices: [0],
          mergedBucketDefault: { Poor: 0, Good: 0, Total: 0 },
          mergedBucketName: COLUMN_NAMES.byName.count_of_scores,
          mergedBucketIndex: 1,
          mergedCounterIndex: 0,
        },
      },
      _DEEM_VIRTUAL_UAG_APPLIANCE_LIST_BY_OVERALL_SCORE: {
        dependencies: [StandardWidgetSubtype.UAG_APPLIANCE_LIST, StandardWidgetSubtype.COUNT_OF_SCORES_BY_UAG_APPLIANCE_OVERALL_SCORE],
        composeFunction: ComposeFunction.MERGE_BUCKETING_ATTRS,
        composeFunctionParams: {
          mergedBucketMatchIndices: [0],
          mergedBucketDefault: { Poor: 0, Neutral: 0, Good: 0, Total: 0 },
          mergedBucketName: COLUMN_NAMES.byName.overall_score,
          mergedBucketIndex: 1,
          mergedCounterIndex: 0,
        },
      },
      _DEEM_VIRTUAL_UAG_APPLIANCE_LIST_BY_OVERALL_CONNECTIONCOUNT_SCORE: {
        dependencies: [
          '_DEEM_VIRTUAL_UAG_APPLIANCE_LIST_BY_OVERALL_SCORE',
          StandardWidgetSubtype.COUNT_OF_SCORES_BY_UAG_APPLIANCE_CONNECTION_COUNT_SCORE,
        ],
        composeFunction: ComposeFunction.MERGE_BUCKETING_ATTRS,
        composeFunctionParams: {
          mergedBucketMatchIndices: [0],
          mergedBucketDefault: { Poor: 0, Neutral: 0, Good: 0, Total: 0 },
          mergedBucketName: COLUMN_NAMES.byName.connection_count_score,
          mergedBucketIndex: 1,
          mergedCounterIndex: 0,
        },
      },
      _DEEM_VIRTUAL_UAG_APPLIANCE_LIST_BY_OVERALL_CONNECTIONCOUNT_CPU_SCORE: {
        dependencies: [
          '_DEEM_VIRTUAL_UAG_APPLIANCE_LIST_BY_OVERALL_CONNECTIONCOUNT_SCORE',
          StandardWidgetSubtype.COUNT_OF_SCORES_BY_UAG_APPLIANCE_CPU_UTILIZATION_SCORE,
        ],
        composeFunction: ComposeFunction.MERGE_BUCKETING_ATTRS,
        composeFunctionParams: {
          mergedBucketMatchIndices: [0],
          mergedBucketDefault: { Poor: 0, Neutral: 0, Good: 0, Total: 0 },
          mergedBucketName: COLUMN_NAMES.byName.cpu_utilization_score,
          mergedBucketIndex: 1,
          mergedCounterIndex: 0,
        },
      },
      _UAG_APPLIANCE_LIST_BY_OVERALL_CONNECTIONCOUNT_CPU_MEMORY_SCORE: {
        dependencies: [
          '_DEEM_VIRTUAL_UAG_APPLIANCE_LIST_BY_OVERALL_CONNECTIONCOUNT_CPU_SCORE',
          StandardWidgetSubtype.COUNT_OF_SCORES_BY_UAG_APPLIANCE_MEMORY_USAGE_SCORE,
        ],
        composeFunction: ComposeFunction.MERGE_BUCKETING_ATTRS,
        composeFunctionParams: {
          mergedBucketMatchIndices: [0],
          mergedBucketDefault: { Poor: 0, Neutral: 0, Good: 0, Total: 0 },
          mergedBucketName: COLUMN_NAMES.byName.memory_utilization_score,
          mergedBucketIndex: 1,
          mergedCounterIndex: 0,
        },
      },
      __DEEM_VIRTUAL_SESSION_DETAILS_APP_PERFORMANCE: {
        dependencies: ['DEEM_SESSION_APP_PERFORMANCE_CPU_USAGE', 'DEEM_SESSION_APP_PERFORMANCE_MEMORY_USAGE'],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          seriesName: 'Usage',
          seriesValues: [
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.CPU,
            DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.MEMORY,
          ],
        },
      },
      __CONNECTION_SERVER_DETAIL_TIMELINE_EVENTS_ERROR_STATUS: {
        dependencies: [StandardWidgetSubtype.CONNECTION_SERVER_DETAIL_TIMELINE_EVENTS_ERROR_STATUS],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          legendLabel: 'Error Status Event',
          seriesName: 'error_status_event',
          seriesValues: [DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.NUMBER_OF_CONNECTION_SERVER_ERROR_STATUS],
        },
      },
      __UAG_APPLIANCE_DETAIL_TIMELINE_EVENTS_ERROR_STATUS: {
        dependencies: [StandardWidgetSubtype.UAG_APPLIANCE_DETAIL_TIMELINE_EVENTS_ERROR_STATUS],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          legendLabel: 'Error Status Event',
          seriesName: 'error_status_event',
          seriesValues: [DeemSolutionDashboardConfig.WIDGET_SERIES_KEY_VALUE.NUMBER_OF_UAG_APPLIANCE_ERROR_STATUS],
        },
      },
      __CONNECTION_SERVER_DETAIL_PERFORMANCE_CPU_PERCENTAGE: {
        dependencies: [
          StandardWidgetSubtype.CONNECTION_SERVER_DETAIL_PERFORMANCE_MAX_CPU_PERCENTAGE,
          StandardWidgetSubtype.CONNECTION_SERVER_DETAIL_PERFORMANCE_AVG_CPU_PERCENTAGE,
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          seriesDataType: 'STRING',
          legendLabel: 'CPU Usage',
          seriesName: '_merged_series',
          seriesValues: ['Max', 'Avg'],
        },
      },
      __CONNECTION_SERVER_DETAIL_PERFORMANCE_MEMORY_UTILIZATION_PERCENTAGE: {
        dependencies: [
          StandardWidgetSubtype.CONNECTION_SERVER_DETAIL_PERFORMANCE_MAX_MEMORY_UTILIZATION_PERCENTAGE,
          StandardWidgetSubtype.CONNECTION_SERVER_DETAIL_PERFORMANCE_AVG_MEMORY_UTILIZATION_PERCENTAGE,
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          seriesDataType: 'STRING',
          legendLabel: 'Memory Usage',
          seriesName: '_merged_series',
          seriesValues: ['Max', 'Avg'],
        },
      },
      __CONNECTION_SERVER_DETAIL_PERFORMANCE_NUM_SESSIONS: {
        dependencies: [
          StandardWidgetSubtype.CONNECTION_SERVER_DETAIL_PERFORMANCE_MAX_NUM_SESSIONS,
          StandardWidgetSubtype.CONNECTION_SERVER_DETAIL_PERFORMANCE_AVG_NUM_SESSIONS,
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          seriesDataType: 'STRING',
          legendLabel: 'Sessions',
          seriesName: '_merged_series',
          seriesValues: ['Max', 'Avg'],
        },
      },
      __UAG_APPLIANCE_DETAIL_PERFORMANCE_CPU_PCT: {
        dependencies: [
          StandardWidgetSubtype.UAG_APPLIANCE_DETAIL_PERFORMANCE_MAX_CPU_PCT,
          StandardWidgetSubtype.UAG_APPLIANCE_DETAIL_PERFORMANCE_AVG_CPU_PCT,
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          seriesDataType: 'STRING',
          legendLabel: 'CPU Usage',
          seriesName: '_merged_series',
          seriesValues: ['Max', 'Avg'],
        },
      },
      __UAG_APPLIANCE_DETAIL_PERFORMANCE_MEMORY_PCT: {
        dependencies: [
          StandardWidgetSubtype.UAG_APPLIANCE_DETAIL_PERFORMANCE_MAX_MEMORY_PCT,
          StandardWidgetSubtype.UAG_APPLIANCE_DETAIL_PERFORMANCE_AVG_MEMORY_PCT,
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          seriesDataType: 'STRING',
          legendLabel: 'Memory Usage',
          seriesName: '_merged_series',
          seriesValues: ['Max', 'Avg'],
        },
      },
      __UAG_APPLIANCE_DETAIL_PERFORMANCE_CONNECTION_COUNT: {
        dependencies: [
          StandardWidgetSubtype.UAG_APPLIANCE_DETAIL_PERFORMANCE_MAX_CONNECTION_COUNT,
          StandardWidgetSubtype.UAG_APPLIANCE_DETAIL_PERFORMANCE_AVG_CONNECTION_COUNT,
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          seriesDataType: 'STRING',
          legendLabel: 'Connections',
          seriesName: '_merged_series',
          seriesValues: ['Max', 'Avg'],
        },
      },
    },
    DEEM_SURVEYS: {
      __SURVEY_RESPONSE_COUNT: {
        dependencies: ['SURVEY_RESPONSE_COUNT'],
        composeFunction: ComposeFunction.SET_TREND_MODE,
        composeFunctionParams: {
          toMode: 'SNAPSHOT',
          fromMode: 'HISTORICAL',
        },
      },
      __SURVEY_RESULT_Q1: {
        dependencies: ['SURVEY_RESULT_Q1'],
        composeFunction: ComposeFunction.SET_TREND_MODE,
        composeFunctionParams: {
          toMode: 'SNAPSHOT',
          fromMode: 'HISTORICAL',
        },
      },
      __SURVEY_RESULT_Q2: {
        dependencies: ['SURVEY_RESULT_Q2'],
        composeFunction: ComposeFunction.SET_TREND_MODE,
        composeFunctionParams: {
          toMode: 'SNAPSHOT',
          fromMode: 'HISTORICAL',
        },
      },
      __SURVEY_RESULT_Q3: {
        dependencies: ['SURVEY_RESULT_Q3'],
        composeFunction: ComposeFunction.SET_TREND_MODE,
        composeFunctionParams: {
          toMode: 'SNAPSHOT',
          fromMode: 'HISTORICAL',
        },
      },
      __SURVEY_RESULT_Q4: {
        dependencies: ['SURVEY_RESULT_Q4'],
        composeFunction: ComposeFunction.SET_TREND_MODE,
        composeFunctionParams: {
          toMode: 'SNAPSHOT',
          fromMode: 'HISTORICAL',
        },
      },
      __SURVEY_RESULT_Q5: {
        dependencies: ['SURVEY_RESULT_Q5'],
        composeFunction: ComposeFunction.SET_TREND_MODE,
        composeFunctionParams: {
          toMode: 'SNAPSHOT',
          fromMode: 'HISTORICAL',
        },
      },
    },
    DEEM_SAM: {
      __DEEM_SAM_DISCOVER_NEW_APPS: {
        dependencies: ['DEEM_SAM_DISCOVER_NEW_APPS_MANAGED', 'DEEM_SAM_DISCOVER_NEW_APPS_UNMANAGED'],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 1,
          seriesDataType: 'STRING', // seriesDataType has effect on drilldown
          legendLabel: 'New Unigue Apps',
          seriesName: '_merged_series',
          seriesValues: ['Managed', 'Unmanaged'],
        },
      },
      __DEEM_SAM_OPTIMIZE_TOP_10: {
        dependencies: ['DEEM_SAM_OPTIMIZE_TOP_10'],
        composeFunction: ComposeFunction.SET_TREND_MODE,
        composeFunctionParams: {
          toMode: 'SNAPSHOT',
          fromMode: 'HISTORICAL',
        },
      },
    },
  };
}
