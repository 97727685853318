/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { DataConverterConfig, I18NCommonService, ShorthandNumberPipe, TimeDurationPipe } from '@dpa/ui-common';
import { mapValues } from 'lodash-es';

import { I18NService } from '@ws1c/intelligence-common';
import {
  AggregationFunction,
  AggregationWidgetChartType,
  ColumnIndex,
  NgxChart,
  Trend,
  TrendDefinition,
  WidgetColorSchema,
  WidgetRangeFilter,
} from '@ws1c/intelligence-models';

interface TranslatorIndex {
  [aggregationFunction: string]: (columnName: string) => string;
}

interface ChartConfig {
  colorizedLevel: number;
  minimumGroupBys: number;
  useDateAsSecondGroupBy: boolean;
  trendMapper?: (trend: Trend) => Trend;
}

/**
 * NgxChartFactoryService
 * @export
 * @class NgxChartFactoryService
 */
@Injectable()
export class NgxChartFactoryService {
  public static readonly labelKeyByAggregationFunction = {
    [AggregationFunction.COUNT]: 'WIDGET_CUSTOMIZE.COUNT_OF_NAME',
    [AggregationFunction.COUNT_DISTINCT]: 'WIDGET_CUSTOMIZE.COUNT_DISTINCT_NAME',
    [AggregationFunction.COUNT_DISTINCT_BY_MINUTE]: 'WIDGET_CUSTOMIZE.COUNT_DISTINCT_BY_MINUTE_NAME',
    [AggregationFunction.AVG]: 'WIDGET_CUSTOMIZE.AVG_NAME',
    [AggregationFunction.LATEST]: 'WIDGET_CUSTOMIZE.LATEST_NAME',
    [AggregationFunction.MIN]: 'WIDGET_CUSTOMIZE.MIN_NAME',
    [AggregationFunction.MAX]: 'WIDGET_CUSTOMIZE.MAX_NAME',
    [AggregationFunction.SUM]: 'WIDGET_CUSTOMIZE.SUM_NAME',
  };

  public static readonly CHART_CONFIGS_BY_TYPE: Record<AggregationWidgetChartType, ChartConfig> = {
    [AggregationWidgetChartType.DONUT]: {
      colorizedLevel: 0,
      minimumGroupBys: 1,
      useDateAsSecondGroupBy: false,
    },
    [AggregationWidgetChartType.METRIC]: {
      colorizedLevel: 0,
      minimumGroupBys: 0,
      useDateAsSecondGroupBy: false,
    },
    [AggregationWidgetChartType.TABLE]: {
      colorizedLevel: 0,
      minimumGroupBys: 0,
      useDateAsSecondGroupBy: false,
    },
    [AggregationWidgetChartType.HORIZONTAL]: {
      colorizedLevel: 1,
      minimumGroupBys: 1,
      useDateAsSecondGroupBy: false,
    },
    [AggregationWidgetChartType.VERTICAL]: {
      colorizedLevel: 1,
      minimumGroupBys: 1,
      useDateAsSecondGroupBy: false,
    },
    [AggregationWidgetChartType.BUBBLE]: {
      colorizedLevel: 0,
      minimumGroupBys: 2,
      useDateAsSecondGroupBy: true,
    },
    [AggregationWidgetChartType.LINE]: {
      colorizedLevel: 0,
      minimumGroupBys: 2,
      useDateAsSecondGroupBy: true,
    },
    [AggregationWidgetChartType.AREA]: {
      colorizedLevel: 0,
      minimumGroupBys: 2,
      useDateAsSecondGroupBy: true,
    },
    [AggregationWidgetChartType.LINE_SPARK]: {
      colorizedLevel: 0,
      minimumGroupBys: 2,
      useDateAsSecondGroupBy: true,
      trendMapper: (trend: Trend) => {
        return Object.assign(new Trend(), trend, {
          trendDefinition: Object.assign(new TrendDefinition(), trend.trendDefinition, {
            bucketingAttributes: [],
          } as TrendDefinition),
        } as Trend);
      },
    },
    [AggregationWidgetChartType.HEAT_MAP]: {
      colorizedLevel: 1,
      minimumGroupBys: 1,
      useDateAsSecondGroupBy: false,
    },
    [AggregationWidgetChartType.TREE_MAP]: {
      colorizedLevel: 0,
      minimumGroupBys: 1,
      useDateAsSecondGroupBy: false,
    },
    [AggregationWidgetChartType.VERTICAL_GROUP]: {
      colorizedLevel: 1,
      minimumGroupBys: 1,
      useDateAsSecondGroupBy: false,
    },
    [AggregationWidgetChartType.HORIZONTAL_GROUP]: {
      colorizedLevel: 1,
      minimumGroupBys: 1,
      useDateAsSecondGroupBy: false,
    },
  };

  public translators: TranslatorIndex;

  /**
   * Creates an instance of NgxChartFactoryService.
   * @param {I18NService} i18NService
   * @param {I18NCommonService} i18NCommonService
   * @memberof NgxChartFactoryService
   */
  public constructor(private i18NService: I18NService, private i18NCommonService: I18NCommonService) {
    const aggregationTranslators = mapValues(NgxChartFactoryService.labelKeyByAggregationFunction, (labelKey: string) => {
      return (columnName: string) => {
        return this.i18NService.translate(labelKey, { name: columnName });
      };
    });

    const timeUnitTranslators = mapValues(TimeDurationPipe.shortHandLabelKeysByTimeUnitName, (labelKey: string) => {
      return (durationValue: number) => this.i18NCommonService.translate(labelKey, { n: durationValue });
    });

    const bigNumberTranslators = ShorthandNumberPipe.BIG_NUMBER_LABEL_KEYS.reduce((translators: any, labelKey: string) => {
      translators[labelKey] = () => {
        return labelKey ? this.i18NService.translate(labelKey) : '';
      };
      return translators;
    }, {});

    this.translators = {
      ...aggregationTranslators,
      ...timeUnitTranslators,
      ...bigNumberTranslators,
      DATE: () => this.i18NService.translate('COMMON_MESSAGES.DATE'),
      DANGEROUS_GROUP_BY_INFO: () => this.i18NService.translate('WIDGET_CUSTOMIZE.GROUPING_BY_DATE_FIELDS_COULD_RESULT_IN_DOUBLE_COUNTING'),
      OTHERS: () => this.i18NService.translate('COMMON_MESSAGES.OTHERS'),
      RAW: (labelKey: string) => this.i18NService.translate(labelKey),
    };

    DataConverterConfig.configKeys.forEach((unit: string) => {
      this.translators[unit] = (value: any) => `${value} ${this.i18NService.translate(unit)}`;
    });
  }

  /**
   * buildNgxChartModel
   * @param {Trend} trend
   * @param {AggregationWidgetChartType} chartType
   * @param {any} customColorOverride
   * @param {WidgetColorSchema[]} colorSchemas
   * @param {string} noBucketingColor
   * @param {boolean} isRanged
   * @param {boolean} isInvertMode
   * @param {boolean} skipSort
   * @param {WidgetRangeFilter} rangeFilter
   * @param {number} xAxisLabelFactor
   * @param {string[]} defaultColors
   * @param {ColumnIndex} [allColumnsByName]
   * @param {boolean} showZeroSeries
   * @returns {NgxChart}
   * @memberof NgxChartFactoryService
   */
  public buildNgxChartModel(
    trend: Trend,
    chartType: AggregationWidgetChartType,
    customColorOverride?: any,
    colorSchemas?: WidgetColorSchema[],
    noBucketingColor?: string,
    isRanged?: boolean,
    isInvertMode?: boolean,
    skipSort?: boolean,
    rangeFilter?: WidgetRangeFilter,
    xAxisLabelFactor?: number,
    defaultColors?: string[],
    allColumnsByName?: ColumnIndex,
    showZeroSeries?: boolean,
  ): NgxChart {
    const chartConfig: ChartConfig = NgxChartFactoryService.CHART_CONFIGS_BY_TYPE[chartType];
    return new NgxChart(
      chartConfig.trendMapper ? chartConfig.trendMapper(trend) : trend,
      this.translators,
      chartConfig.useDateAsSecondGroupBy,
      chartConfig.minimumGroupBys,
      noBucketingColor,
      chartConfig.colorizedLevel,
      this.i18NService.getCurrentLocale(),
      customColorOverride,
      colorSchemas,
      isRanged,
      isInvertMode,
      skipSort,
      rangeFilter,
      xAxisLabelFactor,
      defaultColors,
      allColumnsByName,
      showZeroSeries,
    );
  }
}
