/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { enumConverterFactory } from '@ws1c/intelligence-models/converters/converter-factory';
import { ReportDateRange } from '@ws1c/intelligence-models/reports/report-date-range.model';
import { ReportType } from '@ws1c/intelligence-models/reports/report.enum';
import { DeemPlaybookStepItemBase } from './deem-playbook-step-item-base.model';

/**
 * DeemPlaybookStepItemReport
 * @export
 * @class DeemPlaybookStepItemReport
 * @extends DeemPlaybookStepItemBase
 */
@Serializable
export class DeemPlaybookStepItemReport extends DeemPlaybookStepItemBase {
  @JsonProperty('use_incident_filter')
  public useIncidentFilter: boolean = undefined;

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('filter')
  public filter: string = undefined;

  @JsonProperty({ name: 'report_type', customConverter: enumConverterFactory(ReportType) })
  public reportType: ReportType = undefined;

  @JsonProperty({ name: 'date_range', cls: ReportDateRange })
  public dateRange: ReportDateRange = undefined;

  @JsonProperty('integration')
  public integration: string = undefined;

  @JsonProperty({ name: 'entity' })
  public entity: string = undefined;

  @JsonProperty('column_names')
  public columnNames: string[] = undefined;

  /**
   * constructor
   * @param {Partial<DeemPlaybookStepItemReport>[]} args
   */
  constructor(...args: Array<Partial<DeemPlaybookStepItemReport>>) {
    super();
    Object.assign(this, ...args);
  }
}
