/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { DataDecorator, GenericObject } from '@dpa/ui-common';
import { createSelector, MemoizedSelector } from '@ngrx/store';

import {
  convertDeviceHygieneToAttrObject,
  getFormattedValue,
  getPropertiesColumnMap,
} from '@dpa-shared-merlot/store/dashboard/devices/devices-dashboard-selector-helpers';
import { getDefaultTrendDate } from '@ws1c/dashboard-common';
import { CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';
import { DashboardSelectors, helpers } from '@ws1c/intelligence-core/store/dashboard';
import { getBucketingAttributeValue, getFirstCounterValue } from '@ws1c/intelligence-core/store/dashboard/dashboard-selector-helpers';
import { IntegratedServicesSelectors } from '@ws1c/intelligence-core/store/integrated-services';
import {
  ChronoUnit,
  ComparisonQueryResponse,
  CustomReportPreviewSearchResponse,
  DashboardConfig,
  Device,
  DevicesDashboardConfig,
  EntityThresholds,
  IntegratedService,
  IntegratedServiceType,
  PreviewReportContentRequest,
  PropertyValuePair,
  RuleGroup,
  StandardWidgetSubtype,
  Trend,
  TrendDateRange,
} from '@ws1c/intelligence-models';
import { DeviceProfileState } from './device-profile.store';

/**
 * DeviceProfileSelectors
 *
 * @export
 * @class DeviceProfileSelectors
 */
export class DeviceProfileSelectors {
  public static selectDeviceProfileState = (state: CoreAppState) => state.deviceProfileState;

  /**
   * getDeviceProfileAppPerformance
   * @static
   * @param {string} id
   * @type {MemoizedSelector<CoreAppState, Trend>}
   * @memberof DeviceProfileSelectors
   */
  public static getDeviceProfileAppPerformance = (id: string): MemoizedSelector<CoreAppState, Trend> => {
    return createSelector(DeviceProfileSelectors.selectDeviceProfileState, (state: DeviceProfileState) => {
      return state.performance.app?.[id] || new Trend();
    });
  };

  /**
   * getDeviceProfileDestinationPerformance
   * @static
   * @param {string} id
   * @type {MemoizedSelector<CoreAppState, Trend>}
   * @memberof DeviceProfileSelectors
   */
  public static getDeviceProfileDestinationPerformance = (id: string): MemoizedSelector<CoreAppState, Trend> => {
    return createSelector(DeviceProfileSelectors.selectDeviceProfileState, (state: DeviceProfileState) => {
      return state.performance.destination?.[id] || new Trend();
    });
  };

  /**
   * getDeviceProfileNetworkPerformance
   * @static
   * @param {string} id
   * @type {MemoizedSelector<CoreAppState, Trend>}
   * @memberof getDeviceProfileNetworkPerformance
   */
  public static getDeviceProfileNetworkPerformance = (id: string): MemoizedSelector<CoreAppState, Trend> => {
    return createSelector(DeviceProfileSelectors.selectDeviceProfileState, (state: DeviceProfileState) => {
      return state.performance.network?.[id] ?? new Trend();
    });
  };

  /**
   * getDeviceProfileUrlMonitoringPerformance
   * @static
   * @param {string} id
   * @type {MemoizedSelector<CoreAppState, Trend>}
   * @memberof DeviceProfileSelectors
   */
  public static getDeviceProfileUrlMonitoringPerformance = (id: string): MemoizedSelector<CoreAppState, Trend> => {
    return createSelector(DeviceProfileSelectors.selectDeviceProfileState, (state: DeviceProfileState) => {
      return state.performance.syntheticUrlMonitoring?.[id] ?? new Trend();
    });
  };

  /**
   * getDevice
   * @static
   * @type {MemoizedSelector<CoreAppState, Device>}
   * @memberof DeviceProfileSelectors
   */
  public static getDevice: MemoizedSelector<CoreAppState, Device> = createSelector(
    DeviceProfileSelectors.selectDeviceProfileState,
    (state: DeviceProfileState) => state.device,
  );

  /**
   * getDeviceLastSeen
   * @static
   * @type {MemoizedSelector<CoreAppState, number>}
   * @memberof DeviceProfileSelectors
   */
  public static getDeviceLastSeen: MemoizedSelector<CoreAppState, number> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (standardDashboardData: Map<string, Trend>) => {
      return getFirstCounterValue(
        standardDashboardData.get(StandardWidgetSubtype.DEVICES_PROFILE_LAST_SEEN) ??
          standardDashboardData.get(StandardWidgetSubtype.DEVICES_DETAILS_LAST_SEEN),
      );
    },
  );

  /**
   * getProfileFilterRuleGroup
   * @static
   * @type {MemoizedSelector<CoreAppState, RuleGroup>}
   * @memberof DeviceProfileSelectors
   */
  public static getProfileFilterRuleGroup: MemoizedSelector<CoreAppState, RuleGroup> = createSelector(
    DeviceProfileSelectors.selectDeviceProfileState,
    (state: DeviceProfileState) => state.filterRuleGroup ?? new RuleGroup(),
  );

  /**
   * getProfileTrendDateRange
   * @static
   * @type {MemoizedSelector<CoreAppState, TrendDateRange>}
   * @memberof DeviceProfileSelectors
   */
  public static getProfileTrendDateRange: MemoizedSelector<CoreAppState, TrendDateRange> = createSelector(
    DeviceProfileSelectors.selectDeviceProfileState,
    (state: DeviceProfileState) => state.trendDateRange ?? getDefaultTrendDate(24, ChronoUnit.HOURS),
  );

  /**
   * getThresholdsV2
   * @static
   * @type {MemoizedSelector<CoreAppState, EntityThresholds>}
   * @memberof DeviceProfileSelectors
   */
  public static getThresholdsV2: MemoizedSelector<CoreAppState, EntityThresholds> = createSelector(
    DeviceProfileSelectors.selectDeviceProfileState,
    (state: DeviceProfileState) => state.thresholdsV2,
  );

  /**
   * getPropertiesDeviceInfoResponse
   * @static
   * @type {MemoizedSelector<CoreAppState, CustomReportPreviewSearchResponse>}
   * @memberof DeviceProfileSelectors
   */
  public static getPropertiesDeviceInfoResponse: MemoizedSelector<CoreAppState, CustomReportPreviewSearchResponse> = createSelector(
    DeviceProfileSelectors.selectDeviceProfileState,
    (state: DeviceProfileState) => {
      return getPropertiesColumnMap(state.ediResponse, DevicesDashboardConfig.propertiesDeviceInfoMap[state.device.platform]);
    },
  );

  /**
   * getPropertiesHistoryInfoResponse
   * @static
   * @type {MemoizedSelector<CoreAppState, CustomReportPreviewSearchResponse>}
   * @memberof DeviceProfileSelectors
   */
  public static getPropertiesHistoryInfoResponse: MemoizedSelector<CoreAppState, CustomReportPreviewSearchResponse> = createSelector(
    DeviceProfileSelectors.selectDeviceProfileState,
    (state: DeviceProfileState) => {
      return getPropertiesColumnMap(state.ediResponse, DevicesDashboardConfig.propertiesHistoryInfoMap);
    },
  );

  /**
   * getPropertiesNetworkInfoResponse
   * @static
   * @type {MemoizedSelector<CoreAppState, CustomReportPreviewSearchResponse>}
   * @memberof DeviceProfileSelectors
   */
  public static getPropertiesNetworkInfoResponse: MemoizedSelector<CoreAppState, CustomReportPreviewSearchResponse> = createSelector(
    DeviceProfileSelectors.selectDeviceProfileState,
    (state: DeviceProfileState) => {
      return getPropertiesColumnMap(state.ediResponse, DevicesDashboardConfig.propertiesNetworkInfoMap[state.device.platform]);
    },
  );

  /**
   * getPropertiesBatteryInfoResponse
   * @static
   * @type {MemoizedSelector<CoreAppState, CustomReportPreviewSearchResponse>}
   * @memberof DeviceProfileSelectors
   */
  public static getPropertiesBatteryInfoResponse: MemoizedSelector<CoreAppState, CustomReportPreviewSearchResponse> = createSelector(
    DeviceProfileSelectors.selectDeviceProfileState,
    (state: DeviceProfileState) => {
      return getPropertiesColumnMap(state.ediResponse, DevicesDashboardConfig.propertiesBatteryInfoMap[state.device.platform]);
    },
  );

  /**
   * getPropertiesPreferencesInfoResponse
   * @static
   * @type {MemoizedSelector<CoreAppState, CustomReportPreviewSearchResponse>}
   * @memberof DeviceProfileSelectors
   */
  public static getPropertiesPreferencesInfoResponse: MemoizedSelector<CoreAppState, CustomReportPreviewSearchResponse> = createSelector(
    DeviceProfileSelectors.selectDeviceProfileState,
    (state: DeviceProfileState) => {
      return getPropertiesColumnMap(state.ediResponse, DevicesDashboardConfig.propertiesPreferencesInfoMap[state.device.platform]);
    },
  );

  /**
   * getProfileUserResponse
   * @static
   * @type {MemoizedSelector<CoreAppState, CustomReportPreviewSearchResponse>}
   * @memberof DeviceProfileSelectors
   */
  public static getProfileUserResponse: MemoizedSelector<CoreAppState, CustomReportPreviewSearchResponse> = createSelector(
    DeviceProfileSelectors.selectDeviceProfileState,
    (state: DeviceProfileState) => {
      return getPropertiesColumnMap(state.userPropertiesResponse, DevicesDashboardConfig.userPropertiesMap);
    },
  );

  /**
   * getProfileEdiResponse
   * @static
   * @type {MemoizedSelector<CoreAppState, CustomReportPreviewSearchResponse>}
   * @memberof DeviceProfileSelectors
   */
  public static getProfileEdiResponse: MemoizedSelector<CoreAppState, CustomReportPreviewSearchResponse> = createSelector(
    DeviceProfileSelectors.selectDeviceProfileState,
    (state: DeviceProfileState) => {
      const deviceInfoMap = state.device.isMobileDevice
        ? DevicesDashboardConfig.ediMobileDeviceInfoMap[state.device.platform]
        : DevicesDashboardConfig.ediDesktopDeviceInfoMap;
      return getPropertiesColumnMap(state.ediResponse, deviceInfoMap);
    },
  );

  /**
   * getProfileDeviceInfoResponse
   * @static
   * @type {MemoizedSelector<CoreAppState, CustomReportPreviewSearchResponse>}
   * @memberof DeviceProfileSelectors
   */
  public static getProfileDeviceInfoResponse: MemoizedSelector<CoreAppState, CustomReportPreviewSearchResponse> = createSelector(
    DeviceProfileSelectors.selectDeviceProfileState,
    (state: DeviceProfileState) => getPropertiesColumnMap(state.propertiesResponse, DevicesDashboardConfig.deviceInfoMap),
  );

  /**
   * getProfileDeviceSecurityResponse
   * @static
   * @type {MemoizedSelector<CoreAppState, CustomReportPreviewSearchResponse>}
   * @memberof DeviceProfileSelectors
   */
  public static getProfileDeviceSecurityResponse: MemoizedSelector<CoreAppState, CustomReportPreviewSearchResponse> = createSelector(
    DeviceProfileSelectors.selectDeviceProfileState,
    (state: DeviceProfileState) => getPropertiesColumnMap(state.propertiesResponse, DevicesDashboardConfig.deviceSecurityMap),
  );

  /**
   * getDeviceRiskScore
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof DeviceProfileSelectors
   */
  public static getDeviceRiskScore: MemoizedSelector<CoreAppState, string> = createSelector(
    DeviceProfileSelectors.selectDeviceProfileState,
    (state: DeviceProfileState) => state.riskScore,
  );

  /**
   * getDeviceEventsResponse
   * @static
   * @type {MemoizedSelector<CoreAppState, CustomReportPreviewSearchResponse>}
   * @memberof DeviceProfileSelectors
   */
  public static getDeviceEventsResponse: MemoizedSelector<CoreAppState, CustomReportPreviewSearchResponse> = createSelector(
    DeviceProfileSelectors.selectDeviceProfileState,
    (state: DeviceProfileState) => state.deviceEventsResponse,
  );

  /**
   * getDeviceEventsRequest
   * @static
   * @type {MemoizedSelector<CoreAppState, PreviewReportContentRequest>}
   * @memberof DeviceProfileSelectors
   */
  public static getDeviceEventsRequest: MemoizedSelector<CoreAppState, PreviewReportContentRequest> = createSelector(
    DeviceProfileSelectors.selectDeviceProfileState,
    (state: DeviceProfileState) => state.deviceEventsRequest,
  );

  /**
   * getDeviceCrumbListsIndex
   * @static
   * @param {CoreAppState} state
   * @type {MemoizedSelector<CoreAppState, GenericObject>}
   * @memberof DeviceProfileSelectors
   */
  public static getDeviceCrumbListsIndex: MemoizedSelector<CoreAppState, GenericObject> = createSelector(
    DeviceProfileSelectors.selectDeviceProfileState,
    (state: DeviceProfileState) => state.deviceCrumbListsIndex,
  );

  /**
   * getDeviceHealthStatus
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof DeviceProfileSelectors
   */
  public static getDeviceHealthStatus: MemoizedSelector<CoreAppState, string> = createSelector(
    DeviceProfileSelectors.selectDeviceProfileState,
    (state: DeviceProfileState) => state.deviceHealth || '',
  );

  /**
   * getDeviceHealthResponse
   * @static
   * @param {string} id
   * @type {MemoizedSelector<CoreAppState, CustomReportPreviewSearchResponse>}
   * @memberof DeviceProfileSelectors
   */
  public static getDeviceHealthResponse = (id: string): MemoizedSelector<CoreAppState, CustomReportPreviewSearchResponse> => {
    return createSelector(
      DeviceProfileSelectors.selectDeviceProfileState,
      (state: DeviceProfileState) => state.deviceHealthResponse?.[id] || undefined,
    );
  };

  /**
   * getDeviceProfileLinkForUEM
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof DeviceProfileSelectors
   */
  public static getDeviceProfileLinkForUEM: MemoizedSelector<CoreAppState, string> = createSelector(
    IntegratedServicesSelectors.entitledServices,
    DashboardSelectors.getStandardDashboardByType(StandardWidgetSubtype.DEVICES_PROFILE_UEM_DEVICE_ID),
    (services: IntegratedService[], trend: Trend) => {
      const id = trend?.trendResults?.[0]?.bucketingAttributes[0]?.value;
      const service = services.filter(
        (integratedService: IntegratedService) => integratedService.url && integratedService.type === IntegratedServiceType.WS1_UEM,
      );
      let url = '';
      if (service.length) {
        url = `${new URL(service[0].url).origin}${DashboardConfig.uemDeviceUrlPath}`;
      }
      return url && id ? `${url}${id}` : '';
    },
  );

  /**
   * getDeviceLatestUser
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof DeviceProfileSelectors
   */
  public static getDeviceLatestUser: MemoizedSelector<CoreAppState, string> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (standardDashboardData: Map<string, Trend>) => {
      return getBucketingAttributeValue(standardDashboardData.get(StandardWidgetSubtype.DEVICES_PROFILE_LOGON_USER), 0);
    },
  );

  /**
   * getDeviceLatestOS
   * @static
   * @type {MemoizedSelector<CoreAppState, string>}
   * @memberof DeviceProfileSelectors
   */
  public static getDeviceLatestOS: MemoizedSelector<CoreAppState, string> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (standardDashboardData: Map<string, Trend>) => {
      return getFirstCounterValue<string>(standardDashboardData.get(StandardWidgetSubtype.DEVICES_PROFILE_LATEST_OS));
    },
  );

  /**
   * getZoomSummaryResponse
   * @static
   * @type {MemoizedSelector<CoreAppState, CustomReportPreviewSearchResponse>}
   * @memberof DeviceProfileSelectors
   */
  public static getZoomSummaryResponse: MemoizedSelector<CoreAppState, CustomReportPreviewSearchResponse> = createSelector(
    DeviceProfileSelectors.selectDeviceProfileState,
    (state: DeviceProfileState) => state.performance.zoomSummaryResponse,
  );

  /**
   * getZoomSummaryRequest
   * @static
   * @type {MemoizedSelector<CoreAppState, PreviewReportContentRequest>}
   * @memberof DeviceProfileSelectors
   */
  public static getZoomSummaryRequest: MemoizedSelector<CoreAppState, PreviewReportContentRequest> = createSelector(
    DeviceProfileSelectors.selectDeviceProfileState,
    (state: DeviceProfileState) => state.performance.zoomSummaryRequest,
  );

  /**
   * getProfileInfoByWidgetSubtype
   * @static
   * @type {MemoizedSelector<CoreAppState, Array<string | number>>}
   * @memberof DeviceProfileSelectors
   */
  public static getProfileInfoByWidgetSubtype = (
    widgetSubtype: StandardWidgetSubtype,
    name: string,
    type: DataDecorator,
    bucketIndex: number = 0,
  ): MemoizedSelector<CoreAppState, Array<string | number>> =>
    createSelector(DashboardSelectors.getStandardDashboardData, (trendsByWidgetSubtype: Map<string, Trend>) => {
      const trend = trendsByWidgetSubtype.get(widgetSubtype);
      const value = helpers.getBucketingAttributeValue(trend, bucketIndex) ?? helpers.getFirstCounterValue(trend);
      const formatted = getFormattedValue(value, type);
      return [name, formatted, value, formatted === 'COMMON_MESSAGES.UNKNOWN_VALUE' ? DataDecorator.STRING : type];
    });

  /**
   * getDeviceProfileHygiene
   * @static
   * @type {MemoizedSelector<CoreAppState, PropertyValuePair[]>}
   * @memberof DeviceProfileSelectors
   */
  public static getDeviceProfileHygiene: MemoizedSelector<CoreAppState, PropertyValuePair[]> = createSelector(
    DeviceProfileSelectors.getProfileInfoByWidgetSubtype(
      StandardWidgetSubtype.DEVICES_PROFILE_BOOT_EVENT,
      'STANDARD_DASHBOARD.DEVICES_DETAILS.LAST_BOOT_EVENT',
      DataDecorator.DATETIME,
    ),
    DeviceProfileSelectors.getProfileInfoByWidgetSubtype(
      StandardWidgetSubtype.DEVICES_PROFILE_WINDOWS_OS_UPDATE,
      'STANDARD_DASHBOARD.DEVICES_DETAILS.LAST_OS_UPDATE_DATE',
      DataDecorator.DATETIME,
    ),
    DeviceProfileSelectors.getProfileInfoByWidgetSubtype(
      StandardWidgetSubtype.DEVICES_PROFILE_ENROLLMENT_DATE,
      'STANDARD_DASHBOARD.DEVICES_DETAILS.ENROLLMENT_DATE',
      DataDecorator.DATETIME,
    ),
    DeviceProfileSelectors.getProfileInfoByWidgetSubtype(
      StandardWidgetSubtype.DEVICES_PROFILE_WINDOWS_OS_PENDING,
      'STANDARD_DASHBOARD.DEVICES_DETAILS.PENDING_OS_UPDATE',
      DataDecorator.DATETIME,
    ),
    DeviceProfileSelectors.getProfileInfoByWidgetSubtype(
      StandardWidgetSubtype.DEVICES_PROFILE_LOGON_USER,
      'STANDARD_DASHBOARD.DEVICES_DETAILS.LAST_LOGON_USER',
      DataDecorator.STRING,
    ),
    DeviceProfileSelectors.getProfileInfoByWidgetSubtype(
      StandardWidgetSubtype.DEVICES_PROFILE_LOGICAL_SPACE,
      'STANDARD_DASHBOARD.DEVICES_DETAILS.AVAILABLE_LOGICAL_DISK_SPACE',
      DataDecorator.SIZE_BYTE,
    ),
    DeviceProfileSelectors.getProfileInfoByWidgetSubtype(
      StandardWidgetSubtype.DEVICES_PROFILE_USER_LOGON,
      'STANDARD_DASHBOARD.DEVICES_DETAILS.LAST_LOGON_DATE',
      DataDecorator.DATETIME,
    ),
    DeviceProfileSelectors.getProfileInfoByWidgetSubtype(
      StandardWidgetSubtype.DEVICES_PROFILE_LOGICAL_SPACE,
      'STANDARD_DASHBOARD.DEVICES_DETAILS.AVAILABLE_LOGICAL_DISK_SPACE_PERCENT',
      DataDecorator.STRING_PERCENT,
      1,
    ),
    (
      bootEvent: Array<string | number>,
      osWindowsUpdateTime: Array<string | number>,
      enrollmentDate: Array<string | number>,
      osWindowsPendingTime: Array<string | number>,
      logonUser: Array<string | number>,
      diskSpace: Array<string | number>,
      logonTime: Array<string | number>,
      diskSpacePercent: Array<string | number>,
    ) => {
      return convertDeviceHygieneToAttrObject([
        bootEvent,
        osWindowsUpdateTime,
        enrollmentDate,
        osWindowsPendingTime,
        logonUser,
        diskSpace,
        logonTime,
        diskSpacePercent,
      ]);
    },
  );

  /**
   * getDeviceAppCrash
   * @static
   * @type {MemoizedSelector<CoreAppState, ComparisonQueryResponse>}
   * @memberof DevicesDashboardSelectors
   */
  public static getDeviceAppCrash: MemoizedSelector<CoreAppState, ComparisonQueryResponse> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (trendsByWidgetSubtype: Map<string, Trend>) => {
      return helpers.getOffsetCounterData(trendsByWidgetSubtype, null, StandardWidgetSubtype.DEVICES_DETAILS_APP_CRASH_COUNT);
    },
  );

  /**
   * getDeviceOsCrash
   * @static
   * @type {MemoizedSelector<CoreAppState, ComparisonQueryResponse>}
   * @memberof DevicesDashboardSelectors
   */
  public static getDeviceOsCrash: MemoizedSelector<CoreAppState, ComparisonQueryResponse> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (trendsByWidgetSubtype: Map<string, Trend>) => {
      return helpers.getOffsetCounterData(trendsByWidgetSubtype, null, StandardWidgetSubtype.DEVICES_DETAILS_OS_CRASH_COUNT);
    },
  );

  /**
   * isDeviceProfilePanelVisible
   * @static
   * @type {MemoizedSelector<CoreAppState, boolean>}
   * @memberof DeviceProfileSelectors
   */
  public static isDeviceProfilePanelVisible: MemoizedSelector<CoreAppState, boolean> = createSelector(
    DeviceProfileSelectors.selectDeviceProfileState,
    (state: DeviceProfileState) => state.isSlideOverVisible,
  );
}
