<div class="clr-row clr-align-items-center">
  <dpa-local-search
    [placeHolderText]="'COMMON_ACTIONS.SEARCH' | translate"
    (onSearch)="onSearchQueryChange($event)"
    iconClass="search"
    class="clr-col"
  ></dpa-local-search>
  <dpa-type-ahead
    *ngIf="selectedLayout === LayoutType.CARD_VIEW"
    [selectedItem]="selectedSortOn"
    [placeholderText]="'WIDGET_CUSTOMIZE.WIDGET_CHART_ORDER_BY' | translate"
    [searchableItems]="sortByList"
    [customFormatter]="sortByFormatter"
    [labelFormatter]="sortByFormatter"
    (selectedItemChange)="onSortByChange($event)"
    class="clr-col-lg-2 clr-col-md-3"
  ></dpa-type-ahead>
</div>
<div class="mt-x4 clr-row clr-align-items-center">
  <dpa-marketplace-filter-tags
    [selectedTags]="selectedTags"
    [facetCounts]="response?.facetCountsByType[FacetType.TAGS]"
    (filterChange)="onFilterTagChange($event)"
    class="clr-col"
  ></dpa-marketplace-filter-tags>
  <dpa-multi-layout-switcher
    *ngIf="availableLayouts.length > 1"
    [updateParams]="false"
    [availableLayouts]="availableLayouts"
    [selectedLayout]="selectedLayout"
    (selectedLayoutChange)="selectedLayout = $event"
  ></dpa-multi-layout-switcher>
</div>
<ng-container *ngIf="selectedLayout === LayoutType.CARD_VIEW; else listView">
  <div
    *ngIf="isLoading$ | async; else cards"
    class="spinner-container-centered mt-x4"
  >
    <div class="spinner"></div>
  </div>
  <ng-template #cards>
    <div
      *ngIf="response?.searchResults?.results?.length; else noResults"
      class="clr-row"
    >
      <dpa-card
        *ngFor="let resource of response?.searchResults.results; trackBy: trackByMarketplaceResourceId"
        [header]="resource?.name"
        [description]="resource?.description"
        [hasCustomLabel]="!!resource.customTagText"
        class="clr-col-xl-4 clr-col-lg-4 clr-col-md-6"
      >
        <dpa-custom-tag
          *ngIf="resource.customTagText"
          [bgColorClass]="AppConfig.MARKETPLACE_RESOURCE_TAG_BG_COLOR_CLASS"
          [text]="resource.customTagText"
          class="ml-x1"
        ></dpa-custom-tag>
        <dpa-card-block>
          <dpa-card-block-row>
            <ng-template
              [ngTemplateOutlet]="tags"
              [ngTemplateOutletContext]="{ resource }"
            ></ng-template>
          </dpa-card-block-row>
        </dpa-card-block>
        <dpa-card-block>
          <dpa-card-block-row *ngIf="resource?.modifiedAt">
            <dpa-marketplace-resource-metadata [resource]="resource"></dpa-marketplace-resource-metadata>
          </dpa-card-block-row>
        </dpa-card-block>
        <dpa-card-footer>
          <dpa-card-footer-button>
            <button
              (click)="onPreviewResource(resource)"
              class="btn btn-link mr-2"
            >
              {{ 'COMMON_ACTIONS.PREVIEW' | translate }}
            </button>
            <button
              *ngIf="resource.showDeployButton"
              [disabled]="isDeployDisabledForScriptAndSensor$ | async"
              (click)="onSelectResource(resource, PendoEventMarketplaceTemplatesSelectedSource.DEPLOY)"
              class="btn btn-link mr-2"
            >
              {{ 'COMMON_ACTIONS.DEPLOY' | translate }}
            </button>
            <button
              *ngIf="resource.showAddToWorkspaceButton"
              [disabled]="!isAddToWorkspaceEnabled(resource)"
              (click)="onSelectResource(resource, PendoEventMarketplaceTemplatesSelectedSource.ADD_TO_WORKSPACE)"
              class="btn btn-link mr-2"
            >
              {{ 'MARKETPLACE.ADD_TO_WORKSPACE' | translate }}
            </button>
            <button
              *ngIf="resource.showDownloadButton"
              (click)="onSelectResource(resource, PendoEventMarketplaceTemplatesSelectedSource.DOWNLOAD)"
              class="btn btn-link"
            >
              {{ 'COMMON_ACTIONS.DOWNLOAD' | translate }}
            </button>
          </dpa-card-footer-button>
        </dpa-card-footer>
      </dpa-card>
    </div>
    <ng-template #noResults>
      <dpa-no-search-results
        [message]="'COMMON_MESSAGES.NO_DATA_AVAILABLE' | translate"
        class="no-search-results"
      ></dpa-no-search-results>
    </ng-template>
  </ng-template>
  <dpa-data-grid
    [footerMode]="true"
    [enableColumnSelector]="false"
    [loading]="false"
    [pageSize]="response?.searchResults.size"
    [response]="response?.searchResults"
    [noResultsText]="'COMMON_MESSAGES.NO_RESULTS_FOUND' | translate"
    (pageChange)="onPageChange($event)"
    (refreshClick)="onRefresh()"
  ></dpa-data-grid>
</ng-container>
<ng-template #listView>
  <div class="mt-x4">
    <dpa-data-grid
      [loading]="isLoading$ | async"
      [response]="response?.searchResults"
      [sortOns]="request?.sortOns"
      [selectable]="{ single: false, enabled: false }"
      [pageSize]="response?.searchResults.size"
      [columnWidthByKey]="columnWidthByKey"
      [noResultsText]="'COMMON_MESSAGES.NO_DATA_AVAILABLE' | translate"
      (pageChange)="onPageChange($event)"
      (sortChange)="onSortChange($event)"
      class="valign-cells"
    >
      <div
        dpaDataGridSingleRowAction
        [hidden]="isActionHidden(MarketplaceResourceActionType.ADD_TO_WORKSPACE)"
        [disabled]="isAddToWorkspaceActionDisabled()"
        [label]="'MARKETPLACE.ADD_TO_WORKSPACE' | translate"
        (click)="onSelectResource($event, PendoEventMarketplaceTemplatesSelectedSource.ADD_TO_WORKSPACE)"
        (keyup.enter)="onSelectResource($event, PendoEventMarketplaceTemplatesSelectedSource.ADD_TO_WORKSPACE)"
      ></div>
      <div
        dpaDataGridSingleRowAction
        [hidden]="isActionHidden(MarketplaceResourceActionType.DEPLOY)"
        [disabled]="isDeployDisabledForScriptAndSensor$ | async"
        [label]="'COMMON_ACTIONS.DEPLOY' | translate"
        (click)="onSelectResource($event, PendoEventMarketplaceTemplatesSelectedSource.DEPLOY)"
        (keyup.enter)="onSelectResource($event, PendoEventMarketplaceTemplatesSelectedSource.DEPLOY)"
      ></div>
      <div
        dpaDataGridSingleRowAction
        [hidden]="isActionHidden(MarketplaceResourceActionType.DOWNLOAD)"
        [label]="'COMMON_ACTIONS.DOWNLOAD' | translate"
        (click)="onSelectResource($event, PendoEventMarketplaceTemplatesSelectedSource.DOWNLOAD)"
        (keyup.enter)="onSelectResource($event, PendoEventMarketplaceTemplatesSelectedSource.DOWNLOAD)"
      ></div>
      <div
        dpaDataGridColumn
        [title]="'COMMON_MESSAGES.NAME' | translate"
        [field]="COLUMN_NAMES.byField.name"
        [sortBy]="COLUMN_NAMES.byName.name"
      >
        <ng-template
          dpaDataGridCell
          let-dataItem
        >
          <a
            (click)="onPreviewResource(dataItem)"
            (keyup.enter)="onPreviewResource(dataItem)"
            class="link-normal"
          >
            {{ dataItem?.name }}
          </a>
          <dpa-custom-tag
            *ngIf="dataItem.customTagText"
            [bgColorClass]="AppConfig.MARKETPLACE_RESOURCE_TAG_BG_COLOR_CLASS"
            [text]="dataItem.customTagText"
          ></dpa-custom-tag>
        </ng-template>
      </div>
      <div
        *ngIf="showUsageCountColumn"
        dpaDataGridColumn
        [title]="'MARKETPLACE.USED' | translate"
        [field]="usageCountSortByField"
        [sortBy]="usageCountSortByField"
      >
        <ng-template
          dpaDataGridCell
          let-dataItem
        >
          {{ dataItem?.usageCount | number }}
        </ng-template>
      </div>
      <div
        dpaDataGridColumn
        [title]="'COMMON_MESSAGES.DESCRIPTION' | translate"
        [field]="COLUMN_NAMES.byField.description"
      >
        <ng-template
          dpaDataGridCell
          let-dataItem
        >
          {{ dataItem?.description }}
        </ng-template>
      </div>
      <ng-container *ngIf="resourceTypes.length === 1">
        <div
          *ngIf="resourceTypes.includes(MarketplaceResourceType.DASHBOARD_TEMPLATE)"
          dpaDataGridColumn
          [title]="'COMMON_MESSAGES.WIDGETS' | translate"
          [field]="COLUMN_NAMES.byField.totalWidgets"
        >
          <ng-template
            dpaDataGridCell
            let-dataItem
          >
            {{ dataItem?.metadataByProperty[MarketplaceResourceProperty.WIDGET_COUNT] }}
          </ng-template>
        </div>
        <div
          *ngIf="resourceTypes.includes(MarketplaceResourceType.WIDGET_TEMPLATE)"
          dpaDataGridColumn
          [title]="'WIDGET_CUSTOMIZE.WIDGET_CHART_TYPE' | translate"
          [field]="COLUMN_NAMES.byField.chartType"
        >
          <ng-template
            dpaDataGridCell
            let-dataItem
          >
            <dpa-chart-type [chartType]="dataItem?.metadataByProperty[MarketplaceResourceProperty.CHART_TYPE]"></dpa-chart-type>
          </ng-template>
        </div>
      </ng-container>
      <div
        dpaDataGridColumn
        [title]="'COMMON_MESSAGES.TAGS' | translate"
        [field]="COLUMN_NAMES.byName.tags"
      >
        <ng-template
          dpaDataGridCell
          let-dataItem
        >
          <ng-template
            [ngTemplateOutlet]="tags"
            [ngTemplateOutletContext]="{ resource: dataItem }"
          ></ng-template>
        </ng-template>
      </div>
    </dpa-data-grid>
  </div>
</ng-template>
<ng-template
  #tags
  let-resource="resource"
>
  <span
    *ngIf="resource?.flatTags.length === 1; else multipleTags"
    class="label"
  >
    {{ resource?.flatTags[0] }}
  </span>
  <ng-template #multipleTags>
    <clr-signpost>
      <span clrSignpostTrigger>
        <span class="label clickable">{{ 'WIDGET_TEMPLATE.NUM_OF_TAGS' | translate : { num: resource?.flatTags.length } }}</span>
      </span>
      <clr-signpost-content
        *clrIfOpen
        [clrPosition]="SIGNPOST_POSITION.TOP_MIDDLE"
      >
        <div *ngFor="let tag of resource?.flatTags; trackBy: trackByIndex">
          <span class="label">{{ tag }}</span>
        </div>
      </clr-signpost-content>
    </clr-signpost>
  </ng-template>
</ng-template>
